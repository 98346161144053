import React, { useEffect } from 'react'
import axios from 'axios'
import GeoRasterLayer from 'georaster-layer-for-leaflet'
import parse_georaster from 'georaster'
import {
  createPlanetRequest,
  updateFarmDetails,
  updatePlanetRequest,
} from '../../graphql/mutations'
import { API, graphqlOperation } from 'aws-amplify'
import { toast } from 'react-toastify'
import { use } from 'i18next'
import { useTranslation } from 'react-i18next'

const ConfirmationToast = ({ message, onConfirm }) => {
   const { t } = useTranslation()
  const handleConfirm = () => {
    toast.dismiss() // Close the toast
    onConfirm() // Call the confirmation function
  }

  return (
    <div>
      {message}
      <button onClick={handleConfirm}>{t('confirm')}</button>
    </div>
  )
}

const FetchCropBiomass = ({
  coordinates,
  farmId,
  farm,
  planetRequestId,
  cloudCover,
  startDatem,
  farmName,
  setUpdates,
  quotaRestriction,
}) => {
  const { t } = useTranslation()
  const [isSubscribed, setIsSubscribed] = React.useState(false)
  const [accessToken, setAccessToken] = React.useState('eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJ3dE9hV1o2aFJJeUowbGlsYXctcWd4NzlUdm1hX3ZKZlNuMW1WNm5HX0tVIn0.eyJleHAiOjE3MDQyMjc5NzIsImlhdCI6MTcwNDIyNDM3MiwianRpIjoiOWNiYWY5MjQtNTEwZS00NjNkLTg5YTYtMGYwZmRiMWM4NWQ3IiwiaXNzIjoiaHR0cHM6Ly9zZXJ2aWNlcy5zZW50aW5lbC1odWIuY29tL2F1dGgvcmVhbG1zL21haW4iLCJzdWIiOiJhMzA0YzFlOC05MTg2LTQ1NmYtODI5NS0xYWU3NzM4M2EyY2MiLCJ0eXAiOiJCZWFyZXIiLCJhenAiOiI5YjBlMzk1My1iZTQyLTRlMzAtODU0OS01Y2JlOTFiZTBlNWYiLCJzY29wZSI6ImVtYWlsIHByb2ZpbGUiLCJjbGllbnRJZCI6IjliMGUzOTUzLWJlNDItNGUzMC04NTQ5LTVjYmU5MWJlMGU1ZiIsImVtYWlsX3ZlcmlmaWVkIjpmYWxzZSwiY2xpZW50SG9zdCI6IjEwMi4wLjUuMTMyIiwicHJlZmVycmVkX3VzZXJuYW1lIjoic2VydmljZS1hY2NvdW50LTliMGUzOTUzLWJlNDItNGUzMC04NTQ5LTVjYmU5MWJlMGU1ZiIsImNsaWVudEFkZHJlc3MiOiIxMDIuMC41LjEzMiIsImFjY291bnQiOiI5YzllOTY4NS0yZjFjLTQ1OTUtODJmYy01YjI0MGIwZmM2NDgifQ.Ku6JUtrhfJN4MwcmgLzxR4PnqXSLWU-1H2_TuW3e_jvRasMFIYjrXMkPf1qWar2-Xvezs6xRHavcR8ev-V_TAowFoDktMXne3upXf61ActJfpXIyk3YDUIT1mO2EEpU9zJKRAtlTvh3115UWzgEvIMvy0KzgPH3DZFN_JKIXBEaiB1lWD_ZKcvIZKZos4PW89uhu1ECdP1k-nglPDO9nHX3ZVRjJlxEYA90qzhCzpiQ21v2_sMnJKKCju63HSKoP0VwTz4bg-X7Am9djy-EGlWFV6F28z70AS5Rd8zl0zLuzaT8F8OFVSewkCHlZsGq7NrYh39K11g4V21ZrYQ43lw')

  const [newCoordinates, setNewCoordinates] = React.useState([])

  //convert cordinates to object

//   const convertToNewFormat = () => {
//     const formattedCoordinates = JSON.parse(coordinates).map((item) => {
//       const { latitude, longitude } = item
//       return [latitude, longitude]
//     })
//     setNewCoordinates(formattedCoordinates)
//   }

//   console.log(newCoordinates)

//   React.useEffect(() => {
//     convertToNewFormat()
//     const token = localStorage.getItem('token')
//     const tokenTimestamp = localStorage.getItem('tokenTimestamp')
//     const currentTime = new Date().getTime().toString()

//     if (token && tokenTimestamp && currentTime - tokenTimestamp < 3600000) {
//       setAccessToken(token)
//     } else {
//       fetchToken()
//     }
//   }, [])

  const fetchToken = async () => {
    const url = 'https://services.sentinel-hub.com/oauth/token'
    const data = {
        client_id: '9b0e3953-be42-4e30-8549-5cbe91be0e5f',
        client_secret: '1PVB3NnfqQjBzk5ychmbJRJMoROpURBj',
      // client_id: '2de95318-4dd3-4799-b109-9c9917e5ca15',
      // client_secret: 'bOO(j*Ll?(p&Wa8/8omTc#N7[LEwlzo49mR,S<tx',
      grant_type: 'client_credentials',
    }
    const options = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }

    try {
      const response = await axios.post(url, data, options)
      const newToken = response.data.access_token
      const newTokenTimestamp = new Date().getTime().toString()

      setAccessToken(newToken)
      localStorage.setItem('token', newToken)
      localStorage.setItem('tokenTimestamp', newTokenTimestamp)
    } catch (error) {
      console.error('Failed to fetch token', error)
    }
  }

  const planetScopeApiKey = 'PLAK623cadf538df4cd79b21ae7266b7f325'
  // Button component
  const handleClick = async () => {
    if (quotaRestriction >= 100) {
      toast.error(t('toastMessages.quotaLimitReached'))
      return
    }
    try {
      const response = await subscribePlanetScopeData(
        accessToken,
        planetScopeApiKey
      )
      const subscriptionId = response.data.id
      const quota = response.data.sqkm

      toast.info(
        <ConfirmationToast
          message={t('toastMessages1.confirmSubscription', { farmName, quota })}
          onConfirm={async () => {
            await confirmSubscription(accessToken, subscriptionId)
          }}
        />,
        {
          position: toast.POSITION.TOP_CENTER,
          autoClose: false,
        }
      )
    } catch (error) {
      console.error(error)
    }
  }

  const subscribePlanetScopeData = async (accessToken, planetScopeApiKey) => {
    const url =
      'https://services.sentinel-hub.com/api/v1/dataimport/subscriptions'

    const data = {
      name: 'Soil water content',
      input: {
        planetApiKey: 'PLAK623cadf538df4cd79b21ae7266b7f325',
        provider: 'PLANET',
        bounds: {
          geometry: {
            type: 'Polygon',
            coordinates: [
              [
                [26.318226777781327, 44.105634049099756],
                [26.316772424594447, 44.107765430107065],
                [26.318798982807678, 44.10851011136262],
                [26.320134126996052, 44.1063701974891],
                [26.318226777781327, 44.105634049099756]
              ]
            ],
          },
        },
        data: [
          {
           
            dataFilter: {
              timeRange: {
                from: '2023-09-22T00:00:00Z',
                to: '2023-12-22T23:59:59Z',
              },
              maxCloudCoverage: 5,
            },
            type: "soil_water_content",
            id: "SWC-AMSR2-C_V1.0_100"
          },
        ],
      },
    }

    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    }

    try {
      const response = await axios.post(url, data, options)
      console.log(response.data)
      setIsSubscribed(true)
      return response
    } catch (error) {
      console.error(error)
    }
  }

  const confirmSubscription = async (accessToken, subscriptionId) => {
    const url = `https://services.sentinel-hub.com/api/v1/dataimport/subscriptions/${subscriptionId}/confirm`

    const options = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }

    try {
      const response = await axios.post(url, {}, options)
      console.log(response.data)
      //update the collection id in the database
      await API.graphql(
        graphqlOperation(updateFarmDetails, {
          input: {
            id: farmId,
            byoc: response.data.collectionId,
            subscriptionID: response.data.id,
          },
        })
      )
      toast.success(t('toastMessages2.subscriptionConfirmed'))
      await API.graphql(
        graphqlOperation(updatePlanetRequest, {
          input: { id: planetRequestId, status: 'approved' },
        })
      )
      //reload the page
      setUpdates(true)
      // window.location.reload();
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <button className="package-button" onClick={handleClick}>
      approve
    </button>
  )
}

export default FetchCropBiomass
