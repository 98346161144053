import React from 'react';
import Popup from 'reactjs-popup';
import './profile.css';
import FarmerTopBar from '../../components/topbar/Topbar';
import { Link } from 'react-router-dom';
import { TiWeatherStormy } from 'react-icons/ti';
import { GiFarmTractor } from 'react-icons/gi';
import { CiImageOn } from 'react-icons/ci';
import { MdOutlineSatelliteAlt } from 'react-icons/md';
import { GiDeliveryDrone } from 'react-icons/gi';
import { CiLocationOn } from 'react-icons/ci';
import { FaCcVisa, FaCcPaypal } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { API, graphqlOperation } from 'aws-amplify';
import { listFarmers } from '../../../graphql/queries';
import { updateFarmer } from '../../../graphql/mutations';
import CardDetailsPopup from './CardDetailsPopup';
import SelectedPlan from './SelectedPlan';
import { useTranslation } from 'react-i18next'

const Profile = () => {
  const userName = localStorage.getItem('farmerName');
  const userEmail = localStorage.getItem('farmerEmail');
  const phoneNumber = localStorage.getItem('phoneNumber');
  const [showEditProfile, setShowEditProfile] = React.useState(false);
  const [profileDetails, setProfileDetails] = React.useState({ });
  const [change, setChange] = React.useState(false);
  const [phone, setPhone] = React.useState('');
  const [billingAddress, setBillingAddress] = React.useState('');
  const [city, setCity] = React.useState('');
  const [county, setCounty] = React.useState('');
  const [country, setCountry] = React.useState('');
  const [code, setCode] = React.useState('');
  const [company, setCompany] = React.useState('');
  const [selectedCountry, setSelectedCountry] = React.useState('Romania');
  const [selectedCounty, setSelectedCounty] = React.useState('');
  const [selectedCity, setSelectedCity] = React.useState('');
  
  const { t } = useTranslation()


  React.useEffect(() => {
    fetchProfileDetails();
  }, [change]);
  //fetch profile  details
  const fetchProfileDetails = async () => {
   
    try {
      const profileDetails = await API.graphql(graphqlOperation(listFarmers, {
          filter: {
            email: {
                eq: userEmail,
            },
        },
        }));

        const profileDetailsData = profileDetails.data.listFarmers.items;
        setProfileDetails(profileDetailsData);
        console.log(profileDetailsData);
    } catch (error) {
      console.log(error);
    }
  };
  
  const handleUpdate = async (id) => {
      // Check if the phone number starts with the Kenyan country code (+254)
  let formattedPhoneNumber = phone;
  if (!phone.startsWith('+40')) {
    formattedPhoneNumber = '+40' + phone;
  }
    try {
      await API.graphql(graphqlOperation(updateFarmer, {
        input: {
          id: id,
          phone:  formattedPhoneNumber,
          billingAddress: billingAddress,
          city: selectedCity,
          county: selectedCounty,
          country: selectedCountry,
          postalCode: code,
          company: company,
        }
      }
      ))
      setChange(!change);
      setShowEditProfile(!showEditProfile);
      localStorage.setItem('phoneNumber', '+40721234569');

      toast.success(t('profileUpdated'));
    } catch (error) {
      console.log(error);
      }
  }

  const services = [
    {
      id: 1,
      name: 'Landsat',
      icon: <MdOutlineSatelliteAlt />,
    },
    {
      id: 2,
      name: 'Sentinel 1',
      icon: <CiLocationOn />,
    },
    {
      id: 3,
      name: 'Drone services',
      icon: <GiDeliveryDrone />,
    },
  ];

  //fetch the package object from the local storage
  const packageObject = JSON.parse(localStorage.getItem('packageObject'));

  const handleCardDetailsSubmit = (cardNumber, expirationDate, cvv) => {
    // Perform card details validation and submission logic here
    console.log('Card Number:', cardNumber);
    console.log('Expiration Date:', expirationDate);
    console.log('CVV:', cvv);
  };

  const handleEditProfile =()=>{
     setShowEditProfile(!showEditProfile);
     setPhone(profileDetails[0].phone);
      setBillingAddress(profileDetails[0].billingAddress);
      setSelectedCity(profileDetails[0].city);
      setSelectedCounty(profileDetails[0].county);
      setCountry(profileDetails[0].country);
      setCode(profileDetails[0].postalCode);
      
  }

  const countries = ['Romania'];

  const countiesData = {
    Romania: {
      counties: [
        'Select County',
        'Alba',
        'Arad',
        'Arges',
        'Bacau',
        'Bihor',
        'Bistrita-Nasaud',
        'Botosani',
        'Braila',
        'Brasov',
        'Bucuresti',
        'Buzau',
        'Calarasi',
        'Caras-Severin',
        'Cluj',
        'Constanta',
        'Covasna',
        'Dambovita',
        'Dolj',
        'Galati',
        'Giurgiu',
        'Gorj',
        'Harghita',
        'Hunedoara',
        'Ialomita',
        'Iasi',
        'Ilfov',
        'Maramures',
        'Mehedinti',
        'Mures',
        'Neamt',
        'Olt',
        'Prahova',
        'Salaj',
        'Satu Mare',
        'Sibiu',
        'Suceava',
        'Teleorman',
        'Timis',
        'Tulcea',
        'Vaslui',
        'Vrancea',
      ],
      cities: {
        'Select County': ['Select City'],
        Alba: ['Select City', 'Alba Iulia', 'Sebes', 'Aiud'],
        Arad: ['Select City', 'Arad', 'Ineu', 'Lipova'],
        Arges: ['Select City', 'Pitesti', 'Curtea de Arges', 'Mioveni'],
        Bacau: ['Select City', 'Bacau', 'Onesti', 'Moinesti'],
        Bihor: ['Select City', 'Oradea', 'Salonta', 'Beius'],
        Bihor: ['Select City', 'Oradea', 'Salonta', 'Beius', 'Marghita', 'Stei'],
        BistritaNasaud: ['Select City', 'Bistrita', 'Nasaud'],
        Botosani: ['Select City', 'Botosani', 'Dorohoi', 'Bucecea'],
        Braila: ['Select City', 'Braila', 'Ianca', 'Insuratei'],
        Brasov: ['Select City', 'Brasov', 'Rasnov', 'Sacele', 'Codlea'],
        Bucuresti: ['Select City', 'Bucuresti'],
        Buzau: ['Select City', 'Buzau', 'Ramnicu Sarat', 'Nehoiu'],
        Calarasi: ['Select City', 'Calarasi', 'Oltenita', 'Fundulea'],
        CarasSeverin: ['Select City', 'Resita', 'Oravita', 'Caransebes'],
        Cluj: ['Select City', 'Cluj-Napoca', 'Turda', 'Dej'],
        Constanta: ['Select City', 'Constanta', 'Mangalia', 'Medgidia'],
        Covasna: ['Select City', 'Sfantu Gheorghe', 'Targu Secuiesc', 'Covasna'],
        Dambovita: ['Select City', 'Targoviste', 'Moreni', 'Gaesti'],
        Dolj: ['Select City', 'Craiova', 'Bailesti', 'Calafat'],
        Galati: ['Select City', 'Galati', 'Braila', 'Tecuci'],
        Giurgiu: ['Select City', 'Giurgiu', 'Bolintin-Vale', 'Mihai Viteazu'],
        Gorj: ['Select City', 'Targu Jiu', 'Motru', 'Rovinari'],
        Harghita: ['Select City', 'Miercurea Ciuc', 'Odorheiu Secuiesc', 'Cristuru Secuiesc'],
        Hunedoara: ['Select City', 'Deva', 'Orastie', 'Hateg'],
        Ialomita: ['Select City', 'Slobozia', 'Fetesti', 'Urziceni'],
        Iasi: ['Select City', 'Iasi', 'Pascani', 'Harlau'],
        Ilfov: ['Select City', 'Buftea', 'Popesti-Leordeni', 'Voluntari'],
        Maramures: ['Select City', 'Baia Mare', 'Sighetu Marmatiei', 'Viseu de Sus'],
        Mehedinti: ['Select City', 'Drobeta-Turnu Severin', 'Orsova', 'Strehaia'],
        Mures: ['Select City', 'Targu Mures', 'Sighisoara', 'Reghin'],
        Neamt: ['Select City', 'Piatra Neamt', 'Roman', 'Targu Neamt'],
        Olt: ['Select City', 'Slatina', 'Caracal', 'Corabia'],
        Prahova: ['Select City', 'Ploiesti', 'Baicoi', 'Breaza'],
        Salaj: ['Select City', 'Zalau', 'Simleu Silvaniei', 'Jibou'],
        SatuMare: ['Select City', 'Satu Mare', 'Carei', 'Negresti-Oas'],
        Sibiu: ['Select City', 'Sibiu', 'Medias', 'Cisnadie'],
        Suceava: ['Select City', 'Suceava', 'Falticeni', 'Radauti'],
        Teleorman: ['Select City', 'Alexandria', 'Rosiorii de Vede', 'Turnu Magurele'],
        Timis: ['Select City', 'Timisoara', 'Lugoj', 'Jimbolia'],
        Tulcea: ['Select City', 'Tulcea', 'Macin', 'Babadag'],
        Vaslui: ['Select City', 'Vaslui', 'Barlad', 'Husi'],
        Vrancea: ['Select City', 'Focsani', 'Adjud', 'Marasesti'],
      },
    },
  };

  const handleCountryChange = (event) => {
    const country = event.target.value;
    setSelectedCountry(country);
    setSelectedCounty('');
    setSelectedCity('');
  };

  const handleCountyChange = (event) => {
    const county = event.target.value;
    setSelectedCounty(county);
    setSelectedCity('');
    console.log(countiesData[selectedCountry]?.cities[county]); // Log the cities for the selected county
};

  const handleCityChange = (event) => {
    const city = event.target.value;
    setSelectedCity(city);
  };

  return (
    <div className="home-container">
      <FarmerTopBar />

      <h2 className='ml-2'>{t('welcome to your account')}, {userName}</h2>

      <div className="profile-container">
        <section className="profile-details">
        <SelectedPlan />
      
        </section>

        <section className="profile-names">
          <div className="profile-image">
          <img src={'/Logo/PNG/profile_pic.png'} alt="profile" className="icon"/>
          </div>
          {
            profileDetails.length > 0 && (
              <>

                <div className="profile-info">
                  <h3>{profileDetails[0].firstName}</h3>
                </div>
                <div className="profile-email">
                  <p>{profileDetails[0].email}</p>
                </div>
                <div className="profile-phone">
  <table>
    <tbody>
      <tr>
        <td><strong>{t('phone')}:</strong></td>
        <td>{profileDetails[0].phone}</td>
      </tr>
      <tr>
        <td><strong>{t('billing Address')}:</strong></td>
        <td>{profileDetails[0].billingAddress}</td>
      </tr>
      <tr>
        <td><strong>{t('city')}:</strong></td>
        <td>{profileDetails[0].city}</td>
      </tr>
      <tr>
        <td><strong>{t('county')}:</strong></td>
        <td>{profileDetails[0].county}</td>
      </tr>
      <tr>
        <td><strong>{t('countryForm')}:</strong></td>
        <td>{profileDetails[0].country}</td>
      </tr>
      <tr>
        <td><strong>{t('postal Code')}:</strong></td>
        <td>{profileDetails[0].postalCode}</td>
      </tr>
      <tr>
        <td><strong>{t('companyForm')}:</strong></td>
        <td>{profileDetails[0].company}</td>
      </tr>
    </tbody>
  </table>
</div>

              </>
            )
          }
          <section className="profile-buttons">
          <button to="subscription" className="manage-plan-btn" onClick={()=>handleEditProfile(profileDetails[0].id)}>
            {t('edit Profile')}
          </button>
          <button className='manage-plan-btn' onClick={() => {
            localStorage.clear();
            window.location.href = '/';
          }}>
            {t('logout')}
          </button>
         
          </section>
          {
            //if no phone number, add text to show add  phone
            profileDetails.length > 0 && profileDetails[0].phone === null && (
              <p style={{color:'red', marginTop:'10px'}}>{t('add your phone number')}</p>
            )
          }
       {
          showEditProfile && (
            <>
            <div className="profile-form">
          
            <div className="form-group">
              <label htmlFor='phone'>{t('phone Number')}</label>
              <input placeholder='Phone Number' type='text' id='phone' name='phone'  value={phone} onChange={(e) => setPhone(e.target.value)} />
            </div>

            <div className="form-group">
              <label htmlFor='phone'>{t('address')}</label>
              <input placeholder='Address' type='text' id='address' name='phone'  value={billingAddress} onChange={(e) => setBillingAddress(e.target.value)} />
            </div>


            <div className="form-group">
           <label htmlFor="countrySelect">Country</label>
        <select id="countrySelect" value={selectedCountry} onChange={handleCountryChange}>
          {countries.map((country) => (
            <option key={country} value={country}>
              {country}
            </option>
          ))}
        </select> </div>

            <div className="form-group">
            <label htmlFor="countySelect">County/State</label>
        <select id="countySelect" value={selectedCounty} onChange={handleCountyChange}>
          {countiesData['Romania']?.counties.map((county) => (
            <option key={county} value={county}>
              {county}
            </option>
          ))}
        </select>
          </div>


            <div className="form-group">
            <label htmlFor="citySelect">City</label>
        <select id="citySelect" value={selectedCity} onChange={handleCityChange}>
          {countiesData['Romania']?.cities[selectedCounty]?.map((city) => (
            <option key={city} value={city}>
              {city}
            </option>
          ))}
        </select>
         </div>

            <div className="form-group">
              <label htmlFor='phone'>{t('postal Code')}</label>
              <input placeholder='code' type='text' id='code' name='cpde'  value={code} onChange={(e) => setCode(e.target.value)} />
            </div>

            <div className="form-group">
              <label htmlFor='phone'>{t('companyForm')}</label>
              <input placeholder='company' type='text' id='code' name='cpde'  value={company} onChange={(e) => setCompany(e.target.value)} />
            </div>


          
          </div>
          <button className="manage-plan-btn" onClick={()=> handleUpdate(profileDetails[0].id)}>
              {t('save Changes')}
            </button>
          </>
          )
       }
          

        </section>
      </div>
    </div>
  );
};

export default Profile;
