export function WeatherIcon({ temperature }) {
    if (temperature >= 30) return         <img src={'/assets/icons/sun.png'} alt='wind' className='w-10 h-10'/>
  
    if (temperature >= 20) return         <img src={'/assets/icons/clear-sky.png'} alt='wind' className='w-10 h-10'/>
  
    if (temperature >= 10) return         <img src={'/assets/icons/cloud.png'} alt='wind' className='w-10 h-10'/>
  
    return  <img src={'/assets/icons/cloud.png'} alt='wind' className='w-10 h-10'/>;
  }

  export function pictocodeDescription(pictocode){
    const pictocodeDescriptions = {
      1: 'Clear, cloudless sky',
      2: 'Clear, few cirrus',
      3: 'Clear with cirrus',
      4: 'Clear with few low clouds',
      5: 'Clear with few low clouds and few cirrus',
      6: 'Clear with few low clouds and cirrus',
      7: 'Partly cloudy',
      8: 'Partly cloudy and few cirrus',
      9: 'Partly cloudy and cirrus',
      10: 'Mixed with some thunderstorm clouds possible',
      11: 'Mixed with few cirrus with some thunderstorm clouds possible',
      12: 'Mixed with cirrus and some thunderstorm clouds possible',
      13: 'Clear but hazy',
      14: 'Clear but hazy with few cirrus',
      15: 'Clear but hazy with cirrus',
      16: 'Fog/low stratus clouds',
      17: 'Fog/low stratus clouds with few cirrus',
      18: 'Fog/low stratus clouds with cirrus',
      19: 'Mostly cloudy',
      20: 'Mostly cloudy and few cirrus',
      21: 'Mostly cloudy and cirrus',
      22: 'Overcast',
      23: 'Overcast with rain',
      24: 'Overcast with snow',
      25: 'Overcast with heavy rain',
      26: 'Overcast with heavy snow',
      27: 'Rain, thunderstorms likely',
      28: 'Light rain, thunderstorms likely',
      29: 'Storm with heavy snow',
      30: 'Heavy rain, thunderstorms likely',
      31: 'Mixed with showers',
      32: 'Mixed with snow showers',
      33: 'Overcast with light rain',
      34: 'Overcast with light snow',
      35: 'Overcast with mixture of snow and rain'
    };
  
  
    return pictocodeDescriptions[pictocode];
  }