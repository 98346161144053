import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

import { fetchAllFarmDetails, fetchAllFarmers, fetchFarmDetails, fetchPackageRequests, fetchRequests } from '../../../../../fARMER/fetches/FetchFarmDetails';
import './accounts.css';
import TopBar from '../../topbar/Topbar';
import { deleteFarmer, updateFarmer } from '../../../../../graphql/mutations';
import { API, graphqlOperation } from 'aws-amplify';
import Popup from 'reactjs-popup';
import SingleAccount from './SingleAccount';
import { AiOutlineCloudDownload } from 'react-icons/ai';
import { toast } from 'react-toastify';
import Disabled from './Disabled';

const Accounts = () => {
  const [farmers, setFarmers] = useState([]);
  const [farmDetails, setFarmDetails] = useState([]);
  const [droneRequests, setDroneRequests] = useState([]);
  const [searchTerm, setSearchTerm] = useState(''); //
  const [sortField, setSortField] = useState('createdAt'); // <-- Add this state
  const [sortOrder, setSortOrder] = useState('asc'); //
  const [packageRequests, setPackageRequests] = useState([]);
  const [update, setUpdate] = useState(false);
  


  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based in JavaScript
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };


  const toggleSortOrder = () => {
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };

  

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

    //  function to handle sort field changes
    const handleSortFieldChange = (field) => {
      if (field === sortField) {
        // If the field is the same as the current sort field, toggle the sort order
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
      } else {
        // If the field is different, change the sort field and reset the sort order to 'asc'
        setSortField(field);
        setSortOrder('asc');
      }
    };

//  function to sort farmers based on the sort order
const getFilteredFarmers = () => {
  const filteredFarmers = farmers.filter((farmer) => {
    return (
      (farmer.phone && farmer.phone.includes(searchTerm)) ||
      (farmer.email && farmer.email.toLowerCase().includes(searchTerm.toLowerCase()))
    );
  });

 // Sort the filtered farmers based on the sort field and sort order
 filteredFarmers.sort((a, b) => {
  let valueA, valueB;
  if (sortField === 'createdAt') {
    valueA = new Date(a.createdAt);
    valueB = new Date(b.createdAt);
  } else if (sortField === 'farms') {
    valueA = countFarms(a.id);
    valueB = countFarms(b.id);
  } else if (sortField === 'droneRequests') {
    valueA = countDroneRequests(a.id);
    valueB = countDroneRequests(b.id);
  }
  return sortOrder === 'asc' ? valueA - valueB : valueB - valueA;
});

  return filteredFarmers;
};
    
  

  useEffect(() => {
    async function fetchData() {
        const farmerData = await fetchAllFarmers();
        setFarmers(farmerData);
    }
    fetchData();
    }, [update]);

    console.log(farmers);

    //fetch farm details
    React.useEffect(() => {
        async function fetchData() {
          const farmData = await fetchAllFarmDetails();
          setFarmDetails(farmData);
        }
        fetchData();
      }, []);

      //fetch drone requests
      React.useEffect(() => {
        async function fetchData() {
          const farmData = await fetchRequests();
          setDroneRequests(farmData);
        }
        fetchData();

      }, []);

      //fetch packages requests
      React.useEffect(() => {
        async function fetchData() {
          const farmData = await fetchPackageRequests();
          setPackageRequests(farmData);
        }
        fetchData();
      }, []);

      console.log(packageRequests);


   //count the number of farms for each farmer
    const countFarms = (farmerID) => {
        let count = 0;
        farmDetails.forEach((farm) => {
            if (farm.farmerID === farmerID) {
                count++;
            }
        });
        return count;
    };

    //count the number of drone requests for each farmer
    const countDroneRequests = (farmerID) => {
        let count = 0;
        droneRequests.forEach((request) => {
            if (request.farmerID === farmerID) {
                count++;
            }
        });
        return count;
    };


    //display farmer packages for each farmer
    const farmerPackages = (farmerID) => {
      let packages = [];
      packageRequests.forEach((request) => {
        if (request.requesterID === farmerID && request.status === 'approved') {
          packages.push(request.package);
        }
      });
      return packages;
    };


    
    const deleteAccount = async (id) => {
      const confirmDelete = window.confirm('Are you sure you want to delete this user?');
      if (confirmDelete) {
        try {
          await API.graphql(graphqlOperation(updateFarmer, { input: { id: id, isDisabled:true } }));
          toast.success("Account disabled")
          const newFarmers = farmers.filter((farmer) => farmer.id !== id);
          setFarmers(newFarmers);
        } catch (error) {
          console.log(error);
        }
      }
    };


function generateExcelData(data) {
  // Fields to exclude
  const excludedFields = [  'createdAt', 'updatedAt', '__typename', 'customID', 'issues', 'gmail', 'damages', 'diseases','packages' , 'droneRequests', 'packageRequests','weatherStation'];

  // Filter out excluded fields from the header
  const header = Object.keys(data[0]).filter(field => !excludedFields.includes(field));

  const rows = data.map(item => {
    // Create a new object without the excluded fields
    const rowData = { ...item };
    excludedFields.forEach(field => delete rowData[field]);
    return Object.values(rowData);
  });

  return [header, ...rows];
}


function handleDownloadClick(data) {
  const excelData = generateExcelData(data);
  const ws = XLSX.utils.aoa_to_sheet(excelData);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'buffer' });
  const excelBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  saveAs(excelBlob, 'farmers.xlsx');
}

    

  return (
    <div className='home-container'>
      <TopBar />
    <div className="admin-dashboard">
      <header 
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '1em',
        
      }}
      >
      <h1 className="dashboard-heading">Accounts <input
            type="text"
            placeholder="Search by phone or email"
            value={searchTerm}
            onChange={handleSearchChange}
            style={{ marginLeft: '1em' , height: '40px', borderRadius: '5px'}}
          />
 

          </h1>

<div style={{display: 'flex', justifyContent: 'space-between',
        alignItems: 'center',}}>
          <Popup
          trigger={<button style={{marginLeft: '5px'}}>
            Banned Accounts
          </button>}
           modal
           nested
           width= {500}
           >
            {close => (
              <Disabled close={close} farmers={farmers}  setUpdate={setUpdate}  />
            )
            }
           </Popup>

          <button style={{ marginRight: '5px' }}>
            <AiOutlineCloudDownload onClick={() => handleDownloadClick(farmers)}/>
          </button>          
</div>
  
      </header>
     
      <table className="dashboard-table">
        <thead>
          <tr>
            <th>#</th>
            <th>Name</th>
            <th>Email</th>
            <th>Package</th>
            <th>Phone Number</th>
            <th onClick={() => handleSortFieldChange('createdAt')}>
                Date of Creation {sortField === 'createdAt' ? (sortOrder === 'asc' ? '▲' : '▼') : ''}
              </th>
              <th onClick={() => handleSortFieldChange('farms')}>
                Farms {sortField === 'farms' ? (sortOrder === 'asc' ? '▲' : '▼') : ''}
              </th>
              <th onClick={() => handleSortFieldChange('droneRequests')}>
                Drone Requests {sortField === 'droneRequests' ? (sortOrder === 'asc' ? '▲' : '▼') : ''}
              </th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
        {getFilteredFarmers().length > 0 ? (
          getFilteredFarmers()
          //filter out accounts with isDisabled as true
          .filter((farmer) => farmer.isDisabled !== true)
          .map((farmer) => (

            <tr key={farmer.id}>
              <td>
                {
                  //make the id start from 1
                  farmers.indexOf(farmer) + 1
                }
              </td>
             
              <Popup
                trigger={<td >  {`${farmer.firstName} ${farmer.lastName}`}</td>}
                modal
                closeOnDocumentClick
                overlayClassName='popup-overlay'
                contentStyle={{
                  borderRadius: '10px',
                  padding: '20px',
                  backgroundColor: '#fff',
                  width: '350px',
                  maxHeight: '90vh',
                  overflow: 'auto',

                }}>
                    <SingleAccount farmer={farmer} area={ countFarms(farmer.id)} requestsCount={countDroneRequests(farmer.id)} showPackages={farmerPackages(farmer.id)} />
                </Popup>
              
             
              <td>{farmer.email}</td>
              <td>
                {farmerPackages(farmer.id).length > 0
                  ? farmerPackages(farmer.id)[0].title === 'Meteoblue Forecast'
                    ? farmerPackages(farmer.id).length > 1
                      ? farmerPackages(farmer.id)[1].title
                      : 'AGROGIS Freemium'
                    : farmerPackages(farmer.id)[0].title
                  : 'AGROGIS Freemium'}
              </td>

              <td>{farmer.phone}</td>
              <td>{formatDate(farmer.createdAt)}</td>
              <td>{ countFarms(farmer.id)}</td>
              <td>{countDroneRequests(farmer.id)}</td>
              <td>
                <button className="delete-button" onClick={() => deleteAccount(farmer.id)}>Delete</button>           
              </td>
            </tr>
          ))) : (
            <tr>
              <td colSpan="7" style={{ textAlign: 'center' }}>No data found</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
    </div>
  );
};

export default Accounts;
