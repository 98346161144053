import React from 'react';
import { BiHelpCircle } from 'react-icons/bi';
import { useTranslation } from 'react-i18next'

const getColorForRecommendation = (recommendation) => {
  switch (recommendation) {
    case 0:
      return 'green';
    case 1:
      return 'yellow';
    case 2:
      return 'red';
    default:
      return 'white';
  }
};

const SowingDay = ({ dataForDay }) => {
  const { t } = useTranslation()
  const cropTypes = ['sowing_maize'];

  const formatTime = (timeString) => {
    // Time string is in the format "YYYY-MM-DD hh:mm"
    // Split it into an array ['YYYY-MM-DD', 'hh:mm']
    const parts = timeString.split(' ');
    // Return just the 'hh:mm' part
    return parts[1];
  };

  return (
    <table style={{width:"100%"}}>
      <thead>
        <tr>
          <th>{t('time')}</th>
          {dataForDay.time.map((time, index) => (
            <th key={index}>{formatTime(time)}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {cropTypes.map(cropType => (
          <tr key={cropType}>
              {cropTypes.map(cropType => (
                        <th key={cropType}>
                        {t('sowing Index')} 
                        <span className="tooltip">
                          <BiHelpCircle />
                          <span className="tooltiptext">
                            <div>0: {t('suitable period for application')} <span style={{backgroundColor: 'green'}}> (green) </span></div>
                            <div>1: {t('less suitable period for application')} <span style={{backgroundColor: 'yellow'}}> (yellow) </span></div>
                            <div>2: {t('unsuitable period for application')} <span style={{backgroundColor: 'red'}}> (red) </span></div>
                          </span>
                        </span>
                      </th>
                    ))}
            {dataForDay[cropType].map((value, index) => (
              <td
                key={index}
                style={{
                  backgroundColor: getColorForRecommendation(value),
                  padding: '0px 10px',
                }}
              >
                {value}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default SowingDay;
