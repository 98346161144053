import { API, graphqlOperation } from "aws-amplify";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  createFarmDetails,
  deleteFarmDetails,
  updateFarmDetails,
} from "../../../graphql/mutations";
import { useNavigate } from "react-router-dom";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import { listFarmDetails } from "../../../graphql/queries";
import Popup from "reactjs-popup";
import cropOptions from "./crops/cropData";
import Cycles from "./pastCycles/Cycles";

import { useTranslation } from "react-i18next";
import { ta } from "date-fns/locale";

export const FarmPopup = ({
  farm,
  setFarm,
  onUpdateFarm,
  onClose,
  handleInputChange,
}) => {
  const navigate = useNavigate();
  const [section, setSection] = useState(1);
  const [selectedCrop, setSelectedCrop] = useState("");
  const [selectedCropUse, setSelectedCropUse] = useState("");
  const [isEditEnabled, setIsEditEnabled] = useState(false); // Track edit state
  const [showMore, setShowMore] = useState(false);
  const [cycle, setCycle] = useState(false);
  const [pastCycles, setPastCycles] = useState([]);
  const { t } = useTranslation();
  const [farmDetailsToUpate, setFarmDetailsToUpate] = useState({
    // id: farm.id,
    name: farm.name,
    active: true,
    crop: farm.crop,
    cropUse: farm.cropUse,
    Earlyness: farm.Earlyness,
    Protection: farm.Protection,
    TotalYield: farm.TotalYield,
    YieldPerha: farm.YieldPerha,
    sowingDate: farm.sowingDate,
    harvestDate: farm.harvestDate,
    soilTexture: farm.soilTexture,
    drainage: farm.drainage,
    Management: farm.Management,
    slope: farm.slope,
    revenue: farm.revenue,
    notes: farm.notes,
    startDate: farm.startDate,
    farmerId: farm.farmerId,
    spacialReference: farm.spacialReference,
    rings: farm.rings,
    area: farm.area,
    gmail: farm.gmail,
    farmerID: farm.farmerID,
    byoc: farm.byoc,
    subscriptionID: farm.subscriptionID,
    cadastralSheet: farm.cadastralSheet,
    cadastralMunicipality: farm.cadastralMunicipality,
    cadastralParticle: farm.cadastralParticle,
  });

  const [popupWidth, setPopupWidth] = useState("60%"); // default width

  useEffect(() => {
    const handleResize = () => {
      // Check if the screen width is less than 600px
      if (window.innerWidth < 600) {
        setPopupWidth("90%"); // or any desired width for small screens
      } else {
        setPopupWidth("60%"); // default width for larger screens
      }
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Initial check on component mount
    handleResize();

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    fetchPastFarmCycles();
  }, []);

  async function fetchPastFarmCycles() {
    const farmerID = localStorage.getItem("farmerID");

    try {
      const farmDetails = await API.graphql(
        graphqlOperation(listFarmDetails, {
          filter: {
            farmerID: {
              eq: farmerID,
            },
            active: {
              eq: false,
            },
            name: {
              eq: farm.name,
            },
          },
        })
      );
      const farmDetailsData = farmDetails.data.listFarmDetails.items;
      setPastCycles(farmDetailsData);
      console.log(farmDetailsData);
    } catch (error) {
      console.log(error);
    }
  }

  console.log(pastCycles);

  const handleUploadNewCycle = async (id) => {
    setIsEditEnabled(true);
    await API.graphql(
      graphqlOperation(createFarmDetails, { input: farmDetailsToUpate })
    );

    //add the new item to the existing farms in farmDetails in local storage
    const existingFarmDetails =
      JSON.parse(sessionStorage.getItem("farmDetails")) || [];
    localStorage.setItem(
      "farmDetails",
      JSON.stringify([...existingFarmDetails, farmDetailsToUpate])
    );

    //set status of active to false for the previous cycle
    await API.graphql(
      graphqlOperation(updateFarmDetails, {
        input: { id: farm.id, active: false },
      })
    );
    toast.success(t("toastMessages4.newCycleStarted"));
    //call the callback function to update the farm list in the parent component, prop passed is onUpdateFarm
    // onUpdateFarm(id);
    onClose();
  };

  const handleCropChange = (e) => {
    const crop = e.target.value;
    setSelectedCrop(crop);
    setSelectedCropUse("");
  };

  const handleCropUseChange = (e) => {
    const cropUse = e.target.value;
    setSelectedCropUse(cropUse);
  };

  const getCropUseOptions = () => {
    const selectedCropData = cropOptions.find(
      (crop) => crop.name === selectedCrop
    );
    return selectedCropData ? selectedCropData.cropUses : [];
  };

  const handleNext = () => {
    if (section < 4) {
      setSection(section + 1);
    }
  };

  const handlePrevious = () => {
    if (section > 1) {
      setSection(section - 1);
    }
  };

  const handleInput = (e) => {
    const { name, value } = e.target;

    setFarmDetailsToUpate({ ...farmDetailsToUpate, [name]: value });

    if (name === "crop") {
      setSelectedCrop(value);
      setSelectedCropUse("");
    }

    if (name === "cropUse") {
      setSelectedCropUse(value);
    }
  };

  const handleUpdate = async (id) => {
    try {
      await API.graphql(
        graphqlOperation(updateFarmDetails, {
          input: { id: id, ...farmDetailsToUpate },
        })
      );
      toast.success(t("toastMessages4.farmUpdated"));

      //show updated byoc in toast
      //call the callback function to update the farm list in the parent component, prop passed is onUpdateFarm
      onUpdateFarm(id);
      const existingFarmDetails =
        JSON.parse(sessionStorage.getItem("farmDetails")) || [];
      const updatedFarmDetails = existingFarmDetails.map((farm) => {
        if (farm.id === id) {
          return { ...farm, ...farmDetailsToUpate };
        }
        return farm;
      });
      sessionStorage.setItem("farmDetails", JSON.stringify(updatedFarmDetails));
      setIsEditEnabled(!isEditEnabled);
      onClose();
    } catch (error) {
      console.log(error);
      toast.error(t("toastMessages4.farmUpdatedError"));
    }
  };

  const handleDelete = async (id) => {
    const confirmDelete = window.confirm(t("confirmationMessages.deleteFarm"));

    if (!confirmDelete) {
      return; // If the user cancels the confirmation, exit the function
    }

    try {
      await API.graphql(
        graphqlOperation(deleteFarmDetails, { input: { id: id } })
      );
      toast.success(t("toastMessages4.farmDeleted"));
      //update the farm list in local storage
      const existingFarmDetails =
        JSON.parse(sessionStorage.getItem("farmDetails")) || [];
      const updatedFarmDetails = existingFarmDetails.filter(
        (farm) => farm.id !== id
      );
      sessionStorage.setItem("farmDetails", JSON.stringify(updatedFarmDetails));

      //call the callback function to update the farm list in the parent component, prop passed is onUpdateFarm
      onUpdateFarm(id);

      onClose();
    } catch (error) {
      console.log(error);
      toast.error(t("toastMessages4.farmErrorDelete"));
    }
  };

  console.log(selectedCrop);
  console.log(selectedCropUse);

  return (
    <section>
      <div className="form-section">
        <section
          className="my-fields-header"
          style={{ paddingLeft: 0, paddingRight: 0 }}
        >
          {isEditEnabled ? (
            <h2>{t("edit Farm")}</h2>
          ) : (
            <h2>{t("farm details")}</h2>
          )}

          <Popup
            trigger={
              <button style={{ backgroundColor: "#F67B00", color: "white" }}>
                {t("history")}
              </button>
            }
            modal
            nested
            zIndex={99999999}
            contentStyle={{
              width: popupWidth,
              //padding style
              padding: "0px",
            }}
          >
            {(close) => (
              <>
                <Cycles
                  pastCycles={pastCycles}
                  farm={farm.name}
                  close={close}
                />
              </>
            )}
          </Popup>
        </section>

        {/* Input fields for section 1 */}
        <form>
          {cycle ? (
            <>
              <section className="input-section">
                <div className="form-group">
                  <label htmlFor="farmLocation">{t("crop")}</label>
                  <select
                    name="crop"
                    id="crop"
                    value={selectedCrop}
                    onChange={handleInput}
                    disabled={!isEditEnabled}
                  >
                    <option>{farmDetailsToUpate.crop}</option>
                    {cropOptions.map((crop) => (
                      <option key={crop.name} value={crop.name}>
                        {crop.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group">
                  <label htmlFor="farmSize">{t("start date")}</label>
                  <input
                    type="date"
                    name="startDate"
                    id="startdate"
                    value={farmDetailsToUpate.startDate}
                    onChange={handleInput}
                    disabled={!isEditEnabled}
                  />
                </div>
              </section>

              <div className="form-group">
                <label htmlFor="farmType">{t("crop Use")}</label>
                <select
                  name="cropUse"
                  id="cropUse"
                  defaultDalue={selectedCropUse}
                  onChange={handleInput}
                  disabled={!isEditEnabled}
                >
                  <option value="Fresh fruit">
                    {farmDetailsToUpate.cropUse}
                  </option>
                  {getCropUseOptions().map((cropUse) => (
                    <option key={cropUse} value={cropUse}>
                      {cropUse}
                    </option>
                  ))}
                </select>
              </div>
            </>
          ) : (
            <>
              <div className="form-group">
                <label htmlFor="farmName">{t("farm Name")}</label>
                <input
                  type="text"
                  name="name"
                  id="farmName"
                  value={farmDetailsToUpate.name}
                  disabled={!isEditEnabled}
                  onChange={handleInput}
                />
              </div>

              <section className="input-section">
                <div className="form-group">
                  <label htmlFor="farmLocation">{t("crop")}</label>
                  <select
                    name="crop"
                    id="crop"
                    value={selectedCrop}
                    onChange={handleInput}
                    disabled={!isEditEnabled}
                  >
                    <option>{farmDetailsToUpate.crop}</option>
                    {cropOptions.map((crop) => (
                      <option key={crop.name} value={crop.name}>
                        {crop.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group">
                  <label htmlFor="farmSize">{t("start date")}</label>
                  <input
                    type="date"
                    name="startDate"
                    id="startdate"
                    value={farmDetailsToUpate.startDate}
                    onChange={handleInput}
                    disabled={!isEditEnabled}
                  />
                </div>
              </section>

              <section className="input-section">
                <div className="form-group">
                  <label htmlFor="farmType">{t("crop Use")}</label>
                  <select
                    name="cropUse"
                    id="cropUse"
                    value={selectedCropUse}
                    onChange={handleInput}
                  >
                    <option>{farmDetailsToUpate.cropUse}</option>
                    {getCropUseOptions().map((cropUse) => (
                      <option key={cropUse} value={cropUse}>
                        {cropUse}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="form-group">
                  <label htmlFor="farmLocation">{t("earlyness")}</label>
                  <select
                    name="Earlyness"
                    id="earlyness"
                    onChange={handleInput}
                    disabled={!isEditEnabled}
                  >
                    <option>{farmDetailsToUpate.Earlyness}</option>
                    <option value="Early">{t("early")}</option>
                    <option value="Medium">{t("medium")}</option>
                    <option value="Late">{t("late")}</option>
                  </select>
                </div>
                <div className="form-group">
                  <label htmlFor="farmSize">{t("protection")}</label>
                  <select
                    name="Protection"
                    id="protection"
                    onChange={handleInput}
                    disabled={!isEditEnabled}
                  >
                    <option>{farmDetailsToUpate.Protection}</option>
                    <option value="Open Field">{t("open Field")}</option>
                    <option value="Semi-protected">
                      {t("semi-protected")}
                    </option>
                    <option value="Protected">{t("protected")}</option>
                  </select>
                </div>
              </section>
            </>
          )}

          {showMore && (
            <>
              <section className="input-section">
                <div className="form-group">
                  <label htmlFor="farmLocation">{t("soil Texture")} </label>
                  <select
                    name="soilTexture"
                    id="soilTexture"
                    onChange={handleInput}
                    disabled={!isEditEnabled}
                  >
                    <option>{farmDetailsToUpate.soilTexture}</option>
                    <option value="Clay">{t("clay")}</option>
                    <option value="Sandy">{t("sandy")}</option>
                    <option value="Silty">{t("silty")}</option>
                    <option value="Loamy">{t("loamy")}</option>
                  </select>
                </div>
                <div className="form-group">
                  <label htmlFor="farmSize">{t("drainage")}</label>
                  <select
                    name="drainage"
                    id="drainage"
                    onChange={handleInput}
                    disabled={!isEditEnabled}
                  >
                    <option>{farmDetailsToUpate.drainage}</option>
                    <option value="None">{"none"}</option>
                    <option value="Sub-surface">{t("sub-surface")}</option>
                    <option value="Surface">{"surface"}</option>
                  </select>
                </div>
              </section>

              <section className="input-section">
                <div className="form-group">
                  <label htmlFor="farmType">{t("sowing date")}</label>
                  <input
                    type="date"
                    name="sowingDate"
                    id="soeing"
                    value={farmDetailsToUpate.sowingDate}
                    onChange={handleInput}
                    disabled={!isEditEnabled}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="farmDescription">
                    {t("expected Harvest Date")}
                  </label>
                  <input
                    type="date"
                    name="harvestDate"
                    id="harvest"
                    value={farmDetailsToUpate.harvestDate}
                    onChange={handleInput}
                    disabled={!isEditEnabled}
                  />
                </div>
              </section>

              <section className="input-section">
                <div className="form-group">
                  <label htmlFor="farmType">{t("management Types")}</label>
                  <select
                    name="Management"
                    id="Management"
                    onChange={handleInput}
                    disabled={!isEditEnabled}
                  >
                    <option>{farmDetailsToUpate.Management}</option>
                    <option value="Conventional">{t("conventional")}</option>
                    <option value="Organic">{t("organic")}</option>
                    <option value="Integrated">{t("integrated")}</option>
                  </select>
                </div>
                <div className="form-group">
                  <label htmlFor="farmDescription">{t("slope")}</label>
                  <select
                    name="slope"
                    id="slope"
                    onChange={handleInput}
                    disabled={!isEditEnabled}
                  >
                    <option>{farmDetailsToUpate.slope}</option>
                    <option value="Flat">{t("flat")}</option>
                    <option value="Gentle">{t("gentle")}</option>
                    <option value="Moderate">{t("moderate")}</option>
                    <option value="Steep">{t("steep")}</option>
                  </select>
                </div>
              </section>

              <section className="input-section">
                <div className="form-group">
                  <label htmlFor="farmType">{t("Cadastral Sheet")}</label>
                  <input
                    type="number"
                    name="cadastralSheet"
                    id="Sheet"
                    value={farmDetailsToUpate.cadastralSheet}
                    onChange={handleInput}
                    disabled={!isEditEnabled}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="farmDescription">
                    {t("cadastral Municipality")}
                  </label>
                  <input
                    type="text"
                    name="cadastralMunicipality"
                    id="Cadastral"
                    value={farmDetailsToUpate.cadastralMunicipality}
                    onChange={handleInput}
                    disabled={!isEditEnabled}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="farmDescription">
                    {t("cadastral Particle")}
                  </label>
                  <input
                    type="number"
                    name="cadastralParticle"
                    id="Cadastral"
                    value={farmDetailsToUpate.cadastralParticle}
                    onChange={handleInput}
                    disabled={!isEditEnabled}
                  />
                </div>
              </section>
              {/* <section className="input-section">
              <div className="form-group">
                  <label htmlFor="byoc">
                    {t('byoc')}
                  </label>
                  <input
                    type="text"
                    name="byoc"
                    id="byoc"
                    value={farmDetailsToUpate.byoc}
                    onChange={handleInput}
                    disabled={!isEditEnabled}
                  />
                </div>
              </section> */}
            </>
          )}

          <div className="show-more">
            <div className="show-more-icons">
              <p onClick={() => setShowMore(!showMore)}>
                {showMore ? "Show Less" : "Show More"}
              </p>
              {showMore ? (
                <BsChevronUp onClick={() => setShowMore(!showMore)} />
              ) : (
                <BsChevronDown onClick={() => setShowMore(!showMore)} />
              )}
            </div>
          </div>
        </form>
      </div>

      <div className="farmpopup__buttons">
        <div>
          <button
            onClick={onClose}
            style={{ color: "black", backgroundColor: "#FDFEFF" }}
          >
            {t("close")}
          </button>
          {cycle ? (
            ""
          ) : isEditEnabled ? (
            <button
              style={{ color: "white", backgroundColor: "#218838" }}
              onClick={() => handleUpdate(farm.id)}
            >
              {t("save")}
            </button>
          ) : (
            <button
              style={{ color: "white", backgroundColor: "#218838" }}
              onClick={() => setIsEditEnabled(!isEditEnabled)}
            >
              {t("edit")}
            </button>
          )}

          {cycle ? (
            ""
          ) : (
            <button
              onClick={() => handleDelete(farm.id)}
              style={{ color: "white", backgroundColor: "rgb(195 58 72)" }}
            >
              {t("delete")}
            </button>
          )}

          {cycle ? (
            <button onClick={() => handleUploadNewCycle(farm.id)}>
              {t("submit")}
            </button>
          ) : (
            <button
              onClick={() => {
                setCycle(true);
                setIsEditEnabled(true);
              }}
              style={{ backgroundColor: "#F67B00", color: "white" }}
            >
              {t("start new cycle")}
            </button>
          )}
        </div>
      </div>
    </section>
  );
};
