import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import FarmerTopBar from '../../components/topbar/Topbar'
import './subs.css'
import { Link } from 'react-router-dom'
import { API, graphqlOperation } from 'aws-amplify'
import { listPackageRequests, listPackages } from '../../../graphql/queries'
import {
  createPackageRequest,
  updatePackages,
} from '../../../graphql/mutations'
import { toast } from 'react-toastify'
import Popup from 'reactjs-popup'
import AreaRequest from './areaRequest/AreaRequest'
import { GrAddCircle } from 'react-icons/gr'


export const packagePlans = [
  {
    id: 1,
    title: 'AGROGIS Freemium',
    description:
      'AGROGIS Freemium is the perfect starting point for farmers. With this free package, you can manage up to 10 hectares of parcels, access Landsat 8/9 satellite monitoring, and receive weather forecasts. You can also track and address field issues, use smart scouting, and benefit from IoT soil sensors and weather station integration. Upgrade to our premium packages for advanced features.',
    price: '$49.99',
    features: [
      'Draw max. 10 ha of parcels',
      'Landsat 8/9 satellite monitoring',
      'Example of custom status and weather forecast per plot',
      'Example of ground sensors',
      'Example of an integrated weather station',
      'Example of monitoring data from the drone.',
      'Example of managing land works / agricultural operations',
      'Example of field data collection on pests',
      'Example of field data collection on technical issues',
      'Example of collecting data on crop problems',
      'Free limited consultation for purchase of premium packages',
    ],
    image:
      'https://firebasestorage.googleapis.com/v0/b/fir-api-7421d.appspot.com/o/agrogis%2F%7D%20(3).jpg?alt=media&token=286a0471-088d-49fb-a315-9063be059995',
  },
  {
    id: 2,
    title: 'AGROGIS Starter',
    description:
      "AGROGIS Starter is the ideal choice for farmers ready to expand their operations. With this package, you can draw and manage parcels up to 100 hectares. Enjoy the benefits of Sentinel-2 satellite monitoring, providing you with valuable insights into your crops. All other functionalities are fully active, allowing you to maximize your farm management capabilities. Please note that IoTs and weather stations are not included in this package. Additionally, you'll have access to features like Landsat-8 Land Surface Temperature, providing you with crucial temperature data for better decision-making. Upgrade to higher tiers to unlock more features and scalability.",
    price: '$99.99',
    features: [
      '1000 ha. parcel drawing',
      'Sentinel-2 satellite monitoring',
      'Soil temperature monitoring',
      'Status and personalized weather forecast on each plot',
      'Crop rotation and plot information management',
      'Collect GPS-located field data about pests',
      'Collect GPS-located field data about technical issues',
      'Collect GPS-located field data about crop problems',
      'Order active drone footage (See drone mapping service)',
    ],
    image:
      'https://firebasestorage.googleapis.com/v0/b/fir-api-7421d.appspot.com/o/agrogis%2F%7D.jpg?alt=media&token=0d56a786-840b-48b5-b1e2-16032ea4bb81',
  },
  {
    id: 3,
    title: 'AGROGIS Plus',
    description:
      'Upgrade to AGROGIS Plus for even more advanced farm management capabilities. With AGROGIS Plus, you can draw and manage parcels up to 100 hectares, giving you the flexibility to handle larger areas of land. Experience the power of Planet Scope monitoring, providing high-resolution imagery and detailed insights into your crops. All other functionalities remain fully active, allowing you to make informed decisions and optimize your farming operations. Please note that IoTs and weather stations are not included in this package. Additionally, AGROGIS Plus offers access to features like Landsat-8 Land Surface Temperature, providing you with valuable temperature data to enhance your crop management strategies. Take your farm to the next level with AGROGIS Plus.',
    price: '$199.99',
    features: [
      '100 ha. Parcel drawing',
      'Satellite monitoring of crops with Planet Scope at 3 meters resolution',
      'Soil temperature monitoring',
      'Status and personalized weather forecast on each plot',
      'Crop rotation and plot information management',
      'Collect GPS-located field data about pests',
      'Collect GPS-located field data on technical issues',
      'Collect GPS-located field data on crop problems',
      'Order active drone footage (See drone mapping service)',
      'Example of ground sensors - installation and integration are paid for separately',
      'Example of a weather station - installation and integration are paid for separately',
    ],
    image:
      'https://firebasestorage.googleapis.com/v0/b/fir-api-7421d.appspot.com/o/agrogis%2F%7D%20(2).jpg?alt=media&token=706b3333-d3b5-41f4-b64b-0e5d0609854b',
  },
  {
    id: 4,
    title: 'AGROGIS Eye',
    description:
      "AGROGIS Eye is the perfect package for those seeking precision farm management. Building upon the base AGROGIS Starter package, AGROGIS Eye offers you the power of High-Resolution (HR) monitoring. With HR imagery captured at a resolution of under 50 cm, you can closely monitor your crops and make informed decisions to optimize your farm's productivity.",
    price: '$149.99',
    features: [
      'Limit based on requirements and farm size',
      'Satellite monitoring of crops with Pleiades at 50 centimeters resolution',
      'Soil temperature monitoring',
      'Status and personalized weather forecast on each plot',
      'Crop rotation and plot information management',
      'Collect GPS-located field data about pests',
      'Collect GPS-located field data about technical issues',
      'Collect GPS-located field data about crop problems',
      'Order active drone footage (See drone mapping service)',
      'Example of ground sensors - installation and integration are paid for separately',
      'Example of a weather station - installation and integration are paid for separately',
    ],
    image:
      'https://firebasestorage.googleapis.com/v0/b/fir-api-7421d.appspot.com/o/agrogis%2F%7D%20(4).jpg?alt=media&token=3b1f693f-4e1c-4236-b6c2-cda0605ba273',
  },
  {
    id: 5,
    title: 'AGROGIS Eye Plus',
    description: 'For greenhouse farming',
    price: '$129.99',
    features: [
      'Limit based on requirements and farm size',
      'Drone monitoring of crops at sub-10 centimeter resolution',
      'Soil temperature monitoring',
      'Status and personalized weather forecast on each plot',
      'Crop rotation and plot information management',
      'Collect GPS-located field data about pests',
      'Collect GPS-located field data about technical issues',
      'Collect GPS-located field data about crop problems',
      'Active Drone Image Order (For additional image orders)',
      'Example of ground sensors - installation and integration are paid for separately',
      'Example of a weather station - installation and integration are paid for separately',
    ],
    image:
      'https://firebasestorage.googleapis.com/v0/b/fir-api-7421d.appspot.com/o/agrogis%2F%7D%20(4).jpg?alt=media&token=3b1f693f-4e1c-4236-b6c2-cda0605ba273',
  },
]

const Subscriptions = () => {
  const { t } = useTranslation()
  const [packages, setPackages] = useState([])
  const [loading, setLoading] = useState(true)
  const [myplan, setPlan] = useState([])
  const [updates, setUpdates] = useState(false)
  const [updatePlan, setUpdatePlan] = useState(false)

  //fetch data from backend
  const fetchPackages = async () => {
    try {
      const packagesData = await API.graphql(graphqlOperation(listPackages))
      //arange the packages so that they are displayed in this order(AGROGIS Freemium, AGROGIS Starter, AGROGIS Plus, Meteoblue Forecast)
      const sortedPackages = packagesData.data.listPackages.items.sort(
        (a, b) => {
          return (
            packagePlans.findIndex((p) => p.title === a.title) -
            packagePlans.findIndex((p) => p.title === b.title)
          )
        }
      )

      setPackages(sortedPackages)
      setLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchPackages()
  }, [updatePlan])

  const addRequest = async (id) => {
    //if there is no farmerID in the local storage, then redirect to the login page
    if (!localStorage.getItem('farmerID')) {
      toast.error(t('logToRequest'))
      return
    }
    try {
      await API.graphql(
        graphqlOperation(createPackageRequest, {
          input: {
            requesterID: localStorage.getItem('farmerID'),
            status: 'requested',
            packageID: id,
          },
        })
      )
      toast.success(t('requestSuccess'))
      setUpdates(!updates)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchPlan()
  }, [packages, updates])

  console.log(myplan)
  console.log(packages)

  const fetchPlan = async () => {
    try {
      const packagesRequestData = await API.graphql(
        graphqlOperation(listPackageRequests, {
          filter: {
            requesterID: {
              eq: localStorage.getItem('farmerID'),
            },
          },
        })
      )

      setPlan(
        packagesRequestData.data.listPackageRequests.items.filter(
          (item) => item.package !== null
        )
      )

      setLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  //fetch the package name from the local storage
  const packageName = localStorage.getItem('packageName')

  return (
    <div className="home-container">
      <FarmerTopBar />
      <h2 className='ml-3'>{t('subscriptions for you')}</h2>
      {loading ? (
        <p>{t('loading')}...</p>
      ) : (
        <div className="package-plans-grid new-plans">
          {packages.map((plan, index) => {
            const isPlanSelected = myplan.some(
              (p) => p.package.title === plan.title && p.active === true
            )

            return (
              <div key={index} className="package-card">
                <div className="package-image">
                  <img
                    src={`https://officialagrogis.s3.amazonaws.com/${plan.image}`}
                    alt=""
                  />
                  <div className="package-name plans">
                    <p>{plan.title}</p>
                  </div>
                </div>

                <ul className="package-features">
                  {plan.features &&
                    plan.features.map((feature, index) => (
                      <li key={index}>{t(feature)}</li>
                    ))}
                </ul>

                <div className="package-bottom">
                  {isPlanSelected ? (
                    <Link
                      to={`details/${plan.id}`}
                      className="tab disabled"
                      title="already selected"
                    >
                      {t('selected')}
                      {plan.title !== 'Virtual Weather Station' ? (
                        <span>
                          {t('area limit')}:{' '}
                          {
                            myplan.filter(
                              (p) =>
                                p.package.title === plan.title &&
                                (p.active === true || p.status === 'approved')
                            )[0].areaLimit
                          }{' '}
                          ha
                        </span>
                      ) : (
                        ''
                      )}
                    </Link>
                  ) : plan.title === 'AGROGIS Freemium' ? (
                    <span className="package-button">{t('free')}</span>
                  ) : myplan.some(
                      (p) =>
                        p.package.title === plan.title &&
                        p.status === 'requested'
                    ) ? (
                    <span className="package-button">
                      {t('plan Requested')}
                    </span>
                  ) : (
                    <Link
                      onClick={() => addRequest(plan.id)}
                      className="package-button"
                    >
                      {t('request Plan')}
                    </Link>
                  )}

                  {isPlanSelected &&
                  plan.title !== 'Virtual Weather Station' ? (
                    <Popup
                      trigger={
                        <GrAddCircle
                          size={25}
                          title="request additional area limit"
                        />
                      }
                      modal
                      nested
                      width="50%"
                    >
                      {(close) => (
                        <div className="modal">
                          <></>
                          <AreaRequest
                            requestID={
                              myplan.filter(
                                (p) =>
                                  p.package.title === plan.title &&
                                  p.active === true
                              )[0].id
                            }
                            close={close}
                            updateParentState={setUpdatePlan}
                            onClosed={close}
                            requestStatus={myplan.filter(
                              (p) =>
                                p.package.title === plan.title[0].isAreaRequest
                            )}
                          />
                        </div>
                      )}
                    </Popup>
                  ) : null}
                </div>
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}

export default Subscriptions
