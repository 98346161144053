import React from 'react'
import { API, graphqlOperation } from 'aws-amplify';
import { toast } from 'react-toastify';
import { updateFarmer } from '../../../../../graphql/mutations';


const Disabled = ({farmers, close, setUpdate}) => {
    const enableAccount = async (id) => {
        const confirmEnable = window.confirm("Are you sure you want to enable this account?");
        if (confirmEnable) {
          try {
            await API.graphql(graphqlOperation(updateFarmer, { input: { id: id, isDisabled:false } }));
            toast.success("Account enabled successfully!")
            setUpdate(true);
            //close modal
            close();
          } catch (error) {
            console.log(error);
          }
        }
      };
return (
    <div>
        <h3>Disabled accounts</h3>
        {
            farmers.filter(farmer => farmer.isDisabled).length === 0 && <p>No disabled accounts</p>
        }
        {
            farmers.filter(farmer => farmer.isDisabled).map((farmer, index) => {
                return (
                    <div key={index} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '2px', borderBottom: '1px solid #ccc' }}>
                        <div>
                            <p style={{ fontSize: '14px', fontWeight: 'bold' }}>{farmer.name}</p>
                            <p style={{ fontSize: '12px', color: '#888' }}>{farmer.email}</p>
                        </div>
                        <div>
                            <button onClick={() => enableAccount(farmer.id)} style={{ padding: '3px', fontSize: '12px', color: '#fff', backgroundColor: 'blue', borderRadius: '4px' }}>Enable</button>
                        </div>
                    </div>
                )
            })
        }
    </div>
)
}

export default Disabled