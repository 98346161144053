import React, { useState } from 'react'
import { createDroneRequest } from '../../../graphql/mutations'
import { API, graphqlOperation } from 'aws-amplify'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import Popup from 'reactjs-popup'
import PendingRequets from './PendingRequests'
import { useTranslation } from 'react-i18next'

const DroneInputs = ({ farmDetails, close, setPoly, onUpdate }) => {
  const navigate = useNavigate()
  const [date, setRequestDate] = useState('')
  const [note, setNote] = useState('')
  const [fieldID, setFieldID] = useState('')
  const [fieldName, setFieldName] = useState('')
  const [crop, setCrop] = useState('')
  const [area, setArea] = useState('')
  const [rings, setRings] = useState('')
  const [formattedRings, setFormattedRings] = useState([])
  const { t } = useTranslation()

  const handleFieldSelect = (e) => {
    const selectedValue = JSON.parse(e.target.value)
    if (!selectedValue) {
      console.log('Invalid selected value')
      return
    }

    setFieldID(selectedValue.id)
    setFieldName(selectedValue.name)
    setCrop(selectedValue.crop)
    setArea(selectedValue.area)
    setRings(JSON.parse(selectedValue.rings))

    setPoly(JSON.stringify(selectedValue.rings))
  }

  const handleSubmit = async () => {
    console.log({
      date,
      fieldName,
      farmDetailsID: fieldID,
      crop,
      area,
      note,
      farmerID: localStorage.getItem('farmerID'),
      rings: JSON.stringify(rings),
    })
    if (!date || !fieldName) {
      toast.error(t('toastMessages3.fillFields'))
      return
    }
    try {
      setRings(rings) // Pass the `rings` state value

      await API.graphql(
        graphqlOperation(createDroneRequest, {
          input: {
            date,
            fieldName,
            farmDetailsID: fieldID,
            crop,
            area: parseFloat(area),
            note,
            farmerID: localStorage.getItem('farmerID'),
            rings: JSON.stringify(rings),
            gmail: localStorage.getItem('farmerEmail'),
          },
        })
      )
      toast.success(t('Request sent successfully'))
      close()
      onUpdate()
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div>
      <div className="farmpopup listing">
        <header
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <h2>{t('EnterReqDetails')}</h2>
          <div></div>
        </header>
        <div className="form-group">
          <label htmlFor="issue">{t('report on')}</label>
          <input
            type="date"
            placeholder="Enter issue"
            onChange={(e) => setRequestDate(e.target.value)}
          />
        </div>

        <div className="form-group">
          <label htmlFor="issue">{t('field Name')} </label>
          <select name="field" id="field" onChange={handleFieldSelect}>
            <option>{t('select')}</option>

            {farmDetails &&
              farmDetails.map((item, index) => (
                <option key={index} value={JSON.stringify(item)}>
                  {item.name}
                </option>
              ))}
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="issue">{t('field size')}(ha)</label>
          <input
            type="text"
            value={
              fieldName ? parseFloat(area).toFixed(2) : 'Enter field name first'
            }
          />
        </div>

        <div className="form-group">
          <label htmlFor="issue">{t('crop Type')}</label>
          <input
            type="text"
            value={fieldName ? crop : 'Enter field name first'}
          />
        </div>

        <div className="form-group">
          <label htmlFor="issue">{t('note')}</label>
          <textarea
            style={{ color: '#fff' }}
            name="note"
            id="note"
            cols="30"
            rows="10"
            onChange={(e) => setNote(e.target.value)}
          ></textarea>
        </div>
        <div className="farmpopup__buttons">
          <button onClick={close}>{t('close')}</button>

          <button onClick={() => handleSubmit(close)}>{t('submit')}</button>
        </div>
      </div>
    </div>
  )
}

export default DroneInputs
