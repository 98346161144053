import React, { useEffect, useState } from 'react';
import { AiOutlineCloudDownload } from 'react-icons/ai';
import { IoAddOutline } from 'react-icons/io5';
import { issuesCollection } from '../../../lib/firestoreCollections';
import { addDoc, doc, onSnapshot } from 'firebase/firestore';
import { API, graphqlOperation } from 'aws-amplify';
import { createDamages, createIssues, createOperations, deleteIssues, deleteOperations, updateOperations } from '../../../graphql/mutations';
import { listDamages, listIssues, listOperations } from '../../../graphql/queries';
import './operations.css'
import { RiDeleteBin6Line, RiEdit2Line } from 'react-icons/ri';
import NoDataComponent from '../../null/NullData';
import { fetchOperations } from '../../fetches/FetchFarmDetails';
import { toast } from 'react-toastify';
import { GoLocation } from 'react-icons/go';
import { useTranslation } from 'react-i18next'

const OperationList = ({ clickedCoordinates, handlePointClick, farmDetails, onDelete, setPoly }) => {
    const [showInput, setShowInput] = useState(false);
    const [reportDate, setReportDate] = useState('');
    const [crop, setCrop] = useState('');
    const [area, setArea] = useState('');
    const [cropOperation, setCropOperation] = useState('');
    const [fieldID, setFieldID] = useState('');
    const [coordinates, setCoordinates] = useState([]);
    const [change, setChanged] = useState(false);

    const [fieldName, setFieldName] = useState('');
    const [fertilizer, setFertilizer] = useState('');
    const [fertilizerQuantity, setFertilizerQuantity] = useState(0);
    const [fertilizerQuantityPerHa, setFertilizerQuantityPerHa] = useState(0);
    const [yieldQuantity, setYieldQuantity] = useState(0);
    const [yieldQuantityPerHa, setYieldQuantityPerHa] = useState(0);
    const [irrigationLitres, setIrrigationLitres] = useState(0);
    const [note, setNote] = useState('');
    const [showMore, setShowMore] = useState(true);
    const [rings, setRings] = useState([]);
    const [index, setIndex] = useState(1);
    const { t } = useTranslation()


    //fetch farmer id from local storage

    //send to aws amplify
    const handeSend = async () => {
        const farmerID = localStorage.getItem('farmerID');
        try {
            await API.graphql(
                graphqlOperation(createOperations, {
                    input: {
                        farmerID: farmerID,
                        farmDetailsID: fieldID,
                        crop: crop,
                        operation: cropOperation,
                        date: reportDate,
                        fertilizerType: fertilizer,
                        fertilizerQuantity: fertilizerQuantity,
                        fertilizerQuantityPerHa: (fertilizerQuantity / area).toFixed(2),
                        yieldQuantity: yieldQuantity,
                        yieldQuantityPerHa: (yieldQuantity / area).toFixed(2),
                        irrigationLitres: irrigationLitres,
                        notes: note,
                        gmail: localStorage.getItem('farmerEmail'),
                    },
                })
            );

            toast.success('Operation added successfully');
            setChanged(!change);
            setShowInput(false);

        } catch (error) {
            console.log(error);
        }
    };


    //delete issue
    const handleDelete = async (id) => {
        const confirmUpdate = window.confirm(t('confirmUpdateMessage'))

        if (!confirmUpdate) {
            return; // If the user cancels the confirmation, exit the function
        }

        try {
            await API.graphql(graphqlOperation(deleteOperations, { input: { id: id } }));
            toast.success(t('deleted'))
            setChanged(!change);
        } catch (error) {
            console.log(error);
        }
    };

    //update issue
    const onUpdate = async (id) => {
        try {
            await API.graphql(
                graphqlOperation(updateOperations, {
                    input: {
                        id: id,
                        // status: 'resolved',
                    },
                })
            );
            toast.warn(t('waitForUpdate'));
            setChanged(!change);
        } catch (error) {
            console.log(error);
        }
    };


    const [fetchedOperations, setFetchedOperations] = useState([]);
    //fetch operations
    useEffect(() => {
        async function fetchData() {
            const operationsData = await fetchOperations();
            //remove operations with null farmDetails
            const filteredOperations = operationsData.filter((item) => item.farmDetails !== null);
            setFetchedOperations(filteredOperations);
        }
        fetchData();
    }, [change]);

    console.log(fetchedOperations);

    useEffect(() => {
        async function updateParcel() {
            setPoly(JSON.stringify(fetchedOperations[index].farmDetails.rings));
        }
        updateParcel();
    }, [fetchedOperations, index]);

    const handleCardClick = (coordinates) => {
    };


    const handleFieldSelect = (e) => {
        const selectedValue = JSON.parse(e.target.value);
        if (!selectedValue) {
            console.log('Invalid selected value');
            return;
        }


        setFieldName(selectedValue.name);
        setFieldID(selectedValue.id);
        setCrop(selectedValue.crop);
        setArea(selectedValue.area);
        setRings(JSON.parse(selectedValue.rings));

        setPoly(JSON.stringify(selectedValue.rings));

    };


    console.log(area);

    return (
        <div style={{}}>
            <section className="my-fields-header">
                <h2 style={{ }}>{t('crop Operations')}</h2>

                <div>
                    <button style={{ marginRight: '5px' }}>
                        <AiOutlineCloudDownload />
                    </button>
                    <button>
                        <IoAddOutline onClick={() => setShowInput(true)} />
                    </button>

                </div>
            </section>

            {
                fetchedOperations.length === 0 && (
                    <NoDataComponent />
                )
            }

            <section className='data-list'>

                {
                    fetchedOperations && fetchedOperations.map((item, index) => (
                        <div key={index} className="" >
                             <div className="data-card">
                            {
                                item.operation === 'Fertilization' ? (
                                    <div className="data-card">
                                        <div className="card-header">
                                            <h3>{item.operation}</h3>
                                            <h3>{item.farmDetails.crop}</h3>
                                        </div>
                                        <div className="operations-body">
                                            <div>
                                                <h4>{t('field')}</h4>
                                                <p>{item.farmDetails.name}</p>
                                            </div>

                                            <div>
                                                <h4>{t('fertilizer')}</h4>
                                                <p>{item.fertilizerType}</p>
                                            </div>

                                            <div>
                                                <h4>{t('quantity')}</h4>
                                                <p>{item.fertilizerQuantity} kg</p>
                                            </div>

                                            <div>
                                                <h4>{t('quantity per hectare')}</h4>
                                                <p>{item.fertilizerQuantityPerHa} kg/ha</p>
                                            </div>

                                        </div>
                                     
                                    </div>
                                ) : item.operation === 'Harvesting' ? (
                                    <div key={index} className="data-card" onClick={() => setIndex(index)}>

                                        <div className="card-header">
                                            <h3>{item.operation}</h3>
                                            <h3>{item.farmDetails.crop}</h3>
                                        </div>
                                        <div className="operations-body">
                                            <div>
                                                <h4>{t('field')}</h4>
                                                <p>{item.farmDetails.name}</p>
                                            </div>
                                            <div>
                                                <h4>{t('yield')}</h4>
                                                <p>{item.yieldQuantity}kg</p>
                                            </div>
                                            <div>
                                                <h4>{t('yield per hectare')}</h4>
                                                <p>{item.yieldQuantityPerHa}kg/ha</p>
                                            </div>
                                        </div>

                                   


                                    </div>
                                ) : (
                                    <div key={index} className="data-card" onClick={() => handleCardClick(item.coordinates)}>

                                        <div className="card-header">
                                            <h3>{item.operation}</h3>
                                            <h3>{item.farmDetails.name}</h3>
                                        </div>
                                        <div className="operations-body">
                                            <p>{item.operation}</p>
                                        </div>
                                     
                                    </div>
                                )

                            }
                               <div className="card-footer">
                                            <div className="footer-left">
                                                <p>{item.date}</p>
                                            </div>
                                            <div className="footer-right">
                                                <RiDeleteBin6Line className="delete-icon" onClick={() => handleDelete(item.id)} />
                                                <GoLocation className="update-icon" onClick={() => setIndex(index)} />
                                            </div>
                                        </div>
                            </div>
                        </div>
                    ))

                }
            </section>
            {showInput ? (
                <div className="farmpopup">
                    <h2>{t('crop Operations')}</h2>
                    <div className="form-group">
                        <label htmlFor="issue">{t('operation date')}</label>
                        <input
                            type="date"
                            placeholder="Enter issue"
                            onChange={(e) => setReportDate(e.target.value)}
                        />
                    </div>


                    <div className="form-group">
                        <label htmlFor="issue">{t('farm')} </label>
                        <select name='field' id='field' onChange={handleFieldSelect}>
                            <option>{t('select')}</option>

                            {
                                farmDetails && farmDetails.map((item, index) => (

                                    <option key={index} value={JSON.stringify(item)}>{item.name}</option>
                                ))
                            }
                        </select>
                    </div>

                    <div className="form-group">
            <label htmlFor="issue">{t('crop')} </label>
            <input
                type="text"
                value={
                    fieldName ? crop : 'Enter field name first'
                }
            />
        </div>


                    <div className="form-group">
                        <label htmlFor="issue">{t('crop operation')}</label>
                        <select
                            name="crop"
                            id="crop"
                            onChange={(e) => setCropOperation(e.target.value)}
                        >
                            <option>{t('select')}</option>
                            <option value="Fertilization">{t('fertilization')}</option>
                            <option value="Harvesting">{t('harvesting')}</option>
                            <option value="Irrigation">{t('irrigation')}</option>
                            <option value="Soil management">{t('soil management')}</option>
                            <option value="Sowing or transplanting">{t('sowing or transplanting')}</option>
                            <option value="Tillage">{t('tillage')}</option>
                            <option value="Treatments">{t('treatments')}</option>
                            <option value="Other operations">{t('other operations')}</option>
                            <option value="Crop rotation">{t('crop rotation')}</option>
                            <option value="Pruning">{t('pruning')}</option>
                            <option value="Pest control">{t('pest control')}</option>
                            <option value="Weed control">{t('weed control')}</option>
                            <option value="Mulching">{t('mulching')}</option>
                            <option value="Thinning">{t('thinning')}</option>
                            <option value="Pollination">{t('pollination')}</option>
                            <option value="Cover cropping">{t('cover cropping')}</option>
                            <option value="Staking or trellising">{t('staking or trellising')}</option>
                            <option value="Plant training">{t('plant training')}</option>
                            <option value="Disease management">{t('disease management')}</option>
                            <option value="Nutrient application">{t('nutrient application')}</option>
                            <option value="Crop scouting">{t('crop scouting')}</option>


                        </select>
                    </div>


                    {cropOperation === 'Fertilization' && (
                        <div>
                            <div className='form-group'>
                                <label htmlFor="fertilizer">{t('fertilizer Type')}</label>
                                <select
                                    name="fertilizer"
                                    id="fertilizer"
                                    onChange={(e) => setFertilizer(e.target.value)}
                                >
                                    <option>{t('select')}</option>
                                    <option value="Urea">{t('urea')}</option>
                                    <option value="Diammonium Phosphate">{t('diammonium Phosphate')}</option>
                                    <option value="Monoammonium Phosphate">{t('monoammonium Phosphate')}</option>
                                    <option value="Potassium Nitrate">{t('potassium Nitrate')}</option>
                                    <option value="Triple Superphosphate">{t('triple Superphosphate')}</option>
                                    <option value="Ammonium Nitrate">{t('ammonium Nitrate')}</option>
                                    <option value="Calcium Ammonium Nitrate">{t('calcium Ammonium Nitrate')}</option>
                                    <option value="Potassium Chloride">{t('potassium Chloride')}</option>
                                </select>
                            </div>
                            <div className='form-group'>
                                <label htmlFor="quantity">{t('fertilizer Quantity')}</label>
                                <div className='input-with-addon'>
                                    <span className='addon'>Kg</span>
                                    <input
                                        type="number"
                                        id="quantity"
                                        onChange={(e) => setFertilizerQuantity(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className='form-group'>
                                <label htmlFor="quantity">{('fertilizer Quantity/ Ha')}</label>
                                <div className='input-with-addon'>
                                    <span className='addon'>Kg/ha</span>
                                    <input
                                        type="number"
                                        id="quantityPerHa"
                                        //calculate the fertilizer quantity per hectare using the area of the field. reduce decimal places to 2 and make it editable
                                        value={(fertilizerQuantity / area).toFixed(2)}

                                        onChange={(e) => setFertilizerQuantityPerHa(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className='form-group'>
                                <label htmlFor="note">{t('note')}</label>
                                <textarea
                                    id="note"
                                    onChange={(e) => setNote(e.target.value)}
                                />
                            </div>
                        </div>
                    )}

                    {cropOperation === 'Harvesting' && (
                        <div className='form-group'>
                            <div className='form-group'>
                                <label htmlFor="yield">{t('yield Quantity')}</label>
                                <div className='input-with-addon'>
                                    <span className='addon'>Kg</span>
                                    <input
                                        type="number"
                                        id="yield"
                                        onChange={(e) => setYieldQuantity(e.target.value)}
                                    />
                                </div>

                            </div>

                            <div className='form-group'>
                                <label htmlFor="yield">{t('yield Quantity/Ha')}</label>
                                <div className='input-with-addon'>
                                    <span className='addon'>Kg/ha</span>
                                    <input
                                        type="number"
                                        id="yieldPerHa"
                                        //calculate the yield quantity per hectare using the area of the field. reduce decimal places to 2 and make it editable
                                        value={(yieldQuantity / area).toFixed(2)}
                                        onChange={(e) => setYieldQuantityPerHa(e.target.value)}
                                    />
                                </div>

                            </div>
                            <label htmlFor="note">{('note')}</label>
                            <textarea
                                id="note"
                                onChange={(e) => setNote(e.target.value)}
                            />
                        </div>
                    )}

                    {cropOperation === 'Irrigation' && (
                        <div>
                            <div className='form-group'>
                                <label htmlFor="litres">{t('litres Used')}</label>
                                <div className='input-with-addon'>
                                    <span className='addon'>L</span>
                                    <input
                                        type="number"
                                        id="litres"
                                        onChange={(e) => setIrrigationLitres(e.target.value)}
                                    />
                                </div>
                            </div>

                            <div className='form-group'>

                                <label htmlFor="note">{t('note')}</label>
                                <textarea
                                    id="note"
                                    onChange={(e) => setNote(e.target.value)}
                                />
                            </div>
                        </div>
                    )}


                    <div className="farmpopup__buttons">
                        <button onClick={() => setShowInput(false)}>{t('cancel')}</button>
                        <button onClick={handeSend}>{t('submit')}</button>
                    </div>
                </div>
            ) : (
                ''
            )}
        </div>
    );
};

export default OperationList;
