import React, { useEffect, useMemo, useState, useRef } from 'react';
import ReactDOM from 'react-dom';
import FarmerTopBar from '../../../components/topbar/Topbar';
import { Helmet } from 'react-helmet';
import { AiFillCaretLeft, AiFillCaretRight, AiOutlineDown, AiOutlineUp } from 'react-icons/ai';
import { AiOutlineCloudDownload } from 'react-icons/ai'
import { GoLocation } from 'react-icons/go'
import { BsArrowLeftSquareFill, BsArrowRightSquareFill } from 'react-icons/bs';
import { IoAddOutline } from 'react-icons/io5'
import { IoCloudCircleOutline } from 'react-icons/io5'
import { Link, useNavigate } from 'react-router-dom';
import { onSnapshot } from 'firebase/firestore';
import { polygonCollection } from '../../../../lib/firestoreCollections';
import { FarmPopup } from '../../farms/FarmPopup';
import { API, graphqlOperation } from 'aws-amplify';
import { listFarmDetails, listPackageRequests, listPlanetRequests } from '../../../../graphql/queries';
import FetchingData from '../../../null/FetchingData';
import Sentinel from './packages/SentinelMap';
import axios from 'axios';
import './sentinel.css';
import moment from 'moment';
import Graph from './Bar';
import MapImage from '../../farms/myFields/MapImage';
import { farmerID } from '../../../../userid';
import { fetchAllFarmDetails, fetchFarmDetails } from '../../../fetches/FetchFarmDetails';
import LandsatNDVIMap, { GraphComponent } from './leaflet/LandstaMapComponent';
import MyMapComponent from './leaflet/MyMapComponent';
import { toast } from 'react-toastify';
import { BiHelpCircle } from 'react-icons/bi';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import FeatureAlert from '../../subscriptions/alerts/SubsAlert';
import { set } from 'date-fns';
import PlanetMapComponent from './leaflet/PlanetMapComponent';
import GoUpBtn from './leaflet/GoUpBtn';
import SentinelImageLayer from '../../../__test/NewImagery';
import PlanetScopeDataImporter from '../../../__test/Fetch';
import { createPlanetRequest } from '../../../../graphql/mutations';
import { useTranslation } from 'react-i18next';


const SentinelMemoized = React.memo(Sentinel);

const PlanetSp = ({ handleFarmDetails }) => {
  const [polygons, setPolygons] = useState([])
  const [loading, setLoading] = useState(true)
  const [selectedPolygon, setSelectedPolygon] = useState(null);
  const [selectedFarm, setSelectedFarm] = useState(null);
  const [byoc, setByoc] = useState('empty');
  const [geometry, setGeometry] = useState(null);
  const [clickedCoordinates, setClickedCoordinates] = useState(null); // New state for clicked coordinates
  const [token, setToken] = useState(null);
  const [tokenTimestamp, setTokenTimestamp] = useState(null);
  const [selectedDate, setSelectedDate] = React.useState('2023-10-22');
  const [scrollOffset, setScrollOffset] = useState(0);
  const [fetchingGraphData, setFetchingGraphData] = useState(true);
  const [showImage, setShowImage] = useState(false);
  const [showInfo , setShowInfo] = useState(false);
  const [parentFilteredColorPercentages, setParentFilteredColorPercentages] = useState({});
  const [catalogData, setCatalogData] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [cloudCover, setcloudCover] = useState(null);
  const [ndvi, setndvi] = useState(true);
  const [ndmi, setndmi] = useState(null);
  const [ndwi, setndwi] = useState(null);
  const [ndre, setndre] = useState(null);
  const [msavi, setmsavi] = useState(null);
  const [evapo, setevapo] = useState(null);
  const [updateData, setUpdateData] = useState(false);
  const [shouldDisplayWeather, setShouldDisplayWeather] = useState(false);
  const [plan, setPlan] = useState([]);
  const [testDate, setTestDate] = useState(null);
  const [lateTestDate, setLateTestDate] = useState(null);
  const [index, setIndex] = useState(0);
  const [subscriptionID, setSubscriptionID] = useState(null);
  const [pendingRequests, setPendingRequests] = useState([]);
  const [update, setUpdate] = useState(false);
  const [showGraph, setShowGraph] = useState(false);

  const [state, setState] = useState([
    {
     
      startDate:  new Date(new Date().setDate(new Date().getDate() - 2000)),
      endDate: new Date(),
      key: 'selection'
    }
  ]);

  const { t } = useTranslation();

  const scrollContainer = useRef(null);

  const scroll = (scrollOffset) => {
    scrollContainer.current.scrollLeft += scrollOffset;
  };


  
  let showedDate;
  let lateShowedDate;

  useEffect(() => {
    fetchPlan();
    setLoading(false);
    }, []);

  const fetchPlan = async () => {
    try {
        const packagesRequestData = await API.graphql(graphqlOperation(listPackageRequests, {
            filter: {
                requesterID: {
                    eq: localStorage.getItem('farmerID'),
                },
            },
        }));
         //set plans whose status is approved
         const approvedPlans = packagesRequestData.data.listPackageRequests.items.filter(
          (item) => item.status === 'approved' && item.package !== null
      );
      setPlan(approvedPlans);
        setLoading(false);
     
    } catch (error) {
        console.log(error);
    }
}


  useEffect(() => {
    const hasApprovedMeteobluePlan = plan.some(
        p => p.package.title === 'AGROGIS Plus' && p.status === 'approved'
    );
  
    setShouldDisplayWeather(hasApprovedMeteobluePlan);
  }, [plan]);

  

  
  

  const handleFilteredColorPercentagesChange = (filteredColorPercentages) => {
    setParentFilteredColorPercentages(filteredColorPercentages);
  };



  //fetch image from local storage
  const image = localStorage.getItem('image');

  const handleScroll = (direction) => {
    const container = document.getElementById('date-container');
    const scrollWidth = container.scrollWidth;
    const containerWidth = container.offsetWidth;

    if (direction === 'left') {
      container.scrollLeft -= containerWidth;
      setScrollOffset(scrollOffset - 1);
    } else if (direction === 'right') {
      container.scrollLeft += containerWidth;
      setScrollOffset(scrollOffset + 1);
    }
  };


  const handleDateSelection = (date) => {
    setSelectedDate(date);
    // Handle the selected date in the parent component
    // console.log('Selected Date:', date);
  };


  const renderDateButtons = () => {
    const startDate = new Date();
    const dates = [];

    // Generate dates for the past 400 days
    for (let i = 0; i < 120; i++) {
      const date = new Date(startDate);
      date.setDate(date.getDate() - i);
      dates.push(date);
    }

    const dateFormatter = new Intl.DateTimeFormat('default', {
      month: 'short',
      day: 'numeric',
      year: 'numeric'
    });

    return dates.map((date, index) => {
      // Format the dates as strings for comparison
      const dateString = dateFormatter.format(date);
      const selectedDateString = dateFormatter.format(selectedDate);

      return (
        <button
          key={index}
          onClick={() => handleDateSelection(date)}
          className={dateString === selectedDateString ? 'selected' : ''}
        >
          {dateString}
        </button>
      );
    });
  };

  const timeRange = useMemo(() => ({
    from: moment(selectedDate).subtract(1, 'day').format('YYYY-MM-DD') + 'T00:00:00Z',
    to: moment(selectedDate).format('YYYY-MM-DD') + 'T23:59:59Z'
  }), [selectedDate]);




  const timeRange2 = useMemo(() => ({
    from: lateTestDate+ 'T00:00:00Z',
    to: testDate+'T23:59:59Z'
  }), [state]);
// console.log(timeRange)
//   console.log(timeRange2);
//   console.log(testDate);
//   console.log(selectedDate);

   const polygonCoordinates = selectedPolygon ? selectedPolygon.map(point => [point[0], point[1]]) : [];
  const accessToken = 'eyJraWQiOiJzaCIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiI3MTQ3ZjE0OC0xMTVjLTQ3MzEtOGE3Zi1iYmFkNjcwNDk4YWUiLCJhdWQiOiI2ZGFmZTc2OC0yYjcyLTRhNGEtYTY1MC0xOWVkNWExNTk4MzQiLCJqdGkiOiIzMmY2YTcyZC1kY2NjLTQ2NGYtOThkYy1kMmZhOWQ4ZTkyNTQiLCJleHAiOjE2OTExNjQyMzIsIm5hbWUiOiJBR1JPR0lTIFRFQ0giLCJlbWFpbCI6ImNvbnRhY3RAYWdyb2dpcy5ybyIsImdpdmVuX25hbWUiOiJBR1JPR0lTIiwiZmFtaWx5X25hbWUiOiJURUNIIiwic2lkIjoiNDI4YmIwYTUtMDI4ZS00NGJlLTgwMDItYTE1NWUzZWI2YTk0IiwiZGlkIjoxLCJhaWQiOiI5YzllOTY4NS0yZjFjLTQ1OTUtODJmYy01YjI0MGIwZmM2NDgiLCJkIjp7IjEiOnsicmEiOnsicmFnIjoxfSwidCI6MTIwMDB9fX0.hnLkhPs9Kp42i28tkFTndQudTwaoQCyl886loeyyiHSMo37p7dpc2735ReK-y4z3mmXiWdhv_LU6X3dh3aPg2yqLvsqoIfKLXLt7OPiAcKbVgPgfe-7pc3MQHQRoKwqzzH91-wmN7VcEtiTo5sC92G6WKTdzxJElaVr12CiN60XHQ5qCr0TDfz8nDIgg_KBKey7xssImr3WYgPBXMZGpscgxsdsc46Mx22ecd8Awh4UD0TlCHcxocMRyadRvuqtqAjTapo88RvkqLb3B93t3snSJ6K3ADsnVNPkHder-ZqG_JVI-uaurGlPIkTWBIyXZMOFSWRU33upf9PiQJVkRQw';


const bbox = polygonCoordinates.reduce(
  (acc, coord) => {
    acc[0] = Math.min(acc[0], coord[0]);
    acc[1] = Math.min(acc[1], coord[1]);
    acc[2] = Math.max(acc[2], coord[0]);
    acc[3] = Math.max(acc[3], coord[1]);
    return acc;
  },
  [Infinity, Infinity, -Infinity, -Infinity]
);


// console.log(bbox);

// console.log(`${timeRange.from}/${timeRange.to}`);

const searchParams = {
  provider: 'PLANET',
  planetApiKey: 'PLAK623cadf538df4cd79b21ae7266b7f325',
  bounds: {
    geometry: {
      type: 'Polygon',
      coordinates: [polygonCoordinates],
    },
    properties: {
      crs: 'http://www.opengis.net/def/crs/OGC/1.3/CRS84',
    },
  },
  data: [
    {
      itemType: 'PSScene',
      productBundle: 'analytic_sr_udm2',
      dataFilter: {
        timeRange: {
          from: '2023-06-05T00:00:00.000Z',
        },
        maxCloudCoverage: 90,
        nativeFilter: { // Add this field
          type: 'StringInFilter',
          field_name: 'quality_category',
          config: ['standard']
        }
      },
      harmonizeTo: 'NONE',
    },
  ],
};


  const fetchData = () => {
    fetch('https://services.sentinel-hub.com/api/v1/dataimport/search', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify(searchParams),
    })
    .then(response => response.json())
    .then(data => {
      setCatalogData(data);
     console.log(data)
      // toast('Planet dates fetched successfully');
    })
    .catch(error => {
      console.error(error);
    });
  };

  let cloudCoverage;
  let instruments;
  let gsd;
  let pixelResolution;
  let datetime;

  if (catalogData && catalogData.features.length > 0) {
  // filter the catalogData to get the data for the selected date
  const feature = catalogData.features.find(item => item.properties.published.split('T')[0] === selectedDate);
  
    const properties = feature !== undefined ? feature.properties : {};
     cloudCoverage = properties['cloud_cover'];
     instruments = properties['instrument'];
     gsd = properties['gsd'];
     pixelResolution = properties['pixel_resolution'];
     datetime = properties['published'];
    // use the extracted data to display information to the user
  } else {
    // handle the case where catalogData is not yet available
  }
  
  const date = new Date(datetime);

 const uniqueDates = new Set(); // Using a Set to store unique dates

  if (catalogData) {
    catalogData.features.forEach(item => {
      const date = item.properties.published.split('T')[0];
      uniqueDates.add(date); // Add each date to the Set
    });
  }

  console.log(catalogData)

  const formattedDate = state.startDate ? state.startDate.toISOString().split('T')[0] : '';


  // useEffect(() => {
  //   console.log(selectedDate);
  //   console.log(timeRange);
  //   setTestDate(showedDate)
  //   setLateTestDate(lateShowedDate)
 
  // }, [ updateData]);
  

  // console.log(token)



  useEffect(() => {
     fetchFarmDetails()
    //provoke props function to get farm details
    setLoading(false)
  }, [shouldDisplayWeather])




  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    const storedTokenTimestamp = localStorage.getItem('tokenTimestamp');
    const currentTime = new Date().getTime();

    if (storedToken && storedTokenTimestamp) {
      const tokenExpirationTime = parseInt(storedTokenTimestamp, 10) + 3600000; // 1 hour in milliseconds

      if (currentTime < tokenExpirationTime) {
        setToken(storedToken);
        return; // Exit early to prevent fetching a new token
      }
    }

    fetchToken();
  }, []);

  



  const fetchFarmDetails = async () => {
    try {
      const farmDetails =  await API.graphql(graphqlOperation(listFarmDetails, {
        filter: {      
          farmerID:{
            eq: localStorage.getItem('farmerID')
          } ,  
          active:{
            eq: true
          },
         
        }
      }))
     //only display farm whose name is 'Greaca' or 'Gharic'
      const filteredFarmDetails = farmDetails.data.listFarmDetails.items
      setPolygons(filteredFarmDetails);
      setLoading(false);

    } catch (error) {
        console.log(error);
    }
}

console.log(polygons);

  const fetchToken = async () => {

    const url = 'https://services.sentinel-hub.com/oauth/token';
    const data = {
      client_id: '9b0e3953-be42-4e30-8549-5cbe91be0e5f',
      client_secret: '1PVB3NnfqQjBzk5ychmbJRJMoROpURBj',
      // client_id: '2de95318-4dd3-4799-b109-9c9917e5ca15',
      // client_secret: 'bOO(j*Ll?(p&Wa8/8omTc#N7[LEwlzo49mR,S<tx',
      grant_type: 'client_credentials'
    };
    const options = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    };

    try {
      const response = await axios.post(url, data, options);
      const newToken = response.data.access_token;
      const newTokenTimestamp = new Date().getTime().toString();

      setToken(newToken);
      localStorage.setItem('token', newToken);
      localStorage.setItem('tokenTimestamp', newTokenTimestamp);
    } catch (error) {
      console.error('Failed to fetch token', error);
    }
  };

  const isTokenExpired = () => {
    // Add your token expiration logic here
    // Check if the token has expired based on the token's expiration timestamp
    // You can use libraries like `jsonwebtoken` to decode and check the token expiration
    // Return true if the token has expired, false otherwise
    // Example implementation:
    // const decodedToken = jwt.decode(token);
    // const expirationTimestamp = decodedToken.exp;
    // const currentTimestamp = Math.floor(Date.now() / 1000); // Current timestamp in seconds
    // return expirationTimestamp < currentTimestamp;
  
    // For testing purposes, assuming token expires after 1 hour (3600 seconds)
    const expirationTimestamp = tokenTimestamp + 3600;
    const currentTimestamp = Math.floor(Date.now() / 1000); // Current timestamp in seconds
    return expirationTimestamp < currentTimestamp;
  };


  // console.log(polygons)

  const awsData = [{ "L": [{ "M": { "latitude": { "N": "36.946442442861226" }, "longitude": { "N": "-0.38337338998298015" } } }, { "M": { "latitude": { "N": "36.95674212547839" }, "longitude": { "N": "-0.3906211506166058" } } }, { "M": { "latitude": { "N": "36.94720538464265" }, "longitude": { "N": "-0.3913840746326855" } } }, { "M": { "latitude": { "N": "36.946442442861226" }, "longitude": { "N": "-0.38337338998298015" } } }] }];

  const formattedData = polygons.map((polygon) => {
    const { rings } = polygon;
    const formattedRings = JSON.parse(rings).map((item) => {
      const { latitude, longitude } = item;
      return [latitude, longitude];
    });
    return formattedRings;
  });


  const navigate = useNavigate()
  const [leftWidth, setLeftWidth] = useState(50);
  const [rightWidth, setRightWidth] = useState(50);

  const handleRightArrowClick = () => {
    setLeftWidth(leftWidth + 10);
    setRightWidth(rightWidth - 10);
  };

  const handleLeftArrowClick = () => {
    setLeftWidth(leftWidth - 10);
    setRightWidth(rightWidth + 10);

  };

  const handlePointClick = (coordinates) => {
    setClickedCoordinates(coordinates);
  };


const datemie = new Date(date);
 showedDate = `${datemie.getFullYear()}-${(datemie.getMonth() + 1).toString().padStart(2, '0')}-${datemie.getDate().toString().padStart(2, '0')}`;

//  console.log(`showedDate: ${showedDate}`);

 datemie.setDate(datemie.getDate() - 3);

 lateShowedDate = `${datemie.getFullYear()}-${(datemie.getMonth() + 1).toString().padStart(2, '0')}-${datemie.getDate().toString().padStart(2, '0')}`;

  // console.log(`lateShowedDate: ${lateShowedDate}`);

useEffect(() => {
  setTestDate(showedDate)
  setLateTestDate(lateShowedDate)
}, [index]);

const handleRequest = async () => {
  try {
    await API.graphql(graphqlOperation(createPlanetRequest, {input: {
      farmerID: localStorage.getItem('farmerID'),
      farmDetailsID: selectedFarm.id,
      status: 'pending',
      gmail: localStorage.getItem('farmerEmail'),
    }
    }))
    toast.success(t('successMessages.requestSentToAdmin'))
    setUpdate(!update)
  } catch (error) {
    console.log(error)
  }
}

useEffect(() => {
  pendingRequest()
}, [update])

const pendingRequest = async () => {
  try{
    const pendingRequestData = await API.graphql(graphqlOperation(listPlanetRequests, {
      filter: {
        status: {
          eq: 'pending',
        },
        farmerID: {
          eq: localStorage.getItem('farmerID'),
        },
      },
    }))
    setPendingRequests(pendingRequestData.data.listPlanetRequests.items)
  } catch (error) {
    console.log(error)
  }
}



  
  return (
    <div className="home-container">
      <FarmerTopBar />
      <Helmet>
        <link
          rel="stylesheet"
          href="https://js.arcgis.com/4.26/esri/themes/light/main.css"
        />
      </Helmet>
      {loading ? (
            <FetchingData />
          ) : shouldDisplayWeather ? (
      <section className="mymap-wrapper">
        <div
          className="left-myMap"
          style={{
            position: 'relative',
            width: shouldDisplayWeather ? '' : '100%',
          }}
        >
        
            <>
              {/* <div className='center-buttons'>
      <button onClick={handleLeftArrowClick}><AiFillCaretLeft /></button>
      <button onClick={handleRightArrowClick}><AiFillCaretRight /></button>
    </div> */}
              <section className="my-fields-header">
                <h2 style={{marginTop:5, marginBottom:5}}>Planet scope</h2>

                {/* <div>
        <button style={{ marginRight: '5px' }}>
          <AiOutlineCloudDownload />
        </button>
        <button onClick={() => navigate('/myfarm/create')}>
          <IoAddOutline />
        </button>
      </div> */}
              </section>
              <section className="myfield-container">
                {/* <p>You have not created any fields yet</p> */}
                {loading && <FetchingData />}
                {formattedData.map((polygon, index) => (
                  <div key={index} className="polygon-item">
                    <div style={{ width: '100%' }}>
                      <MapImage selectedPolygon={polygon} />
                    </div>
                    <div className="package-name plans">
                      <p>{polygons[index].area.toFixed(2)} ha</p>
                    </div>
                    <div
                      className="location-icon"
                      onClick={() => setSelectedPolygon(polygon)}
                    >
                      <GoLocation />
                    </div>

                    <div className="polygon-info">
                      <p>
                        {t('farm')}: <strong>{polygons[index].name}</strong>
                      </p>
                      <p>
                        {t('crop')}: <strong>{polygons[index].crop}</strong>
                      </p>

                      <button
                        onClick={() => {
                          setSelectedFarm(polygons[index])
                          setByoc(polygons[index].byoc)
                          setSubscriptionID(polygons[index].subscriptionID)
                          setSelectedPolygon(polygon)
                          fetchToken()
                          setTimeout(() => {
                            setFetchingGraphData(false)
                          }, 5000) // 5 seconds delay
                        }}
                      >
                        {t('see more')}
                      </button>
                    </div>
                    {
                      //if polygon has null or 'empty' byoc do not display available
                      polygons[index].byoc === null ||
                      polygons[index].byoc === 'empty' ? (
                        ''
                      ) : (
                        <div className="status-sticker">{t('available')}</div>
                      )
                    }
                  </div>
                ))}
              </section>
              {selectedFarm && (
                <div style={{height: "auto"}} className="farmpopup">
                  {byoc === 'empty' ? (
                    <>
                      <h2>
                        {t(
                          'seems it is your first time to use PlanetScope data for this farm'
                        )}
                      </h2>
                      <h4>{'here is what to expect'}:</h4>
                      <ul>
                        <li>
                          {t(
                            'click on the button below to subscribe to PlanetScope data'
                          )}
                          .
                        </li>
                        <li>
                          {t(
                            'once subscribed, please note that the data will not be immediately available'
                          )}
                          .
                        </li>
                        <li>
                          {t(
                            'the data needs to be ingested, which may take some time, typically a few hours to a day'
                          )}
                          .
                        </li>
                        <li>
                          {t(
                            'once ingested, you will be able to access and explore PlanetScope imagery for your farm'
                          )}
                          .
                        </li>
                      </ul>

                      <div className="sentinel-buttons">
                        {pendingRequests.length > 0 &&
                        pendingRequests.filter(
                          (request) => request.farmDetailsID === selectedFarm.id
                        ).length > 0 ? (
                          <>
                            <button>{t('pending request')}</button>
                          </>
                        ) : (
                          <button onClick={handleRequest}>
                            {t('request subscription')}
                          </button>
                        )}
                        {/* <PlanetScopeDataImporter coordinates={selectedPolygon} accessToken={token} farmId = {selectedFarm.id} farm={selectedFarm} /> */}
                        <button
                          onClick={() => {
                            setSelectedFarm(null)
                            // setSelectedPolygon(null)
                            setFetchingGraphData(true)
                            setShowImage(false)
                          }}
                          style={{
                            color: 'black',
                            backgroundColor: '#E0E0E0',
                            marginTop: '20px',
                          }}
                        >
                          {t('close')}
                        </button>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="sentinel-header">
                        <h2>{selectedFarm.crop}</h2>
                        <h3>{selectedFarm.name}</h3>
                      </div>

                      <section className="parent-component">
                        <button
                          className="arrow-btns"
                          onClick={() => scroll(-100)}
                        >
                          {' '}
                          <BsArrowLeftSquareFill color="#339475" size={25} />
                        </button>

                        <div className="date-container" ref={scrollContainer}>
                          {Array.from(uniqueDates).map((date, index) => (
                            <button
                              key={index}
                              className={
                                date === selectedDate ? 'selected' : ''
                              }
                              onClick={() => {
                                setIndex(index)
                                setSelectedDate(date)
                                setUpdateData(!updateData)
                                setFetchingGraphData(true) // Set fetchingGraphData to true immediately
                                setTimeout(() => {
                                  setFetchingGraphData(false)
                                }, 5000) // 5 seconds delay
                              }}
                            >
                              {date}
                            </button>
                          ))}
                        </div>
                        <button
                          className="arrow-btns"
                          onClick={() => scroll(100)}
                        >
                          <BsArrowRightSquareFill color="#339475" size={25} />
                        </button>
                      </section>
                      <div className="sentinel-switch">
                        <section className="crop-health">
                          <div className="index-group">
                            <button
                              style={{
                                color: msavi ? 'black' : 'white',
                                backgroundColor: msavi ? '#fff' : '#339475',
                                marginTop: '20px',
                                border: 'white 1px solid',
                                marginRight: '10px',
                              }}
                              onClick={() => {
                                setndvi(false)
                                setndmi(false)
                                setndre(false)
                                setndwi(false)
                                setmsavi(true)
                                setevapo(false)
                              }}
                            >
                              {t('early Stage')}
                            </button>
                          </div>

                          <div className="index-group">
                            <button
                              style={{
                                color: ndvi ? 'black' : 'white',
                                backgroundColor: ndvi ? '#fff' : '#339475',
                                marginTop: '20px',
                                border: 'white 1px solid',
                                marginRight: '10px',
                              }}
                              onClick={() => {
                                setndmi(false)
                                setndvi(true)
                                setndwi(false)
                                setndre(false)
                                setmsavi(false)
                                setevapo(false)
                              }}
                            >
                              {t('mid Stage')}
                            </button>
                          </div>

                          <div className="index-group">
                            <button
                              style={{
                                color: ndre ? 'black' : 'white',
                                backgroundColor: ndre ? '#fff' : '#339475',
                                marginTop: '20px',
                                border: 'white 1px solid',
                                marginRight: '10px',
                              }}
                              onClick={() => {
                                setndvi(false)
                                setndmi(false)
                                setndre(true)
                                setndwi(false)
                                setmsavi(false)
                                setevapo(false)
                              }}
                            >
                              {t('late Stage')}
                            </button>
                          </div>
                        </section>

                        {/* 
                      <section className='index-group'>
                       
                      <button
                        style={{ color: ndwi ? 'black' : 'white', backgroundColor: ndwi ? '#fff' : '#339475', marginTop: '20px', border: 'white 1px solid', marginRight: '10px' }}
                        onClick={() => {
                          setndvi(false)
                          setndmi(false)
                          setndre(false)
                          setndwi(true)
                          setmsavi(false)
                          setevapo(false)
                        }
                        }

                      >Water Logging</button>
                    
                      
                      </section> */}
                        {/* 
                      <section className='index-group'>
                    
                      <button
                        style={{ color: evapo ? 'black' : 'white', backgroundColor: evapo ? '#fff' : '#339475', marginTop: '20px', border: 'white 1px solid', marginRight: '10px' }}
                        onClick={() => {
                          setndvi(false)
                          setndmi(false)
                          setndre(false)
                          setndwi(false)
                          setmsavi(false)
                          setevapo(true)
                        }
                        }
                      >EVAPO</button>
                      <span>EVAPO</span>
                  
                      </section> */}
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          marginTop: '20px',
                        }}
                      >
                        <h3>
                          {t('field analytics')}{' '}
                          <span
                            className="tooltip"
                            style={{ paddingBottom: '3px' }}
                          >
                            <BiHelpCircle color="white" size={20} />
                            <span
                              className="tooltiptext"
                              style={{
                                width: '200px',
                                fontSize: '12px',
                                textAlign: 'center',
                                left: '-160%',
                                bottom: '100%',
                                padding: '10px',
                              }}
                            >
                              <p style={{ fontSize: '13px' }}>
                                {ndvi
                                  ? t('indexDescriptions.ndvi')
                                  : ndmi
                                  ? t('indexDescriptions.ndmi')
                                  : ndre
                                  ? t('indexDescriptions.ndre')
                                  : msavi
                                  ? t('indexDescriptions.msavi')
                                  : ndwi
                                  ? t('indexDescriptions.ndwi')
                                  : ''}
                              </p>
                            </span>
                          </span>{' '}
                        </h3>

                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}
                        >
                          <h3>{t('display Graph')}</h3>
                          <span className="tooltip">
                            {showGraph ? (
                              <AiOutlineUp
                                color="white"
                                size={20}
                                onClick={() => setShowGraph(!showGraph)}
                              />
                            ) : (
                              <AiOutlineDown
                                color="white"
                                size={20}
                                onClick={() => setShowGraph(!showGraph)}
                              />
                            )}{' '}
                          </span>
                        </div>
                      </div>
                      {fetchingGraphData ? (
                        <FetchingData />
                      ) : (
                        <GraphComponent
                          showGraph={showGraph}
                          filteredColorPercentages={
                            parentFilteredColorPercentages
                          }
                          area={selectedFarm.area}
                          ndvi={ndvi}
                          ndmi={ndmi}
                          ndre={ndre}
                          msavi={msavi}
                          ndwi={ndwi}
                          evapo={evapo}
                        />
                      )}

                      <div className="sentinel-buttons">
                        <button
                          style={{
                            color: 'black',
                            backgroundColor: '#E0E0E0',
                            marginTop: '20px',
                          }}
                          onClick={() => setShowImage(!showImage)}
                        >
                          {t('show Image')}
                        </button>

                        <button
                          style={{
                            color: 'black',
                            backgroundColor: '#E0E0E0',
                            marginTop: '20px',
                          }}
                          onClick={() => {
                            setShowInfo(true)
                          }}
                        >
                          {t('image Info')}
                        </button>

                        <button
                          onClick={() => {
                            setSelectedFarm(null)
                            // setSelectedPolygon(null)
                            setFetchingGraphData(true)
                            setShowImage(false)
                          }}
                          style={{
                            color: 'black',
                            backgroundColor: '#E0E0E0',
                            marginTop: '20px',
                          }}
                        >
                          Close
                        </button>
                      </div>
                      {showImage && (
                        <SentinelImageLayer
                          accessToken={token}
                          bbox={bbox}
                          timeRange={timeRange}
                          coordinates={selectedPolygon}
                          byoc={byoc}
                        />
                      )}
                      {showInfo && catalogData && (
                        <div>
                          <h2>Image metadata</h2>
                          <ul>
                            <li>
                              {t('cloud Coverage')}: {cloudCoverage}%
                            </li>
                            <li>
                              {t('instruments')}: {instruments}
                            </li>
                            <li>
                              {' '}
                              {t('ground Sample Distance')} (GSD): {gsd}{' '}
                              {t('meters')}
                            </li>
                            <li>
                              {t('pixel Resolution')}: {pixelResolution}
                            </li>
                            <li>
                              {t('datetime')}: {datetime.split('T')[0]}
                            </li>
                          </ul>
                        </div>
                      )}
                    </>
                  )}
                </div>
              )}
            </>
         
        </div>

        <div
          className="right-myMap imagery"
          style={{
            position: 'relative',
            height: shouldDisplayWeather ? '' : 0,
            // width: shouldDisplayWeather ? '' : 0,
          }}
        >
          {/* <SentinelMemoized selectedPolygon={selectedPolygon} handlePointClick={handlePointClick} token = {token}  timeRange={{
            from: moment(selectedDate).subtract(3, 'week').format('YYYY-MM-DD') + 'T00:00:00Z',
            to:  moment(selectedDate).format('YYYY-MM-DD') + 'T00:00:00Z'
          }}
          selectedDate={selectedDate}
          // onImageReady= {handleImageReady}
           /> */}

          <PlanetMapComponent
            selectedPolygon={selectedPolygon}
            token={token}
            onFilteredColorPercentages={handleFilteredColorPercentagesChange}
            timeRange={timeRange}
            updateData={updateData}
            parentFilteredColorPercentages={parentFilteredColorPercentages}
            fetchMetaData={fetchData}
            cloudCover={cloudCoverage}
            ndviData={ndvi}
            ndmiData={ndmi}
            ndreData={ndre}
            ndwiData={ndwi}
            msaviData={msavi}
            evapoData={evapo}
            showedDate={showedDate}
            lateShowedDate={lateShowedDate}
            byoc={byoc}
            subscriptionID={subscriptionID}
            shouldDisplayWeather={shouldDisplayWeather}
          />
        </div>

        <GoUpBtn />
      </section>
       ) : (
        !shouldDisplayWeather && <FeatureAlert />
      )}
    </div>
  )
};

export default PlanetSp;
