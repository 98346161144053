import React from 'react'
import { useNavigate } from 'react-router-dom'
import styled, { keyframes } from 'styled-components'
import { useTranslation } from 'react-i18next'

// Keyframe animation for fading in
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

// Styled components
const AlertContainer = styled.div`
  width: 100%;
  background-color: #ffffff;
  text-align: center;
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  animation: ${fadeIn} 0.3s ease-in-out;
`

const AlertTitle = styled.h2`
  font-size: 24px;
  margin-bottom: 10px;
  color: #333333;
`

const AlertMessage = styled.p`
  font-size: 16px;
  color: #333333;
  margin-bottom: 20px;
`

const AlertButton = styled.button`
  background-color: #339475;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;

  &:hover {
    background-color: #339475;
  }
`

const FeatureAlert = ({ featureName, path }) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  return (
    <AlertContainer>
      <AlertTitle>{t('access Denied')}</AlertTitle>
      <AlertMessage>
        {t('you do not have the required plan to access the')} {featureName}{' '}
        {t('feature')}.
      </AlertMessage>
      <AlertButton
        navigate
        onClick={() => navigate('/farmer/account/subscription')}
      >
        {t('upgrade Plan')}
      </AlertButton>
    </AlertContainer>
  )
}

export default FeatureAlert
