import React, { useEffect, useMemo, useState } from 'react';

const SentinelTrueColor = ({ accessToken, coordinates,showedDate, lateShowedDate }) => {
  const [trueColor, setTrueColor] = useState(null);

  const timeRange = useMemo(() => {
    if (showedDate != NaN-NaN-NaN && lateShowedDate!= NaN-NaN-NaN){
      return { from: lateShowedDate + 'T00:00:00Z',
         to: showedDate + 'T23:59:59Z'
        };
    }else{
      return { from: '2022-05-01T00:00:00Z', to: '2022-05-31T23:59:59Z' };
    }
  }, [showedDate, lateShowedDate]);

  useEffect(() => {
    const fetchSentinelData = async () => {
      try {
        const response = await fetch('https://services.sentinel-hub.com/api/v1/process', {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            input: {
              bounds: {
                properties: {
                  crs: "http://www.opengis.net/def/crs/OGC/1.3/CRS84"
                },
                geometry: {
                  type: "Polygon",
                  coordinates: [coordinates]
                }
              },
              data: [{
                type: 'S2L2A', // Sentinel-2 Level-2A data
                dataFilter: {
                  timeRange: { from: timeRange.from, to: timeRange.to }
                }
              }]
            },
            output: { responses: [{ identifier: 'default', format: { type: 'image/png' } }] },
            evalscript: `
              //VERSION=3
              function setup() {
                return {
                  input: ["B02", "B03", "B04", "dataMask"],
                  output: { bands: 4 }
                };
              }

              function evaluatePixel(sample) {
                let gain = 2.5;
                return [sample.B04 * gain, sample.B03 * gain, sample.B02 * gain, sample.dataMask];
              }
            `
          })
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const blob = await response.blob();
        const url = URL.createObjectURL(blob);
        setTrueColor(url);
      } catch (error) {
        console.error("Failed to fetch Sentinel data:", error);
      }
    };

    fetchSentinelData();
  }, [accessToken, timeRange, coordinates]);

  console.log("True color:", trueColor);  

  return (
    <div>
      {trueColor ? <img src={trueColor} alt="True Color" /> : <p>Loading image...</p>}
    </div>
  );
};

export default SentinelTrueColor;
