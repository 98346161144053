import React, { useEffect, useState } from 'react';
import { API, Auth, graphqlOperation } from 'aws-amplify';
import { Link, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import LeftContainer from './LeftContainer';
import './signup.css';
import { createFarmer } from '../../../graphql/mutations';
import { toast } from 'react-toastify';
import jwt_decode from "jwt-decode";
import { useTranslation } from 'react-i18next';
import i18n from "i18next";
import { BsGlobe2 } from 'react-icons/bs';
import Flag from 'react-world-flags'
import { countryCodes } from './countries';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css'


const Signup = ({ onSignup }) => {
  const remember = localStorage.getItem('remember');
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState(''); // New state for phone number
  const [selectedCountryCode, setSelectedCountryCode] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(remember);
  const [adminCode, setAdminCode] = useState('');
  const [isAdmin, setIsAdmin] = useState('false'); // New state for admin registration
  const [adminCodeError, setAdminCodeError] = useState(''); // New state for admin code error
  const [errorSignUp, setErrorSignUp] = useState(''); // 
  const [user, setUser] = useState(null)
  const [showDropdown, setShowDropdown] = useState(false);
  


  const handleCountryCodeChange = (selectedOption) => {
    setSelectedCountryCode(selectedOption.value);
  };

;



  const {t} = useTranslation();

  let userObject = {}

//   const handleCallbackResponse = (response) => {
//     console.log("response", response.credential)
//      userObject = jwt_decode(response.credential)
//     console.log("userObject", userObject)
//     setUser(userObject)
//     //console log name and email
//     console.log(userObject.name)
//     //store userObject's name and email in local storage
//     localStorage.setItem('google', JSON.stringify(userObject))
//     //navigate to home page
//     //send request to backend to create a new user with name, email and password
//     handleRegister()

// }

// //send request to backend to create a new user with name, email and password
// const handleRegister = async () => {
//   //if account exist, add login functionality
//   try{
//     await Auth.signIn(userObject.email, userObject.sub);

//     const user = await Auth.currentAuthenticatedUser();

//         //store id in local storage
//         localStorage.setItem('farmerID', user.attributes.sub);
//         localStorage.setItem('farmerEmail', user.attributes.email);
//         localStorage.setItem('farmerName', user.attributes.given_name);
//         localStorage.setItem('phoneNumber', user.attributes.phone_number);
//         localStorage.setItem('packageName', 'AGROGIS Freemium')

//              // Create new farmer record
//       try {
//         await API.graphql(
//           graphqlOperation(createFarmer, {
//             input: {
//               id: user.attributes.sub,
//               email: user.attributes.email,
//               firstName: user.attributes.given_name,
//               lastName: user.attributes.family_name,
//               phone: user.attributes.phone_number,
//             },
//           })
//         );
//         } catch (error) {
//         console.log('Error creating farmer record:', error);
//         }

//     navigate('/farmer')
//   }catch(error){
//     console.log(error)
//   }

//   try {
//     const { user } = await Auth.signUp({
//       username: userObject.email,
//       password: userObject.sub,
//       attributes: {
//         email: userObject.email,
//         given_name: userObject.name,
//         family_name: userObject.family_name,
//         phone_number: userObject.phone_number,
//       },
//     });


//     navigate('verify-email');

//     // Registration successful, do something (e.g., redirect to a confirmation page).
//   } catch (error) {
//     // If the error is due to a user already existing, display the error message to the user.
//     if (error?.response?.data?.message) {
//       console.log('Error:', error.response.data.message);
//           } else {
//       // Handle other error scenarios (e.g., network error, invalid input, etc.).
//       console.log('Error:', error.message);
//       setErrorSignUp(`Error: ${error.message}, click on the sign in button to sign in`);
//     }
//   }

//   onSignup( userObject.email, userObject.sub,  isAdmin);
// };

//719668832114-ieqsiradroo9m4tb6584acqhcr80siet.apps.googleusercontent.com
//117852649508-t13ajvt9etu46132uenkvuvver29vpol.apps.googleusercontent.com

// useEffect(()=>{
// /* global google */
// google.accounts.id.initialize({
//   client_id: '117852649508-t13ajvt9etu46132uenkvuvver29vpol.apps.googleusercontent.com',
//   callback: handleCallbackResponse,
// })

// google.accounts.id.renderButton(
//   document.getElementById('signInDiv'),
//   {
//     width: 250,
//     height: 50,
//     longtitle: true,
//     type: 'standard',
//     text: 'standard',
//   }
// );

// google.accounts.id.prompt()
// }, [])



  useEffect(() => {
    const remember = localStorage.getItem('remember');

  }, []);

  const handleSignUp = async (e) => {
    setLoading(true);
    e.preventDefault();


  // Check if the phone number starts with the Kenyan country code (+254)
  // let formattedPhoneNumber = phoneNumber;
  // if (!phoneNumber.startsWith('+40')) {
  //   formattedPhoneNumber = '+40' + phoneNumber;
  // }

  const formattedPhoneNumber = selectedCountryCode + phoneNumber;



    try {
      const { user } = await Auth.signUp({
        username: email,
        password,
        attributes: {
          email,
          given_name: firstName,
          family_name: lastName,
          phone_number: formattedPhoneNumber, // New attribute for phone number
          //
        },
      });

    
      setLoading(false);

      navigate('/verify-email');
    } catch (error) {
      setErrorSignUp(error.message);
      setLoading(false);
      // Handle signup error, display error message, etc.
    }



      

    onSignup(email, password,  isAdmin);
  };

  const toggleAdminRegistration = () => {
    setIsAdmin(!isAdmin);
  };


  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
    toggleDropdown();
};

const toggleDropdown = () => {
  setShowDropdown(!showDropdown);
};

const linkSpacing = {
  margin: '0 10px', // Adjust the value as needed
  
};


const customStyles = {
  control: (provided, state) => ({
    ...provided,
    height: '50px', // Set the desired height
    borderRadius: '5px 0 0 5px',
    border: state.isFocused ? '1px solid #757575' : '1px solid #757575',
    boxShadow: state.isFocused ? '0 0 0 1px #757575' : 'none',
    outline: 'none',
  }),
  singleValue: (provided) => ({
    ...provided,
    lineHeight: '50px', // Adjust line height to vertically center the text
  }),
};

  return (
    <div className='register-container'>
      <LeftContainer />
      <section className='right-container'>
      <div className='signup-inputs'>
      {
          localStorage.getItem('emailToBeVerified')  ?
          <>
           <p>Pending verification for email {localStorage.getItem('emailToBeVerified')}</p>
           <button className='bg-primary text-white' onClick={() => {
              localStorage.removeItem('emailToBeVerified');
              navigate('/verify-email');
            }
            }>Verify Email</button>
          </>
            : ''
        }
        <h1>{t('signUpTitle')}</h1>
        <p>{t('enterDetails')}</p>
        <form onSubmit={handleSignUp}>
          <div className='names'>
            <section style={{ marginRight: '10px' }}>
              <div className='form-group'>
                <label htmlFor='firstName'>{t('firstNameLabel')}</label>
                <input
                  placeholder={t('firstNamePlaceholder')}
                  type='text'
                  id='firstName'
                  name='firstName'
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </div>
            </section>
            <section>
              <div className='form-group'>
                <label htmlFor='lastName'>{t('lastNameLabel')}</label>
                <input
                  placeholder={t('lastNamePlaceholder')}
                  type='text'
                  id='lastName'
                  name='lastName'
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </div>
            </section>
          </div>
          <div className='form-group'>
            <label htmlFor='email'>{t('emailLabel')}</label>
            <input
              placeholder={t('emailPlaceholder')}
              type='email'
              id='email'
              name='email'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

         
          <div className='form-group'>
            <label htmlFor='phoneNumber'>{t('phonePlaceholder')}</label>
          <PhoneInput
      placeholder={t('phonePlaceholder')}
      value={phoneNumber}
      onChange={setPhoneNumber}/>
</div>


          <div className='form-group'>
            <label htmlFor='password'>{t('passwordLabel')}</label>
            <input
              placeholder={t('passwordPlaceholder')}
              type='password'
              id='password'
              name='password'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>

          <div className='terms'>
              <input
                style={{ padding: '0', margin: '0 10px 0 0' }}
                type='checkbox'
                id='privacy_policy'
                name='privacy_policy'
             
              />
                <Link to="/privacy-policy" target="_blank" style={linkSpacing}>
  {t('privacyPolicyLink')}
</Link>{' '}
{t('andText')}{' '}
<Link to="/terms-and-conditions" target="_blank" style={linkSpacing}>
  {t('termsOfServiceLink')}
</Link>
            </div>


          <button type='submit' className='signup-button'>
            {t('signUpButton')}
          </button>
          {errorSignUp && <p style={{ color: 'red' }}>{errorSignUp}</p>}
        </form>

        <div className='google-signup'>
          <p>{t('orText')}</p>
          {/* <div id='signInDiv'></div> */}
        </div>

        <p style={{ textAlign: 'center' }}>
          {t('haveAccountText')} <Link to='/'>{t('signInLink')}</Link>
        </p>
      </div>
    </section>
    <section style={{position:'absolute', top:'0', right:'10px', margin:'10px'}}>
    <div className='' onClick={toggleDropdown} style={{position:'relative'}}> 
        <BsGlobe2 />
        
        {showDropdown && (
        <div className='lang-switch'style={{right:'1%'}} >
        <Flag code="RO" height="14" onClick={() => changeLanguage('fr')} />
      <button onClick={() => changeLanguage('fr')}>Română</button>
      <Flag code="GB" height="14"  onClick={() => changeLanguage('en')} />
      <button onClick={() => changeLanguage('en')}>English</button>     
      <Flag code="IT" height="14" onClick={() => {changeLanguage('it')}} />
      <button onClick={() => {
                  changeLanguage('it')
                  
                }}>Italiano</button> 
      </div>
      )}
      </div>
      </section>
    </div>
  );
};

export default Signup;
