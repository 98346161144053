import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Helmet } from "react-helmet";
import { AiFillCaretLeft, AiFillCaretRight } from "react-icons/ai";
import FarmRegistration from "../farms/FarmRegistration";
import FarmerTopBar from "../../components/topbar/Topbar";
import StationsList from "../weather/StationsList";
import MyFarms from "../farms/myFields/MyFarms";
import FieldList from "../imagery/fieldsList";
import IssueList from "./IssueList";
import { API, graphqlOperation } from "aws-amplify";
import { listFarmDetails } from "../../../graphql/queries";
import PointMap from "../farms/myFields/PointMap";
import { farmerID } from "../../../userid";
import {
  fetchFarmDetails,
  fetchIssuesDetails,
} from "../../fetches/FetchFarmDetails";

const Issues = ({ setPoly }) => {
  const [leftWidth, setLeftWidth] = useState(50);
  const [rightWidth, setRightWidth] = useState(50);
  const [clickedCoordinates, setClickedCoordinates] = useState(null); // New state for clicked coordinates
  const [polygons, setPolygons] = useState([]);
  const [selectedFarm, setSelectedFarm] = useState(null);
  const [selectedCoordinates, setSelectedCoordinates] = useState(null);
  const [issues, setIssues] = useState([]);

  const [rings, setRings] = useState([]);

  //use the firts item of polygons as the initial value of formattedRings

  const [formattedRings, setFormattedRings] = useState([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const style = {
    height: windowWidth < 700 ? "50vh" : "100vh",
    width: "100%",
  };

  useEffect(() => {
    if (!rings.length) {
      return;
    }

    const parsedRings = JSON.parse(rings);
    console.log("parsedRings", parsedRings);

    const coordinates = JSON.parse(parsedRings[0]); // Access the nested rings array
    const formattedRings = coordinates.map((item) => [
      item.latitude,
      item.longitude,
    ]);
    setFormattedRings(formattedRings);
  }, [rings, polygons]);

  console.log("formattedRings:", formattedRings);

  useEffect(() => {
    async function fetchData() {
      const farmDetailsData = await fetchFarmDetails();
      setPolygons(farmDetailsData);
      //set rings to the first item of polygons
      console.log(farmDetailsData);
    }

    fetchData();
  }, []);

  console.log("polygons:", polygons);

  useEffect(() => {
    async function fetchData() {
      const issuesData = await fetchIssuesDetails();
      setIssues(issuesData);
      // setSelectedCoordinates(JSON.parse(issuesData[0].coordinates));
    }
    fetchData();
  }, []);

  console.log(issues);

  const handlePointClick = (coordinates) => {
    setClickedCoordinates(coordinates);
  };

  //funtion to get the clicked coordinates from child component
  const handleSelectedCoordinates = (coordinates) => {
    // Parse the coordinates because they are stringified
    const parsedCoordinates = JSON.parse(coordinates);

    console.log(parsedCoordinates);
    setSelectedCoordinates(parsedCoordinates);
  };
  return (
    <div className="home-container">
      <FarmerTopBar />
      <Helmet>
        <link
          rel="stylesheet"
          href="https://js.arcgis.com/4.26/esri/themes/light/main.css"
        />
      </Helmet>

      <section className="mymap-wrapper">
        <div className="left-myMap" style={{ position: "relative" }}>
          <IssueList
            clickedCoordinates={clickedCoordinates}
            farmDetails={polygons}
            onCoordinatesClick={handleSelectedCoordinates}
            setPoly={setRings}
          />
        </div>
        <div className="right-myMap">
          <PointMap
            handlePointClick={handlePointClick}
            selectedIssueCoordinates={clickedCoordinates}
            displayPoint={selectedCoordinates}
            selectedPolygon={formattedRings}
            style={style}
          />
        </div>
      </section>
    </div>
  );
};

export default Issues;
