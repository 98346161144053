
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Helmet } from 'react-helmet';
import { AiFillCaretLeft, AiFillCaretRight, AiOutlineCloudDownload } from 'react-icons/ai';
import FarmRegistration from '../farms/FarmRegistration';
import FarmerTopBar from '../../components/topbar/Topbar';
import MyFarms from '../farms/myFields/MyFarms';
import IssueList from '../issues/IssueList';
import { IoAddOutline } from 'react-icons/io5';
import { API, graphqlOperation } from 'aws-amplify';
import { listFarmDetails } from '../../../graphql/queries';
import OperationList from './OperationsList';
import PointMap from '../farms/myFields/PointMap';


const CropOperations = () => {
    const [leftWidth, setLeftWidth] = useState(50);
  const [rightWidth, setRightWidth] = useState(50);
  const [clickedCoordinates, setClickedCoordinates] = useState(null); // New state for clicked coordinates
  const [polygons, setPolygons] = useState([])
  const [rings, setRings] = useState([]);
  const [formattedRings, setFormattedRings] = useState([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const style = {
    height: windowWidth < 600 ? '50vh' : '100vh',
    width: '100%',
  };


  useEffect(() => {
    if (!rings.length) {
      return;
    }
  
    const parsedRings = JSON.parse(rings);
    const coordinates = JSON.parse(parsedRings[0]); // Access the nested rings array
    const formattedRings = coordinates.map((item) => [item.latitude, item.longitude]);
    setFormattedRings(formattedRings);
  }, [rings]);

  useEffect(() => {
    fetchFarmDetails()
    //provoke props function to get farm details
  }, [])


  async function fetchFarmDetails(){
    const  farmerID = localStorage.getItem('farmerID');

    try {
        const farmDetails = await API.graphql(graphqlOperation(listFarmDetails,{
          filter: {
            farmerID: {
                eq: farmerID,
            },
        },
        }))
        const farmDetailsData = farmDetails.data.listFarmDetails.items
        .filter((farm) => farm.active === true)
        setPolygons(farmDetailsData)
        // console.log(farmDetailsData)
    } catch (error) {
      console.log(error);
    }
  }


  const handleRightArrowClick = () => {
    setLeftWidth(leftWidth +10);
    setRightWidth(rightWidth - 10);
  };

  const handleLeftArrowClick = () => {
    setLeftWidth(leftWidth - 10);
    setRightWidth(rightWidth + 10);
    
  };


  const handlePointClick = (coordinates) => {
    
  };

  return (
    <div className='home-container'>
      <FarmerTopBar />
      <Helmet>
        <link
          rel="stylesheet"
          href="https://js.arcgis.com/4.26/esri/themes/light/main.css"
        />
      </Helmet>
      
      <section className='mymap-wrapper'>
        

      <div className='left-myMap' style={{ position:'relative', padding:'0 10px' }}>
        <section className="my-fields-header">
        
      </section>
        {/* <div className='center-buttons'>
          <button onClick={handleLeftArrowClick}><AiFillCaretLeft /></button>
          <button onClick={handleRightArrowClick}><AiFillCaretRight /></button>
        </div> */}
         {/* <DamageList  farmDetails= {polygons} clickedCoordinates={clickedCoordinates}    /> */}
         <OperationList farmDetails= {polygons} clickedCoordinates={clickedCoordinates} setPoly={setRings}   />
        </div>
       
        <div className='right-myMap' style={{}}>
          <PointMap  handlePointClick={handlePointClick} selectedIssueCoordinates={clickedCoordinates} selectedPolygon={formattedRings} style={style} />
        </div>
      </section>
    </div>
  );
};

export default CropOperations;
