import { useState } from 'react';

let weatherData = {};

export function setWeatherData(data) {
  weatherData = data;
}

export function getWeatherData() {
  return weatherData;
}

export function useWeatherData() {
  const [data, setData] = useState(weatherData);

  function updateWeatherData(newData) {
    setWeatherData(newData);
    setData(newData);
  }

  return [data, updateWeatherData];
}
