import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import FarmerTopBar from '../../components/topbar/Topbar';
import { Helmet } from 'react-helmet';
import MyFarms from '../farms/myFields/MyFarms';
import NoDataComponent from '../../null/NullData';

const Iot = () => {
    const [leftWidth, setLeftWidth] = useState(50);
  const [rightWidth, setRightWidth] = useState(50);

  const handleRightArrowClick = () => {
    setLeftWidth(leftWidth +10);
    setRightWidth(rightWidth - 10);
  };

  const handleLeftArrowClick = () => {
    setLeftWidth(leftWidth - 10);
    setRightWidth(rightWidth + 10);
    
  };
  return (
    <div className='home-container'>
      <FarmerTopBar />
      <Helmet>
        <link
          rel="stylesheet"
          href="https://js.arcgis.com/4.26/esri/themes/light/main.css"
        />
      </Helmet>
     
      <section className='mymap-wrapper'>
        

        <div style={{height: '90vh', width: `${leftWidth}%` , position:'relative' }}>
            
        <NoDataComponent />
        </div>

        <div style={{ height: '90vh', width: `${rightWidth}%` }}>
          <MyFarms />
        </div>
      </section>
    </div>
  );
};

export default Iot;
