import React from 'react';
import { FiLoader } from 'react-icons/fi';
import { BiError } from 'react-icons/bi';
import './NoDataAnimation.css';

const NoDataComponent = () => {
    return (
        <div className="container" style={{width:'100%', height:"100%", display:'flex', justifyContent:'center', alignItems:'center'}}>
            <FiLoader className="rotate"/>
        </div>
    );
}

export default NoDataComponent;
