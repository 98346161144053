import React , {useState, useEffect} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import FarmerTopBar from '../components/topbar/Topbar';
import './news.css';
import {BiArrowBack} from 'react-icons/bi'
import { listNews } from '../../graphql/queries';
import { API, graphqlOperation } from 'aws-amplify';
import ReactQuill from 'react-quill';
import { useTranslation } from 'react-i18next';

const NewsDetails = () => {
 const [news, setNews] = useState([]);
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    fetchNews();
    }
    , []);

  
  const fetchNews = async () => {
    try {
      const newsData = await API.graphql(graphqlOperation(listNews));
      setNews(newsData.data.listNews.items);
      console.log(newsData);
    } catch (error) {
      console.log(error);
    }
  }

  const newsItem = news.find((item) => item.id === id);

  return (
    <div className='home-container news-details-container'>
      <FarmerTopBar />
      {newsItem && (
        <div className="news-details-wrapper">
          <div className="news-details-content mb-8">
            <img src={`https://officialagrogis.s3.amazonaws.com/${newsItem.image}`} alt={newsItem.title} className="news-details-image" />
            <section className='flex justify-between items-center'>
            <h2 className="news-details-title">{newsItem.title}</h2>
            <buttton onClick={()=>navigate(-1)} className='bg-tertiary text-white p-1 rounded mr-2 cursor-pointer'> {t('close')}</buttton>
            </section>
            <p className="news-details-date">Uploaded on: {newsItem.createdAt.slice(0, 10)}</p>
            <ReactQuill theme="snow" value={newsItem.content} readOnly={true} modules={{ toolbar: false }}/>

          </div>
        </div>
      )}
      {/* <div className='close-btn' onClick={()=>navigate(-1)}>
        <BiArrowBack />
      </div> */}
    </div>
  );
};

export default NewsDetails;
