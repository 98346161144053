import React from 'react'
import FarmerTopBar from '../../components/topbar/Topbar'
import {AiOutlineFilePdf} from 'react-icons/ai'
import './document.css'
import { useTranslation } from 'react-i18next'
const Document = () => {
   const { t } = useTranslation()
    const pdfFiles = [
        { name: 'File 1', url: 'https://example.com/file1.pdf', size: '2 MB' },
        { name: 'File 2', url: 'https://example.com/file2.pdf', size: '1.5 MB' },
        { name: 'File 3', url: 'https://example.com/file3.pdf', size: '3.2 MB' },
        // Add more PDF files as needed
      ];
    
    
      const handleClick = (url) => {
        window.open(url, '_blank');
      };
  return (
    <div className='home-container'>
        <FarmerTopBar />
        <div className='pages-container'>
        <h2 className='document-title'>{t('documents')}</h2>
        <div className="pdf-grid">
        {pdfFiles.map((file, index) => (
          <div key={index} className="pdf-card" onClick={() => handleClick(file.url)}>
            <div className="pdf-card__icon">
              <AiOutlineFilePdf />
            </div>
            <section className='card-info'>
            <div className="pdf-card__info">
              <p className="pdf-card__name">{file.name}</p>
              <p className="pdf-card__size">{file.size}</p>
            </div>
            <div className='date'>
                <p>12/12/2021</p>
            </div>
            </section>
           
          </div>
        ))}
      </div>
      </div>
    </div>
  )
}

export default Document