import React from 'react';
import SatelliteMap from './SatelliteMap'; // Adjust the path to your component

function Wms() {
  return (
    <div>
      <SatelliteMap />
    </div>
  );
}

export default Wms;
