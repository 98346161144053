import { API, graphqlOperation } from 'aws-amplify'
import React, { useEffect, useState } from 'react'
import { createDroneRequest, createDroneUploads, updateDroneRequest, updateDroneUploads } from '../../../../../graphql/mutations'
import { toast } from 'react-toastify'
import AWS from "aws-sdk";
import { listDroneUploads } from '../../../../../graphql/queries';

const UploadInputs = ({ onClose, farm, setUpdates }) => {
  const [customerId, setCustomerId] = useState('')
  const [droneRequestID, setDroneRequestID] = useState(farm.id)
  const [crop, setCrop] = useState('')
  const [variety, setVariety] = useState('NDVI')
  const [date, setDate] = useState('')
  const [selectedFile, setSelectedFile] = useState(null);
  const [progress, setProgress] = useState(0);
  const [droneImages, setDroneImage] = useState([]);
  const [link, setLink] = useState('')
  const [varietyLinks, setVarietyLinks] = useState([]);
  const [hasPreviousDrones, setHasPreviousDrones] = useState(false);
  const [uploadedData, setUploadedData] = useState([]);

  

  const fetchDroneUploads = async () => {
    try {
      const droneUploadsData = await API.graphql(graphqlOperation(listDroneUploads));
      //get the upload with the current requets with id farm.id
      const filteredData = droneUploadsData.data.listDroneUploads.items.filter((item) => item.droneRequestID === farm.id);
      setUploadedData(filteredData);
      console.log(filteredData);
    } catch (error) {
      console.log(error);
    } 
  }

  useEffect(() => {
    fetchDroneUploads()
    if (farm.hasUpload === true) {
      setHasPreviousDrones(true)
    }
  }, [farm.status])

  const addVarietyLink = () => {
    if (variety && link && date) {
      setVarietyLinks([...varietyLinks, { variety, link, date }]);
      setVariety('');
      setLink('');
    }
  };

  const removeVarietyLink = (index) => {
    const updatedVarietyLinks = [...varietyLinks];
    updatedVarietyLinks.splice(index, 1);
    setVarietyLinks(updatedVarietyLinks);
  };


  const handleUpload = async (droneRequestID) => {

    const data = {
      droneRequestID: droneRequestID,
      link: JSON.stringify(varietyLinks),
      farmerID: farm.farmerID,
      gmail: farm.farmer.email,
    };

    try {
      if(hasPreviousDrones){
        await API.graphql(
          graphqlOperation(updateDroneUploads, {
            input: { id: uploadedData[0].id, 
              link://add the new link to the ones that were existing before
              JSON.stringify([...JSON.parse(uploadedData[0].link), ...varietyLinks]),
              farmerID: farm.farmerID,
              gmail: farm.farmer.email,
             }
          })
        );
      }else{

        await API.graphql(
          graphqlOperation(createDroneUploads, {
            input: data,
          })
        );

        // After successfully uploading the image for the first time, update the request hasUpload to true
        

        const updateRequestStatusData = {
          id: droneRequestID,
          status: 'uploaded', // Update the status to 'uploaded',
          hasUpload: true,
        };
      
      
        try {
          // Update the request status
          await API.graphql(
            graphqlOperation(updateDroneRequest, {
              input: updateRequestStatusData,
            })
          );
          setUpdates(true)
          //close the modal
          onClose();
        } catch (error) {
          toast.error('Error updating request status');
          console.log(error);
        }
      
      }
 


      setVariety('');
      setLink('');
      setVarietyLinks([]);

      toast.success('Uploaded successfully');
      // Reset the variety and link fields
      setVariety('');
      setLink('');
      // onClose();
    } catch (error) {
      toast.error('Error uploading data');
      console.log(error);
    }

    //         // After successfully uploading the image
    // const updateRequestStatusData = {
    //   id: droneRequestID,
    //   status: 'uploaded', // Update the status to 'uploaded'
    // };

    // try {
    //   // Update the request status
    //   await API.graphql(
    //     graphqlOperation(updateDroneRequest, {
    //       input: updateRequestStatusData,
    //     })
    //   );

    //   toast.success('Updated successfully');
    //   onClose();
    // } catch (error) {
    //   toast.error('Error updating request status');
    //   console.log(error);
    // }
  }


  return (
    <div>
      <form className='form-upload' >
        <h1>Upload Form</h1>
        <section style={{ marginRight: '10px' }}>
          {/* <p>Previous mapping date: {hasPreviousDrones ? 'I have previous data' : 'New request'}</p> */}
          {/* <div className='form-group'>
                <label htmlFor='custimerId'>Customer ID</label>
                <input placeholder='' type='text' id='id' name='id' value={customerId} onChange={(e)=> setCustomerId(e.target.value)} />
                </div> */}
        </section>
        <section>
          <div className='form-group'>
            <label htmlFor='crop'>Mapping date</label>
            <input placeholder='' type='date' id='crop' name='crop' value={date} onChange={(e) => setDate(e.target.value)} />
          </div>


          <div className='form-group'>
            <label htmlFor='username'>Field Name </label>
            <input placeholder='' type='text' id='id' name='id' value={farm.id} />
          </div>

          <div className='form-group'>
            <label htmlFor='username'>Field Name </label>
            <input placeholder='' type='text' id='id' name='id' value={farm.fieldName} />
          </div>

          <div className='form-group'>
            <label htmlFor='username'>Field Area </label>
            <input placeholder='' type='number' id='id' name='id' value={(farm.area).toFixed(2)} ha />
          </div>
        </section>

        <div className='form-group'>
          <label htmlFor='crop'>Crop Type</label>
          <input placeholder='' type='text' id='crop' name='crop' value={farm.crop} onChange={(e) => setCrop(e.target.value)} />
        </div>

        <div className='form-group'>
          <label htmlFor='variety'>Type of product being uploaded</label>
          <input
            type='text'
            id='tryp'
            name='password'
            value={variety}
            onChange={(e) => setVariety(e.target.value)}
          />
        </div>

        <div className='form-group'>
          <label htmlFor='link'>Tile Id</label>
          <input
            type='text'
            id='link'
            name='link'
            value={link}
            onChange={(e) => setLink(e.target.value)}
          />
        </div>

        <div className='form-group'>
          <button type='button' onClick={addVarietyLink}>
            Add another id
          </button>
        </div>

        <div className='variety-links-list'>
          {varietyLinks.map((item, index) => (
            <div key={index} className='variety-link-item'>
              <span>{item.variety}</span>
              <span>{item.link}</span>
              <button
                type='button'
                onClick={() => removeVarietyLink(index)}
              >
                Remove
              </button>
            </div>
          ))}
        </div>

      </form>

      <div className='form-group upload-buttons'>
        <button className='signup-button' onClick={() => onClose()}>Cancel</button>

        <button className='signup-button' onClick={() => handleUpload(farm.id)}>Submit</button>
      </div>
    </div>
  )
}

export default UploadInputs