import logo from './logo.svg';
import { App as CapacitorApp } from '@capacitor/app';
import { useNavigate } from 'react-router-dom';
import './App.css';
import Signup from './ADMIN/components/registration/Signup';
import {Routes, Route, Link,  } from 'react-router-dom';
import Login from './ADMIN/components/registration/Login';
import ForgotPassword from './ADMIN/components/registration/ForgotPassword';
import Home from './ADMIN/components/Home/Home';
import Sidebar from './ADMIN/components/Home/sideNavigation/Sidebar';
import UploadData from './ADMIN/components/Home/pages/dataUpload/UploadData';
import Sales from './ADMIN/components/Home/pages/sales/Sales';
import Farms from './ADMIN/components/Home/pages/farms/Farms';
import Docs from './ADMIN/components/Home/pages/documents/Invoice';
import Components from './ADMIN/components/Home/pages/components/Components';
import Help from './ADMIN/components/Home/pages/help/Help';
import FarmerHome from './fARMER/components/home/FarmerHome';
import FarmerSidebar from './fARMER/components/sideNavigation/FarmerSidebar';
import News from './fARMER/pages/News';
import NewsItemDetail from './fARMER/pages/NewsDescription';
import Document from './fARMER/pages/documents/Document';
import FarmerHelp from './fARMER/pages/help/Help';
import Courses from './fARMER/pages/academy/Courses';
import FarmerTopBar from './fARMER/components/topbar/Topbar';
import CourseDetails from './fARMER/pages/academy/CourseDetails';
import Subscriptions from './fARMER/account/subscriptions/Subscriptions';
import SelectSub from './fARMER/account/subscriptions/SelectSub';
import NewsDetails from './fARMER/pages/NewsDescription';
import Maping from './fARMER/account/farms/Registration';
import WeatherMap from './fARMER/account/weather/Weather';
import FarmList from './fARMER/account/farms/FarmList';
import WaetherStations from './fARMER/account/weather/stations';
import LandSat from './fARMER/account/imagery/Landsat';
import FieldList from './fARMER/account/imagery/fieldsList';
import Nvdi from './fARMER/account/imagery/Nvdi';
import Issues from './fARMER/account/issues/Issues';
import SoilAnalysis from './fARMER/account/soilAnalysis/SoilAnalysis';
import Damages from './fARMER/account/cropDamage/Damages';
import Pests from './fARMER/account/dis_pests/Pests';

import VerifyEmail from './ADMIN/components/registration/verification/VerifyEmail';
import { useEffect, useRef, useState } from 'react';
import Iot from './fARMER/account/IoT/Iot';
import CropOperations from './fARMER/account/operations/CropOperations';
import Diseases from './fARMER/account/diseases/Disease';

import SentList from './fARMER/account/imagery/sentinel/SentList';
import LandsatList from './fARMER/account/imagery/sentinel/LandSatList';
import Overview from './ADMIN/components/Home/pages/overview/Overview';
import Support from './fARMER/account/support/Support';
import DroneRequest from './fARMER/account/drone/DroneRequest';
import Accounts from './ADMIN/components/Home/pages/accounts/Accounts';
import PendingRequets from './fARMER/account/drone/PendingRequests';
import LandsatImage from './fARMER/account/imagery/arcgis/LandsatImage';
import PrivacyPolicy from './ADMIN/components/registration/policies/PrivacyPolicy';
import TermsAndConditions from './ADMIN/components/registration/policies/TermsAndConditions';
import InvoiceGenerator from './ADMIN/components/Home/pages/documents/Invoice';
import Profile from './fARMER/account/profile/Profile';
import WeatherStations from './ADMIN/components/Home/pages/stations/WeatherStation';
import PlanetScope from './fARMER/account/imagery/planet_scope/PlanetScope';
import DroneService from './fARMER/account/imagery/drone_service/DroneService';
import Wms from './fARMER/account/imagery/wms-testing/Wms';
import PlanetMap from './fARMER/__test/PlanetScope';
import PlanetScopeDataImporter from './fARMER/__test/Fetch';
import CreateAcademyForm from './ADMIN/components/Home/pages/posts/Academy';
import Imagery from './fARMER/__test/PlanetMetaData';
import PlanetSp from './fARMER/account/imagery/sentinel/PlanetList';
import NewImagery from './fARMER/__test/NewImagery';
import SentinelHubBYOC from './fARMER/__test/Option';
import Colors from './fARMER/__test/Colors';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import PlanetMetaData from './fARMER/__test/PlanetMetaData';
import PlanetRequests from './ADMIN/components/Home/pages/planetRequests/PlanetRequest';
import AdminLogin from './ADMIN/components/registration/adminRegestration/Login';
import { local } from 'd3';
import TestId from './fARMER/account/imagery/drone_service/__test/TestId';
import AddAdmin from './ADMIN/components/Home/pages/AddAdmin';
import CreateNews from './ADMIN/components/Home/pages/posts/News';
import ConnectionStatus from './connectionStatus';
import SentinelAdvanced from './fARMER/account/imagery/sentinel/SentinelAdvanced';
import FetchCropBiomass from './fARMER/__test/FetchCropBiomass';
import CropBiomass from './fARMER/account/imagery/sentinel/cropBiomass';
import Lst from './fARMER/account/imagery/sentinel/lst';
import Leaflet from './fARMER/__test/Leaf';
import SWC from './fARMER/account/imagery/sentinel/swc';
import AppDownloadPopup from './fARMER/components/AppDownloadPopup';
import { toast } from 'react-toastify';



function App() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isAdmin, setIsAdmin] = useState(false); // New state for admin registration
  const [showText, setShowText] = useState(false);
  
  const navigate = useNavigate();
  useEffect(() => {
      // Clear all localStorage data when the user leaves the site
      const clearLocalStorage = () => {
        localStorage.removeItem('farmDetails');
        localStorage.removeItem('issuesDetails');
        localStorage.removeItem('damagesDetails');
        localStorage.removeItem('diseasesDetails');
      };
    
      window.addEventListener('beforeunload', clearLocalStorage);
    
      // Cleanup the event listener when the component is unmounted
      return () => {
        window.removeEventListener('beforeunload', clearLocalStorage);
      };
    }, []);

  useEffect(() => {
    const backButtonListener = CapacitorApp.addListener('backButton', () => {
      if (window.location.pathname === '/farmer') {
        CapacitorApp.exitApp();
      } else {
        navigate(-1);
      }
    });

    return () => {
      backButtonListener.remove();
    };
  }, [navigate]);



const admin = localStorage.getItem('administrator');
const farmerEmail = localStorage.getItem('farmerEmail');


  const handleSignup = (email,password,  isAdmin) => {
    setEmail(email);
    setPassword(password)
    setIsAdmin(isAdmin);
  };

  const tawkMessengerRef = useRef(null);

  const handleMinimize =() => {
      tawkMessengerRef.current.minimize();
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowText(true);
    }, 3000);

    return () => clearTimeout(timer); // Cleanup the timer on component unmount

  }, []); // Empty dependency array to run the effect only once on component mount



  return (
    <div className="App">
      <ConnectionStatus />
  {/* <button onClick={handleMinimize}> Minimize the Chat </button> */}

     <AppDownloadPopup />
      {/* ADMINS ROUTES */}
      <Routes>
      <Route path="/signup.html" component={() => {
          window.location.href = '/public/signup.html';
          window.location.reload(); // Reload the page
          return null;
        }} />

        {/* <Route path="/" element={ <>
          <Sidebar />
           <Overview/>
          </>} /> */}
          <Route path="/" element={<Login />} />
        <Route path="/signup" element={<Signup onSignup={handleSignup}  />} />
        
        <Route path="/verify-email" element={<VerifyEmail email={email} isAdmin={isAdmin} password={password}  />} />
        <Route path='/privacy-policy' element={<PrivacyPolicy/>} />
        <Route path='/terms-and-conditions' element={<TermsAndConditions/>} />

        <Route path='/forgot' element={<ForgotPassword/>} />

        <Route path="/admin/login" element={<AdminLogin />} />
        {
          admin === 'true' ? (
            <>
            <Route path='/dashboard' element={
          <>
          <Sidebar />
           <Overview/>
          </>
        } />
         <Route path='/upload' element={
          <>
          <Sidebar />
           <UploadData/>
          </>
        } />
          <Route path='/sales' element={
          <>
          <Sidebar />
           <Sales/>
          </>
        } />
         <Route path='/planetrequests' element={
          <>
          <Sidebar />
           <PlanetRequests/>
          </>
        } />
          <Route path='/academy' element={
          <>
          <Sidebar />
           <CreateAcademyForm/>
          </>
        } />

<Route path='/news' element={
          <>
          <Sidebar />
           <CreateNews/>
          </>
        } />
          <Route path='/farms' element={
          <>
          <Sidebar />
           <Farms/>
          </>
        } />
          <Route path='/accounts' element={
          <>
          <Sidebar />
           <Accounts />
          </>
        } />
         <Route path='/document' element={
          <>
          <Sidebar />
           <Docs/>
          </>
        } />
          <Route path='/components' element={
          <>
          <Sidebar />
           <Components/>
          </>
        } />
          <Route path='/invoice' element={
          <>
          <Sidebar />
           <InvoiceGenerator/>
          </>
        } />
          <Route path='/weather-station' element={
          <>
           <Sidebar />
          <WeatherStations />
           
          </>
        } />
         <Route path='/add/admin' element={
          <>
           <Sidebar />
          <AddAdmin />
           
          </>
        } />
            </>
          ):(
            <>
              <Route path='/dashboard'
               element={
                <>
                              <p>Seems you are  trying to access an admin route. You will have to sign in as an admin.</p>

              <ul>
                <li>You need yo have an account created with Agrogis</li>
                <li>You need to be signed in as an admin</li>
                <li>Only authorized personel can access this route</li>
              </ul>
              <Link to='/admin/login'>Sign in as <strong>admin</strong></Link>
              </>
              } />
            </>
          )
        }
      
        
          <Route path='/wms' element={
          <>
          {/* <Sidebar /> */}
           <Leaflet />
          </>
        } />
         <Route path='/fetch' element={
          <>
           <FetchCropBiomass />
          </>
        } />
         

{/* FARMERS ROUTES */}
{
          farmerEmail !== null ? (
<>
<Route path='/farmer/' element={
          <>
          <FarmerSidebar />
           <FarmerHome/>
          </>
        } />

<Route path='/myfarm/create' element={
          <>
          <FarmerSidebar />
           <Maping/>
          </>
        } />

<Route path='/myfarm' element={
          <>
          <FarmerSidebar />
           <FarmList />
          </>
        } />

<Route path='/weather' element={
          <>
          <FarmerSidebar />
           <WeatherMap />
          </>
        } />

<Route path='/stations' element={
          <>
          <FarmerSidebar />
           <WaetherStations />
          </>
        } />

<Route path='/sentinel' element={
          <>
          <FarmerSidebar />
          <SentList />
          </>
        } />

        <Route path='/advanced' element={
          <>
          <FarmerSidebar />
          <SentinelAdvanced />
          </>
        } />

<Route path='/landsat' element={
          <>
          <FarmerSidebar />
          <LandsatList />
          </>
        } />

<Route path='/planet' element={
          <>
          <FarmerSidebar />
          <PlanetSp />
          </>
        } />

<Route path='/biomass' element={
          <>
          <FarmerSidebar />
          <CropBiomass />
          </>
        } />

<Route path='/lst' element={
          <>
          <FarmerSidebar />
          <Lst />
          </>
        } />

<Route path='/swc' element={
          <>
          <FarmerSidebar />
          <SWC />
          </>
        } />

<Route path='/drone' element={
          <>
          <FarmerSidebar />
          <DroneService />
          </>
        } />


<Route path='/landsat/details/:id' element={
          <>
          <FarmerSidebar />
           <Nvdi/>
          </>
        } />

<Route path='/issues' element={
          <>
          <FarmerSidebar />
           <Issues />
          </>
        } />

<Route path='/iot' element={
          <>
          <FarmerSidebar />
           <Iot />
          </>
        } />

<Route path='/support' element={
          <>
          <FarmerSidebar />
           <Support />
          </>
        } />

<Route path='/requests' element={
          <>
          <FarmerSidebar />
           <PendingRequets />
          </>
        } />

<Route path='/analysis' element={
          <>
          <FarmerSidebar />
            <SoilAnalysis />
           {/* <LandsatImage /> */}
          </>
        } />

<Route path='/sensors' element={
          <>
          <FarmerSidebar />
           <SoilAnalysis />
          </>
        } />

<Route path='/damages' element={
          <>
          <FarmerSidebar />
           <Damages />
          </>
        } />

<Route path='/diseases' element={
          <>
          <FarmerSidebar />
           <Pests />
          </>
        } />

<Route path='/operations' element={
          <>
          <FarmerSidebar />
           <CropOperations />
          </>
        } />

<Route path='/farmer/news' element={
          <>
          <FarmerSidebar />
           <News/>
          </>
        } />

<Route path='/farmer/news/details/:id' element={
          <>
          <FarmerSidebar />
           <NewsDetails/>
          </>
        } />

<Route path='/farmer/docs/' element={
          <>
          <FarmerSidebar />
           <Document/>
          </>
        } />


<Route path='/farmer/help/' element={
          <>
          <FarmerSidebar />
           <FarmerHelp />
          </>
        } />


<Route path='/farmer/academy/' element={
          <>
          <FarmerSidebar />
           <Courses />
          </>
        } />


<Route path='/farmer/academy/details/:id' element={
          <>
          <FarmerSidebar />
           <CourseDetails />
          </>
        } />

<Route path='/farmer/account/' element={
          <>
          <FarmerSidebar />
           <Profile />
          </>
        } />

<Route path='/farmer/account/subscription' element={
          <>
          <FarmerSidebar />
           <Subscriptions />
          </>
        } />

<Route path='/farmer/account/subscription/details/:id' element={
          <>
          <FarmerSidebar />
           <SelectSub />
          </>
        } />

</>
          ):(
            <>
              <Route path='*' element={
          <>
          {
            showText ? (
              <p>Just a moment</p>
            ):(
              <>
              <h1>Not signed in</h1>
          <Link to='/'>Sign in</Link>
          </>
            )
          }
          </>
        
        } />
            </>
          )
}

       
        <Route path='*' element={
          <>
          <h1>404 NOT FOUND</h1>
          </>
        
        } />
      </Routes>
    </div>
  );
}

export default App;
