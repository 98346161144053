import React, { useEffect, useMemo, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import FarmerTopBar from '../../../components/topbar/Topbar'
import { Helmet } from 'react-helmet'
import {
  AiFillCaretLeft,
  AiFillCaretRight,
  AiOutlineDown,
  AiOutlineUp,
} from 'react-icons/ai'
import { AiOutlineCloudDownload } from 'react-icons/ai'
import { GoLocation } from 'react-icons/go'

import { IoAddOutline, IoCloudCircleOutline } from 'react-icons/io5'
import { Link, useNavigate } from 'react-router-dom'
import { onSnapshot } from 'firebase/firestore'
import { polygonCollection } from '../../../../lib/firestoreCollections'
import { FarmPopup } from '../../farms/FarmPopup'
import { API, graphqlOperation } from 'aws-amplify'
import {
  listFarmDetails,
  listPackageRequests,
} from '../../../../graphql/queries'
import FetchingData from '../../../null/FetchingData'
import Sentinel from './packages/SentinelMap'
import axios from 'axios'
import './sentinel.css'
import moment from 'moment'
import Graph from './Bar'
import MapImage from '../../farms/myFields/MapImage'
import { farmerID } from '../../../../userid'
import {
  fetchAllFarmDetails,
  fetchFarmDetails,
} from '../../../fetches/FetchFarmDetails'
import LandsatNDVIMap, { GraphComponent } from './leaflet/LandstaMapComponent'
import MyMapComponent from './leaflet/MyMapComponent'
import { toast } from 'react-toastify'
import { BiHelpCircle } from 'react-icons/bi'
import { DateRangePicker } from 'react-date-range'
import 'react-date-range/dist/styles.css' // main style file
import 'react-date-range/dist/theme/default.css' // theme css file
import FeatureAlert from '../../subscriptions/alerts/SubsAlert'
import { set } from 'date-fns'
import GoUpBtn from './leaflet/GoUpBtn'
import { BsArrowLeftSquareFill, BsArrowRightSquareFill } from 'react-icons/bs'
import { useTranslation } from 'react-i18next'
import { useMobileWidth } from '../../../../contexts/WidthContext'
import Advanced from './advanced/Advanced'
import PointMap from '../../farms/myFields/PointMap'

const SentinelMemoized = React.memo(Sentinel)

const SentinelAdvanced = ({ handleFarmDetails }) => {
  const [polygons, setPolygons] = useState([])
  const [loading, setLoading] = useState(true)
  const [loadingPlan, setLoadingPlan] = useState(true)
  const [selectedPolygon, setSelectedPolygon] = useState(null)
  const [selectedFarm, setSelectedFarm] = useState(null)
  const [geometry, setGeometry] = useState(null)
  const [clickedCoordinates, setClickedCoordinates] = useState(null) // New state for clicked coordinates
  const [token, setToken] = useState(null)
  const [tokenTimestamp, setTokenTimestamp] = useState(null)
  const [selectedDate, setSelectedDate] = useState(new Date())
  const [scrollOffset, setScrollOffset] = useState(0)
  const [fetchingGraphData, setFetchingGraphData] = useState(true)
  const [showImage, setShowImage] = useState(false)
  const [parentFilteredColorPercentages, setParentFilteredColorPercentages] =
    useState({})
  const [catalogData, setCatalogData] = useState(null)
  const [refresh, setRefresh] = useState(false)
  const [cloudCover, setcloudCover] = useState(null)
  const [ndvi, setndvi] = useState(true)
  const [ndmi, setndmi] = useState(null)
  const [ndwi, setndwi] = useState(null)
  const [ndre, setndre] = useState(null)
  const [msavi, setmsavi] = useState(null)
  const [evapo, setevapo] = useState(null)
  const [updateData, setUpdateData] = useState(false)
  const [shouldDisplayWeather, setShouldDisplayWeather] = useState(false)
  const [plan, setPlan] = useState([])
  const [testDate, setTestDate] = useState(null)
  const [lateTestDate, setLateTestDate] = useState(null)
  const [index, setIndex] = useState(0)
  const [showGraph, setShowGraph] = useState(false)
  const [cloudCoverage, setCloudCoverage] = useState(30)
  const [instruments, setInstruments] = useState(null)
  const [datetime, setDatetime] = useState(null)
  // const [gsd, setGsd] = useState(null)
  // const [platform, setPlatform] = useState(null)

  const [state, setState] = useState([
    {
      startDate: new Date(new Date().setDate(new Date().getDate() - 2000)),
      endDate: new Date(),
      key: 'selection',
    },
  ])

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const style = {
    height: windowWidth < 700 ? '50vh' : '100vh',
    width: '50%',
  };


  const { t } = useTranslation()

  const scrollContainer = useRef(null)

  const scroll = (scrollOffset) => {
    scrollContainer.current.scrollLeft += scrollOffset
  }

  let showedDate
  let lateShowedDate

  useEffect(() => {
    fetchPlan()
    setLoading(false)
  }, [])

  const fetchPlan = async () => {
    try {
      const packagesRequestData = await API.graphql(
        graphqlOperation(listPackageRequests, {
          filter: {
            requesterID: {
              eq: localStorage.getItem('farmerID'),
            },
          },
        })
      )
      //set plans whose status is approved
      const approvedPlans =
        packagesRequestData.data.listPackageRequests.items.filter(
          (item) => item.status === 'approved' && item.package !== null
        )
      setPlan(approvedPlans)
      setLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    const hasApprovedMeteobluePlan = plan.some(
      (p) =>
        p.package.title === 'AGROGIS Plus' ||
        ('AGROGIS Starter' && p.status === 'approved')
    )

    setShouldDisplayWeather(hasApprovedMeteobluePlan)
    setLoadingPlan(false)
  }, [plan])

  console.log(plan)
  console.log(shouldDisplayWeather)

  function handleSelect(ranges) {
    console.log(ranges)
    setState([ranges.selection])
  }

  const handleFilteredColorPercentagesChange = (filteredColorPercentages) => {
    setParentFilteredColorPercentages(filteredColorPercentages)
  }

  //fetch image from local storage
  const image = localStorage.getItem('image')

  const handleScroll = (direction) => {
    const container = document.getElementById('date-container')
    const scrollWidth = container.scrollWidth
    const containerWidth = container.offsetWidth

    if (direction === 'left') {
      container.scrollLeft -= containerWidth
      setScrollOffset(scrollOffset - 1)
    } else if (direction === 'right') {
      container.scrollLeft += containerWidth
      setScrollOffset(scrollOffset + 1)
    }
  }

  const handleDateSelection = (date) => {
    // setSelectedDate(date);
    // Handle the selected date in the parent component
    console.log('Selected Date:', date)
  }

  const renderDateButtons = () => {
    const startDate = new Date()
    const dates = []

    // Generate dates for the past 20 weeks
    for (let i = 0; i < 100; i++) {
      const currentDate = new Date(
        startDate.getTime() - i * 7 * 24 * 60 * 60 * 1000
      )
      dates.push(currentDate)
    }

    const dateFormatter = new Intl.DateTimeFormat('default', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    })

    return dates.map((date, index) => {
      // Format the dates as strings for comparison
      const dateString = dateFormatter.format(date)
      const selectedDateString = dateFormatter.format(selectedDate)

      return (
        <button
          key={index}
          onClick={() => handleDateSelection(date)}
          className={dateString === selectedDateString ? 'selected' : ''}
        >
          {dateString}
        </button>
      )
    })
  }

  console.log(state[0].startDate)

  const timeRange = useMemo(
    () => ({
      from: moment(state[0].startDate).format('YYYY-MM-DD') + 'T00:00:00Z',
      to: moment(state[0].endDate).format('YYYY-MM-DD') + 'T23:59:59Z',
    }),
    [state]
  )

  const timeRange2 = useMemo(
    () => ({
      from: lateTestDate + 'T00:00:00Z',
      to: testDate + 'T23:59:59Z',
    }),
    [state]
  )
  console.log(timeRange)
  console.log(timeRange2)
  console.log(testDate)
  console.log(selectedDate)

  const polygonCoordinates = selectedPolygon
    ? selectedPolygon.map((point) => [point[0], point[1]])
    : []
  const accessToken =
    'eyJraWQiOiJzaCIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiI3MTQ3ZjE0OC0xMTVjLTQ3MzEtOGE3Zi1iYmFkNjcwNDk4YWUiLCJhdWQiOiI2ZGFmZTc2OC0yYjcyLTRhNGEtYTY1MC0xOWVkNWExNTk4MzQiLCJqdGkiOiIzMmY2YTcyZC1kY2NjLTQ2NGYtOThkYy1kMmZhOWQ4ZTkyNTQiLCJleHAiOjE2OTExNjQyMzIsIm5hbWUiOiJBR1JPR0lTIFRFQ0giLCJlbWFpbCI6ImNvbnRhY3RAYWdyb2dpcy5ybyIsImdpdmVuX25hbWUiOiJBR1JPR0lTIiwiZmFtaWx5X25hbWUiOiJURUNIIiwic2lkIjoiNDI4YmIwYTUtMDI4ZS00NGJlLTgwMDItYTE1NWUzZWI2YTk0IiwiZGlkIjoxLCJhaWQiOiI5YzllOTY4NS0yZjFjLTQ1OTUtODJmYy01YjI0MGIwZmM2NDgiLCJkIjp7IjEiOnsicmEiOnsicmFnIjoxfSwidCI6MTIwMDB9fX0.hnLkhPs9Kp42i28tkFTndQudTwaoQCyl886loeyyiHSMo37p7dpc2735ReK-y4z3mmXiWdhv_LU6X3dh3aPg2yqLvsqoIfKLXLt7OPiAcKbVgPgfe-7pc3MQHQRoKwqzzH91-wmN7VcEtiTo5sC92G6WKTdzxJElaVr12CiN60XHQ5qCr0TDfz8nDIgg_KBKey7xssImr3WYgPBXMZGpscgxsdsc46Mx22ecd8Awh4UD0TlCHcxocMRyadRvuqtqAjTapo88RvkqLb3B93t3snSJ6K3ADsnVNPkHder-ZqG_JVI-uaurGlPIkTWBIyXZMOFSWRU33upf9PiQJVkRQw'

  const bbox = polygonCoordinates.reduce(
    (acc, coord) => {
      acc[0] = Math.min(acc[0], coord[0])
      acc[1] = Math.min(acc[1], coord[1])
      acc[2] = Math.max(acc[2], coord[0])
      acc[3] = Math.max(acc[3], coord[1])
      return acc
    },
    [Infinity, Infinity, -Infinity, -Infinity]
  )

  console.log(bbox)

  console.log(`${timeRange.from}/${timeRange.to}`)

  const searchParams = {
    bbox,
    datetime: `${timeRange.from}/${timeRange.to}`,
    collections: ['sentinel-2-l2a'],
    limit: 100,
  }

  const fetchData = () => {
    fetch('https://services.sentinel-hub.com/api/v1/catalog/1.0.0/search', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(searchParams),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data)
        setCatalogData(data)
        const cloudCovers = data.features.map(
          (feature) => feature.properties['eo:cloud_cover']
        )
        setcloudCover(cloudCovers[0])
        console.log(cloudCovers[0])
        // toast('Data fetched successfully');
      })
      .catch((error) => {
        console.error(error)
      })
  }

  let gsd
  let platform

  const processCatalogData = () => {
    if (catalogData && catalogData.features.length > 0) {
      //return features which only have unique dates
      const uniqueFeatures = catalogData.features.filter(
        (thing, index, self) =>
          index ===
          self.findIndex(
            (t) =>
              t.properties.datetime.substr(0, 10) ===
              thing.properties.datetime.substr(0, 10)
          )
      )

      console.log(uniqueFeatures)
      const feature = uniqueFeatures[index]
      const properties = uniqueFeatures[index].properties
      setCloudCoverage(properties['eo:cloud_cover'])
      setInstruments(properties['sentinel:instrument'])
      gsd = properties['gsd']
      platform = properties['platform']
      // datetime = properties['datetime'];
      setDatetime(properties['datetime'])
      // use the extracted data to display information to the user
    } else {
      // handle the case where catalogData is not yet available
    }
  }

  useEffect(() => {
    processCatalogData()
  }, [catalogData, index])
  const date = new Date(datetime)

  // const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
  const dateCloudCoverArray = [] // Using an Array to store date and cloud cover objects

  if (catalogData) {
    //return items which only have unique dates
    const uniqueFeatures = catalogData.features.filter(
      (thing, index, self) =>
        index ===
        self.findIndex(
          (t) =>
            t.properties.datetime.substr(0, 10) ===
            thing.properties.datetime.substr(0, 10)
        )
    )
    console.log(uniqueFeatures)
    uniqueFeatures.forEach((item) => {
      const date = item.properties.datetime.split('T')[0]
      const cloudCover = item.properties['eo:cloud_cover']
      dateCloudCoverArray.push({ date, cloudCover }) // Add each date and cloud cover to the Array
    })
  }

  const formattedDate = state.startDate
    ? state.startDate.toISOString().split('T')[0]
    : ''

  // useEffect(() => {
  //   console.log(timeRange);
  //   setTestDate(showedDate)
  //   setLateTestDate(lateShowedDate)

  // }, [ updateData]);

  // console.log(token)

  useEffect(() => {
    fetchFarmDetails()

    //provoke props function to get farm details
    setLoading(false)
  }, [shouldDisplayWeather])

  useEffect(() => {
    const storedToken = localStorage.getItem('token')
    const storedTokenTimestamp = localStorage.getItem('tokenTimestamp')
    const currentTime = new Date().getTime()

    if (storedToken && storedTokenTimestamp) {
      const tokenExpirationTime = parseInt(storedTokenTimestamp, 10) + 3600000 // 1 hour in milliseconds

      if (currentTime < tokenExpirationTime) {
        setToken(storedToken)
        return // Exit early to prevent fetching a new token
      }
    }

    fetchToken()
  }, [])

  useEffect(() => {
    async function fetchData() {
      const farmDetailsData = await fetchFarmDetails()
      //only get farms whose farm name is either 'AGROGIS' , or "Grau 1" or "Eddu" or "Gharic"
      // const filteredData = farmDetailsData.filter(farm => farm.name === 'AGROGIS' || farm.name === 'water' || farm.name === 'Grau 1' || farm.name === 'eddu' || farm.name === 'Gharic');
      //display those with active as true
      const filteredData = farmDetailsData.filter(
        (farm) => farm.active === true
      )
      setPolygons(filteredData)

      // console.log(farmDetailsData);
    }

    fetchData()
  }, [index])

  // const fetchToken = async () => {

  //   const url = 'https://services.sentinel-hub.com/oauth/token';
  //   const data = {
  //     client_id: '9b0e3953-be42-4e30-8549-5cbe91be0e5f',
  //     client_secret: '1PVB3NnfqQjBzk5ychmbJRJMoROpURBj',

  //     grant_type: 'client_credentials'
  //   };
  //   const options = {
  //     headers: {
  //       'Content-Type': 'application/x-www-form-urlencoded'
  //     },
  //   };

  //   try {
  //     const response = await axios.post(url, data, options);
  //     const newToken = response.data.access_token;
  //     const newTokenTimestamp = new Date().getTime().toString();

  //     setToken(newToken);
  //     localStorage.setItem('token', newToken);
  //     localStorage.setItem('tokenTimestamp', newTokenTimestamp);
  //   } catch (error) {
  //     console.error('Failed to fetch token', error);
  //   }
  // };

  const fetchToken = async () => {
    const url = 'https://services.sentinel-hub.com/oauth/token'
    const clientId = '9b0e3953-be42-4e30-8549-5cbe91be0e5f'
    const clientSecret = '1PVB3NnfqQjBzk5ychmbJRJMoROpURBj'

    const formData = new URLSearchParams()
    formData.append('grant_type', 'client_credentials')
    formData.append('client_id', clientId)
    formData.append('client_secret', clientSecret)

    const options = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/json',
      },
    }

    try {
      fetch(url, {
        method: 'POST',
        body: formData,
        ...options,
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data)
          const { access_token } = data
          setToken(access_token)
          setTokenTimestamp(new Date().getTime().toString())
          localStorage.setItem('token', access_token)
          localStorage.setItem(
            'tokenTimestamp',
            new Date().getTime().toString()
          )
        })
    } catch (error) {
      console.error('Failed to fetch token', error)
    }
  }

  const isTokenExpired = () => {
    // Add your token expiration logic here
    // Check if the token has expired based on the token's expiration timestamp
    // You can use libraries like `jsonwebtoken` to decode and check the token expiration
    // Return true if the token has expired, false otherwise
    // Example implementation:
    // const decodedToken = jwt.decode(token);
    // const expirationTimestamp = decodedToken.exp;
    // const currentTimestamp = Math.floor(Date.now() / 1000); // Current timestamp in seconds
    // return expirationTimestamp < currentTimestamp;

    // For testing purposes, assuming token expires after 1 hour (3600 seconds)
    const expirationTimestamp = tokenTimestamp + 3600
    const currentTimestamp = Math.floor(Date.now() / 1000) // Current timestamp in seconds
    return expirationTimestamp < currentTimestamp
  }

  console.log(polygons)

  const awsData = [
    {
      L: [
        {
          M: {
            latitude: { N: '36.946442442861226' },
            longitude: { N: '-0.38337338998298015' },
          },
        },
        {
          M: {
            latitude: { N: '36.95674212547839' },
            longitude: { N: '-0.3906211506166058' },
          },
        },
        {
          M: {
            latitude: { N: '36.94720538464265' },
            longitude: { N: '-0.3913840746326855' },
          },
        },
        {
          M: {
            latitude: { N: '36.946442442861226' },
            longitude: { N: '-0.38337338998298015' },
          },
        },
      ],
    },
  ]

  const formattedData = polygons.map((polygon) => {
    const { rings } = polygon
    const formattedRings = JSON.parse(rings).map((item) => {
      const { latitude, longitude } = item
      return [latitude, longitude]
    })
    return formattedRings
  })

  console.log(formattedData)

  const navigate = useNavigate()
  const [leftWidth, setLeftWidth] = useState(50)
  const [rightWidth, setRightWidth] = useState(50)

  const handleRightArrowClick = () => {
    setLeftWidth(leftWidth + 10)
    setRightWidth(rightWidth - 10)
  }

  const handleLeftArrowClick = () => {
    setLeftWidth(leftWidth - 10)
    setRightWidth(rightWidth + 10)
  }

  const handlePointClick = (coordinates) => {
    setClickedCoordinates(coordinates)
  }

  const datemie = new Date(selectedDate)
  showedDate = `${datemie.getFullYear()}-${(datemie.getMonth() + 1)
    .toString()
    .padStart(2, '0')}-${datemie.getDate().toString().padStart(2, '0')}`

  console.log(`showedDate: ${showedDate}`)

  datemie.setDate(datemie.getDate() - 3)

  lateShowedDate = `${datemie.getFullYear()}-${(datemie.getMonth() + 1)
    .toString()
    .padStart(2, '0')}-${datemie.getDate().toString().padStart(2, '0')}`

  console.log(`lateShowedDate: ${lateShowedDate}`)

  useEffect(() => {
    setTestDate(showedDate)
    setLateTestDate(lateShowedDate)
  }, [index])

  return (
    <div className="home-container sentinel-page">
      <FarmerTopBar />
      <Helmet>
        <link
          rel="stylesheet"
          href="https://js.arcgis.com/4.26/esri/themes/light/main.css"
        />
      </Helmet>

      <section className="mymap-wrapper advanced-page">
        {!selectedFarm && (
        <div className="left-myMap" style={{ position: 'relative' }}>
          {loadingPlan ? (
            <FetchingData />
          ) : shouldDisplayWeather ? (
            <>
              {/* <div className='center-buttons'>
      <button onClick={handleLeftArrowClick}><AiFillCaretLeft /></button>
      <button onClick={handleRightArrowClick}><AiFillCaretRight /></button>
    </div> */}
              <section className="my-fields-header">
                <h2>Advance Overview</h2>

                {/* <div>
        <button style={{ marginRight: '5px' }}>
          <AiOutlineCloudDownload />
        </button>
        <button onClick={() => navigate('/myfarm/create')}>
          <IoAddOutline />
        </button>
      </div> */}
              </section>
              <section className="myfield-container">
                {/* <p>You have not created any fields yet</p> */}
                {loading && <FetchingData />}

                {formattedData.map((polygon, index) => (
                  <div key={index} className="polygon-item">
                    {/* <h3>Polygon {index + 1}:</h3> */}

                    {/* <img src='https://cdn.agricolus.com/api/images/resize/_UOSWtxhGZwPQw6JvpPk3w==.Jpeg?container=images&width=180&height=180&format=jpg&stretch=false&crop=true&cropfrom=null&quality=100' alt='polygon' /> */}
                    <div style={{ width: '100%' }}>
                      <MapImage selectedPolygon={polygon} />
                    </div>
                    <div className="package-name plans">
                      <p>{polygons[index].area.toFixed(2)} ha</p>
                    </div>
                    <div
                      className="location-icon"
                      onClick={() => setSelectedPolygon(polygon)}
                    >
                      <GoLocation />
                    </div>

                    <div className="polygon-info">
                      <p>
                        {t('farm')}: <strong>{polygons[index].name}</strong>
                      </p>
                      <p>
                        {t('crop')}: <strong>{polygons[index].crop}</strong>
                      </p>

                      {/* <ul>
                      {polygon.map((point, pointIndex) => (
                          <li key={pointIndex}>
                              latitude: {point[0]}, longitude: {point[1]}
                          </li>
                      ))}
                  </ul> */}
                      <button
                        onClick={() => {
                          setSelectedFarm(polygons[index])
                          setSelectedPolygon(polygon)
                          fetchToken()
                          setTimeout(() => {
                            setFetchingGraphData(false)
                          }, 5000) // 5 seconds delay
                        }}
                      >
                        {t('see more')}
                      </button>
                    </div>
                  </div>
                ))}
              </section>
              {selectedFarm && (
                <div className="farmpopup">
                  <div className="sentinel-header">
                    <h2>{selectedFarm.crop}</h2>
                    <h3>{selectedFarm.name}</h3>
                  </div>
                  {/* <h3 style={{margin:'20px 0'}}>Select Date Range <span className="tooltip" style={{paddingBottom:'3px'}}>
                    <BiHelpCircle color='white' size={20} />
                    <span className="tooltiptext" 
                    style={{width:'200px', 
                    fontSize:'12px', 
                    textAlign:'center',
                    left:'-160%',
                    bottom:'100%',
                     padding:'10px'}}
                    >
                      <p style={{fontSize:"13px"}}>date range helps to get specific dates when the imagery was uploaded</p>
                    </span>
                  </span></h3> */}

                  {/* <DateRangePicker
  ranges={state}
  onChange={handleSelect}
/> */}
                  {/* <section className='card-header'>
        <h5 style={{margin:'20px 0 10px 0'}}>Image being displayed:{showedDate} </h5>
        </section> */}

                  <section className="parent-component">
                    <button className="arrow-btns" onClick={() => scroll(-100)}>
                      {' '}
                      <BsArrowLeftSquareFill color="#339475" size={25} />
                    </button>
                    <div className="date-container" ref={scrollContainer}>
                      {dateCloudCoverArray.map((item, index) => (
                        <button
                          style={{ position: 'relative' }}
                          key={index}
                          className={
                            item.date === selectedDate ? 'selected' : ''
                          }
                          onClick={() => {
                            setIndex(index)
                            setSelectedDate(item.date)
                            setUpdateData(!updateData)
                            setFetchingGraphData(true) // Set fetchingGraphData to true immediately
                            setTimeout(() => {
                              setFetchingGraphData(false)
                            }, 5000) // 5 seconds delay
                          }}
                        >
                          {item.date}
                          {item.cloudCover > 25 && (
                            <IoCloudCircleOutline
                              color="gray"
                              size={20}
                              style={{
                                position: 'absolute',
                                top: '-10px',
                                right: '-10px',
                              }}
                            />
                          )}
                        </button>
                      ))}
                    </div>
                    <button className="arrow-btns" onClick={() => scroll(100)}>
                      <BsArrowRightSquareFill color="#339475" size={25} />
                    </button>
                  </section>
                  <div className="sentinel-switch">
                    <section className="crop-health">
                      <div className="index-group">
                        <button
                          style={{
                            color: msavi ? 'black' : 'white',
                            backgroundColor: msavi ? '#fff' : '#339475',
                            marginTop: '20px',
                            border: 'white 1px solid',
                            marginRight: '10px',
                          }}
                          onClick={() => {
                            setndvi(false)
                            setndmi(false)
                            setndre(false)
                            setndwi(false)
                            setmsavi(true)
                            setevapo(false)
                          }}
                        >
                          {t('early Stage')}
                        </button>
                      </div>

                      <div className="index-group">
                        <button
                          style={{
                            color: ndvi ? 'black' : 'white',
                            backgroundColor: ndvi ? '#fff' : '#339475',
                            marginTop: '20px',
                            border: 'white 1px solid',
                            marginRight: '10px',
                          }}
                          onClick={() => {
                            setndmi(false)
                            setndvi(true)
                            setndwi(false)
                            setndre(false)
                            setmsavi(false)
                            setevapo(false)
                          }}
                        >
                          {t('mid Stage')}
                        </button>
                      </div>

                      <div className="index-group">
                        <button
                          style={{
                            color: ndre ? 'black' : 'white',
                            backgroundColor: ndre ? '#fff' : '#339475',
                            marginTop: '20px',
                            border: 'white 1px solid',
                            marginRight: '10px',
                          }}
                          onClick={() => {
                            setndvi(false)
                            setndmi(false)
                            setndre(true)
                            setndwi(false)
                            setmsavi(false)
                            setevapo(false)
                          }}
                        >
                          {t('late Stage')}
                        </button>
                      </div>
                    </section>

                    <section className="index-group">
                      <button
                        style={{
                          color: ndmi ? 'black' : 'white',
                          backgroundColor: ndmi ? '#fff' : '#339475',
                          marginTop: '20px',
                          border: 'white 1px solid',
                          marginRight: '10px',
                        }}
                        onClick={() => {
                          setndmi(true)
                          setndvi(false)
                          setndwi(false)
                          setndre(false)
                          setmsavi(false)
                          setevapo(false)
                        }}
                      >
                        {t('water stress')}
                      </button>
                    </section>

                    <section className="index-group">
                      <button
                        style={{
                          color: ndwi ? 'black' : 'white',
                          backgroundColor: ndwi ? '#fff' : '#339475',
                          marginTop: '20px',
                          border: 'white 1px solid',
                          marginRight: '10px',
                        }}
                        onClick={() => {
                          setndvi(false)
                          setndmi(false)
                          setndre(false)
                          setndwi(true)
                          setmsavi(false)
                          setevapo(false)
                        }}
                      >
                        {t('water Logging')}
                      </button>
                    </section>

                    {/* <section className='index-group'>
                    
                      <button
                        style={{ color: evapo ? 'black' : 'white', backgroundColor: evapo ? '#fff' : '#339475', marginTop: '20px', border: 'white 1px solid', marginRight: '10px' }}
                        onClick={() => {
                          setndvi(false)
                          setndmi(false)
                          setndre(false)
                          setndwi(false)
                          setmsavi(false)
                          setevapo(true)
                        }
                        }
                      >EVAPO</button>
                      <span>EVAPO</span>
                  
                      </section> */}
                  </div>
                  <div className="mobile-map" style={{ width: '100%' }}>
                    {/* <MyMapComponent selectedPolygon={selectedPolygon} token = {token}  onFilteredColorPercentages={handleFilteredColorPercentagesChange}
        timeRange={timeRange2}
        updateData={updateData}
    parentFilteredColorPercentages={parentFilteredColorPercentages}
    fetchMetaData={fetchData}
    cloudCover={cloudCoverage}
    ndviData={ndvi}
    ndmiData={ndmi}
    ndreData={ndre}
    ndwiData={ndwi}
    msaviData={msavi}
    evapoData={evapo}
    showedDate={showedDate}
    lateShowedDate={lateShowedDate}
 /> */}
                  </div>
                  {/* <button onClick={() => setShowImage(true)} style={{color:'black', backgroundColor:'#E0E0E0'}}>Request Image</button>
        {
          showImage && (
            <img style={{height:"auto"}} src={image} alt='sentinel' />

          )
            
        } */}
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      marginTop: '20px',
                    }}
                  >
                    <h3 style={{}}>
                      {t('field analytics')}{' '}
                      <span
                        className="tooltip"
                        style={{ paddingBottom: '3px' }}
                      >
                        <BiHelpCircle color="white" size={20} />
                        <span
                          className="tooltiptext"
                          style={{
                            width: '200px',
                            fontSize: '12px',
                            textAlign: 'center',
                            left: '-160%',
                            bottom: '100%',
                            padding: '10px',
                          }}
                        >
                          <p style={{ fontSize: '13px' }}>
                            {ndvi
                              ? "Normalized Difference Vegetation Index (NDVI) is a remote sensing index that measures the health and density of vegetation by analyzing the difference between near-infrared and red light reflectance from the Earth's surface."
                              : ndmi
                              ? "Normalized Difference Moisture Index (NDMI) is a remote sensing index that measures the water content of vegetation by analyzing the difference between near-infrared and mid-infrared light reflectance from the Earth's surface."
                              : ndre
                              ? "Normalized Difference Red Edge (NDRE) is a remote sensing index that measures the health and density of vegetation by analyzing the difference between near-infrared and red edge light reflectance from the Earth's surface."
                              : msavi
                              ? "Modified Soil Adjusted Vegetation Index (MSAVI) is a remote sensing index that measures the health and density of vegetation by analyzing the difference between near-infrared and red light reflectance from the Earth's surface."
                              : ndwi
                              ? "Normalized Difference Water Index (NDWI) is a remote sensing index that measures the water content of vegetation by analyzing the difference between near-infrared and mid-infrared light reflectance from the Earth's surface."
                              : ''}
                          </p>
                        </span>
                      </span>
                    </h3>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <h3>{t('display Graph')}</h3>
                      <span className="tooltip">
                        {showGraph ? (
                          <AiOutlineUp
                            color="white"
                            size={20}
                            onClick={() => setShowGraph(!showGraph)}
                          />
                        ) : (
                          <AiOutlineDown
                            color="white"
                            size={20}
                            onClick={() => setShowGraph(!showGraph)}
                          />
                        )}{' '}
                      </span>
                    </div>
                  </div>
                  {fetchingGraphData ? (
                    <FetchingData />
                  ) : cloudCoverage > 50 ? (
                    <p>
                      {t(
                        'too much cloud cover. Select another date to view graph'
                      )}
                      .
                    </p>
                  ) : (
                    <GraphComponent
                      showGraph={showGraph}
                      filteredColorPercentages={parentFilteredColorPercentages}
                      area={selectedFarm.area}
                      ndvi={ndvi}
                      ndmi={ndmi}
                      ndre={ndre}
                      msavi={msavi}
                      ndwi={ndwi}
                      evapo={evapo}
                    />
                  )}

                  <div className="sentinel-buttons" style={{paddingBottom:'100px'}}>
                    <button
                      style={{
                        color: 'black',
                        backgroundColor: '#E0E0E0',
                        marginTop: '20px',
                      }}
                      onClick={() => setShowImage(!showImage)}
                    >
                      {t('image Info')}
                    </button>

                    <button
                      onClick={() => {
                        setSelectedFarm(null)
                        // setSelectedPolygon(null)
                        setFetchingGraphData(true)
                        setShowImage(false)
                      }}
                      style={{
                        color: 'black',
                        backgroundColor: '#E0E0E0',
                        marginTop: '20px',
                      }}
                    >
                      {t('close')}
                    </button>
                  </div>

                  {showImage && catalogData && (
                    <div>
                      <h2>{t('image metadata')}</h2>
                      <ul>
                        <li>
                          {t('cloud Coverage')}: {cloudCoverage}%
                        </li>
                        {/* <li>Instruments: {instruments.join(', ')}</li> */}
                        <li>
                          {t('ground Sample Distance')} (GSD): {gsd}{' '}
                          {t('meters')}
                        </li>
                        <li>
                          {t('satellite platform')}: {platform}
                        </li>
                        <li>
                          {t('datetime')}: {datetime}
                        </li>
                      </ul>
                    </div>
                  )}
                  {/* <div className='graph-option'>
                    <h1>Graphs herwe</h1>
                    <Advanced selectedPolygon={selectedPolygon} token = {token} setSelectedFarm={setSelectedFarm}  />
                  </div> */}
                </div>
              )}
            </>
          ) : (
            !shouldDisplayWeather && <FeatureAlert />
          )}
        </div>
        )}

{
  selectedFarm && (

        <div
          className="right-myMap imagery"
          style={{
            position: 'relative',
            height: shouldDisplayWeather ? '' : 0,
            width: '100%',
            height: '100%',
          }}
        >
        
 <Advanced selectedPolygon={selectedPolygon} token = {token} setSelectedFarm={setSelectedFarm}   />


          <GoUpBtn />
        </div>
         )
        }
      </section>
    </div>
 
  )
}

export default SentinelAdvanced
