import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from "i18next";
import { BsGlobe2 } from 'react-icons/bs';
import Flag from 'react-world-flags'
import './policies.css';
const PrivacyPolicy = () => {
  const [showDropdown, setShowDropdown] = useState(false);

  const { t } = useTranslation();
  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
    toggleDropdown();
};

const toggleDropdown = () => {
  setShowDropdown(!showDropdown);
};
  return (
    <div
    className='privacy-policy'
      style={{
        backgroundColor: 'white',
        color: 'darkgreen',
        padding: '20px',
        borderRadius: '8px',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
        maxWidth: '800px',
        margin: '0 auto',
        lineHeight: '1.6',
        fontFamily: 'Arial, sans-serif',
       
        height: '100vh',
        position:'relative'
      }}
    >
            <div className='' onClick={toggleDropdown} style={{position:'relative', textAlign:'right'}}> 
        <BsGlobe2 />
        
        {showDropdown && (
        <div className='lang-switch'style={{right:'1%'}} >
        <Flag code="RO" height="14" onClick={() => changeLanguage('fr')} />
      <button onClick={() => changeLanguage('fr')}>Română</button>
      <Flag code="GB" height="14"  onClick={() => changeLanguage('en')} />
      <button onClick={() => changeLanguage('en')}>English</button>      
      </div>
      )}
      </div>
     <h1 style={{ marginBottom: '20px' }}>{t('privacyPolicy')}</h1>
      <p>{t('agrogisCommitted')}</p>
      <p>{t('privacyPolicyApplies')}</p>
      <h2>{t('definitions')}</h2>
      <p>{t('termsDefined')}</p>
      <ul>
        <li>{t('cookie')}</li>
        <li>{t('company')}</li>
        <li>{t('country')}</li>
        <li>{t('customer')}</li>
        <li>{t('device')}</li>
        <li>{t('ipAddress')}</li>
        <li>{t('personnel')}</li>
        <li>{t('personalData')}</li>
        <li>{t('service')}</li>
        <li>{t('thirdPartyService')}</li>
        <li>{t('you')}</li>
      </ul>

      <h2>{t('whatInfoCollected')}</h2>
      <p>{t('infoCollectedWhen')}</p>
      <p>{t('infoCollectedInclude')}</p>
      <ul>
        <li>{t('nameUsername')}</li>
        <li>{t('phoneNumbers')}</li>
        <li>{t('emailAddresses')}</li>
        <li>{t('mailingAddresses')}</li>
        <li>{t('billingAddresses')}</li>
        <li>{t('debitCreditCardNumbers')}</li>
        <li>{t('password')}</li>
      </ul>

      <p>{t('optionalInfoFromMobileDevices')}</p>
      <ul>
        <li>{t('locationGPS')}</li>
        <li>{t('cameraPictures')}</li> 
        <li>{t('photoGalleryPictures')}</li> 
      </ul>
      <h2>{t('whenAgrogisUseEndUserInfo')}</h2>
      <p>{t('agrogisCollectsEndUserData')}</p>
      <h2>{t('whenAgrogisUseCustomerInfo')}</h2>
      <p>{t('receiveInfoFromThirdParties')}</p>
      <h2>{t('doWeShareInfoWithThirdParties')}</h2>
      <p>{t('mayShareInfoWithThirdParties')}</p>
      <p>{t('engageTrustedThirdPartyServiceProviders')}</p>
      <p>{t('maySharePortionsOfLogFileData')}</p>
      <p>{t('mayDisclosePersonalInfoIfRequiredByLaw')}</p>
      
      <h2>{t('howDoWeStoreAndProtectYourInfo')}</h2>
      <p>{t('agrogisEmploysReasonableSecurityMeasures')}</p>
      <p>{t('agrogisRetainsPersonalInfo')}</p>
      <h2>{t('childrensPrivacy')}</h2>
      <p>{t('agrogisDoesNotKnowinglyCollectOrSolicit')}</p>
      <h2>{t('updatesToThisPrivacyPolicy')}</h2>
      <p>{t('mayUpdateThisPrivacyPolicy')}</p>
      <h2>{t('contactUs')}</h2>
      <p>{t('ifYouHaveAnyQuestionsOrConcerns')}</p>
      <p>
        AGROGIS TECH S.R.L.
        PICTOR ION ANDREESCU 32B, ROOM 3
        GIURGIU, ROMANIA
        Email: privacy@agrogis.com
      </p>
      <p>
        By using AGROGIS and its services, you acknowledge and agree to the terms of this Privacy Policy.
      </p>


    </div>
  );
};

export default PrivacyPolicy;
