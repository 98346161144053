import React, { useEffect, useRef, useState } from 'react'
import { loadModules } from 'esri-loader'
import { useTranslation } from 'react-i18next'
import { t } from 'i18next'

const MyFarms = ({
  selectedPolygon,
  handlePointClick,
  selectedIssueCoordinates,
}) => {
  const mapViewRef = useRef(null)
  const [mapType, setMapType] = useState('hybrid')
  const [openPrint, setOpenPrint] = useState(false)
  const [view, setView] = useState(null) // <-- Add this line
  const [printWidget, setPrintWidget] = useState(null) // <-- Add this line

  useEffect(() => {
    loadModules(
      [
        'esri/Map',
        'esri/views/MapView',
        'esri/Graphic',
        'esri/layers/GraphicsLayer',
        'esri/geometry/Point',
        'esri/widgets/Search',
        'esri/widgets/Print', // Add this line
      ],
      { css: true }
    )
      .then(([Map, MapView, Graphic, GraphicsLayer, Point, Search, Print]) => {
        const map = new Map({
          basemap: mapType,
        })

        const view = new MapView({
          container: mapViewRef.current,
          map: map,
          center: [26.015659, 44.552808],
          zoom: 10,
        })

        setView(view) // <-- Add this line

        const graphicsLayer = new GraphicsLayer()
        map.add(graphicsLayer)

        const testCoordinates = [
          [36.936980196189914, -0.358368571266233],
          [36.944533296775845, -0.35802525522150386],
          [36.93835348720554, -0.3648915736571745],
          [36.936980196189914, -0.358368571266233],
        ]

        if (selectedPolygon && selectedPolygon.length >= 3) {
          const polygonCoordinates = selectedPolygon.map((point) => [
            point[0],
            point[1],
          ])
          const polygon = {
            type: 'polygon',
            rings:
              polygonCoordinates.length >= 3
                ? [polygonCoordinates]
                : testCoordinates,
          }

          const polygonGraphic = new Graphic({
            geometry: polygon,
            symbol: {
              type: 'simple-fill',
              color: [255, 255, 255, 0.4],
              outline: {
                color: [255, 255, 255],
                width: 2,
              },
            },
          })

          graphicsLayer.add(polygonGraphic)

          view.when(() => {
            view.goTo(polygonGraphic.geometry)
          })
        }

        // Add the search widget
        const searchWidget = new Search({
          view: view,
        })

        view.ui.add(searchWidget, 'top-right')

        // Create a symbol for the point
        const dotSymbol = {
          type: 'simple-marker',
          color: [255, 255, 255],
          size: 10,
        }

        // Create a graphics layer to display the clicked points
        const clickedPointsLayer = new GraphicsLayer()
        map.add(clickedPointsLayer)

        // Event handler for map click
        const handleClick = (event) => {
          const clickedPoint = new Point({
            longitude: event.mapPoint.longitude,
            latitude: event.mapPoint.latitude,
          })

          const clickedPointGraphic = new Graphic({
            geometry: clickedPoint,
            symbol: dotSymbol,
          })

          clickedPointsLayer.removeAll() // Clear previous clicked points
          clickedPointsLayer.add(clickedPointGraphic)

          // Get the coordinates of the clicked point
          const coordinates = {
            latitude: clickedPoint.latitude,
            longitude: clickedPoint.longitude,
          }
          handlePointClick(coordinates)
        }

        // Add the click event listener to the view
        view.on('click', handleClick)
      })
      .catch((error) => {
        console.error('Error loading the ArcGIS API for JavaScript:', error)
      })
  }, [selectedPolygon, mapType])

  const handleMapTypeChange = (event) => {
    setMapType(event.target.value)
  }

  const handlePrintClick = () => {
    loadModules(['esri/widgets/Print'], { css: true }).then(([Print]) => {
      if (printWidget) {
        view.ui.remove(printWidget)
        setPrintWidget(null)
      } else {
        const print = new Print({
          view: view,
          printServiceUrl:
            'https://utility.arcgisonline.com/arcgis/rest/services/Utilities/PrintingTools/GPServer/Export%20Web%20Map%20Task',
        })

        view.ui.add(print, {
          position: 'top-right',
        })

        setPrintWidget(print)
      }
    })
  }

  return (
    <div
      className="right-map-component"
      ref={mapViewRef}
      style={{ width: '100%', height: '100%', position: 'relative' }}
    >
      <div
        style={{
          position: 'absolute',
          top: '15px',
          left: '10%',
          color: 'white',
        }}
      >
        {/* <label>
          <select
            style={{ backgroundColor: '#fff', color: '#757575' }}
            value={mapType}
            onChange={handleMapTypeChange}
          >
            <option value="hybrid">{t('hybrid')}</option>
            <option value="streets">{t('streets')}</option>
            <option value="topo">{t('topo')}</option>
            <option value="satellite">{t('satellite')}</option>
            <option value="gray">{t('gray')}</option>
            <option value="oceans">{t('oceans')}</option>
            <option value="national-geographic">{t('national-geographic')}</option>
            <option value="osm">{t('osm')}</option>
            <option value="dark-gray">{t('dark-gray')}</option>

          </select>
        </label> */}
        {/* <button
          style={{
            backgroundColor: '#fff',
            color: '#757575',
            height: '30px',
            marginLeft: '10px',
            marginTop: '50%'
          }}
          onClick={handlePrintClick}
        >
          {t('print')}
        </button> */}
      </div>
    </div>
  )
}

export default MyFarms
