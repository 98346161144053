import * as React from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  LineElement,
  CategoryScale,
} from 'chart.js';

ChartJS.register(
  LinearScale,
  PointElement,
  LineElement,
  CategoryScale,
);

export default function MyLineChart({ dataStat }) {

  console.log(dataStat)
  const data = {
    labels: dataStat && dataStat.map((item) => item.interval.from.split('T')[0]),
    datasets: [
      {
        label: 'NDVI',
        data: dataStat && dataStat.map((item) => item.outputs.data.bands.B0.stats.mean),
        fill: false,
        backgroundColor: 'rgb(255, 99, 132)',
        borderColor: 'rgba(255, 99, 132, 0.2)',
      },
    ],
  }

  const options = {
    // scales: {
    //   y: {
    //     beginAtZero: true,
    //   },
    // },  

    // plugins: {
    //   legend: {
    //     display: false,
    //   },
    //   title: {
    //     display: true,
    //     text: 'NDVI',
    //   },
    // },

    // responsive: true,
    // maintainAspectRatio: false,
    
  }

  return (
    <div style={{ width: '1000px', height: '300px' }}>
    <Line data={data} options={options} />
    </div>
  );
}
