import React, { useEffect } from 'react'
import axios from 'axios'
import GeoRasterLayer from 'georaster-layer-for-leaflet'
import parse_georaster from 'georaster'
import {
  createPlanetRequest,
  updateFarmDetails,
  updatePlanetRequest,
} from '../../graphql/mutations'
import { API, graphqlOperation } from 'aws-amplify'
import { toast } from 'react-toastify'
import { use } from 'i18next'
import { useTranslation } from 'react-i18next'

const ConfirmationToast = ({ message, onConfirm }) => {
   const { t } = useTranslation()
  const handleConfirm = () => {
    toast.dismiss() // Close the toast
    onConfirm() // Call the confirmation function
  }

  return (
    <div>
      {message}
      <button onClick={handleConfirm}>{t('confirm')}</button>
    </div>
  )
}

const PlanetScopeDataImporter = ({
  coordinates,
  farmId,
  farm,
  planetRequestId,
  cloudCover,
  startDatem,
  farmName,
  setUpdates,
  quotaRestriction,
}) => {
  const { t } = useTranslation()
  const [isSubscribed, setIsSubscribed] = React.useState(false)
  const [accessToken, setAccessToken] = React.useState(null)
  const [objectCoordinates, setObjectCoordinates] = React.useState(
    JSON.parse(coordinates)
  )
  const [newCoordinates, setNewCoordinates] = React.useState([])

  //convert cordinates to object

  const convertToNewFormat = () => {
    const formattedCoordinates = JSON.parse(coordinates).map((item) => {
      const { latitude, longitude } = item
      return [latitude, longitude]
    })
    setNewCoordinates(formattedCoordinates)
  }

  console.log(newCoordinates)

  React.useEffect(() => {
    convertToNewFormat()
    const token = localStorage.getItem('token')
    const tokenTimestamp = localStorage.getItem('tokenTimestamp')
    const currentTime = new Date().getTime().toString()

    if (token && tokenTimestamp && currentTime - tokenTimestamp < 3600000) {
      setAccessToken(token)
    } else {
      fetchToken()
    }
  }, [])

  const fetchToken = async () => {
    const url = 'https://services.sentinel-hub.com/oauth/token'
    const data = {
      client_id: '9b0e3953-be42-4e30-8549-5cbe91be0e5f',
      client_secret: '1PVB3NnfqQjBzk5ychmbJRJMoROpURBj',
      // client_id: '6dafe768-2b72-4a4a-a650-19ed5a159834',
      // client_secret: 'Kc,XHKDul[:wz^SCpkMt.p%+Xaf08TAr#pn;TthJ',
      // client_id: '2de95318-4dd3-4799-b109-9c9917e5ca15',
      // client_secret: 'bOO(j*Ll?(p&Wa8/8omTc#N7[LEwlzo49mR,S<tx',
      grant_type: 'client_credentials',
    }
    const options = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }

    try {
      const response = await axios.post(url, data, options)
      const newToken = response.data.access_token
      const newTokenTimestamp = new Date().getTime().toString()

      setAccessToken(newToken)
      localStorage.setItem('token', newToken)
      localStorage.setItem('tokenTimestamp', newTokenTimestamp)
    } catch (error) {
      console.error('Failed to fetch token', error)
    }
  }

  const planetScopeApiKey = 'PLAK623cadf538df4cd79b21ae7266b7f325'
  // Button component
  const handleClick = async () => {
    if (quotaRestriction >= 100) {
      toast.error(t('toastMessages.quotaLimitReached'))
      return
    }
    try {
      const response = await subscribePlanetScopeData(
        accessToken,
        planetScopeApiKey
      )
      const subscriptionId = response.data.id
      const quota = response.data.sqkm

      toast.info(
        <ConfirmationToast
          message={t('toastMessages1.confirmSubscription', { farmName, quota })}
          onConfirm={async () => {
            await confirmSubscription(accessToken, subscriptionId)
          }}
        />,
        {
          position: toast.POSITION.TOP_CENTER,
          autoClose: false,
        }
      )
    } catch (error) {
      console.error(error)
    }
  }

  const subscribePlanetScopeData = async (accessToken, planetScopeApiKey) => {
    const url =
      'https://services.sentinel-hub.com/api/v1/dataimport/subscriptions'

    const data = {
      name: farmName,
      input: {
        planetApiKey: planetScopeApiKey,
        provider: 'PLANET',
        bounds: {
          geometry: {
            type: 'Polygon',
            coordinates: [newCoordinates],
          },
        },
        data: [
          {
            itemType: 'PSScene',
            productBundle: 'analytic_sr_udm2',
            dataFilter: {
              timeRange: {
                from: '2023-01-01T00:00:00.000Z',
              },
              maxCloudCoverage: 90,
              nativeFilter: {
                // Add this field
                type: 'StringInFilter',
                field_name: 'quality_category',
                config: ['standard'],
              },
            },
            harmonizeTo: 'NONE',
          },
        ],
      },
    }

    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    }

    try {
      const response = await axios.post(url, data, options)
      console.log(response.data)
      setIsSubscribed(true)
      return response
    } catch (error) {
      console.error(error)
    }
  }

  const confirmSubscription = async (accessToken, subscriptionId) => {
    const url = `https://services.sentinel-hub.com/api/v1/dataimport/subscriptions/${subscriptionId}/confirm`

    const options = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }

    try {
      const response = await axios.post(url, {}, options)
      console.log(response.data)
      //update the collection id in the database
      await API.graphql(
        graphqlOperation(updateFarmDetails, {
          input: {
            id: farmId,
            byoc: response.data.collectionId,
            subscriptionID: response.data.id,
          },
        })
      )
      toast.success(t('toastMessages2.subscriptionConfirmed'))
      await API.graphql(
        graphqlOperation(updatePlanetRequest, {
          input: { id: planetRequestId, status: 'approved' },
        })
      )
      //reload the page
      setUpdates(true)
      // window.location.reload();
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <button className="package-button" onClick={handleClick}>
      {t('approve')}
    </button>
  )
}

export default PlanetScopeDataImporter
