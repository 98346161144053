import React, { useEffect, useRef, useState } from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import NoDataComponent from '../../../null/Wait';

const MapImage = ({ selectedPolygon }) => {
  const mapViewRef = useRef(null);
  const mapRef = useRef(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Initialize the map only once
    if (!mapRef.current) {
      mapRef.current = L.map(mapViewRef.current, {
        center: [44.552808, 26.015659],
        zoom: 10,
        zoomControl: false // Disable the default zoom control
      });

      // Use Esri's satellite tile layer
      L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', {
        attribution: ''
      }).addTo(mapRef.current);

      L.tileLayer('https://services.arcgisonline.com/ArcGIS/rest/services/Reference/World_Boundaries_and_Places/MapServer/tile/{z}/{y}/{x}').addTo(mapRef.current);
    }

    // Clear previous layers
    if (mapRef.current && mapRef.current._layers) {
      for (let i in mapRef.current._layers) {
        if (mapRef.current._layers[i]._path !== undefined) {
          try {
            mapRef.current.removeLayer(mapRef.current._layers[i]);
          } catch (e) {
            console.log("problem with " + e + mapRef.current._layers[i]);
          }
        }
      }
    }

    // Add the polygon if available
    if (selectedPolygon && selectedPolygon.length >= 3) {
      const latlngs = selectedPolygon.map(point => [point[1], point[0]]);
      L.polygon(latlngs, {
        color: 'white',
        fillColor: 'white',
        fillOpacity: 0.4
      }).addTo(mapRef.current);

      // Fit the map to the polygon bounds
      const bounds = L.latLngBounds(latlngs);
      mapRef.current.fitBounds(bounds);
    }

    setLoading(false);
  }, [selectedPolygon]);

  return (
    <div className='right-map-component' style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '150px', zIndex: -1 }}>
      <div ref={mapViewRef} style={{ width: '100%', height: '150px' }}></div>
    </div>
  );
};

export default MapImage;
