import React, { useEffect, useRef, useState } from 'react'
import { loadModules } from 'esri-loader'
import { API, graphqlOperation } from 'aws-amplify'
import { listFarmDetails } from '../../../../graphql/queries'
import { select } from 'd3'
import { useTranslation } from 'react-i18next'

const PointMap = ({
  selectedPolygon,
  handlePointClick,
  selectedIssueCoordinates,
  displayPoint,
  parcelData,
  style,
}) => {
  const { t } = useTranslation()
  const mapViewRef = useRef(null)
  const [mapType, setMapType] = useState('satellite')
  const [hoveredFarm, setHoveredFarm] = useState(null)
  const [polygons, setPolygons] = useState([])
  const farmerID = localStorage.getItem(
    'CognitoIdentityServiceProvider.7qdqjb4le4n1s9hm92ji49895i.LastAuthUser'
  )

  console.log(selectedPolygon)
  useEffect(() => {
    fetchFarmDetails()
    //provoke props function to get farm details
  }, [])

  async function fetchFarmDetails() {
    try {
      const farmDetails = await API.graphql(
        graphqlOperation(listFarmDetails, {
          filter: {
            farmerID: {
              eq: farmerID,
            },
          },
        })
      )
      const farmDetailsData = farmDetails.data.listFarmDetails.items
      setPolygons(farmDetailsData)
      console.log(farmDetailsData)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    loadModules(
      [
        'esri/Map',
        'esri/views/MapView',
        'esri/Graphic',
        'esri/layers/GraphicsLayer',
        'esri/geometry/Point',
        'esri/widgets/Search',
        'esri/PopupTemplate',
      ],
      { css: true }
    )
      .then(
        ([
          Map,
          MapView,
          Graphic,
          GraphicsLayer,
          Point,
          Search,
          PopupTemplate,
        ]) => {
          const map = new Map({
            basemap: mapType,
          })

          const view = new MapView({
            container: mapViewRef.current,
            map: map,
            center: [26.015659, 44.552808],
            zoom: 6,
          })

          const graphicsLayer = new GraphicsLayer()
          map.add(graphicsLayer)

          const testCoordinates = [
            [36.936980196189914, -0.358368571266233],
            [36.944533296775845, -0.35802525522150386],
            [36.93835348720554, -0.3648915736571745],
            [36.936980196189914, -0.358368571266233],
          ]
          const popupTemplate = () => {
            return {
              title: t('popupTitles.parcelDetails'),
              content: `
        <ul>
          <li>${t('parcelDetails.name')}: ${parcelData.farmDetails.name}</li>
          <li>${t('parcelDetails.crop')}: ${parcelData.farmDetails.crop}</li>
          <li>${t('parcelDetails.area')}: ${parcelData.farmDetails.area.toFixed(
                2
              )} ha</li>
        </ul>
      `,
            }
          }

          if (selectedPolygon && selectedPolygon.length >= 3) {
            const polygonCoordinates = selectedPolygon.map((point) => [
              point[0],
              point[1],
            ])
            const polygon = {
              type: 'polygon',
              rings:
                polygonCoordinates.length >= 3
                  ? [polygonCoordinates]
                  : testCoordinates,
            }

            const polygonGraphic = new Graphic({
              geometry: polygon,
              symbol: {
                type: 'simple-fill',
                color: [255, 255, 255, 0.4],
                outline: {
                  color: [255, 255, 255],
                  width: 2,
                },
              },
              // popupTemplate: popupTemplate()
            })

            graphicsLayer.add(polygonGraphic)

            view.when(() => {
              view.goTo(polygonGraphic.geometry)
              // view.popup.open({
              //   features: [polygonGraphic],
              //   location: polygonGraphic.geometry.centroid, // You can customize the location

              // });
            })
          }

          // Add the search widget
          const searchWidget = new Search({
            view: view,
          })

          view.ui.add(searchWidget, 'top-right')

          // Create a symbol for the point
          const dotSymbol = {
            type: 'simple-marker',
            color: [255, 255, 255],
            size: 10,
          }

          // Create a graphics layer to display the clicked points
          const clickedPointsLayer = new GraphicsLayer()
          map.add(clickedPointsLayer)

          // Event handler for map click
          const handleClick = (event) => {
            const clickedPoint = new Point({
              longitude: event.mapPoint.longitude,
              latitude: event.mapPoint.latitude,
            })

            const clickedPointGraphic = new Graphic({
              geometry: clickedPoint,
              symbol: dotSymbol,
            })

            clickedPointsLayer.removeAll() // Clear previous clicked points
            clickedPointsLayer.add(clickedPointGraphic)

            // Get the coordinates of the clicked point
            const coordinates = {
              latitude: clickedPoint.latitude,
              longitude: clickedPoint.longitude,
            }
            handlePointClick(coordinates)
          }

          // Add the click event listener to the view
          view.on('click', handleClick)

          if (displayPoint && selectedPolygon) {
            const selectedCoordinates = new Point({
              longitude: displayPoint.longitude,
              latitude: displayPoint.latitude,
            })

            const selectedCoordinatesGraphic = new Graphic({
              geometry: selectedCoordinates,
              symbol: dotSymbol,
            })

            clickedPointsLayer.add(selectedCoordinatesGraphic)

            // go to selected coordinates
            view.when(() => {
              view.goTo({
                target: selectedCoordinatesGraphic.geometry,
                zoom: 16,
              })
            })
          }
          // Add the click event listener to the view
          view.on('click', handleClick)
        }
      )
      .catch((error) => {
        console.error('Error loading the ArcGIS API for JavaScript:', error)
      })
  }, [selectedPolygon, mapType, polygons, displayPoint])

  const handleMapTypeChange = (event) => {
    setMapType(event.target.value)
  }

  return (
    <div className="right-map-component" ref={mapViewRef} style={style}></div>
  )
}

export default PointMap
