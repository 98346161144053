import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Helmet } from 'react-helmet';
import { AiFillCaretLeft, AiFillCaretRight, AiOutlineCloudDownload } from 'react-icons/ai';
import FarmRegistration from '../farms/FarmRegistration';
import FarmerTopBar from '../../components/topbar/Topbar';
import WeatherList from './WeatherList';
import StationsList from './StationsList';
import MyFarms from '../farms/myFields/MyFarms';
import { IoAddOutline } from 'react-icons/io5';
import PointMap from '../farms/myFields/PointMap';
import { useTranslation } from 'react-i18next'

const WaetherStations = () => {
  const { t } = useTranslation()
    const [leftWidth, setLeftWidth] = useState(50);
  const [rightWidth, setRightWidth] = useState(50);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const style = {
    height: windowWidth < 600 ? '50vh' : '100vh',
    width: '100%',
  };


  const handleRightArrowClick = () => {
    setLeftWidth(leftWidth +10);
    setRightWidth(rightWidth - 10);
  };

  const handleLeftArrowClick = () => {
    setLeftWidth(leftWidth - 10);
    setRightWidth(rightWidth + 10);
    
  };
  return (
    <div className='home-container'>
      <FarmerTopBar />
      <Helmet>
        <link
          rel="stylesheet"
          href="https://js.arcgis.com/4.26/esri/themes/light/main.css"
        />
      </Helmet>
      
      <section className='mymap-wrapper'>
        

      <div className='left-myMap' style={{position: 'relative'}}>
        <section className="my-fields-header">
        <h3>{t('weather Stations')}</h3>
     
    
      </section>
        {/* <div className='center-buttons'>
          <button onClick={handleLeftArrowClick}><AiFillCaretLeft /></button>
          <button onClick={handleRightArrowClick}><AiFillCaretRight /></button>
        </div> */}
          <StationsList/>
        </div>


        <div className='right-myMap imagery' style={{ position:'relative'}}>
          <PointMap  style={style} />
        </div>
      </section>
    </div>
  );
};

export default WaetherStations;
