import React from 'react'
import FarmerSidebar from '../../components/sideNavigation/FarmerSidebar'
import TopBar from '../../../ADMIN/components/Home/topbar/Topbar'
import './help.css'
import FarmerTopBar from '../../components/topbar/Topbar'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
const FarmerHelp = () => {
   const { t } = useTranslation()
    const navigate = useNavigate();
    const faqItems = [
        {
          question: t('faq.activateSubscription.question'),
          answer: t('faq.activateSubscription.answer'),
        },
        {
          question: t('faq.agrogisApplication.question'),
          answer: t('faq.agrogisApplication.answer'),
        },
        {
          question: t('faq.activateSubscription.question'),
          answer: t('faq.activateSubscription.answer'),
        },
        {
          question: t('faq.benefitsOfPlans.question'),
          answer: t('faq.benefitsOfPlans.answer'),
        },
        {
          question: t('faq.largeFarm.question'),
          answer: t('faq.largeFarm.answer'),
        },
        {
          question: t('faq.inputParcels.question'),
          answer: t('faq.inputParcels.answer'),
        },
        {
          question: t('faq.parcelInputHelp.question'),
          answer: t('faq.parcelInputHelp.answer'),
        },
        {
          question: t('faq.subscriptionError.question'),
          answer: t('faq.subscriptionError.answer'),
        },
        {
          question: t('faq.meteorologicalData.question'),
          answer: t('faq.meteorologicalData.answer'),
        },
        {
          question: t('faq.dataCollection.question'),
          answer: t('faq.dataCollection.answer'),
        },
        {
          question: t('faq.viewSatelliteImages.question'),
          answer: t('faq.viewSatelliteImages.answer'),
        },
        {
          question: t('faq.indicesMeaning.question'),
          answer: t('faq.indicesMeaning.answer'),
        },
        {
          question: t('faq.planetScopeIndices.question'),
          answer: t('faq.planetScopeIndices.answer'),
        },
      ];
    return (
        <div className='home-container'>
            <FarmerTopBar />
            <div className='faq-container' style={{marginTop:'20px'}}>
                <section className='faq-top'>
                    <h2>{t('FAQ')}</h2>
                    <button
                        className='bg-tertiary text-white btn'
                        onClick={() => navigate('/farmer')}
                    >{t('close')}</button>
                </section>
                <section className='faq-bottom'>
                    {faqItems.map((item, index) => (
                        <div key={index}>
                            <h3>{item.question}</h3>
                            <p>{item.answer}</p>
                        </div>
                    ))}
                </section>

            </div>
        </div>
    )
}

export default FarmerHelp