import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import LeftContainer from './LeftContainer';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [codeMessage, setCodeMessage] = useState('');
  const [codeErrorMessage, setCodeErrorMessage] = useState('');
  const [newPasswordErrorMessage, setNewPasswordErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingCode, setLoadingCode] = useState(false);

  const navigate = useNavigate();

  async function handleForgotPassword() {
    try {
      setLoadingCode(true)
      await Auth.forgotPassword(email);
      console.log('A code has been sent to your email.');
      setCodeMessage('A code has been sent to your email.');
       setLoadingCode(false)
    } catch (error) {
      console.error('Error occurred while resetting password', error);
        setCodeErrorMessage(error.message);
        setLoadingCode(false)
    }
  }

  async function handleForgotPasswordSubmit() {
    try {
        setLoading(true)
      await Auth.forgotPasswordSubmit(email, code, newPassword);
      console.log('Password has been successfully updated!');
      toast.success('Password has been successfully updated!');
        setLoading(false)
        navigate('/')
    } catch (error) {
      console.error('Error occurred while updating password', error);
        setNewPasswordErrorMessage(error.message);
        setLoading(false)

    }
  }

  return (
    <div className='register-container'>
    <LeftContainer />
    <section className='right-container'>
    <div>
        <h1>Forgot Password</h1>
                    <p>Don’t worry it happens. Please enter the email
                        address associated with your account.</p>
                        <ol>
        <li>Enter your email address and click "Send Code".</li>
        <li>Check your email for the confirmation code.</li>
        <li>Enter the confirmation code and your new password below.</li>
        <li>Click "Reset Password" to update your password.</li>
      </ol>
        <div className='form-group' >
        <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
        />
        </div>
        {
            codeMessage ? <p>{codeMessage}</p> : codeErrorMessage ? <p>{codeErrorMessage}</p> : null
        }
{
            loadingCode ? <button className='signup-button' disabled>loading...</button> : <button className='signup-button' onClick={handleForgotPassword}>Send Code</button>
}
      <div className='form-group' style={{marginTop:'10px'}}>
      <input
        type="text"
        placeholder="Confirmation Code"
        value={code}
        onChange={(e) => setCode(e.target.value)}
      />
      </div>

      <div className='form-group' >
      <input
        type="password"
        placeholder="New Password"
        value={newPassword}
        onChange={(e) => setNewPassword(e.target.value)}
      />
        </div>
        {
            newPasswordErrorMessage ? <p className='text-red-400'>{newPasswordErrorMessage}</p> : null
        }

        {
            loading ? <button className='signup-button' disabled>loading...</button> : <button className='signup-button' onClick={handleForgotPasswordSubmit}>Reset Password</button>
        }

    </div>
    </section>
    </div>
  );
}

export default ForgotPassword;
