import React, { useEffect, useMemo, useState } from 'react'
import TopBar from '../../topbar/Topbar'
import './upload.css'
import { setUserId } from 'firebase/analytics'
import UploadInputs from './UploadInputs'
import Popup from 'reactjs-popup'
import { fetchRequests } from '../../../../../fARMER/fetches/FetchFarmDetails'

import { GoLocation } from 'react-icons/go'
import { IoAddOutline } from 'react-icons/io5'
import { useNavigate } from 'react-router-dom'
import MyFarms from '../../../../../fARMER/account/farms/myFields/MyFarms'
import MapImage from '../../../../../fARMER/account/farms/myFields/MapImage'
import NoDataComponent from '../../../../../fARMER/null/NullData'
import DroneInputs from '../../../../../fARMER/account/drone/DroneInputs'
import { listDroneRequests } from '../../../../../graphql/queries'
import { API, graphqlOperation } from 'aws-amplify'
import { polygon } from 'leaflet'
import { upload } from '@testing-library/user-event/dist/upload'

const MyFarmsMemoized = React.memo(MyFarms);
const MapImageMemoized = React.memo(MapImage)




const UploadData = () => {
 const [requests , setRequests] = useState([])
 const [polygons, setPolygons] = useState([])
 const [loading, setLoading] = useState(true)
 const [selectedPolygon, setSelectedPolygon] = useState(null);
 const [selectedFarm, setSelectedFarm] = useState(null);
 const [geometry, setGeometry] = useState(null);
 const [clickedCoordinates, setClickedCoordinates] = useState(null); // New state for clicked coordinate
  const [farmDetails, setFarmDetails] = useState(null);
  const [completedRequests, setCompletedRequests] = useState([]);
  const [condition , setCondition] = useState('uploaded')
  const [updates, setUpdates] = useState(false);
 
 useEffect(() => {
  fetchFarmDetails()
  //provoke props function to get farm details
  setLoading(false)
}, [condition])

console.log(polygons)

 async function fetchFarmDetails() {
  try {
    const farmDetails = await API.graphql(graphqlOperation(listDroneRequests))
    const farmDetailsData = farmDetails.data.listDroneRequests.items.filter((item) => item.area !== null && item.farmDetails !== null)
    //filter oyt farms with active as false
    const activeFarms = farmDetailsData.filter((item) => item.farmDetails.active === true )
    //filter out requests with status 'uploaded'
    const filteredData = activeFarms.filter(item => item.status !== condition)
    setPolygons(filteredData)
    console.log(farmDetailsData)
  } catch (error) {
    console.log(error);
  }
}



const formattedData = useMemo(() => {
  return polygons.map((polygon) => {
    const { rings } = polygon;
    const formattedRings = JSON.parse(rings).map((item) => {
      const { latitude, longitude } = item;
      return [latitude, longitude];
    });
    return formattedRings;
  });
}, [polygons]);


// console.log(formattedData);



const groupedFarms = useMemo(() => {
  // Group the farms by farmer's last name
  const groups = formattedData.reduce((groups, polygon, index) => {
    const farmerLastName = polygons[index].farmer ?   polygons[index].farmer.firstName+ ' ' + polygons[index].farmer.lastName: null;
    if (!groups[farmerLastName]) {
      groups[farmerLastName] = [];
    }
   
    groups[farmerLastName].push({ polygon: polygon, farm: polygons[index] });
    return groups;
  }, {});

  // Convert the grouped farms into an array and sort by the number of requests
  return Object.entries(groups).sort((a, b) => b[1].length - a[1].length);

}, [formattedData, polygons]);

console.log(groupedFarms);

  const [leftWidth, setLeftWidth] = useState(50);
  const [rightWidth, setRightWidth] = useState(50);

  const handleRightArrowClick = () => {
    setLeftWidth(leftWidth + 10);
    setRightWidth(rightWidth - 10);
  };

  const handleLeftArrowClick = () => {
    setLeftWidth(leftWidth - 10);
    setRightWidth(rightWidth + 10);
  };

  const handlePointClick = (coordinates) => {
    setClickedCoordinates(coordinates);
  };



  return (
    <div className='home-container'>
        <TopBar />
       
        <section className='mymap-wrapper'>


<div className='left-myMap' style={{ width: `${leftWidth}%`, position: 'relative' }}>

  <section className='my-fields-header'>

    <h3
    className={condition === 'uploaded' ? 'active-requests' : ''}
    onClick={() => {
      setCondition('uploaded')
    }
    }
  
    >Requests Placed</h3>

    <h3
    className={condition === 'pending' ? 'active-requests' : ''}
    onClick={() => {
      setCondition('pending')
    }
    }
    >Requests Completed</h3>

   
  </section>
  {
  !polygons.length && (
    <div className='no-issues' style={{width:"100%"}}>
    <NoDataComponent />
    </div>
  )
}
  <section className='myfield-cont-requests'>
  {
  groupedFarms.map(([farmerLastName, farms]) => (
    <div key={farmerLastName}>
   

      <h3 style={{margin:'10px 0'}}>{farmerLastName}</h3>
      <div className='requests-object'>
      {farms[0].farmer !== null && farms.map(({ polygon, farm }, index) => (
        <div key={index} className='polygon-item'>
        
          {/* your previous elements ... */}
          <div style={{ width: '100%', }}>
                  <MapImageMemoized selectedPolygon={polygon} />
                </div>
                <div className='package-name plans'>
                  <p>{farm.area.toFixed(2)} ha</p>
                </div>
                <div className='location-icon' onClick={() => { setSelectedPolygon(polygon) }} >
                  <GoLocation />
                </div>

          <div className='polygon-info'>
            <section style={{display:'flex', justifyContent:'space-between'}}>
            <div>
            {
              farm.farmDetails && (
                <p>Field: <strong>{farm.farmDetails.name} </strong></p>
              )
            }
            {/* <p>Farm: <strong>{farm.farmDetails.name}</strong></p> */}
            <p>Crop: <strong>{farm.crop}</strong></p>
            </div>
            <div>
            {/* <p>Type: <strong>NDVI</strong></p> */}
            </div>
            </section>
            <button onClick={() => {
              setSelectedFarm(farm)
              setSelectedPolygon(polygon)
            }}

            >See more</button>
          </div>
        </div>
      ))}
      </div>
    </div>
  ))
}
  </section>
  {selectedFarm && (
    <div className="farmpopup ">
      <UploadInputs
        farm={selectedFarm}
        setUpdates={setUpdates}
        onClose={() => setSelectedFarm(null)}
      />
    </div>
  )}
</div>

<div style={{ width: `${rightWidth}%` }}>
  <MyFarms selectedPolygon={selectedPolygon} handlePointClick={handlePointClick} />
</div>
</section>
                        
    </div>
  )
}

export default UploadData