import React, { useEffect, useMemo, useState } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { listFarmDetails, listPackageRequests, listPlanetRequests } from '../../../../../graphql/queries';
import { deletePackageRequest, deletePlanetRequest, updateFarmDetails, updatePackageRequest, updatePlanetRequest } from '../../../../../graphql/mutations';
import { toast } from 'react-toastify';
import Popup from 'reactjs-popup';
import { AiFillEdit, AiOutlineClose } from 'react-icons/ai';
import TopBar from '../../topbar/Topbar';
import PlanetScopeDataImporter from '../../../../../fARMER/__test/Fetch';
import './planet.css';
import { fetchFarmDetails } from '../../../../../fARMER/fetches/FetchFarmDetails';

const PlanetRequests = () => {
    const [farmerId, setFarmerId] = useState('');
    const [quota, setQuota] = useState(0);
    const [requests, setRequests] = useState([]);
    const [loading, setLoading] = useState(true);
    const [updates, setUpdates] = useState(false);
    const [farms, setFarms] = useState([]);
    const [selectedTab, setSelectedTab] = useState('requested'); // Default: 'requested'
    const [expirationDate, setExpirationDate] = useState('date');
    const [areaLimit, setAreaLimit] = useState(100.00);
    const [filterValue, setFilterValue] = useState(''); 
    const [cloudCover, setcloudCover] = useState(90);
    const [startDate, setStartDate] = useState('');
    const [farmsQuota, setFarmsQuota] = useState([]);
    const [planetQuotas, setPlanetQuotas] = useState([]);
    console.log(filterValue)


  const handleFilterChange = (event) => {
    setFilterValue(event.target.value); // Update filter value based on user input
  };
  

    useEffect(() => {
        console.log("running")
        fetchRequests();
        fetchPlanetRequests();  
    }, [updates]);

   
    const fetchRequests = async () => {
        try {
            const packagesRequestData = await API.graphql(graphqlOperation(listPlanetRequests));
            //use filter to eliminate requests with null   requestedBy
            const planetRequests = packagesRequestData.data.listPlanetRequests.items.filter((request) => request.farmer !== null && request.farmDetails !== null);
            setRequests(planetRequests);
            setLoading(false);
        } catch (error) {
            console.log(error);
        }
    }

    const fetchPlanetRequests = async () => {
        try{
            const data = await API.graphql(graphqlOperation(listFarmDetails), {
                filter: {
                    //byoc not 'empty' or null
                    byoc: {
                        ne: 'empty'
                    }
                }
            })

            const planetQuotas = data.data.listFarmDetails.items.filter((item) => item.active && item.farm !== null && item.byoc !== null && item.byoc !== 'empty');
            setPlanetQuotas(planetQuotas);
            console.log(planetQuotas);
        } catch (error) {
            console.log(error);
        }
    }

    const removeByoc = () => async () => {
        try {
            //  //edit byoc field to 'empty;
            //   await API.graphql(graphqlOperation(updateFarmDetails), {
            //     input: {
            //         id:id,
            //         byoc: 'empty'
            //     }
            //  })
toast.success('id')
console.log('id')
            } catch (error) {
                console.log(error);
            }
    }

        

  
    const quotaCount = async (id) => {
        try {
            const fetchData = await API.graphql(graphqlOperation(listFarmDetails, {
                filter: {
                    farmerID: {
                        eq: id
                    }
                }
            }));
            const farmDetails = fetchData.data.listFarmDetails.items.filter((item) => item.active && item.farm !== null && item.byoc !== null && item.byoc !== 'empty');

            const quota = farmDetails.reduce((acc, item) => {
                return acc + item.area;
            }, 0);
            setQuota(quota);
            return quota;
        } catch (error) {
            console.log(error);
        }
    }

    console.log(quota);
      
    const deleteRequest = async (id) => {
        try {
            await API.graphql(graphqlOperation(deletePlanetRequest, { input: { id } }))
            toast.success('Request Deleted Successfully')
            setUpdates(!updates);
        } catch (error) {
            console.log(error);
        }
    };


    return (
        <div className='home-container'>
        <TopBar  filterValue={filterValue} handleFilterChange={handleFilterChange} />
        <div className='header' style={{display:'flex',justifyContent:'space-between'}}>

           <h2>Planet Requests</h2>
           <Popup
            trigger={<button className='package-button'>View approved requests</button>}
            modal
            nested
            width= '80%'
            >
            {close => (
                <div className="modal approved-planet-requests">
                    <button className="close" onClick={close}>
                        &times;
                    </button>
                    <div className="header"> Approved Requests </div>
                  
                    <div className="content">
                        {' '}
                        <table>
                            <thead>
                                <tr>
                                <th>Farmer</th>
                                <th>Email</th>
                                <th>Farm Name</th>
                                <th>Quota</th>
                                
                                </tr>
                            </thead>
                            <tbody>
                               {
                                      planetQuotas.map((quota, index) => (  
                                        <tr key={index}>
                                            <td>{quota.farmer.firstName}</td>
                                            <td>{quota.farmer.email}</td>
                                            <td>{quota.name}</td>
                                            <td>{(quota.area/100).toFixed(2)}</td>
                                            {/* button to make the byoc back to empty */}
                                            <td>
                                                <button
                                                className='package-button'
                                                onClick={async () => {
                                                    try{

                                                        await API.graphql(graphqlOperation(updateFarmDetails, {
                                                             input: {
                                                                 id: quota.id,
                                                                 byoc: 'empty'
                                                             }
                                                         }))
                                                         toast.success("planet subscription removed, you can now make a new request with no additional quota restrictions.", {
                                                                position: "top-right",
                                                                autoClose: 10000,
                                                         })
                                                         close();
                                                         fetchPlanetRequests();
                                                    } catch (error) {
                                                        console.log(error);
                                                    }

                                                }}
                                                >
                                                Delete
                                                </button>
                                            </td>
                                        </tr>
                                    ))
                               }
                            </tbody>
                        </table>
                    </div>
                    <div className="actions">
                        <button
                        className="button"
                        onClick={() => {
                            console.log('modal closed ');
                            close();
                        }}
                        >
                        close modal
                        </button>
                    </div>
                </div>
            )}
            </Popup>
           </div>
            <div className='package-sales-plans'>
                {
                    // if there is no request with status 'requested', display 'No data'
                    requests.filter((request)=> {return request.status === 'pending'}).length === 0 && <p>No data</p>
                }
                {
                    loading ? <p>Loading...</p> : (
                        requests.length === 0 ? <p>No data</p> : (
                            requests.filter((request)=> {return request.farmer.firstName.toLowerCase().includes(filterValue.toLowerCase()) || request.farmer.lastName.toLowerCase().includes(filterValue.toLowerCase())
                            }).map((request, index) => (
                                request.status === 'pending' ? (
                                <div key={index} className='package-card'>
                                    <p><strong>Name</strong>: {request.farmer.firstName}</p>
                                    <p><strong>Farm name</strong>: {request.farmDetails.name}</p>
                                    <p><strong>Phone</strong>: {request.farmer.phone}</p>
                                    <p><strong>Email</strong>: {request.farmer.email}</p>
                                    <p><strong>Requested on</strong>: {request.createdAt.split('T')[0]}</p>
                                    <p><strong>Quota</strong>: {((request.farmDetails.area/100).toFixed(2))} km <sup>2</sup></p>
                             
                                   {/* <input
                                   type='number'
                                   placeholder='cloud-cover'
                                   onChange={()=> setcloudCover(cloudCover)}
                                   />
                                     <input
                                   type='date'
                                   placeholder='start date'
                                   value = {startDate}
                                      onChange={()=> setStartDate(startDate)}
                                   /> */}
                                    <section className='add-pfarmpopup__buttonsackage'>
                                         
                                                <>
                                               <Popup
                                                    trigger={<button className='package-button' onClick={quotaCount(request.farmer.id)}>Approve</button>}
                                                    modal
                                                    nested
                                                    width= {500}
                                                    >
                                                    {close => (
                                                        <div className="modal">
                                                            <button className="close" onClick={close}>
                                                                &times;
                                                            </button>
                                                            <p>Farm name <strong>{request.farmDetails.name}</strong></p>
                                                            <p>Quota <strong>{((request.farmDetails.area/100).toFixed(2))} km <sup>2</sup></strong></p>
                                                            <p>Quota used <strong>{((quota/100).toFixed(2))} km <sup>2</sup></strong></p>
                                                            <p>Quota remaining <strong>{(1- (quota/100).toFixed(2))} km <sup>2</sup></strong></p>
                                                        <PlanetScopeDataImporter farmId={request.farmDetails.id} coordinates={request.farmDetails.rings} planetRequestId={request.id} cloudCover={cloudCover} startDate={startDate} farmName={request.farmDetails.name}  setUpdates={setUpdates} quotaRestriction = {quota} />
                                                        </div>
                                                    )}
                                                    </Popup>
                                            <button
                                                className='package-button'
                                                onClick={() => deleteRequest(request.id)}
                                            >
                                                Delete 
                                            </button>
                                                </>
                                        
                                       
                                    </section>
                                </div>
                                ): null
                            ))
                        )
                    )
                }
            </div>
        </div>
    )
}

export default PlanetRequests;
