import React, { useEffect, useMemo, useState } from 'react'
import { setUserId } from 'firebase/analytics'
import Popup from 'reactjs-popup'

import { GoLocation } from 'react-icons/go'

import { API, graphqlOperation } from 'aws-amplify'
import MyFarms from '../farms/myFields/MyFarms'
import { fetchRequests, fetchRequestsForFarmer } from '../../fetches/FetchFarmDetails'
import { listDroneRequests } from '../../../graphql/queries'
import FarmerTopBar from '../../components/topbar/Topbar'
import MapImage from '../farms/myFields/MapImage'
import DroneInputs from './DroneInputs'
import DroneRequest from './DroneRequest'
import NoDataComponent from '../../null/NullData'
import NoDataComponent2 from '../../null/Wait'
import { IoAddOutline } from 'react-icons/io5'
import { useTranslation } from 'react-i18next'

const MyFarmsMemoized = React.memo(MyFarms);
const MapImageMemoized = React.memo(MapImage)




const PendingRequets = () => {
    const [requests, setRequests] = useState([])
    const [polygons, setPolygons] = useState([])
    const [loading, setLoading] = useState(true)
    const [selectedPolygon, setSelectedPolygon] = useState(null);
    const [selectedFarm, setSelectedFarm] = useState(null);
    const [geometry, setGeometry] = useState(null);
    const [clickedCoordinates, setClickedCoordinates] = useState(null); // New state for clicked coordinate
    const [update, setUpdate] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState('All');
    const [popUpWidth, setPopUpWidth] = useState('90%');


    //alerts
    // console.log(daysArray);
    useEffect(()=>{
      function handleResize() {
        if (window.innerWidth < 600) {
          setPopUpWidth('90%')
        } else {
          setPopUpWidth('60%')
        }
      }
    
      window.addEventListener('resize', handleResize)
    
      return () => {
        window.removeEventListener('resize', handleResize)
      }
    },[])
    
  
    const { t } = useTranslation()

    //fetch the requests
    React.useEffect(() => {
        setLoading(true)
        async function fetchData() {
            const requestsData = await fetchRequestsForFarmer();
            setPolygons(requestsData);
        }
        fetchData();
        setLoading(false)
    }, [update]);

    // //fetch farm details
    // useEffect(() => {
    //     fetchDroneRequests()
    //     setLoading(false)
    // }, [])

    // async function fetchDroneRequests() {
    //     try {
    //         const farmDetails = await API.graphql(graphqlOperation(listDroneRequests))
    //         const droneRequests = farmDetails.data.listDroneRequests.items
    //         setPolygons(droneRequests)
    //         console.log(droneRequests)
    //     } catch (error) {
    //         console.log(error);
    //     }
    // }

    console.log(polygons);

    const formattedData = useMemo(() => {

        return polygons.map((polygon) => {
            if (polygon.rings === undefined) {
                return [];
            }
            const { rings } = polygon;
            const formattedRings = JSON.parse(rings).map((item) => {
                const { latitude, longitude } = item;
                return [latitude, longitude];
            });
            return formattedRings;
        });
    }, [polygons]);

    const filteredData = useMemo(() => {
        if (selectedStatus === 'All') {
            return polygons;
        }
        return polygons.filter((polygon) => polygon.status === selectedStatus);
    }, [polygons, selectedStatus]);



    const [leftWidth, setLeftWidth] = useState(50);
    const [rightWidth, setRightWidth] = useState(50);

    const handleRightArrowClick = () => {
        setLeftWidth(leftWidth + 10);
        setRightWidth(rightWidth - 10);
    };

    const handleLeftArrowClick = () => {
        setLeftWidth(leftWidth - 10);
        setRightWidth(rightWidth + 10);

    };

    const handlePointClick = (coordinates) => {
        setClickedCoordinates(coordinates);
    };

    const onUpdate = () => {
        setUpdate(!update)
    }


    return (
        <div className='home-container'>
            <FarmerTopBar />

            <section className='mymap-wrapper'>


            <div ssName='left-myMap' style={{position:'relative', padding:'10px' }}>



                    <section className="my-fields-header">
                        <div style={{marginTop:0}} className='sort-items'
                        >
                            <h3   style={{marginTop:5, marginBottom:5}}>{t('requests Placed')}</h3>

                            <div className='sort-tabs'>

                                <section>
                                    <p>{t('status')}</p>
                                    <select name="status" id="status"

                                        onChange={(e) => setSelectedStatus(e.target.value)}

                                    >
                                        <option>{t('all')}</option>
                                        <option value="pending">{t('pending')}</option>
                                        <option value="uploaded">{t('uploaded')}</option>

                                    </select>
                                </section>
                            </div>
                        </div>

                        <div
                            className='add-download-buttons'
                        >
                            <Popup
                                trigger={<button>
                                    <IoAddOutline />
                                </button>}
                                modal
                                nested
                                zIndex={99999999}
                                contentStyle={{
                                    width: popUpWidth,
                                    //padding style
                                    padding: "0px",
                                }}
                            >
                                {close => (
                                    <DroneRequest close={close} onUpdate={onUpdate} />
                                )}
                            </Popup>
                        </div>

                    </section>

                    <section className='my-fields-header'>

                    </section>
                    {
                        loading && (
                            <div className='no-issues' style={{ width: "100%" }}>
                                <NoDataComponent2 />
                            </div>
                        )
                    }
                    {
                        !polygons.length && (
                            <div className='no-issues' style={{ width: "100%" }}>
                                <NoDataComponent />
                            </div>
                        )
                    }

                    <section className='myfield-container' style={{ height: "" }}>
                        {/* <p>You have not created any fields yet</p> */}

                        {
                            filteredData.map((polygon, index) => (
                                <div key={index} className='polygon-item'>

                                    <div style={{ width: '100%', }}>
                                        <MapImageMemoized selectedPolygon={polygon} />
                                    </div>
                                    <div className='package-name plans'>
                                        <p>{polygons[index].area.toFixed(2)} ha</p>
                                    </div>
                                    <div className='location-icon' onClick={() => { setSelectedPolygon(polygon) }} onMouseOver={() => setSelectedPolygon(polygon)}>
                                        <GoLocation />
                                    </div>

                                    <div className='polygon-info' >
                                        <p>{t('farm')}: <strong>{polygons[index].fieldName}</strong></p>
                                        <p>{t('crop')}: <strong>{polygons[index].crop}</strong></p>

                                        {/* <button onClick={() => {
                                            setSelectedFarm(polygons[index])
                                            setSelectedPolygon(polygon)
                                        }}
                                        >{t('see more')}</button> */}
                                    </div>
                                </div>
                            ))
                        }



                    </section>

                </div>

                <div className='right-myMap imagery' style={{ position:'relative'}}>
                    <MyFarms selectedPolygon={selectedPolygon} handlePointClick={handlePointClick} />
                </div>
            </section>

        </div>
    )
}

export default PendingRequets