import React, { useEffect, useState } from 'react';
import { API, Auth, graphqlOperation } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import './VerifyEmail.css';
import { createFarmer } from '../../../../graphql/mutations';
import { toast } from 'react-toastify';
import { local } from 'd3';

const VerifyEmail = ({email, isAdmin, password}) => {
  const [verificationCode, setVerificationCode] = useState('');
  const [error, setError] = useState('');
  const [myEmail, setEmail] = useState('');
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

 
  console.log(email, password)
  useEffect(() => {
    if(!localStorage.getItem('emailToBeVerified')){
     
  localStorage.setItem('emailToBeVerified', email);
  localStorage.setItem('password', password);
    }else{
      localStorage.setItem('emailToBeVerified', localStorage.getItem('emailToBeVerified'));
      localStorage.setItem('password', localStorage.getItem('password'))
    }
  }, [email]);
  const handleVerification = async (e) => {
    e.preventDefault();
    try {
  setLoading(true)

      await Auth.confirmSignUp(localStorage.getItem('emailToBeVerified'), verificationCode);

      
    
    } catch (error) {
      setError(error.message);
      setLoading(false)
    }

    
    await Auth.signIn(localStorage.getItem('emailToBeVerified'), localStorage.getItem('password'));

    const user = await Auth.currentAuthenticatedUser();

    console.log(user.attributes);

          //store id in local storage
          localStorage.setItem('farmerID', user.attributes.sub);
          localStorage.setItem('farmerEmail', user.attributes.email);
          localStorage.setItem('farmerName', user.attributes.given_name);
          localStorage.setItem('phoneNumber', user.attributes.phone_number);
          localStorage.setItem('packageName', 'AGROGIS Freemium')
 
      // Create new farmer record
      try {
await API.graphql(
  graphqlOperation(createFarmer, {
    input: {
      id: user.attributes.sub,
      email: user.attributes.email,
      firstName: user.attributes.given_name,
      lastName: user.attributes.family_name,
      phone: user.attributes.phone_number,
    },
  })
);
      //remove emailToBeVerified from local storage
      localStorage.removeItem('emailToBeVerified');
      localStorage.removeItem('password')
toast.success('Account created successfully');
setLoading(false)
navigate('/farmer');
window.location.reload();
} catch (error) {
console.log('Error creating farmer record:', error);
}
  };

  const handleResendCode = async () => {
    try {
      await Auth.resendSignUp(localStorage.getItem('emailToBeVerified'));
      // Code resent successfully
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div className="w-10/12 md:w-1/2 lg:w-1/3  m-auto px-4 py-10 mt-20 bg-gray-100 rounded-lg shadow-md">
  <h2 className="text-2xl font-bold text-gray-700 mb-2">Verify Email</h2>
  <p className="text-gray-600 mb-4">Please enter the verification code sent to your email.</p>
  <form onSubmit={handleVerification} className="space-y-4">
    {
      error && (
        <input type="text" placeholder="Email" value={myEmail} onChange={(e) => setEmail(e.target.value)} className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500" />
      )
    }
    <input
      type="text"
      placeholder="Verification Code"
      value={verificationCode}
      onChange={(e) => setVerificationCode(e.target.value)}
      className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500"
    />
    <button type="submit" className="w-full px-3 py-2 bg-primary text-white rounded-md">{loading ? "loading..." : "Verify"}</button>
  </form>
  {error && <p className="text-red-500 mt-2">{error}</p>}
  <p className="py-4 text-gray-600">Didn't receive the code? <button onClick={handleResendCode} className="text-white bg-primary hover:underline">Resend Code</button></p>
</div>

  );
};

export default VerifyEmail;
