import React, { useState, useEffect } from 'react';
import LeftContainer from './LeftContainer';
import { Link, useNavigate } from 'react-router-dom';
import { API, Auth, graphqlOperation } from 'aws-amplify';
import { listFarmers } from '../../../graphql/queries';
import { toast } from 'react-toastify';
import { createFarmer } from '../../../graphql/mutations';
import { useTranslation } from 'react-i18next';
import i18n from "i18next";
import Flag from 'react-world-flags'
import { BsGlobe2 } from 'react-icons/bs';

const Login = () => {
  const remember = localStorage.getItem('remember');

  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showEmailConfirmation, setShowEmailConfirmation] = useState(false);
  const [loading , setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [rememberMe, setRememberMe] = useState(remember);
   const [showDropdown, setShowDropdown] = useState(false);

    const {t} = useTranslation();

  useEffect(() => {
    localStorage.setItem('remember', rememberMe);
  }, [rememberMe]);
  
  useEffect(() => {
    checkEmailConfirmationStatus();
    const remember = localStorage.getItem('remember');
    if (remember === 'true') {
      navigate('/farmer'); //
    }
  }, []);

  const checkEmailConfirmationStatus = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      setShowEmailConfirmation(!user.attributes.email_verified);
    } catch (error) {
      console.log('Error checking email confirmation status:', error);
    }
  };

  const handleSignin = async (e) => {
    setLoading(true);
    e.preventDefault();


    try {
      await Auth.signIn(email, password);

      const user = await Auth.currentAuthenticatedUser();

      //console log the user attributes
      console.log(user.attributes);
//clear local storage
      //store id in local storage
      localStorage.setItem('farmerID', user.attributes.sub);
      localStorage.setItem('farmerEmail', user.attributes.email);
      localStorage.setItem('farmerName', user.attributes.given_name);
      localStorage.setItem('phoneNumber', user.attributes.phone_number);
      localStorage.setItem('packageName', 'AGROGIS Freemium')
 // Check if farmer record already exists
 const { data } = await API.graphql(
  graphqlOperation(listFarmers, {
    filter: {
      email: { eq: user.attributes.email }
    }
  })
);

if (data.listFarmers.items.length === 0) {
  // Create new farmer record
  await API.graphql(
    graphqlOperation(createFarmer, {
      input: {
        id: user.attributes.sub,
        email: user.attributes.email,
        firstName: user.attributes.given_name,
        lastName: user.attributes.family_name,
        phone: user.attributes.phone_number,
      },
    })
  );
}

if(data.listFarmers.items.length > 0){

  //if isDisabled is false, proceed to /farmer
    // Assuming isDisabled is a property in the user data
    const isDisabled = data.listFarmers.items[0].isDisabled;

    // Check if the user is not disabled
    if (!isDisabled) {
      localStorage.setItem('remember', true);
  
      // Proceed to /farmer route
      navigate('/farmer');
      window.location.reload();
    } else {
      // Handle the case where the user is disabled (optional)
      toast.error("Account disabled")
      setLoading(false);
      //clear localstorage
      localStorage.clear();
      console.error('User is disabled. Unable to proceed to /farmer route.');
    }

}
    } catch (error) {
      console.log('Error signing in:', error);
      setLoading(false);
      setErrorMessage(error.message);
    }
  };

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
    toggleDropdown();
};

const toggleDropdown = () => {
  setShowDropdown(!showDropdown);
};
  return (
    <div className="register-container">
      {
        localStorage.getItem('remember') === 'true' ? navigate('/farmer') : ''
      }
      <LeftContainer />

        <section className='right-container'>
      <div className='signup-inputs'>
        <div className='mobile-logo'>
          <img src={'/Logo/PNG/simbol-dark.png'} alt='logo' />
        </div>
        {
          localStorage.getItem('emailToBeVerified')  ?
          <>
           <p>Pending verification for email {localStorage.getItem('emailToBeVerified')}</p>
           <button className='bg-primary text-white  ' onClick={() => {
              navigate('/verify-email');
            }
            }>Verify Email</button>
          </>
            : ''
        }
        <h1>{t('signInTitle')}</h1>
        <p>{t('enterDetails')}</p>
        {showEmailConfirmation && (
          <div className='email-confirmation'>
            <p>{t('confirmEmail')}</p>
            <Link to='/verify-email'>{t('verifyEmailLink')}</Link>
          </div>
        )}
        <form onSubmit={handleSignin}>
          <div className='form-group'>
            <label htmlFor='email'>{t('emailLabel')}</label>
            <input
              placeholder={t('emailPlaceholder')}
              type='email'
              id='email'
              name='email'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          <div className='form-group'>
            <label htmlFor='password'>{t('passwordLabel')}</label>
            <input
              placeholder={t('passwordPlaceholder')}
              type='password'
              id='password'
              name='password'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>

          <div className='terms-container'>
            <div className='terms'>
            
            </div>

            <div className='forgot-password'>
              <Link to='/forgot'>{t('forgotPasswordLink')}</Link>
            </div>
          </div>

          <button type='submit' className='signup-button'>
            {loading ? <div className='registration-loader'></div> : t('loginButton')}
          </button>

          <p className='error'>
            {errorMessage}
            {errorMessage === t('userNotConfirmedError') ? <Link to='/verify-email'>{t('verifyEmailLink')}</Link> : ''}
          </p>
        </form>

        <div className='google-signup'>
          <p>{t('orText')}</p>
          <div id='signInDiv'></div>
        </div>

        <div style={{ textAlign: 'center', display:'flex', justifyContent:'center', alignItems:'center' }}>
         <p style={{marginRight:'5px'}}>{t('noAccountText')}</p> 
          <Link to='/signup'>{t('signUpLink')}</Link>
        </div>
      </div>
    </section>
    <section style={{position:'absolute', top:'0', right:'10px', margin:'10px'}}>
    <div className='' onClick={toggleDropdown} style={{position:'relative'}}> 
        <BsGlobe2 />
        
        {showDropdown && (
        <div className='lang-switch'style={{right:'1%'}} >
        <Flag code="RO" height="14" onClick={() => changeLanguage('fr')} />
      <button onClick={() => changeLanguage('fr')}>Română</button>
      <Flag code="GB" height="14"  onClick={() => changeLanguage('en')} />
      <button onClick={() => changeLanguage('en')}>English</button>      
      <Flag code="IT" height="14" onClick={() => {changeLanguage('it')}} />
      <button onClick={() => {
                  changeLanguage('it')
                  
                }}>Italiano</button>
      </div>
      )}
      </div>
      </section>
    </div>
  );
};

export default Login;
