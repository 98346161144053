import React, { useEffect, useState } from 'react';
import './weather.css';
import { onSnapshot } from 'firebase/firestore';
import { polygonCollection } from '../../../lib/firestoreCollections';
import axios from 'axios';
import { listFarmDetails } from '../../../graphql/queries';
import { API, graphqlOperation } from 'aws-amplify';
import { WiDaySunny, WiCloud, WiDayCloudy, WiRain, WiWindBeaufort0, WiBarometer, WiRaindrops, WiHumidity, WiSnowflakeCold } from 'react-icons/wi';
import Popup from 'reactjs-popup';
import { GrFormNext, GrFormPrevious } from 'react-icons/gr';
import { AlertComponent } from './Alerts';
import NoDataComponent from '../../null/NullData';
import SowingData from './agroModels/SowingData';
import Forecast from './agroModels/forecast/Forecast';
import SowingForecast from './agroModels/forecast/SowingForecast';
import TemperatureGraph from './charts/TemperatureGraph';
import { getWeatherData, setWeatherData, useWeatherData } from './StatesWeather';

import { BiHelpCircle } from 'react-icons/bi';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next'
import { FiWind } from 'react-icons/fi';
import { WeatherIcon, pictocodeDescription } from './WeatherIcon';

async function fetchWeatherData(lat, lon) {

  const apiKey = 'L37mPWyC1MlYKBre'
  const url = `https://my.meteoblue.com/packages/basic-1h_basic-day_current_agro-1h_agro-day_agromodelsowing-1h?apikey=${apiKey}&lat=${lon}&lon=${lat}&format=json`

  return await fetch(url)
    .then((response) => response.json())
    .catch((error) => {
      console.error(error)
    })
}





export function WeatherCard({ polygon, shouldDisplayWeather, trigger }) {
  const { t } = useTranslation()
  const [weatherData, setWeatherData] = useWeatherData();
  const [loading, setLoading] = useState(true);
  const [showForecast, setShowForecast] = useState(false);
  const [forecastData, setForecastData] = useState(null);
  const [selectedDateIndex, setSelectedDateIndex] = useState(0);
  const [selectedDay, setSelectedDay] = useState(0);
  const [error, setError] = useState(null);
  const [popUpWidth, setPopUpWidth] = useState('90%');


  //alerts
  // console.log(daysArray);
  useEffect(()=>{
    function handleResize() {
      if (window.innerWidth < 600) {
        setPopUpWidth('100%')
      } else {
        setPopUpWidth('60%')
      }
    }
  
    window.addEventListener('resize', handleResize)
  
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  },[])
  

  const handleDateChange = (index) => {
    setSelectedDateIndex(index);
  };

  const generateWeatherDescription = () => {
    let description = ''

    if (weatherData.precipitation_probability > 30) {
      description += t('heavyRain')
    } else if (weatherData.precipitation_probability > 10) {
      description += t('rain')
    } else if (weatherData.precipitation_probability > 5) {
      description += t('lightShowers')
    } else {
      description += t('noRain')
    }

    if (weatherData.windspeed > 10) {
      description += t('strongWinds')
    } else if (weatherData.windspeed > 5) {
      description += t('moderateWinds')
    } else {
      description += t('lightWinds')
    }

    return description
  }


  const description = generateWeatherDescription();

  const formatDate = (dateString, showDate = true) => {
    const date = new Date(dateString);
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);
  
    if (
      date.getDate() === tomorrow.getDate() &&
      date.getMonth() === tomorrow.getMonth() &&
      date.getFullYear() === tomorrow.getFullYear()
    ) {
      return showDate ? `tomorrow at ${date.getHours()}:00` : `${date.getHours()}:00`;
    } else {
      return `${date.getFullYear()}-${(date.getMonth() + 1)
        .toString()
        .padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')} ${date.getHours()}:00`;
    }
  };
  
  const createAlert = (message, name) => {
    return {
      message,
      time: new Date().toLocaleTimeString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      }),
      timestamp: Date.now(),
      farmName: name,
    };
  };
  
  const checkWeatherForecast = (data, lat, lon, name) => {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    const tomorrowDateString = `${tomorrow.getFullYear()}-${(tomorrow.getMonth() + 1)
      .toString()
      .padStart(2, '0')}-${tomorrow.getDate().toString().padStart(2, '0')}`;

      if(data.error_message){
        // toast.error(data.error_message)
        setError(data.error_message)
        return;
      }
  
    const tomorrowForecastIndex = data.data_day.time.findIndex(
      (time) => time === tomorrowDateString
    );
  
    if (tomorrowForecastIndex !== -1) {
      const data_1h = data.data_1h;
      const data_day = data.data_day
      const hours = data_1h.time.slice(24, 48);
  
      const alerts = [];
      let highRainStartHour = null;
      let highRainEndHour = null;
      let highTempStartHour = null;
      let highTempEndHour = null;
  
      for (let index = 0; index < hours.length; index++) {
        const hour = hours[index];
        const temperature_1h = data_1h.temperature[index + 24];
        const windSpeed_1h = data_1h.windspeed[index + 24];
        const precipitation_1h = data_1h.precipitation_probability[index + 24];
  
        localStorage.setItem('gvh', windSpeed_1h);
  
        // High Wind Speed Alert
        if (windSpeed_1h > 10) {
          const message = `We expect high wind speeds exceeding 10 m/s at ${hour}`;
          alerts.push(createAlert(message, name));
        }
  
        // High Rain Alert
        if (precipitation_1h > 10) {
          if (!highRainStartHour) {
            highRainStartHour = hour;
          }
          highRainEndHour = hour;
        } else if (highRainStartHour) {
          let alertMessage;
          if (highRainStartHour === highRainEndHour) {
            alertMessage = `There will be heavy rain  ${formatDate(highRainStartHour)}`;
          } else {
            alertMessage = `There will be heavy rain from ${formatDate(highRainStartHour)}  to ${formatDate(highRainEndHour, false)}`;
          }
          alerts.push(createAlert(alertMessage, name));
          highRainStartHour = null;
          highRainEndHour = null;
        }
  
        
        // High Temperature Alert
        if (temperature_1h > 30) {
          if (!highTempStartHour) {
            highTempStartHour = hour;
          }
          highTempEndHour = hour;
        } else if (highTempStartHour) {
          let alertMessage;
          if (highTempStartHour === highTempEndHour) {
            alertMessage = `We expect high temperatures exceeding 30 at ${formatDate(highTempStartHour)}`;
          } else {
            alertMessage = `We expect high temperatures exceeding 30 from ${formatDate(highTempStartHour)} to ${formatDate(highTempEndHour, false)}`;
          }
          alerts.push(createAlert(alertMessage, name));
          highTempStartHour = null;
          highTempEndHour = null;
        }
      }
  
      // Construct a unique key for the alerts based on latitude and longitude
      const alertsKey = `alerts_${lon}_${lat}`;
  
      // Retrieve existing alerts from local storage or initialize an empty array
      let storedAlerts = JSON.parse(localStorage.getItem(alertsKey));
      if (!Array.isArray(storedAlerts)) {
        storedAlerts = [];
      }
  
      // Filter out duplicate alerts and store updated alerts
      alerts.forEach((newAlert) => {
        const isDuplicate = storedAlerts.some(
          (storedAlert) =>
            storedAlert.message === newAlert.message &&
            storedAlert.farmName === newAlert.farmName
        );
  
        if (!isDuplicate) {
          storedAlerts.push(newAlert);
        }
      });
  
      localStorage.setItem(alertsKey, JSON.stringify(storedAlerts));
    }
  };
  
  


  const processData = (data) => {
    const now = new Date();
    const currentHour = `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, '0')}-${String(now.getDate()).padStart(2, '0')} ${String(now.getHours()).padStart(2, '0')}:00`;

      if(data.error_message){
      // toast.error(data.error_message)
      setError(data.error_message)
      return;
    }

    const hourIndex = data.data_1h.time.findIndex(time => time === currentHour);

    let currentTemperature = null;
    let midnightTemperature = null;
    let windspeed = null;
    let sealevelpressure = null;
    let precipitation = null;
    let snowfraction = null;
    let humidity = null;
    let leafWetness = null;
    let soilTemperature = null;
    let transpiration = null;
    let dewpoint = null;
    let pictocode = null;
    let highestDayTemp = null;
    let lowestDayTemp = null;


    const forecast = data.data_day;
    let data_1h = data.data_1h;

    if (hourIndex !== -1) {
      currentTemperature = data.data_current.temperature.toFixed(0);
      pictocode = data.data_current.pictocode;
      midnightTemperature = data.data_1h.temperature[23];
      windspeed = data.data_1h.windspeed[hourIndex]
      sealevelpressure = data.data_1h.sealevelpressure[hourIndex];
      precipitation = data.data_1h.precipitation_probability[hourIndex];
      humidity = data.data_1h.relativehumidity[hourIndex];
      snowfraction = data.data_1h.snowfraction[hourIndex];
      leafWetness = data.data_1h.leafwetnessindex[hourIndex];
      soilTemperature = data.data_1h.soiltemperature_0to10cm[hourIndex];
      transpiration = data.data_1h.evapotranspiration[hourIndex];
      dewpoint = data.data_1h.dewpointtemperature[hourIndex];
      highestDayTemp = data.data_day.temperature_max[0];
      lowestDayTemp = data.data_day.temperature_min[0];
      precipitation = data.data_1h.precipitation[hourIndex];
    }

    setWeatherData({ currentTemperature, forecast, windspeed, sealevelpressure, precipitation, snowfraction, humidity, leafWetness, soilTemperature, transpiration, dewpoint, data_1h, highestDayTemp, lowestDayTemp, pictocode, precipitation });
    setLoading(false);
  };



  useEffect(() => {

    const getCacheKey = (lat, lon) => `weatherData_${lat}_${lon}`;

    const getCachedWeatherData = (lat, lon) => {
      const cacheKey = getCacheKey(lat, lon);
      console.log(cacheKey);
      const cachedData = localStorage.getItem(cacheKey);
      const cachedTimestamp = localStorage.getItem(cacheKey + '_timestamp');


      if (cachedData && cachedTimestamp) {
        const age = Date.now() - Number(cachedTimestamp);
        //10 hours in milliseconds
        console.log(JSON.parse(cachedData));
        const maxAge =  5 * 60 * 60 * 1000;
       
        if (age < maxAge) {
          return JSON.parse(cachedData);
        }
      }
      return null;
    };


    const lon = polygon.lat;
    const lat = polygon.lon;
    const name = polygon.name;

    console.log(lat, lon)

    const cachedWeatherData = getCachedWeatherData(lat, lon);
    console.log(cachedWeatherData)

    if (cachedWeatherData) {
      checkWeatherForecast(cachedWeatherData, lat, lon, name);

      processData(cachedWeatherData);
    } else {
      fetchWeatherData(polygon.lat, polygon.lon)
        .then((data) => {
          console.log(data);
          const cacheKey = getCacheKey(lat, lon);
          localStorage.setItem(cacheKey, JSON.stringify(data));
          localStorage.setItem(cacheKey + '_timestamp', Date.now());

          checkWeatherForecast(data, lat, lon, name);

          processData(data);
        })

        .catch((error) => {
          setError(error.message);
          setLoading(false);
        });
    } 

  }, []);


   if (!weatherData) return <div>{t('loading')}...</div>;

  const selectedForecast = weatherData.forecast && weatherData.forecast.time.length > selectedDay
    ? {
      time: weatherData.forecast.time[selectedDay],
      temperature: weatherData.forecast.temperature_mean[selectedDay],
      windspeed: weatherData.forecast.windspeed_mean[selectedDay],
      sealevelpressure: weatherData.forecast.sealevelpressure_mean[selectedDay],
      relativehumidity: weatherData.forecast.relativehumidity_mean[selectedDay],
      precipitation: weatherData.forecast.precipitation_probability[selectedDay],
      snowfraction: weatherData.forecast.snowfraction[selectedDay],
      soilTemperature: weatherData.forecast.soiltemperature_0to10cm_mean[selectedDay],
      transpiration: weatherData.forecast.evapotranspiration[selectedDay],
      dewpoint: weatherData.forecast.dewpointtemperature_mean[selectedDay],
      leafWetness: weatherData.forecast.leafwetnessindex[selectedDay],
    }
    : null;

  const days = [
    {
      id: 1,
      date: '10-06-2023'
    },
    {
      id: 2,
      date: '11-06-2023'
    },
    {
      id: 3,
      date: '12-06-2023'
    },
    {
      id: 4,
      date: '13-06-2023'
    },
    {
      id: 5,
      date: '14-06-2023'
    },
    {
      id: 6,
      date: '15-06-2023'
    },
    {
      id: 7,
      date: '16-06-2023'
    },
  ]

  //convert the dates to display a day only , for example 2021-06-10 to Wednesday for the above array
  const daysArray = days.map(day => {
    const date = new Date(day.date);
    const dayName = date.toLocaleString('default', { weekday: 'long' });
    return {
      ...day,
      date: dayName
    }
  })



  return (
    <div className=" meteoblue weather-card" onClick={() => setShowForecast(!showForecast)}>
      <div className="flex justify-between weather-card-heaer">
        <section className='flex items-center'>

      <img src='/assets/icons/placeholder.png' alt='wind' className='w-8 h-8'/>

        <h3>{polygon.name}</h3>
        </section>
        <section className='flex flex-col items-end'>
        <h3>Today</h3>
        <p className='text-sm text-slate-400'>
      {  pictocodeDescription(weatherData.pictocode)}
        </p>
        </section>

      </div>
      <div className="flex flex-col gap-2 weather-card-body">
      
      <section className="flex justify-around">

          <div className="flex space-x-2 justify-center items-center">
            <WeatherIcon temperature={weatherData.currentTemperature} pictocode={weatherData.pictocode} />
            <p className='text-xl font-semibold'>{weatherData.currentTemperature}°C</p>
          </div>

          <div className="flex flex-col items-start  justify-center">
            <div className='flex space-x-2 items-center'>
            <i className="fa fa-arrow-up text-orange-300 "></i>
           <p className='text-base text-orange-300 font-semibold'>{weatherData.highestDayTemp}°C</p>
            </div>
            <div className='flex space-x-2  items-center'>

            <i className="fa fa-arrow-down text-blue-400"></i>
           <p className='text-base text-blue-400 font-semibold'>{weatherData.lowestDayTemp}°C</p>
            </div>
          </div>
      </section>

      <section className="flex justify-around">
        <div className='flex flex-col items-center'>
        <img src={'/assets/icons/wind.png'} alt='wind' className='w-8 h-8'/>
        <p className='text-sm text-slate-400'>{t('wind')}</p>
        <p className='text-xl font-semibold'>{weatherData.windspeed ? weatherData.windspeed : '2.00'} m/s</p>
      
        </div>
        <div className='flex flex-col items-center'>
        <img src={'/assets/icons/humidity.png'} alt='wind' className='w-8 h-8'/>
        <p className='text-sm text-slate-400'>Humidity</p>
        <p className='text-xl font-semibold'>{weatherData.humidity} %</p>
        {/* <p className='text-sm text-slate-400'>{weatherData.precipitation} mm</p> */}
        </div>
      </section>
         
      </div>
      {
        error ? <p style={{ color: 'red' }}>{t('meteoblue plan has expired')}</p> :
      
      <Popup
        trigger={<button className="manage-plan-btn add-card">{t('see more details')}</button>}
        modal
        closeOnDocumentClick
        overlayClassName="popup-overlay"
        contentStyle={{
          borderRadius: '10px',
          padding: '20px',
          backgroundColor: '#fff',
          width: popUpWidth,
          height: '90%',
          paddingTop:'50px',
          overflowX: 'scroll'
           
        }}
      >
        <header style={{display:'flex', justifyContent:'space-around', width:'100%'}}>
        {/* <h2>{t('current Weather')}</h2> */}
       
        </header>
       
        {weatherData && (
          <>
            <div className="day-display">
              {/* {daysArray.map((day, index) => (
                <button
                  key={index}
                  className={selectedDay === index ? 'selected' : ''}
                  onClick={() => setSelectedDay(index)}
                >
                  {day.date}
                </button>
              ))} */}
            </div>

           
            <section className='meteoblue'>

              {selectedForecast && (
                <div style={{width:'90%'}}>
                   <h2>{t('current Weather')}</h2>
                  <h3>{t('today')}</h3>
                  <div className="meteoblue weather-card" onClick={() => setShowForecast(!showForecast)}>
                    <div className="weather-card-hader">
                      <h3>{polygon.name}</h3>
                    </div>
                    <div className="weather-card-body popup">
                      <div className="weather-card-body-left">
                        <WeatherIcon temperature={weatherData.currentTemperature} />
                        <p>{weatherData.currentTemperature}°C</p>
                      </div>
                      <div className="weather-card-body-right">


                        <p>{t('wind Speed')}: {weatherData.windspeed} m/s</p>
                        <p>{t('pressure')}: {weatherData.sealevelpressure} hPa</p>
                        <p>{t('precipitation')}: {weatherData.precipitation} mm</p>
                        <p>{t('humidity')}: {weatherData.humidity} %</p>
                        <p>{t('snow Fraction')}: {weatherData.snowfraction}</p>
                      </div>
                      {/* <div className='more-weather-insights'>
                        <h4>Tonight's weather forecast</h4>
                        <div className="weather-card-body-left with-desc">
                          <div className="">
                            <WeatherIcon temperature={weatherData.currentTemperature} />
                            <p>{weatherData.currentTemperature}°C</p>
                          </div>
                          <h4>{description}</h4>
                        </div>
                      </div> */}
                    </div>
                  </div>
                  <h3>{t('agro Models')}</h3>
                  <div className="meteoblue weather-card">
                    <p>{t('soil Temperature')} : {weatherData.soilTemperature} °C</p>
                    <p>{t('transpiration')} : {weatherData.transpiration} mm</p>
                    <p>{t('dew Point')} : {weatherData.dewpoint} °C</p>
                    <p>{t('leaf Wetness')} : {(weatherData.leafWetness) * 100} %</p>
                  </div>
                </div>
              )}


              {selectedForecast && (
                <div style={{width:'90%'}}>
                   <h2>{t('7 day Forecast')}  <span className="tooltip" style={{paddingBottom:'3px'}}>
                          <BiHelpCircle />
                          <span className="tooltiptext" 
                          style={{width:'200px', 
                          fontSize:'12px', 
                          textAlign:'center',
                            padding:'10px'}}
                          >
                            <p style={{fontSize:"13px"}}>{t('mean value of the days forecast')}</p>
                          </span>
                        </span></h2>
                        <div className="day-display">

{
  weatherData.forecast && (
    weatherData.forecast.time.slice(0, 7).map((time, index) => {
      const [year, month, day] = time.split('-');
      const formattedDate = `${day}/${month}`;

      return (
        <button key={time} onClick={() => setSelectedDay(index)} className={selectedDay === index ? 'selected' : ''}>
          {formattedDate}
        </button>
      );
    })
  )
}

{/* {weatherData.forecast.time[0]} */}



</div>
                  <div className="meteoblue weather-card" onClick={() => setShowForecast(!showForecast)}>
                    <div className="weather-card-hader">
                      <h3>{polygon.name}</h3>
                    </div>
                    <div className="weather-card-body popup">
                      <div className="weather-card-body-left">
                        <WeatherIcon temperature={weatherData.currentTemperature} />
                        <p>{selectedForecast.temperature}°C</p>
                      </div>
                      <div className="weather-card-body-right">
                        <p>{t('wind Speed')} : {selectedForecast.windspeed} m/s</p>
                        <p>{t('pressure')} : {selectedForecast.sealevelpressure} hPa</p>
                        <p>{t('relative Humidity')} : {selectedForecast.relativehumidity}%</p>
                        <p>{t('precipitation')}: {selectedForecast.precipitation} %</p>
                        <p>{t('snow Fraction')}: {selectedForecast.snowfraction}</p>
                      </div>
                    </div>
                  </div>
                  <h3>{t('agro Models(Forecast)')}</h3>
                  <div className="meteoblue weather-card">
                    <p>{t('soil Temperature')} : {selectedForecast.soilTemperature} °C</p>
                    <p>{t('transpiration')} : {selectedForecast.transpiration} mm</p>
                    <p>{t('dew Point')} : {selectedForecast.dewpoint} °C</p>
                    <p>{('leaf Wetness')} : {(selectedForecast.leafWetness) * 100} %</p>

                  </div>
                </div>
              )}


            </section>
            <div style={{ display: 'flex', justifyContent: 'space-around', width: '100%' }}>
              {/* <div>
                  <h3>Sowing Data</h3>
                 {weatherData && <SowingData weatherData={weatherData} />}
              </div> */}
              <div style={{ width: '100%' }}>
                <h3>{t('forecast')}</h3>
                {weatherData && <SowingForecast weatherData={weatherData} selectedDay={selectedDay} />}
              </div>
            </div>

            <div style={{ width: '100%', marginTop: '20px' }}>
              <h3>{t('temperature Graph')}</h3>
              {weatherData && <TemperatureGraph weatherData={weatherData} />}
            </div>

          </>
        )}
      </Popup>
      }
    </div>
  );
}


const FinalWeatherList = ({shouldDisplayWeather}) => {
  const [polygons, setPolygons] = useState([]);
  const [farmDetails, setFarmDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [weatherData, setWeatherData] = useState({});

  useEffect(() => {
    fetchFarmDetails();
    setLoading(false);
  }, []);

  async function fetchFarmDetails() {
    const farmerID = localStorage.getItem('farmerID');

    try {
      const farmDetails = await API.graphql(graphqlOperation(listFarmDetails, {
        filter: {
          farmerID: {
            eq: farmerID,
          },
          active: {
            eq: true,
          }
        },
      }));
      const farmDetailsData = farmDetails.data.listFarmDetails.items;
     // only display farm details with active as true
      const farmDetailsDataFiltered = farmDetailsData;
      setFarmDetails(farmDetailsDataFiltered);
      console.log(farmDetailsData);
    } catch (error) {
      console.log(error);
    }
  }

  const farmDetailsData = farmDetails.map(farm => {
    const farmData = JSON.parse(farm.rings)[0];
    return {
      ...farm,
      lat: farmData.latitude,
      lon: farmData.longitude
    };
  });

  console.log(farmDetailsData);

  if (farmDetailsData.length === 0) return <NoDataComponent message="No farms found" />;

  return (
    <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 sm:grid-cols-2 gap-2 meteoblue wrapper">
      {farmDetailsData.map(farm => {
        return <WeatherCard polygon={farm} key={farm.id} shouldDisplayWeather={shouldDisplayWeather} />;
      })}
    </div>
  );
};

export default FinalWeatherList;