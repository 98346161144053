import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from "i18next";
import { BsGlobe2 } from 'react-icons/bs';
import Flag from 'react-world-flags'
import './policies.css';
const TermsAndConditions = () => {
  const [showDropdown, setShowDropdown] = useState(false);

  const { t } = useTranslation();
  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
    toggleDropdown();
};

const toggleDropdown = () => {
  setShowDropdown(!showDropdown);
};
  return (
    <div
    className='privacy-policy'
    style={{
        backgroundColor: 'white',
        color: 'darkgreen',
        padding: '20px',
        borderRadius: '8px',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
        maxWidth: '800px',
        margin: '0 auto',
        lineHeight: '1.6',
        fontFamily: 'Arial, sans-serif',
      
        height: '100vh',
        position:'relative'
      }}
    >
         <div className='' onClick={toggleDropdown} style={{position:'relative', textAlign:'right'}}> 
        <BsGlobe2 />
        
        {showDropdown && (
        <div className='lang-switch'style={{right:'1%'}} >
        <Flag code="RO" height="14" onClick={() => changeLanguage('fr')} />
      <button onClick={() => changeLanguage('fr')}>Română</button>
      <Flag code="GB" height="14"  onClick={() => changeLanguage('en')} />
      <button onClick={() => changeLanguage('en')}>English</button>      
      </div>
      )}
      </div>
      <h1 style={{ marginBottom: '20px' }}>TERMS AND CONDITIONS</h1>
      <p>
        Welcome to AGROGIS! These Terms and Conditions outline the rules and regulations for using our application. By accessing or using our services, you agree to comply with these Terms and Conditions. If you do not agree with any part of these terms, please do not use our application. Please read these Terms and Conditions ("Terms", "Terms and Conditions") carefully before using the AGROGIS application (the "Service") operated by AGROGIS TECH S.R.L. ("us", "we", or "our"). Your access to and use of the Service is conditioned upon your acceptance of and compliance with these Terms. These Terms apply to all visitors, users, and others who wish to access or use the Service. By accessing or using the Service, you agree to be bound by these Terms. If you disagree with any part of the terms, then you do not have permission to access the Service.
      </p>
      <h2>1. Personal Data</h2>
      <p>
        <b>1.1 Definition of Personal Data:</b> The term "personal data" refers to any information that identifies or makes a physical person identifiable. This may include details about their characteristics, habits, lifestyle, personal relationships, health status, economic situation, and more.
      </p>
      <p>
        <b>1.2 Data Collection:</b> The data we collect and process include:
      </p>
      <ul>
        <li><b>1.2.1 Personal data:</b> This includes information such as name, surname, address, telephone number, and any other necessary details to provide services and fulfill legal obligations.</li>
        <li><b>1.2.2 Specific data:</b> This includes geolocation data, browsing data, user habits, or any other information known during the use of our services, acquired directly or indirectly from user behavior.</li>
      </ul>
      <p>
        <b>1.3 Data Controller:</b> [Your Company Name], with registered office at [address], is the Data Controller responsible for the processing of personal data. You may contact our Data Protection Officer (DPO) at [email address] for any inquiries regarding your personal data.
      </p>

      <h2>2. Purpose and Legal Basis for Data Processing</h2>
      <p>
        <b>2.1 Purpose of Data Processing:</b> We process your personal data for the following purposes:
      </p>
      <ul>
        <li><b>2.1.1 Providing services:</b> This includes precision farming programs, training courses, consultancy services, and verticalization.</li>
        <li><b>2.1.2 Fulfilling obligations:</b> We process data to fulfill agreements, contact you, provide assistance, and comply with legal obligations related to the services.</li>
        <li><b>2.1.3 Communication:</b> We may contact you through mailing lists or other means to keep you updated about our services.</li>
        <li><b>2.1.4 Behavior monitoring:</b> We monitor your interactions with our services and communication channels to optimize our service quality.</li>
        <li><b>2.1.5 Statistical and scientific research:</b> We conduct research activities for national and international purposes.</li>
        <li><b>2.1.6 Promotional campaigns:</b> We create campaigns to inform and promote our services.</li>
        <li><b>2.1.7 Enabling business partners:</b> We share data with partners to fulfill contractual obligations for goods or services requested by you.</li>
      </ul>
      <p>
        <b>2.2 Legal Basis for Data Processing:</b>
      </p>
      <ul>
        <li><b>2.2.1 Contractual necessity:</b> We process data necessary for providing services, fulfilling contractual obligations, and meeting legal requirements.</li>
        <li><b>2.2.2 Consent:</b> Your consent, given at the time of subscription, is valid for all purposes mentioned above.</li>
        <li><b>2.2.3 Legal obligations:</b> We process data to comply with specific storage and data traceability requirements mandated by law.</li>
        <li><b>2.2.4 Legitimate interests:</b> We process data to protect the use of our services, optimize customer experience, and improve our algorithms and services through scientific research.</li>
        <li><b>2.2.5 Marketing and profiling:</b> We use marketing and profiling data to optimize communication and service delivery.</li>
      </ul>

      <h2>3. Geographical Location</h2>
      <p>
        <b>3.1 Geolocation Use:</b> The use of our services may require the utilization of your geographical location, such as when performing treatments via the app with GPS enabled. Disabling geolocation may limit the functionality of the service.
      </p>

      <h2>4. Obligation of Communication</h2>
      <p>
        <b>4.1 Essential Data:</b> Your data is essential for using our services. Failure to provide consent or incomplete/incorrect data may result in the inability to provide the requested services.
      </p>
      <p>
        <b>4.2 Optional Data:</b> Commercial contacts and promotional activities are optional, and you have the right to refuse your consent for these purposes.
      </p>

      <h2>5. Data Recipients</h2>
      <p>
        <b>5.1 Third-Party Services:</b> We may share your personal data with systems and services such as Google, Esri Arcgis, Microsoft Azure, Google, Apple, Stripe, Hotjar, or others to provide the best possible service.
      </p>
      <p>
        <b>5.2 Business Partners:</b> Your data may be transmitted to our business partners for the activation and/or operation of goods or services requested through our platform.
      </p>

      <h2>6. Data Retention</h2>
      <p>
        <b>6.1 Processing Period:</b> We will retain your data for the necessary duration to fulfill the service contract and up to 2 (two) years after its termination. However, please review the extended information for specific retention periods based on the contracted service type.
      </p>
      <p>
        <b>6.2 Legal Obligations:</b> Some legal obligations may require us to retain data for up to 10 (ten) years for accounting purposes.
      </p>
      <p>
        <b>6.3 Commercial and Marketing Information:</b> We have a legitimate interest in retaining commercial and marketing information, which may be subject to anonymization or pseudonymization upon your request. However, these data will not be removed from the system.
      </p>
      <p>
        <b>6.4 Scientific Data:</b> Scientific data is also retained based on legitimate interests and is linked to anonymization or pseudonymization processes to improve our services.
      </p>

      <h2>7. Your Rights</h2>
      <p>
        <b>7.1 Your Rights:</b> You have the following rights regarding your personal data, as per EU Regulation 2016/679:
      </p>
      <ul>
        <li><b>7.1.1 Access, correction, and update:</b> You can access, correct, or update your personal data.</li>
        <li><b>7.1.2 Blocking or restriction:</b> You can request the blocking or restriction of the use of your data.</li>
        <li><b>7.1.3 Data deletion:</b> You can request the partial or complete deletion of your personal data.</li>
        <li><b>7.1.4 Data portability:</b> You can request the portability of your data.</li>
      </ul>
      <p>
        <b>7.2 Possibility to Claim:</b> If you believe your rights have been violated, you have the right to lodge a complaint with the relevant data protection authority.
      </p>
      <p>
        <b>7.3 Withdrawal of Consent:</b> If data processing is based on your consent, you have the right to revoke it at any time by contacting us. However, if other legal bases exist, some data may be retained to fulfill expected obligations.
      </p>
      <p>
        <b>7.4 User Profiling:</b> We use your personal data to understand your behavior and habits to define a profile related to you.
      </p>
      <p>
        <b>7.5 Automated Processing:</b> Your personal data will not be used with automated evaluation processes that you can object to.
      </p>

      <h2>8. Data Treatment</h2>
      <p>
        <b>8.1 Data Treatment:</b> Your personal data will be processed using both electronic and paper instruments, depending on the type of service you have contracted.
      </p>

      <h2>9. Transfer of Data to Third Countries</h2>
      <p>
        <b>9.1 Data Transfer:</b> For certain treatments related to marketing services and other services, your data may be communicated outside of Europe. We ensure that our suppliers comply with the necessary conditions and warranties provided by relevant regulations.
      </p>

      <h2>10. Other Considerations</h2>
      <p>
        <b>10.1 Limited Use of Personal Data:</b> We use your personal data solely for the purposes outlined in this document. If we intend to use your data for other purposes, we will seek your permission before doing so.
      </p>

      <p>
        If you have any questions or concerns regarding our Terms and Conditions or the processing of your personal data, please contact us through the provided channels.
      </p>
      <p>
        AGROGIS TECH S.R.L.
        PICTOR ION ANDREESCU 32B, ROOM 3
        GIURGIU, ROMANIA
        Email: privacy@agrogis.com
      </p>
    </div>
  );
};

export default TermsAndConditions;
