import React, { useEffect, useMemo, useState } from 'react';
import { MapContainer, Polygon, TileLayer, useMap } from 'react-leaflet';
import * as d3 from 'd3'
import { useRef } from 'react';
import 'leaflet/dist/leaflet.css';
import axios from 'axios';
import GeoRasterLayer from 'georaster-layer-for-leaflet';
import parse_georaster from 'georaster';
import L from 'leaflet';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Cell } from 'recharts';
import isEqual from 'lodash.isequal';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next'

// Custom component to create GeoRasterLayer
const GeoRasterLayerComponent = ({
  georaster,
  selectedPolygon,
  polygonCoordinates,
  setColorPercentages,
  timeRange,
  ndmiData,
  ndreData,
  ndwiData,
  msaviData,
  evapoData,
}) => {
  const { t } = useTranslation()
  const map = useMap();
  const layerRef = useRef();

  useEffect(() => {
    if (georaster) {
      if (layerRef.current) {
        map.removeLayer(layerRef.current);
        layerRef.current = null;
      }

      const layer = new GeoRasterLayer({
        georaster,
        opacity: 1,
        pixelValuesToColorFn: (values) => {
          const ndvi = values[0];
          const ndmi = values[0];
          const ndre = values[0];
          const ndwi = values[0];
          const msavi = values[0];
          const evapo = values[0];

          let color = 'transparent';

          // Define your color interpolation scale using D3 here
          const colorScale = d3
            .scaleSequential(d3.interpolateViridis)
            .domain([-1, 1]);

            // Define a custom color scale for NDMI (bluish colors)
const ndmiColorScale = d3.scaleLinear()
.domain([-1, -0.9, -0.8, -0.7, -0.6, -0.5, -0.4, 
  -0.3, -0.2, -0.1, 0, 0.1, 0.2,
  0.3, 0.4, 0.5, 0.6, 0.7, 0.8,
  0.9, 1])
  .range(['#CC0000', '#D12111', '#D12111', '#D12111', '#D12111', '#D12111', '#D12111', '#D12111', '#E1D991', '#D9DCA9', '#D2DFC0', '#CAE2D8', '#C3E6EF', '#B8E0F9', '#A4C8E6', '#91B0D4', '#7E97C1', '#6B7FAE', '#57679C', '#444F89'])

// Define a custom color scale for NDVI (green to red)
const ndviColorScale =d3.scaleLinear()
.domain([-0.2, 0.05, 0.1, 0.15, 0.2, 0.25, 0.3, 
  0.35, 0.4, 0.45, 0.5, 0.55, 0.6,
  0.65, 0.7, 0.75, 0.8, 0.85, 0.9,
  0.95, 1])
.range(['#CCCCCC', '#CCCCCC', '#E02D2C', '#EF4C3A', '#FE6C4A', '#FF8D5A', '#FFAB69', '#FFC67D', '#FFE093', '#FFEFAB', '#FDFEC2', '#EAF7AC', '#D5EF94', '#B9E383', '#9BD873', '#77CA6F', '#53BD6B', '#14AA60', '#009755', '#007E47']);

const ndreColorScale = d3
  .scaleSequential(d3.interpolateRdYlGn) // Red to green color scale
  .domain([-1, 1]); // Adjust the domain based on your data range

  const msaviColorScale = d3.scaleLinear()
  .domain([-1, 0, 1])
  .range(['#C52F1D', '#C5142A', '#E02D2C', '#EF4C3A', '#FE6C4A', '#FF8D5A', '#FFAB69', '#FFC67D', '#FFE093', '#FFEFAB', '#FDFEC2', '#EAF7AC', '#D5EF94', '#B9E383', '#9BD873', '#77CA6F', '#53BD6B', '#14AA60', '#009755', '#007E47']);

  const ndwiColorScale = d3.scaleLinear()
  .domain([-1, -0.3, 0, 0.2, 1])
  .range(['#CC0000', '#E6A754', '#CAE2D8', '#444F89'])

  const etColorScale = d3
  .scaleSequential(d3.interpolateYlOrRd) // Yellow to red color scale
  .domain([0, 1]); // Adjust the domain based on your ET data range



          if (ndmiData) {
            if (ndmi >= -1 && ndmi <= 1) {
              color = ndmiColorScale(ndmi);
            }
          } else if (ndreData) {
            if (ndre >= -1 && ndre <= 1) {
              color = ndviColorScale(ndre);
            }
          } else if (ndwiData) {
            if (ndwi >= -1 && ndwi <= 1) {
              color = ndwiColorScale(ndwi);
            }
          } else if (msaviData) {
            if (msavi >= -1 && msavi <= 1) {
              if (msavi >= 0 && msavi <= 0.0025) {
                color = 'transparent';
              } else {
                color = ndviColorScale(msavi);
              }
            }
          }
           else if (evapoData) {
            if (evapo >= -1 && evapo <= 1) {
              if (evapo >= 0 && evapo <= 0.0025) {
                color = 'transparent';
              } else {
              color = ndmiColorScale(evapo);
            }
          }

          } else {
            if (ndvi >= -1 && ndvi <= 1) {
              color = ndviColorScale(ndvi);
            }

          }

          const ndwiColorRanges = [
            { range: [-1, -0.3], color: '#CC0000' },
            { range: [-0.3, 0], color: '#E6A754' },
            { range: [0, 0.2], color: '#CAE2D8' },
            { range: [0.2, 1], color: '#444F89' },
          ];

          const ndmiColorRanges = [
            { range: [-1.00, -0.90], color: '#CC0000' },
            { range: [-0.90, -0.80], color: '#D12111' },
            { range: [-0.80, -0.70], color: '#D64321' },
            { range: [-0.70, -0.60], color: '#DC6432' },
            { range: [-0.60, -0.50], color: '#E18543' },
            { range: [-0.50, -0.40], color: '#E6A754' },
            { range: [-0.40, -0.30], color: '#EBC864' },
            { range: [-0.30, -0.20], color: '#E8D57A' },
            { range: [-0.20, -0.10], color: '#E1D991' },
            { range: [-0.10, 0.00], color: '#D9DCA9' },
            { range: [0.00, 0.10], color: '#D2DFC0' },
            { range: [0.10, 0.20], color: '#CAE2D8' },
            { range: [0.20, 0.30], color: '#C3E6EF' },
            { range: [0.30, 0.40], color: '#B8E0F9' },
            { range: [0.40, 0.50], color: '#A4C8E6' },
            { range: [0.50, 0.60], color: '#91B0D4' },
            { range: [0.60, 0.70], color: '#7E97C1' },
            { range: [0.70, 0.80], color: '#6B7FAE' },
            { range: [0.80, 0.90], color: '#57679C' },
            { range: [0.90, 1.00], color: '#444F89' },
          ];
          
        

          const ndviColorRanges = [
            { range: [-1, 0.05], color: '#C52F1D' },
            { range: [0.05, 0.10], color: '#C5142A' },
            { range: [0.10, 0.15], color: '#E02D2C' },
            { range: [0.15, 0.20], color: '#EF4C3A' },
            { range: [0.20, 0.25], color: '#FE6C4A' },
            { range: [0.25, 0.30], color: '#FF8D5A' },
            { range: [0.30, 0.35], color: '#FFAB69' },
            { range: [0.35, 0.40], color: '#FFC67D' },
            { range: [0.40, 0.45], color: '#FFE093' },
            { range: [0.45, 0.50], color: '#FFEFAB' },
            { range: [0.50, 0.55], color: '#FDFEC2' },
            { range: [0.55, 0.60], color: '#EAF7AC' },
            { range: [0.60, 0.65], color: '#D5EF94' },
            { range: [0.65, 0.70], color: '#B9E383' },
            { range: [0.70, 0.75], color: '#9BD873' },
            { range: [0.75, 0.80], color: '#77CA6F' },
            { range: [0.80, 0.85], color: '#53BD6B' },
            { range: [0.85, 0.90], color: '#14AA60' },
            { range: [0.90, 0.95], color: '#009755' },
            { range: [0.95, 1.00], color: '#007E47' },
          ];
          
          setColorPercentages(prevColorPercentages => {
            if(ndreData){
  
              const newColorPercentages = { ...prevColorPercentages };

              if (color !== 'transparent') {
                for (const { range, color } of ndviColorRanges) {
                  // Initialize counts to 0 for colors that don't exist in prevColorPercentages
                  if (newColorPercentages[color] === undefined) {
                    newColorPercentages[color] = 0;
                  }
                  
                  const [min, max] = range;
                  if (ndre >= min && ndre <= max) {
                    newColorPercentages[color] += 1;
                    // break; // Exit the loop after finding the matching range
                  }
                }
              }
              
              // Remove any properties with undefined or null values
              for (const color in newColorPercentages) {
                if (newColorPercentages[color] === undefined || newColorPercentages[color] === null) {
                  delete newColorPercentages[color];
                }
              }
              

            return newColorPercentages;
          }
            else if(ndmiData){
               const newColorPercentages = { ...prevColorPercentages };
               if (color !== 'transparent') {
                for (const { range, color } of ndmiColorRanges) {
                  // Initialize counts to 0 for colors that don't exist in prevColorPercentages
                  if (newColorPercentages[color] === undefined) {
                    newColorPercentages[color] = 0;
                  }
                  
                  const [min, max] = range;
                  if (ndmi >= min && ndmi <= max) {
                    newColorPercentages[color] += 1;
                  }
                }
              }
              
              // Remove any properties with undefined or null values
              for (const color in newColorPercentages) {
                if (newColorPercentages[color] === undefined || newColorPercentages[color] === null) {
                  delete newColorPercentages[color];
                }
              }

              
            return newColorPercentages;
            }else if(ndwiData){
            
              const newColorPercentages = { ...prevColorPercentages };

              if (color !== 'transparent') {
                for (const { range, color } of ndwiColorRanges) {
                  // Initialize counts to 0 for colors that don't exist in prevColorPercentages
                  if (newColorPercentages[color] === undefined) {
                    newColorPercentages[color] = 0;
                  }
                  
                  const [min, max] = range;
                  if (ndwi >= min && ndwi <= max) {
                    newColorPercentages[color] += 1;
                  }
                }
              }
              
              // Remove any properties with undefined or null values
              for (const color in newColorPercentages) {
                if (newColorPercentages[color] === undefined || newColorPercentages[color] === null) {
                  delete newColorPercentages[color];
                }
              }
              return newColorPercentages;
            }else if(evapoData){
              const newColorPercentages = { ...prevColorPercentages };
              if (color !== 'transparent') {
               for (const { range, color } of ndmiColorRanges) {
                 // Initialize counts to 0 for colors that don't exist in prevColorPercentages
                 if (newColorPercentages[color] === undefined) {
                   newColorPercentages[color] = 0;
                 }
                 
                 const [min, max] = range;
                 if (evapo >= min && evapo <= max) {
                   newColorPercentages[color] += 1;
                 }
               }
             }
             
             // Remove any properties with undefined or null values
             for (const color in newColorPercentages) {
               if (newColorPercentages[color] === undefined || newColorPercentages[color] === null) {
                 delete newColorPercentages[color];
               }
             }
              return newColorPercentages;
            }else if(msaviData){
            const newColorPercentages = { ...prevColorPercentages };

              if (color !== 'transparent') {
                for (const { range, color } of ndviColorRanges) {
                  // Initialize counts to 0 for colors that don't exist in prevColorPercentages
                  if (newColorPercentages[color] === undefined) {
                    newColorPercentages[color] = 0;
                  }
                  
                  const [min, max] = range;
                  if (msavi >= min && msavi <= max) {
                    newColorPercentages[color] += 1;
                    // break; // Exit the loop after finding the matching range
                  }
                }
              }
              
              // Remove any properties with undefined or null values
              for (const color in newColorPercentages) {
                if (newColorPercentages[color] === undefined || newColorPercentages[color] === null) {
                  delete newColorPercentages[color];
                }
              }
              

            return newColorPercentages;
            } 
            else{
              const newColorPercentages = { ...prevColorPercentages };

              if (color !== 'transparent') {
                for (const { range, color } of ndviColorRanges) {
                  // Initialize counts to 0 for colors that don't exist in prevColorPercentages
                  if (newColorPercentages[color] === undefined) {
                    newColorPercentages[color] = 0;
                  }
                  
                  const [min, max] = range;
                  if (ndvi >= min && ndvi <= max) {
                    newColorPercentages[color] += 1;
                  }
                }
              }
              
              // Remove any properties with undefined or null values
              for (const color in newColorPercentages) {
                if (newColorPercentages[color] === undefined || newColorPercentages[color] === null) {
                  delete newColorPercentages[color];
                }
              }
              

            return newColorPercentages;
          }
          });
          return color;
        },
        resolution: 100,
      });

      layer.addTo(map);
      map.fitBounds(layer.getBounds());

      if (polygonCoordinates.length > 0) {
        const polygonCenter = polygonCoordinates.reduce(
          (prev, curr) => [prev[0] + curr[0], prev[1] + curr[1]],
          [0, 0]
        ).map((coordinate) => coordinate / polygonCoordinates.length);

        // map.flyTo([polygonCenter[1], polygonCenter[0]], 16);

        const latLngs = polygonCoordinates.map((coord) => [coord[1], coord[0]]);
        const polygon = L.polygon(latLngs, { color: 'yellow', fill: false }).addTo(
          map
        );
      }

      layerRef.current = layer;
    }

    return () => {
      if (layerRef.current) {
        map.removeLayer(layerRef.current);
      }
    };
  }, [map, timeRange]);

  return null;
};

// Main component
const NDVIMap = ({ token, selectedPolygon, onFilteredColorPercentages , parentFilteredColorPercentages, fetchMetaData, cloudCover, ndmiData,ndreData,ndwiData,msaviData,evapoData, updateData, showedDate, lateShowedDate, shouldDisplayWeather}) => {
  const [loading, setLoading] = useState(false);
  const [georaster, setGeoraster] = useState(null);
  const [colorPercentages, setColorPercentages] = useState({});
  const [catalogData, setCatalogData] = useState({});
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const { t } = useTranslation()
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  let isCloud
  const style = {
    height: windowWidth < 770 ? '50vh' : '100vh',
    width: '100%',
  };

  const url = 'https://services.sentinel-hub.com/api/v1/process';
  console.log(colorPercentages)
  const timeRange = useMemo(() => {
    if (showedDate != NaN-NaN-NaN && lateShowedDate!= NaN-NaN-NaN){
      return { from: lateShowedDate + 'T00:00:00Z',
         to: showedDate + 'T23:59:59Z'
        };
    }else{
      return { from: '2022-05-01T00:00:00Z', to: '2022-05-31T23:59:59Z' };
    }
  }, [showedDate, lateShowedDate]);

  console.log(timeRange)


console.log(cloudCover);
console.log('check')
  const polygonCoordinates = selectedPolygon ? selectedPolygon.map(point => [point[0], point[1]]) : [];
 
  console.log(polygonCoordinates)
  let evalscript = ''; 

  const fetchData = async () => {
    const accessToken = token;
    const requestData = new FormData();
    if (ndmiData){
       evalscript = `//VERSION=3
       function setup() {
         return {
           input: [{ bands: ['B08', 'B11', 'SCL'] }], // Using NIR (B08) and SWIR 1 (B11)
           output: [{ id: 'default', bands: 1, sampleType: 'FLOAT32' }],
         };
       }
       
       function evaluatePixel(sample) {
         if ([8, 9, 10].includes(sample.SCL)) {
           return [null]; // return null for cloud pixels
         } else {
           let ndmi = (sample.B08 - sample.B11) / (sample.B08 + sample.B11);
           return { default: [ndmi] };
         }
       }
       `;
      
  }else if(ndreData){
    evalscript = `//VERSION=3
    function setup() {
      return {
        input: [{ bands: ['B05', 'B08', 'SCL'] }],
        output: [{ id: 'default', bands: 1, sampleType: 'FLOAT32' }],
      };
    }
    
    function evaluatePixel(sample) {
      if ([8, 9, 10].includes(sample.SCL)) {
        return [null]; // return null for cloud pixels
      } else {
        let ndre = (sample.B08 - sample.B05) / (sample.B08 + sample.B05);
        return { default: [ndre] };
      }
    }
    `;
  
  }else if(ndwiData){
    evalscript = `//VERSION=3
    function setup() {
      return {
        input: [{ bands: ['B03', 'B08', 'SCL'] }], // Using Green (B03) and NIR (B08)
        output: [{ id: 'default', bands: 1, sampleType: 'FLOAT32' }],
      };
    }
    
    function evaluatePixel(sample) {
      if ([8, 9, 10].includes(sample.SCL)) {
        return [null]; // return null for cloud pixels
      } else {
        let ndwi = (sample.B03 - sample.B08) / (sample.B03 + sample.B08);
        return { default: [ndwi] };
      }
    }
    `;
  }else if(msaviData){
    evalscript = `//VERSION=3
    function setup() {
      return {
        input: [{ bands: ['B04', 'B08', 'SCL'] }],
        output: [{ id: 'default', bands: 1, sampleType: 'FLOAT32' }],
      };
    }
    
    function evaluatePixel(sample) {
      if ([8, 9, 10].includes(sample.SCL)) {
        return [null]; // return null for cloud pixels
      } else {
        let msavi = (2 * sample.B08 + 1 - Math.sqrt(Math.pow((2 * sample.B08 + 1), 2) - 8 * (sample.B08 - sample.B04))) / 2;
        return { default: [msavi] };
      }
    }
    `;
  }else if(evapoData){
    evalscript = `//VERSION=3
    function setup() {
      return {
        input: [{ bands: ['B04', 'B08', 'SCL'] }],
        output: [{ id: 'default', bands: 1, sampleType: 'FLOAT32' }],
      };
    }
    
    function evaluatePixel(sample) {
      if ([8, 9, 10].includes(sample.SCL)) {
        return [null]; // return null for cloud pixels
      } else {
        let evapo = (2 * sample.B08 + 1 - Math.sqrt(Math.pow((2 * sample.B08 + 1), 2) - 8 * (sample.B08 - sample.B04))) / 2;
        return { default: [evapo] };
      }
    }
    `;
  }

  else{
     evalscript = `
     //VERSION=3
     function setup() {
       return{
         input: [{
           bands: ["B04", "B08", "SCL"],
           units: "DN"
         }],
         output: {
           id: "default",
           bands: 1,
           sampleType: SampleType.FLOAT32
         }
       }
     }
     
     function evaluatePixel(sample) {
       isCloud = [7, 8, 9, 10].includes(sample.SCL) ? 1 : 0;
      if (isCloud) {
        return { default: [null], cloud: [isCloud] }; // return null for NDVI of cloud pixels
      } else {
        let ndvi = (sample.B08 - sample.B04) / (sample.B08 + sample.B04);
        return { default: [ndvi], cloud: [isCloud] };
      }
    }
     
    `;
  }
  
  
    requestData.append(
      'request',
      JSON.stringify({
        input: {
          bounds: {
            properties: { crs: 'http://www.opengis.net/def/crs/OGC/1.3/CRS84' },
            geometry: {
              type: 'Polygon',
              coordinates: [polygonCoordinates],
            },
          },
          data: [
            {
              type: 'S2L2A',
              dataFilter: { timeRange: 
                { from: timeRange.from, to: timeRange.to } },
            },
          ],
        },
        output: { responses: [{ identifier: 'default', format: { type: 'image/tiff' } }],  resolution: 10 },
      })
    );
    requestData.append('evalscript', evalscript);
    // requestData.append('config', "3f3ae38b-897b-46a5-8136-0cbb5d8df6a3")
  

    const requestOptions = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      body: requestData,
    };

    try {
      const response = await fetch(url, requestOptions);
      console.log(response)
      if (response.ok) {
        const arrayBuffer = await response.arrayBuffer();
        const georaster = await parse_georaster(arrayBuffer);
        console.log(georaster);
        return georaster;
      } else {
        console.log('Error:', response.status, response.statusText);
        return null;
      }
    } catch (error) {
      console.log('Error:', error);
      return null;
    }
  };


  

  useEffect(() => {
    if (!selectedPolygon) return;
  

    // Reset georaster and colorPercentages when selectedDate changes
    setGeoraster(null);
    setColorPercentages({});
  
    if (!token || !polygonCoordinates) return;

    setLoading(true);
    fetchData().then(georaster => {


      setGeoraster(georaster);
      console.log(georaster);

      fetchMetaData()
      setColorPercentages({});
      setLoading(false);
      
    })
    .catch(error => {
      console.log(error);
      setLoading(false);
    }
    )
    .finally(() => setLoading(false));
  }, [selectedPolygon, timeRange, ndmiData,ndreData,ndwiData,msaviData,evapoData]);


  //counting the pixels
  const filteredColorPercentages = Object.entries(colorPercentages).reduce((filtered, [color, count]) => {
    if (color !== 'transparent') {
      filtered[color] = count;
    }
    return filtered;
  }, {});



  useEffect(() => {
    if (!isEqual(filteredColorPercentages, parentFilteredColorPercentages)) {

      onFilteredColorPercentages(filteredColorPercentages);
    }
  }, [filteredColorPercentages, parentFilteredColorPercentages]);
// const { t } = useTranslation()
  const getColorLabel = (color) => {
    switch (color) {
      case 'green':
        return t('vigorDescriptions.highVigor')
      case 'yellow':
        return t('vigorDescriptions.mediumVigor')
      case 'blue':
        return t('vigorDescriptions.lowVigor')
      case 'red':
        return t('vigorDescriptions.noVigor')
      default:
        return ''
    }
  };
  

  const validColorCount = Object.values(filteredColorPercentages).reduce((total, count) => total + count, 0);
  const chartData = Object.keys(filteredColorPercentages).map(color => ({
    color,
    label: getColorLabel(color),
    percentage: ((filteredColorPercentages[color] || 0) / validColorCount * 100).toFixed(2),
  }));

  const mapKey = useMemo(() => Math.random(), [georaster]); // Generate a new key every time georaster changes


  return (
    <>
         {loading && (
          <div className="loading-image-overlay">
            <div className="loading-text">{t('loading')}...</div>
          </div>
        )}

{
          !georaster && !loading  &&  (
            <div className="loading-image-overlay">
              <div className="visual-wait">{t('vizualization will appear here upon selecting a farm.')}</div>
            </div>
          )}
          {
            
          }
       
        {
          cloudCover>99.99 && (
            <div className="loading-image-overlay">
              <div className="loading-text">{t('cloud Cover')}: {cloudCover}%</div>
            </div>
          )
        }
      <MapContainer
        
       center={[44.53004060621996, 25.958722230021067]} zoom={15} style={style}>
   
        <TileLayer
          url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
          attribution="Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community"
        />
        <TileLayer
  url="https://services.arcgisonline.com/ArcGIS/rest/services/Reference/World_Boundaries_and_Places/MapServer/tile/{z}/{y}/{x}"
/>

        {georaster && (
          <>
            <GeoRasterLayerComponent
              georaster={georaster}
              polygonCoordinates={polygonCoordinates}
              setColorPercentages={setColorPercentages}
              timeRange={timeRange}
              ndmiData= {ndmiData}
              ndreData= {ndreData}
              ndwiData= {ndwiData}
              msaviData= {msaviData}
              evapoData= {evapoData}
            />
            
          </>
        )}
        {/* {polygonCoordinates.length > 0 && <Polygon positions={polygonCoordinates.map(coord => [coord[1], coord[0]])} color="red" />} */}
      </MapContainer>


    </>
  );
};

export default NDVIMap;

