import React from 'react'
import { useTranslation } from 'react-i18next'

const GoUpBtn = () => {
  // Scroll to top logic smoothly
  const { t } = useTranslation()
  const handleGoUp = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  return (
    <button className="goup-btn" onClick={handleGoUp}>
      {t('gO UP')}
    </button>
  )
}

export default GoUpBtn
