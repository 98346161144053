import React, { useState } from 'react';
import SowingDay from './SowingDay';

const SowingForecast = ({ weatherData, selectedDay }) => {

  const getRelevantDataForDay = (dayIndex) => {
    const startIndex = (24 * dayIndex) +6;
    const endIndex = startIndex + 13;
    return {
      time: weatherData.data_1h.time.slice(startIndex, endIndex),
      sowing_maize: weatherData.data_1h.sowing_maize.slice(startIndex, endIndex),
    };
  };

  console.log(weatherData);

  return (
    <div style={{width:'100%'}}>
      <div>
        {/* {['Today', 'Tomorrow', 'Day 3', 'Day 4', 'Day 5', 'Day 6', 'Day 7'].map((label, index) => (
          <button key={index} onClick={() => setSelectedDay(index)}>
            {label}
          </button>
        ))} */}
      </div>
      <SowingDay dataForDay={getRelevantDataForDay(selectedDay)} />
    </div>
  );
};

export default SowingForecast;
