import React, { useState } from "react";
import { Link } from "react-router-dom";
import { listPackageRequests } from "../../../graphql/queries";
import { API, graphqlOperation } from "aws-amplify";
import FetchingData from "../../null/FetchingData";
import { useTranslation } from "react-i18next";

const SelectedPlan = () => {
  const [plan, setPlan] = useState([]);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  React.useEffect(() => {
    fetchPlan();
  }, []);
  const fetchPlan = async () => {
    try {
      const packagesRequestData = await API.graphql(
        graphqlOperation(listPackageRequests, {
          filter: {
            requesterID: {
              eq: localStorage.getItem("farmerID"),
            },
            active: {
              eq: true, // Add this filtering condition
            },
          },
        })
      );
      setPlan(
        packagesRequestData.data.listPackageRequests.items.filter(
          (item) => item.package !== null
        )
      );
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  console.log(plan);

  return (
    <div className="package-wrapper">
      {loading && <FetchingData />}
      {
        //check if the package object is not null
        plan.length >= 1 ? (
          plan.map((plan, index) => (
            <div key={index} className="package-card">
              <p>{t("your Plan")}</p>
              <div className="package-image">
                <img
                  src={`https://officialagrogis.s3.amazonaws.com/${plan.package.image}`}
                  alt=""
                />
                <div className="package-name">
                  <p>{plan.package.title}</p>
                </div>
              </div>
              <ul style={{ paddingLeft: "15px" }}>
                {plan.package.features &&
                  plan.package.features.map((feature, idx) => (
                    <li key={idx}>{feature}</li>
                  ))}
              </ul>
              <Link to="subscription" className="manage-plan-btn">
                {t("manage Plan")}
              </Link>
            </div>
          ))
        ) : plan.length === 0 && !loading ? (
          <div className="package-card">
            <p>{t("your Plan")}</p>
            <div className="package-image">
              <img
                src="https://officialagrogis.s3.amazonaws.com/public/PACKAGES/AGROGIS%20Freemium.freemium2.jpg"
                alt="plan"
              />
              <div className="package-name">
                <p>AGROGIS {t("freemium")}</p>
              </div>
            </div>
            <div className="package-info mb-4">
              <p>
                {t(
                  "this is a free basic Package built for introduction to AgroGIS for a maximum of 5 farms Management with limited features"
                )}
              </p>
            </div>
            <Link to="subscription" className="manage-plan-btn">
              {t("manage Plan")}
            </Link>
          </div>
        ) : null
      }
    </div>
  );
};

export default SelectedPlan;
