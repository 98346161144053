import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

const ConnectionStatus = () => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true);
      toast.success('Internet connection restored',{
        position: "top-center",
        autoClose: 5000,
      });
    };

    const handleOffline = () => {
      setIsOnline(false);
        toast.error('Internet connection lost', {
            position: "top-center",
            autoClose: 5000,
        });
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  return (
    <div>
     
    </div>
  );
};

export default ConnectionStatus;
