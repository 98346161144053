import React from 'react';
import {
    LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
    } from 'recharts';

    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    const formatTime = (date) => {
      const day = date.getDate();
      const month = monthNames[date.getMonth()];
      const hour = String(date.getHours()).padStart(2, '0');
      return `${day} ${month} ${hour}:00`;
    };
    
    const processDataForGraph = (data) => {
      // Modify this function to extract temperatures and times from your weather data
      const graphData = data.data_1h.time.map((time, index) => {
        const date = new Date(time);
        const hour = date.getHours();
        if ([6, 10, 14, 18].includes(hour)) {
          return {
            time: formatTime(date),
            temperature: data.data_1h.temperature[index],
          };
        }
        return null;
      }).filter(Boolean); // filter out null values
    
      return graphData;
    };
    
    const TemperatureGraph = ({weatherData}) => {
      const graphData = processDataForGraph(weatherData);
    
      return (
        <LineChart
          width={1000} // Adjust the width as needed
          height={300}
          data={graphData}
          margin={{
            top: 5,
            right: 30,
            left: 10,
            bottom: 55,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="time" angle={-45} textAnchor="end" interval={0} />
          <YAxis dataKey="temperature" label=''/>
          <Tooltip />
          <Legend />
          <Line type="monotone" dataKey="temperature" stroke="#8884d8" activeDot={{ r: 8 }} />
        </LineChart>
      );
    };
    
    export default TemperatureGraph;