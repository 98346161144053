/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createSoilAnalysis = /* GraphQL */ `
  mutation CreateSoilAnalysis(
    $input: CreateSoilAnalysisInput!
    $condition: ModelSoilAnalysisConditionInput
  ) {
    createSoilAnalysis(input: $input, condition: $condition) {
      id
      farmDetails {
        id
        farmer {
          id
          customID
          firstName
          lastName
          email
          phone
          billingAddress
          city
          country
          postalCode
          company
          county
          isDisabled
          createdAt
          updatedAt
          __typename
        }
        active
        farmerID
        name
        crop
        cropUse
        Earlyness
        Protection
        area
        TotalYield
        YieldPerha
        sowingDate
        harvestDate
        soilTexture
        drainage
        Management
        ownership
        cadastralSheet
        cadastralMunicipality
        cadastralParticle
        slope
        revenue
        notes
        startDate
        spatialReference
        byoc
        subscriptionID
        rings
        damages {
          nextToken
          __typename
        }
        diseases {
          nextToken
          __typename
        }
        operations {
          nextToken
          __typename
        }
        droneRequests {
          nextToken
          __typename
        }
        issues {
          nextToken
          __typename
        }
        SoilAnalysis {
          nextToken
          __typename
        }
        WeatherStation {
          nextToken
          __typename
        }
        planetRequests {
          nextToken
          __typename
        }
        gmail
        createdAt
        updatedAt
        __typename
      }
      farmDetailsID
      sand
      clay
      silt
      ph
      totalNitrogen
      assimilablePhosphorus
      totalOrganicCarbon
      cToNRatio
      exchangeablePotassium
      cationExchangeCapacity
      bulkDensity
      skeleton
      depth
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateSoilAnalysis = /* GraphQL */ `
  mutation UpdateSoilAnalysis(
    $input: UpdateSoilAnalysisInput!
    $condition: ModelSoilAnalysisConditionInput
  ) {
    updateSoilAnalysis(input: $input, condition: $condition) {
      id
      farmDetails {
        id
        farmer {
          id
          customID
          firstName
          lastName
          email
          phone
          billingAddress
          city
          country
          postalCode
          company
          county
          isDisabled
          createdAt
          updatedAt
          __typename
        }
        active
        farmerID
        name
        crop
        cropUse
        Earlyness
        Protection
        area
        TotalYield
        YieldPerha
        sowingDate
        harvestDate
        soilTexture
        drainage
        Management
        ownership
        cadastralSheet
        cadastralMunicipality
        cadastralParticle
        slope
        revenue
        notes
        startDate
        spatialReference
        byoc
        subscriptionID
        rings
        damages {
          nextToken
          __typename
        }
        diseases {
          nextToken
          __typename
        }
        operations {
          nextToken
          __typename
        }
        droneRequests {
          nextToken
          __typename
        }
        issues {
          nextToken
          __typename
        }
        SoilAnalysis {
          nextToken
          __typename
        }
        WeatherStation {
          nextToken
          __typename
        }
        planetRequests {
          nextToken
          __typename
        }
        gmail
        createdAt
        updatedAt
        __typename
      }
      farmDetailsID
      sand
      clay
      silt
      ph
      totalNitrogen
      assimilablePhosphorus
      totalOrganicCarbon
      cToNRatio
      exchangeablePotassium
      cationExchangeCapacity
      bulkDensity
      skeleton
      depth
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteSoilAnalysis = /* GraphQL */ `
  mutation DeleteSoilAnalysis(
    $input: DeleteSoilAnalysisInput!
    $condition: ModelSoilAnalysisConditionInput
  ) {
    deleteSoilAnalysis(input: $input, condition: $condition) {
      id
      farmDetails {
        id
        farmer {
          id
          customID
          firstName
          lastName
          email
          phone
          billingAddress
          city
          country
          postalCode
          company
          county
          isDisabled
          createdAt
          updatedAt
          __typename
        }
        active
        farmerID
        name
        crop
        cropUse
        Earlyness
        Protection
        area
        TotalYield
        YieldPerha
        sowingDate
        harvestDate
        soilTexture
        drainage
        Management
        ownership
        cadastralSheet
        cadastralMunicipality
        cadastralParticle
        slope
        revenue
        notes
        startDate
        spatialReference
        byoc
        subscriptionID
        rings
        damages {
          nextToken
          __typename
        }
        diseases {
          nextToken
          __typename
        }
        operations {
          nextToken
          __typename
        }
        droneRequests {
          nextToken
          __typename
        }
        issues {
          nextToken
          __typename
        }
        SoilAnalysis {
          nextToken
          __typename
        }
        WeatherStation {
          nextToken
          __typename
        }
        planetRequests {
          nextToken
          __typename
        }
        gmail
        createdAt
        updatedAt
        __typename
      }
      farmDetailsID
      sand
      clay
      silt
      ph
      totalNitrogen
      assimilablePhosphorus
      totalOrganicCarbon
      cToNRatio
      exchangeablePotassium
      cationExchangeCapacity
      bulkDensity
      skeleton
      depth
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createMeteoblue = /* GraphQL */ `
  mutation CreateMeteoblue(
    $input: CreateMeteoblueInput!
    $condition: ModelMeteoblueConditionInput
  ) {
    createMeteoblue(input: $input, condition: $condition) {
      id
      cachedData
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateMeteoblue = /* GraphQL */ `
  mutation UpdateMeteoblue(
    $input: UpdateMeteoblueInput!
    $condition: ModelMeteoblueConditionInput
  ) {
    updateMeteoblue(input: $input, condition: $condition) {
      id
      cachedData
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteMeteoblue = /* GraphQL */ `
  mutation DeleteMeteoblue(
    $input: DeleteMeteoblueInput!
    $condition: ModelMeteoblueConditionInput
  ) {
    deleteMeteoblue(input: $input, condition: $condition) {
      id
      cachedData
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAdmin = /* GraphQL */ `
  mutation CreateAdmin(
    $input: CreateAdminInput!
    $condition: ModelAdminConditionInput
  ) {
    createAdmin(input: $input, condition: $condition) {
      id
      email
      firstName
      lastName
      phone
      gmail
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAdmin = /* GraphQL */ `
  mutation UpdateAdmin(
    $input: UpdateAdminInput!
    $condition: ModelAdminConditionInput
  ) {
    updateAdmin(input: $input, condition: $condition) {
      id
      email
      firstName
      lastName
      phone
      gmail
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAdmin = /* GraphQL */ `
  mutation DeleteAdmin(
    $input: DeleteAdminInput!
    $condition: ModelAdminConditionInput
  ) {
    deleteAdmin(input: $input, condition: $condition) {
      id
      email
      firstName
      lastName
      phone
      gmail
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createFarmer = /* GraphQL */ `
  mutation CreateFarmer(
    $input: CreateFarmerInput!
    $condition: ModelFarmerConditionInput
  ) {
    createFarmer(input: $input, condition: $condition) {
      id
      customID
      firstName
      lastName
      email
      phone
      billingAddress
      city
      country
      postalCode
      company
      county
      isDisabled
      farms {
        items {
          id
          active
          farmerID
          name
          crop
          cropUse
          Earlyness
          Protection
          area
          TotalYield
          YieldPerha
          sowingDate
          harvestDate
          soilTexture
          drainage
          Management
          ownership
          cadastralSheet
          cadastralMunicipality
          cadastralParticle
          slope
          revenue
          notes
          startDate
          spatialReference
          byoc
          subscriptionID
          rings
          gmail
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      issues {
        items {
          id
          farmerID
          farmDetailsID
          fieldName
          date
          notes
          status
          type
          coordinates
          image
          gmail
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      damages {
        items {
          id
          farmerID
          farmDetailsID
          fieldName
          date
          type
          intensity
          notes
          coordinates
          image
          gmail
          status
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      diseases {
        items {
          id
          farmerID
          farmDetailsID
          date
          type
          status
          notes
          coordinates
          image
          gmail
          intensity
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      packages {
        items {
          id
          title
          description
          price
          features
          image
          farmerID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      droneRequests {
        items {
          id
          farmerID
          farmDetailsID
          date
          fieldName
          crop
          area
          note
          status
          hasUpload
          rings
          gmail
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      weatherStation {
        items {
          id
          farmDetailsID
          api
          farmerID
          gmail
          status
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      packageRequests {
        items {
          id
          packageID
          requesterID
          status
          active
          expirationDate
          areaLimit
          areaRequested
          isAreaRequest
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      planetRequests {
        items {
          id
          farmerID
          farmDetailsID
          status
          gmail
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateFarmer = /* GraphQL */ `
  mutation UpdateFarmer(
    $input: UpdateFarmerInput!
    $condition: ModelFarmerConditionInput
  ) {
    updateFarmer(input: $input, condition: $condition) {
      id
      customID
      firstName
      lastName
      email
      phone
      billingAddress
      city
      country
      postalCode
      company
      county
      isDisabled
      farms {
        items {
          id
          active
          farmerID
          name
          crop
          cropUse
          Earlyness
          Protection
          area
          TotalYield
          YieldPerha
          sowingDate
          harvestDate
          soilTexture
          drainage
          Management
          ownership
          cadastralSheet
          cadastralMunicipality
          cadastralParticle
          slope
          revenue
          notes
          startDate
          spatialReference
          byoc
          subscriptionID
          rings
          gmail
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      issues {
        items {
          id
          farmerID
          farmDetailsID
          fieldName
          date
          notes
          status
          type
          coordinates
          image
          gmail
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      damages {
        items {
          id
          farmerID
          farmDetailsID
          fieldName
          date
          type
          intensity
          notes
          coordinates
          image
          gmail
          status
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      diseases {
        items {
          id
          farmerID
          farmDetailsID
          date
          type
          status
          notes
          coordinates
          image
          gmail
          intensity
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      packages {
        items {
          id
          title
          description
          price
          features
          image
          farmerID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      droneRequests {
        items {
          id
          farmerID
          farmDetailsID
          date
          fieldName
          crop
          area
          note
          status
          hasUpload
          rings
          gmail
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      weatherStation {
        items {
          id
          farmDetailsID
          api
          farmerID
          gmail
          status
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      packageRequests {
        items {
          id
          packageID
          requesterID
          status
          active
          expirationDate
          areaLimit
          areaRequested
          isAreaRequest
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      planetRequests {
        items {
          id
          farmerID
          farmDetailsID
          status
          gmail
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteFarmer = /* GraphQL */ `
  mutation DeleteFarmer(
    $input: DeleteFarmerInput!
    $condition: ModelFarmerConditionInput
  ) {
    deleteFarmer(input: $input, condition: $condition) {
      id
      customID
      firstName
      lastName
      email
      phone
      billingAddress
      city
      country
      postalCode
      company
      county
      isDisabled
      farms {
        items {
          id
          active
          farmerID
          name
          crop
          cropUse
          Earlyness
          Protection
          area
          TotalYield
          YieldPerha
          sowingDate
          harvestDate
          soilTexture
          drainage
          Management
          ownership
          cadastralSheet
          cadastralMunicipality
          cadastralParticle
          slope
          revenue
          notes
          startDate
          spatialReference
          byoc
          subscriptionID
          rings
          gmail
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      issues {
        items {
          id
          farmerID
          farmDetailsID
          fieldName
          date
          notes
          status
          type
          coordinates
          image
          gmail
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      damages {
        items {
          id
          farmerID
          farmDetailsID
          fieldName
          date
          type
          intensity
          notes
          coordinates
          image
          gmail
          status
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      diseases {
        items {
          id
          farmerID
          farmDetailsID
          date
          type
          status
          notes
          coordinates
          image
          gmail
          intensity
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      packages {
        items {
          id
          title
          description
          price
          features
          image
          farmerID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      droneRequests {
        items {
          id
          farmerID
          farmDetailsID
          date
          fieldName
          crop
          area
          note
          status
          hasUpload
          rings
          gmail
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      weatherStation {
        items {
          id
          farmDetailsID
          api
          farmerID
          gmail
          status
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      packageRequests {
        items {
          id
          packageID
          requesterID
          status
          active
          expirationDate
          areaLimit
          areaRequested
          isAreaRequest
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      planetRequests {
        items {
          id
          farmerID
          farmDetailsID
          status
          gmail
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createFarmDetails = /* GraphQL */ `
  mutation CreateFarmDetails(
    $input: CreateFarmDetailsInput!
    $condition: ModelFarmDetailsConditionInput
  ) {
    createFarmDetails(input: $input, condition: $condition) {
      id
      farmer {
        id
        customID
        firstName
        lastName
        email
        phone
        billingAddress
        city
        country
        postalCode
        company
        county
        isDisabled
        farms {
          nextToken
          __typename
        }
        issues {
          nextToken
          __typename
        }
        damages {
          nextToken
          __typename
        }
        diseases {
          nextToken
          __typename
        }
        packages {
          nextToken
          __typename
        }
        droneRequests {
          nextToken
          __typename
        }
        weatherStation {
          nextToken
          __typename
        }
        packageRequests {
          nextToken
          __typename
        }
        planetRequests {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      active
      farmerID
      name
      crop
      cropUse
      Earlyness
      Protection
      area
      TotalYield
      YieldPerha
      sowingDate
      harvestDate
      soilTexture
      drainage
      Management
      ownership
      cadastralSheet
      cadastralMunicipality
      cadastralParticle
      slope
      revenue
      notes
      startDate
      spatialReference
      byoc
      subscriptionID
      rings
      damages {
        items {
          id
          farmerID
          farmDetailsID
          fieldName
          date
          type
          intensity
          notes
          coordinates
          image
          gmail
          status
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      diseases {
        items {
          id
          farmerID
          farmDetailsID
          date
          type
          status
          notes
          coordinates
          image
          gmail
          intensity
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      operations {
        items {
          id
          farmerID
          farmDetailsID
          crop
          operation
          fertilizerType
          fertilizerQuantity
          fertilizerQuantityPerHa
          yieldQuantity
          yieldQuantityPerHa
          irrigationLitres
          irrigationLitresPerHa
          date
          notes
          gmail
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      droneRequests {
        items {
          id
          farmerID
          farmDetailsID
          date
          fieldName
          crop
          area
          note
          status
          hasUpload
          rings
          gmail
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      issues {
        items {
          id
          farmerID
          farmDetailsID
          fieldName
          date
          notes
          status
          type
          coordinates
          image
          gmail
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      SoilAnalysis {
        items {
          id
          farmDetailsID
          sand
          clay
          silt
          ph
          totalNitrogen
          assimilablePhosphorus
          totalOrganicCarbon
          cToNRatio
          exchangeablePotassium
          cationExchangeCapacity
          bulkDensity
          skeleton
          depth
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      WeatherStation {
        items {
          id
          farmDetailsID
          api
          farmerID
          gmail
          status
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      planetRequests {
        items {
          id
          farmerID
          farmDetailsID
          status
          gmail
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      gmail
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateFarmDetails = /* GraphQL */ `
  mutation UpdateFarmDetails(
    $input: UpdateFarmDetailsInput!
    $condition: ModelFarmDetailsConditionInput
  ) {
    updateFarmDetails(input: $input, condition: $condition) {
      id
      farmer {
        id
        customID
        firstName
        lastName
        email
        phone
        billingAddress
        city
        country
        postalCode
        company
        county
        isDisabled
        farms {
          nextToken
          __typename
        }
        issues {
          nextToken
          __typename
        }
        damages {
          nextToken
          __typename
        }
        diseases {
          nextToken
          __typename
        }
        packages {
          nextToken
          __typename
        }
        droneRequests {
          nextToken
          __typename
        }
        weatherStation {
          nextToken
          __typename
        }
        packageRequests {
          nextToken
          __typename
        }
        planetRequests {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      active
      farmerID
      name
      crop
      cropUse
      Earlyness
      Protection
      area
      TotalYield
      YieldPerha
      sowingDate
      harvestDate
      soilTexture
      drainage
      Management
      ownership
      cadastralSheet
      cadastralMunicipality
      cadastralParticle
      slope
      revenue
      notes
      startDate
      spatialReference
      byoc
      subscriptionID
      rings
      damages {
        items {
          id
          farmerID
          farmDetailsID
          fieldName
          date
          type
          intensity
          notes
          coordinates
          image
          gmail
          status
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      diseases {
        items {
          id
          farmerID
          farmDetailsID
          date
          type
          status
          notes
          coordinates
          image
          gmail
          intensity
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      operations {
        items {
          id
          farmerID
          farmDetailsID
          crop
          operation
          fertilizerType
          fertilizerQuantity
          fertilizerQuantityPerHa
          yieldQuantity
          yieldQuantityPerHa
          irrigationLitres
          irrigationLitresPerHa
          date
          notes
          gmail
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      droneRequests {
        items {
          id
          farmerID
          farmDetailsID
          date
          fieldName
          crop
          area
          note
          status
          hasUpload
          rings
          gmail
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      issues {
        items {
          id
          farmerID
          farmDetailsID
          fieldName
          date
          notes
          status
          type
          coordinates
          image
          gmail
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      SoilAnalysis {
        items {
          id
          farmDetailsID
          sand
          clay
          silt
          ph
          totalNitrogen
          assimilablePhosphorus
          totalOrganicCarbon
          cToNRatio
          exchangeablePotassium
          cationExchangeCapacity
          bulkDensity
          skeleton
          depth
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      WeatherStation {
        items {
          id
          farmDetailsID
          api
          farmerID
          gmail
          status
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      planetRequests {
        items {
          id
          farmerID
          farmDetailsID
          status
          gmail
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      gmail
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteFarmDetails = /* GraphQL */ `
  mutation DeleteFarmDetails(
    $input: DeleteFarmDetailsInput!
    $condition: ModelFarmDetailsConditionInput
  ) {
    deleteFarmDetails(input: $input, condition: $condition) {
      id
      farmer {
        id
        customID
        firstName
        lastName
        email
        phone
        billingAddress
        city
        country
        postalCode
        company
        county
        isDisabled
        farms {
          nextToken
          __typename
        }
        issues {
          nextToken
          __typename
        }
        damages {
          nextToken
          __typename
        }
        diseases {
          nextToken
          __typename
        }
        packages {
          nextToken
          __typename
        }
        droneRequests {
          nextToken
          __typename
        }
        weatherStation {
          nextToken
          __typename
        }
        packageRequests {
          nextToken
          __typename
        }
        planetRequests {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      active
      farmerID
      name
      crop
      cropUse
      Earlyness
      Protection
      area
      TotalYield
      YieldPerha
      sowingDate
      harvestDate
      soilTexture
      drainage
      Management
      ownership
      cadastralSheet
      cadastralMunicipality
      cadastralParticle
      slope
      revenue
      notes
      startDate
      spatialReference
      byoc
      subscriptionID
      rings
      damages {
        items {
          id
          farmerID
          farmDetailsID
          fieldName
          date
          type
          intensity
          notes
          coordinates
          image
          gmail
          status
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      diseases {
        items {
          id
          farmerID
          farmDetailsID
          date
          type
          status
          notes
          coordinates
          image
          gmail
          intensity
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      operations {
        items {
          id
          farmerID
          farmDetailsID
          crop
          operation
          fertilizerType
          fertilizerQuantity
          fertilizerQuantityPerHa
          yieldQuantity
          yieldQuantityPerHa
          irrigationLitres
          irrigationLitresPerHa
          date
          notes
          gmail
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      droneRequests {
        items {
          id
          farmerID
          farmDetailsID
          date
          fieldName
          crop
          area
          note
          status
          hasUpload
          rings
          gmail
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      issues {
        items {
          id
          farmerID
          farmDetailsID
          fieldName
          date
          notes
          status
          type
          coordinates
          image
          gmail
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      SoilAnalysis {
        items {
          id
          farmDetailsID
          sand
          clay
          silt
          ph
          totalNitrogen
          assimilablePhosphorus
          totalOrganicCarbon
          cToNRatio
          exchangeablePotassium
          cationExchangeCapacity
          bulkDensity
          skeleton
          depth
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      WeatherStation {
        items {
          id
          farmDetailsID
          api
          farmerID
          gmail
          status
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      planetRequests {
        items {
          id
          farmerID
          farmDetailsID
          status
          gmail
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      gmail
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createIssues = /* GraphQL */ `
  mutation CreateIssues(
    $input: CreateIssuesInput!
    $condition: ModelIssuesConditionInput
  ) {
    createIssues(input: $input, condition: $condition) {
      id
      farmer {
        id
        customID
        firstName
        lastName
        email
        phone
        billingAddress
        city
        country
        postalCode
        company
        county
        isDisabled
        farms {
          nextToken
          __typename
        }
        issues {
          nextToken
          __typename
        }
        damages {
          nextToken
          __typename
        }
        diseases {
          nextToken
          __typename
        }
        packages {
          nextToken
          __typename
        }
        droneRequests {
          nextToken
          __typename
        }
        weatherStation {
          nextToken
          __typename
        }
        packageRequests {
          nextToken
          __typename
        }
        planetRequests {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      farmerID
      farmDetails {
        id
        farmer {
          id
          customID
          firstName
          lastName
          email
          phone
          billingAddress
          city
          country
          postalCode
          company
          county
          isDisabled
          createdAt
          updatedAt
          __typename
        }
        active
        farmerID
        name
        crop
        cropUse
        Earlyness
        Protection
        area
        TotalYield
        YieldPerha
        sowingDate
        harvestDate
        soilTexture
        drainage
        Management
        ownership
        cadastralSheet
        cadastralMunicipality
        cadastralParticle
        slope
        revenue
        notes
        startDate
        spatialReference
        byoc
        subscriptionID
        rings
        damages {
          nextToken
          __typename
        }
        diseases {
          nextToken
          __typename
        }
        operations {
          nextToken
          __typename
        }
        droneRequests {
          nextToken
          __typename
        }
        issues {
          nextToken
          __typename
        }
        SoilAnalysis {
          nextToken
          __typename
        }
        WeatherStation {
          nextToken
          __typename
        }
        planetRequests {
          nextToken
          __typename
        }
        gmail
        createdAt
        updatedAt
        __typename
      }
      farmDetailsID
      fieldName
      date
      notes
      status
      type
      coordinates
      image
      gmail
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateIssues = /* GraphQL */ `
  mutation UpdateIssues(
    $input: UpdateIssuesInput!
    $condition: ModelIssuesConditionInput
  ) {
    updateIssues(input: $input, condition: $condition) {
      id
      farmer {
        id
        customID
        firstName
        lastName
        email
        phone
        billingAddress
        city
        country
        postalCode
        company
        county
        isDisabled
        farms {
          nextToken
          __typename
        }
        issues {
          nextToken
          __typename
        }
        damages {
          nextToken
          __typename
        }
        diseases {
          nextToken
          __typename
        }
        packages {
          nextToken
          __typename
        }
        droneRequests {
          nextToken
          __typename
        }
        weatherStation {
          nextToken
          __typename
        }
        packageRequests {
          nextToken
          __typename
        }
        planetRequests {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      farmerID
      farmDetails {
        id
        farmer {
          id
          customID
          firstName
          lastName
          email
          phone
          billingAddress
          city
          country
          postalCode
          company
          county
          isDisabled
          createdAt
          updatedAt
          __typename
        }
        active
        farmerID
        name
        crop
        cropUse
        Earlyness
        Protection
        area
        TotalYield
        YieldPerha
        sowingDate
        harvestDate
        soilTexture
        drainage
        Management
        ownership
        cadastralSheet
        cadastralMunicipality
        cadastralParticle
        slope
        revenue
        notes
        startDate
        spatialReference
        byoc
        subscriptionID
        rings
        damages {
          nextToken
          __typename
        }
        diseases {
          nextToken
          __typename
        }
        operations {
          nextToken
          __typename
        }
        droneRequests {
          nextToken
          __typename
        }
        issues {
          nextToken
          __typename
        }
        SoilAnalysis {
          nextToken
          __typename
        }
        WeatherStation {
          nextToken
          __typename
        }
        planetRequests {
          nextToken
          __typename
        }
        gmail
        createdAt
        updatedAt
        __typename
      }
      farmDetailsID
      fieldName
      date
      notes
      status
      type
      coordinates
      image
      gmail
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteIssues = /* GraphQL */ `
  mutation DeleteIssues(
    $input: DeleteIssuesInput!
    $condition: ModelIssuesConditionInput
  ) {
    deleteIssues(input: $input, condition: $condition) {
      id
      farmer {
        id
        customID
        firstName
        lastName
        email
        phone
        billingAddress
        city
        country
        postalCode
        company
        county
        isDisabled
        farms {
          nextToken
          __typename
        }
        issues {
          nextToken
          __typename
        }
        damages {
          nextToken
          __typename
        }
        diseases {
          nextToken
          __typename
        }
        packages {
          nextToken
          __typename
        }
        droneRequests {
          nextToken
          __typename
        }
        weatherStation {
          nextToken
          __typename
        }
        packageRequests {
          nextToken
          __typename
        }
        planetRequests {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      farmerID
      farmDetails {
        id
        farmer {
          id
          customID
          firstName
          lastName
          email
          phone
          billingAddress
          city
          country
          postalCode
          company
          county
          isDisabled
          createdAt
          updatedAt
          __typename
        }
        active
        farmerID
        name
        crop
        cropUse
        Earlyness
        Protection
        area
        TotalYield
        YieldPerha
        sowingDate
        harvestDate
        soilTexture
        drainage
        Management
        ownership
        cadastralSheet
        cadastralMunicipality
        cadastralParticle
        slope
        revenue
        notes
        startDate
        spatialReference
        byoc
        subscriptionID
        rings
        damages {
          nextToken
          __typename
        }
        diseases {
          nextToken
          __typename
        }
        operations {
          nextToken
          __typename
        }
        droneRequests {
          nextToken
          __typename
        }
        issues {
          nextToken
          __typename
        }
        SoilAnalysis {
          nextToken
          __typename
        }
        WeatherStation {
          nextToken
          __typename
        }
        planetRequests {
          nextToken
          __typename
        }
        gmail
        createdAt
        updatedAt
        __typename
      }
      farmDetailsID
      fieldName
      date
      notes
      status
      type
      coordinates
      image
      gmail
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createDamages = /* GraphQL */ `
  mutation CreateDamages(
    $input: CreateDamagesInput!
    $condition: ModelDamagesConditionInput
  ) {
    createDamages(input: $input, condition: $condition) {
      id
      farmer {
        id
        customID
        firstName
        lastName
        email
        phone
        billingAddress
        city
        country
        postalCode
        company
        county
        isDisabled
        farms {
          nextToken
          __typename
        }
        issues {
          nextToken
          __typename
        }
        damages {
          nextToken
          __typename
        }
        diseases {
          nextToken
          __typename
        }
        packages {
          nextToken
          __typename
        }
        droneRequests {
          nextToken
          __typename
        }
        weatherStation {
          nextToken
          __typename
        }
        packageRequests {
          nextToken
          __typename
        }
        planetRequests {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      farmerID
      farmDetails {
        id
        farmer {
          id
          customID
          firstName
          lastName
          email
          phone
          billingAddress
          city
          country
          postalCode
          company
          county
          isDisabled
          createdAt
          updatedAt
          __typename
        }
        active
        farmerID
        name
        crop
        cropUse
        Earlyness
        Protection
        area
        TotalYield
        YieldPerha
        sowingDate
        harvestDate
        soilTexture
        drainage
        Management
        ownership
        cadastralSheet
        cadastralMunicipality
        cadastralParticle
        slope
        revenue
        notes
        startDate
        spatialReference
        byoc
        subscriptionID
        rings
        damages {
          nextToken
          __typename
        }
        diseases {
          nextToken
          __typename
        }
        operations {
          nextToken
          __typename
        }
        droneRequests {
          nextToken
          __typename
        }
        issues {
          nextToken
          __typename
        }
        SoilAnalysis {
          nextToken
          __typename
        }
        WeatherStation {
          nextToken
          __typename
        }
        planetRequests {
          nextToken
          __typename
        }
        gmail
        createdAt
        updatedAt
        __typename
      }
      farmDetailsID
      fieldName
      date
      type
      intensity
      notes
      coordinates
      image
      gmail
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateDamages = /* GraphQL */ `
  mutation UpdateDamages(
    $input: UpdateDamagesInput!
    $condition: ModelDamagesConditionInput
  ) {
    updateDamages(input: $input, condition: $condition) {
      id
      farmer {
        id
        customID
        firstName
        lastName
        email
        phone
        billingAddress
        city
        country
        postalCode
        company
        county
        isDisabled
        farms {
          nextToken
          __typename
        }
        issues {
          nextToken
          __typename
        }
        damages {
          nextToken
          __typename
        }
        diseases {
          nextToken
          __typename
        }
        packages {
          nextToken
          __typename
        }
        droneRequests {
          nextToken
          __typename
        }
        weatherStation {
          nextToken
          __typename
        }
        packageRequests {
          nextToken
          __typename
        }
        planetRequests {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      farmerID
      farmDetails {
        id
        farmer {
          id
          customID
          firstName
          lastName
          email
          phone
          billingAddress
          city
          country
          postalCode
          company
          county
          isDisabled
          createdAt
          updatedAt
          __typename
        }
        active
        farmerID
        name
        crop
        cropUse
        Earlyness
        Protection
        area
        TotalYield
        YieldPerha
        sowingDate
        harvestDate
        soilTexture
        drainage
        Management
        ownership
        cadastralSheet
        cadastralMunicipality
        cadastralParticle
        slope
        revenue
        notes
        startDate
        spatialReference
        byoc
        subscriptionID
        rings
        damages {
          nextToken
          __typename
        }
        diseases {
          nextToken
          __typename
        }
        operations {
          nextToken
          __typename
        }
        droneRequests {
          nextToken
          __typename
        }
        issues {
          nextToken
          __typename
        }
        SoilAnalysis {
          nextToken
          __typename
        }
        WeatherStation {
          nextToken
          __typename
        }
        planetRequests {
          nextToken
          __typename
        }
        gmail
        createdAt
        updatedAt
        __typename
      }
      farmDetailsID
      fieldName
      date
      type
      intensity
      notes
      coordinates
      image
      gmail
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteDamages = /* GraphQL */ `
  mutation DeleteDamages(
    $input: DeleteDamagesInput!
    $condition: ModelDamagesConditionInput
  ) {
    deleteDamages(input: $input, condition: $condition) {
      id
      farmer {
        id
        customID
        firstName
        lastName
        email
        phone
        billingAddress
        city
        country
        postalCode
        company
        county
        isDisabled
        farms {
          nextToken
          __typename
        }
        issues {
          nextToken
          __typename
        }
        damages {
          nextToken
          __typename
        }
        diseases {
          nextToken
          __typename
        }
        packages {
          nextToken
          __typename
        }
        droneRequests {
          nextToken
          __typename
        }
        weatherStation {
          nextToken
          __typename
        }
        packageRequests {
          nextToken
          __typename
        }
        planetRequests {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      farmerID
      farmDetails {
        id
        farmer {
          id
          customID
          firstName
          lastName
          email
          phone
          billingAddress
          city
          country
          postalCode
          company
          county
          isDisabled
          createdAt
          updatedAt
          __typename
        }
        active
        farmerID
        name
        crop
        cropUse
        Earlyness
        Protection
        area
        TotalYield
        YieldPerha
        sowingDate
        harvestDate
        soilTexture
        drainage
        Management
        ownership
        cadastralSheet
        cadastralMunicipality
        cadastralParticle
        slope
        revenue
        notes
        startDate
        spatialReference
        byoc
        subscriptionID
        rings
        damages {
          nextToken
          __typename
        }
        diseases {
          nextToken
          __typename
        }
        operations {
          nextToken
          __typename
        }
        droneRequests {
          nextToken
          __typename
        }
        issues {
          nextToken
          __typename
        }
        SoilAnalysis {
          nextToken
          __typename
        }
        WeatherStation {
          nextToken
          __typename
        }
        planetRequests {
          nextToken
          __typename
        }
        gmail
        createdAt
        updatedAt
        __typename
      }
      farmDetailsID
      fieldName
      date
      type
      intensity
      notes
      coordinates
      image
      gmail
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createDiseases = /* GraphQL */ `
  mutation CreateDiseases(
    $input: CreateDiseasesInput!
    $condition: ModelDiseasesConditionInput
  ) {
    createDiseases(input: $input, condition: $condition) {
      id
      farmer {
        id
        customID
        firstName
        lastName
        email
        phone
        billingAddress
        city
        country
        postalCode
        company
        county
        isDisabled
        farms {
          nextToken
          __typename
        }
        issues {
          nextToken
          __typename
        }
        damages {
          nextToken
          __typename
        }
        diseases {
          nextToken
          __typename
        }
        packages {
          nextToken
          __typename
        }
        droneRequests {
          nextToken
          __typename
        }
        weatherStation {
          nextToken
          __typename
        }
        packageRequests {
          nextToken
          __typename
        }
        planetRequests {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      farmerID
      farmDetails {
        id
        farmer {
          id
          customID
          firstName
          lastName
          email
          phone
          billingAddress
          city
          country
          postalCode
          company
          county
          isDisabled
          createdAt
          updatedAt
          __typename
        }
        active
        farmerID
        name
        crop
        cropUse
        Earlyness
        Protection
        area
        TotalYield
        YieldPerha
        sowingDate
        harvestDate
        soilTexture
        drainage
        Management
        ownership
        cadastralSheet
        cadastralMunicipality
        cadastralParticle
        slope
        revenue
        notes
        startDate
        spatialReference
        byoc
        subscriptionID
        rings
        damages {
          nextToken
          __typename
        }
        diseases {
          nextToken
          __typename
        }
        operations {
          nextToken
          __typename
        }
        droneRequests {
          nextToken
          __typename
        }
        issues {
          nextToken
          __typename
        }
        SoilAnalysis {
          nextToken
          __typename
        }
        WeatherStation {
          nextToken
          __typename
        }
        planetRequests {
          nextToken
          __typename
        }
        gmail
        createdAt
        updatedAt
        __typename
      }
      farmDetailsID
      date
      type
      status
      notes
      coordinates
      image
      gmail
      intensity
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateDiseases = /* GraphQL */ `
  mutation UpdateDiseases(
    $input: UpdateDiseasesInput!
    $condition: ModelDiseasesConditionInput
  ) {
    updateDiseases(input: $input, condition: $condition) {
      id
      farmer {
        id
        customID
        firstName
        lastName
        email
        phone
        billingAddress
        city
        country
        postalCode
        company
        county
        isDisabled
        farms {
          nextToken
          __typename
        }
        issues {
          nextToken
          __typename
        }
        damages {
          nextToken
          __typename
        }
        diseases {
          nextToken
          __typename
        }
        packages {
          nextToken
          __typename
        }
        droneRequests {
          nextToken
          __typename
        }
        weatherStation {
          nextToken
          __typename
        }
        packageRequests {
          nextToken
          __typename
        }
        planetRequests {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      farmerID
      farmDetails {
        id
        farmer {
          id
          customID
          firstName
          lastName
          email
          phone
          billingAddress
          city
          country
          postalCode
          company
          county
          isDisabled
          createdAt
          updatedAt
          __typename
        }
        active
        farmerID
        name
        crop
        cropUse
        Earlyness
        Protection
        area
        TotalYield
        YieldPerha
        sowingDate
        harvestDate
        soilTexture
        drainage
        Management
        ownership
        cadastralSheet
        cadastralMunicipality
        cadastralParticle
        slope
        revenue
        notes
        startDate
        spatialReference
        byoc
        subscriptionID
        rings
        damages {
          nextToken
          __typename
        }
        diseases {
          nextToken
          __typename
        }
        operations {
          nextToken
          __typename
        }
        droneRequests {
          nextToken
          __typename
        }
        issues {
          nextToken
          __typename
        }
        SoilAnalysis {
          nextToken
          __typename
        }
        WeatherStation {
          nextToken
          __typename
        }
        planetRequests {
          nextToken
          __typename
        }
        gmail
        createdAt
        updatedAt
        __typename
      }
      farmDetailsID
      date
      type
      status
      notes
      coordinates
      image
      gmail
      intensity
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteDiseases = /* GraphQL */ `
  mutation DeleteDiseases(
    $input: DeleteDiseasesInput!
    $condition: ModelDiseasesConditionInput
  ) {
    deleteDiseases(input: $input, condition: $condition) {
      id
      farmer {
        id
        customID
        firstName
        lastName
        email
        phone
        billingAddress
        city
        country
        postalCode
        company
        county
        isDisabled
        farms {
          nextToken
          __typename
        }
        issues {
          nextToken
          __typename
        }
        damages {
          nextToken
          __typename
        }
        diseases {
          nextToken
          __typename
        }
        packages {
          nextToken
          __typename
        }
        droneRequests {
          nextToken
          __typename
        }
        weatherStation {
          nextToken
          __typename
        }
        packageRequests {
          nextToken
          __typename
        }
        planetRequests {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      farmerID
      farmDetails {
        id
        farmer {
          id
          customID
          firstName
          lastName
          email
          phone
          billingAddress
          city
          country
          postalCode
          company
          county
          isDisabled
          createdAt
          updatedAt
          __typename
        }
        active
        farmerID
        name
        crop
        cropUse
        Earlyness
        Protection
        area
        TotalYield
        YieldPerha
        sowingDate
        harvestDate
        soilTexture
        drainage
        Management
        ownership
        cadastralSheet
        cadastralMunicipality
        cadastralParticle
        slope
        revenue
        notes
        startDate
        spatialReference
        byoc
        subscriptionID
        rings
        damages {
          nextToken
          __typename
        }
        diseases {
          nextToken
          __typename
        }
        operations {
          nextToken
          __typename
        }
        droneRequests {
          nextToken
          __typename
        }
        issues {
          nextToken
          __typename
        }
        SoilAnalysis {
          nextToken
          __typename
        }
        WeatherStation {
          nextToken
          __typename
        }
        planetRequests {
          nextToken
          __typename
        }
        gmail
        createdAt
        updatedAt
        __typename
      }
      farmDetailsID
      date
      type
      status
      notes
      coordinates
      image
      gmail
      intensity
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createOperations = /* GraphQL */ `
  mutation CreateOperations(
    $input: CreateOperationsInput!
    $condition: ModelOperationsConditionInput
  ) {
    createOperations(input: $input, condition: $condition) {
      id
      farmDetails {
        id
        farmer {
          id
          customID
          firstName
          lastName
          email
          phone
          billingAddress
          city
          country
          postalCode
          company
          county
          isDisabled
          createdAt
          updatedAt
          __typename
        }
        active
        farmerID
        name
        crop
        cropUse
        Earlyness
        Protection
        area
        TotalYield
        YieldPerha
        sowingDate
        harvestDate
        soilTexture
        drainage
        Management
        ownership
        cadastralSheet
        cadastralMunicipality
        cadastralParticle
        slope
        revenue
        notes
        startDate
        spatialReference
        byoc
        subscriptionID
        rings
        damages {
          nextToken
          __typename
        }
        diseases {
          nextToken
          __typename
        }
        operations {
          nextToken
          __typename
        }
        droneRequests {
          nextToken
          __typename
        }
        issues {
          nextToken
          __typename
        }
        SoilAnalysis {
          nextToken
          __typename
        }
        WeatherStation {
          nextToken
          __typename
        }
        planetRequests {
          nextToken
          __typename
        }
        gmail
        createdAt
        updatedAt
        __typename
      }
      farmerID
      farmDetailsID
      crop
      operation
      fertilizerType
      fertilizerQuantity
      fertilizerQuantityPerHa
      yieldQuantity
      yieldQuantityPerHa
      irrigationLitres
      irrigationLitresPerHa
      date
      notes
      gmail
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateOperations = /* GraphQL */ `
  mutation UpdateOperations(
    $input: UpdateOperationsInput!
    $condition: ModelOperationsConditionInput
  ) {
    updateOperations(input: $input, condition: $condition) {
      id
      farmDetails {
        id
        farmer {
          id
          customID
          firstName
          lastName
          email
          phone
          billingAddress
          city
          country
          postalCode
          company
          county
          isDisabled
          createdAt
          updatedAt
          __typename
        }
        active
        farmerID
        name
        crop
        cropUse
        Earlyness
        Protection
        area
        TotalYield
        YieldPerha
        sowingDate
        harvestDate
        soilTexture
        drainage
        Management
        ownership
        cadastralSheet
        cadastralMunicipality
        cadastralParticle
        slope
        revenue
        notes
        startDate
        spatialReference
        byoc
        subscriptionID
        rings
        damages {
          nextToken
          __typename
        }
        diseases {
          nextToken
          __typename
        }
        operations {
          nextToken
          __typename
        }
        droneRequests {
          nextToken
          __typename
        }
        issues {
          nextToken
          __typename
        }
        SoilAnalysis {
          nextToken
          __typename
        }
        WeatherStation {
          nextToken
          __typename
        }
        planetRequests {
          nextToken
          __typename
        }
        gmail
        createdAt
        updatedAt
        __typename
      }
      farmerID
      farmDetailsID
      crop
      operation
      fertilizerType
      fertilizerQuantity
      fertilizerQuantityPerHa
      yieldQuantity
      yieldQuantityPerHa
      irrigationLitres
      irrigationLitresPerHa
      date
      notes
      gmail
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteOperations = /* GraphQL */ `
  mutation DeleteOperations(
    $input: DeleteOperationsInput!
    $condition: ModelOperationsConditionInput
  ) {
    deleteOperations(input: $input, condition: $condition) {
      id
      farmDetails {
        id
        farmer {
          id
          customID
          firstName
          lastName
          email
          phone
          billingAddress
          city
          country
          postalCode
          company
          county
          isDisabled
          createdAt
          updatedAt
          __typename
        }
        active
        farmerID
        name
        crop
        cropUse
        Earlyness
        Protection
        area
        TotalYield
        YieldPerha
        sowingDate
        harvestDate
        soilTexture
        drainage
        Management
        ownership
        cadastralSheet
        cadastralMunicipality
        cadastralParticle
        slope
        revenue
        notes
        startDate
        spatialReference
        byoc
        subscriptionID
        rings
        damages {
          nextToken
          __typename
        }
        diseases {
          nextToken
          __typename
        }
        operations {
          nextToken
          __typename
        }
        droneRequests {
          nextToken
          __typename
        }
        issues {
          nextToken
          __typename
        }
        SoilAnalysis {
          nextToken
          __typename
        }
        WeatherStation {
          nextToken
          __typename
        }
        planetRequests {
          nextToken
          __typename
        }
        gmail
        createdAt
        updatedAt
        __typename
      }
      farmerID
      farmDetailsID
      crop
      operation
      fertilizerType
      fertilizerQuantity
      fertilizerQuantityPerHa
      yieldQuantity
      yieldQuantityPerHa
      irrigationLitres
      irrigationLitresPerHa
      date
      notes
      gmail
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createPackages = /* GraphQL */ `
  mutation CreatePackages(
    $input: CreatePackagesInput!
    $condition: ModelPackagesConditionInput
  ) {
    createPackages(input: $input, condition: $condition) {
      id
      title
      description
      price
      features
      image
      paidBy {
        id
        customID
        firstName
        lastName
        email
        phone
        billingAddress
        city
        country
        postalCode
        company
        county
        isDisabled
        farms {
          nextToken
          __typename
        }
        issues {
          nextToken
          __typename
        }
        damages {
          nextToken
          __typename
        }
        diseases {
          nextToken
          __typename
        }
        packages {
          nextToken
          __typename
        }
        droneRequests {
          nextToken
          __typename
        }
        weatherStation {
          nextToken
          __typename
        }
        packageRequests {
          nextToken
          __typename
        }
        planetRequests {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      farmerID
      packageRequests {
        items {
          id
          packageID
          requesterID
          status
          active
          expirationDate
          areaLimit
          areaRequested
          isAreaRequest
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updatePackages = /* GraphQL */ `
  mutation UpdatePackages(
    $input: UpdatePackagesInput!
    $condition: ModelPackagesConditionInput
  ) {
    updatePackages(input: $input, condition: $condition) {
      id
      title
      description
      price
      features
      image
      paidBy {
        id
        customID
        firstName
        lastName
        email
        phone
        billingAddress
        city
        country
        postalCode
        company
        county
        isDisabled
        farms {
          nextToken
          __typename
        }
        issues {
          nextToken
          __typename
        }
        damages {
          nextToken
          __typename
        }
        diseases {
          nextToken
          __typename
        }
        packages {
          nextToken
          __typename
        }
        droneRequests {
          nextToken
          __typename
        }
        weatherStation {
          nextToken
          __typename
        }
        packageRequests {
          nextToken
          __typename
        }
        planetRequests {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      farmerID
      packageRequests {
        items {
          id
          packageID
          requesterID
          status
          active
          expirationDate
          areaLimit
          areaRequested
          isAreaRequest
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deletePackages = /* GraphQL */ `
  mutation DeletePackages(
    $input: DeletePackagesInput!
    $condition: ModelPackagesConditionInput
  ) {
    deletePackages(input: $input, condition: $condition) {
      id
      title
      description
      price
      features
      image
      paidBy {
        id
        customID
        firstName
        lastName
        email
        phone
        billingAddress
        city
        country
        postalCode
        company
        county
        isDisabled
        farms {
          nextToken
          __typename
        }
        issues {
          nextToken
          __typename
        }
        damages {
          nextToken
          __typename
        }
        diseases {
          nextToken
          __typename
        }
        packages {
          nextToken
          __typename
        }
        droneRequests {
          nextToken
          __typename
        }
        weatherStation {
          nextToken
          __typename
        }
        packageRequests {
          nextToken
          __typename
        }
        planetRequests {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      farmerID
      packageRequests {
        items {
          id
          packageID
          requesterID
          status
          active
          expirationDate
          areaLimit
          areaRequested
          isAreaRequest
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createPackageRequest = /* GraphQL */ `
  mutation CreatePackageRequest(
    $input: CreatePackageRequestInput!
    $condition: ModelPackageRequestConditionInput
  ) {
    createPackageRequest(input: $input, condition: $condition) {
      id
      packageID
      package {
        id
        title
        description
        price
        features
        image
        paidBy {
          id
          customID
          firstName
          lastName
          email
          phone
          billingAddress
          city
          country
          postalCode
          company
          county
          isDisabled
          createdAt
          updatedAt
          __typename
        }
        farmerID
        packageRequests {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      requestedBy {
        id
        customID
        firstName
        lastName
        email
        phone
        billingAddress
        city
        country
        postalCode
        company
        county
        isDisabled
        farms {
          nextToken
          __typename
        }
        issues {
          nextToken
          __typename
        }
        damages {
          nextToken
          __typename
        }
        diseases {
          nextToken
          __typename
        }
        packages {
          nextToken
          __typename
        }
        droneRequests {
          nextToken
          __typename
        }
        weatherStation {
          nextToken
          __typename
        }
        packageRequests {
          nextToken
          __typename
        }
        planetRequests {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      requesterID
      status
      active
      expirationDate
      areaLimit
      areaRequested
      isAreaRequest
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updatePackageRequest = /* GraphQL */ `
  mutation UpdatePackageRequest(
    $input: UpdatePackageRequestInput!
    $condition: ModelPackageRequestConditionInput
  ) {
    updatePackageRequest(input: $input, condition: $condition) {
      id
      packageID
      package {
        id
        title
        description
        price
        features
        image
        paidBy {
          id
          customID
          firstName
          lastName
          email
          phone
          billingAddress
          city
          country
          postalCode
          company
          county
          isDisabled
          createdAt
          updatedAt
          __typename
        }
        farmerID
        packageRequests {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      requestedBy {
        id
        customID
        firstName
        lastName
        email
        phone
        billingAddress
        city
        country
        postalCode
        company
        county
        isDisabled
        farms {
          nextToken
          __typename
        }
        issues {
          nextToken
          __typename
        }
        damages {
          nextToken
          __typename
        }
        diseases {
          nextToken
          __typename
        }
        packages {
          nextToken
          __typename
        }
        droneRequests {
          nextToken
          __typename
        }
        weatherStation {
          nextToken
          __typename
        }
        packageRequests {
          nextToken
          __typename
        }
        planetRequests {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      requesterID
      status
      active
      expirationDate
      areaLimit
      areaRequested
      isAreaRequest
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deletePackageRequest = /* GraphQL */ `
  mutation DeletePackageRequest(
    $input: DeletePackageRequestInput!
    $condition: ModelPackageRequestConditionInput
  ) {
    deletePackageRequest(input: $input, condition: $condition) {
      id
      packageID
      package {
        id
        title
        description
        price
        features
        image
        paidBy {
          id
          customID
          firstName
          lastName
          email
          phone
          billingAddress
          city
          country
          postalCode
          company
          county
          isDisabled
          createdAt
          updatedAt
          __typename
        }
        farmerID
        packageRequests {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      requestedBy {
        id
        customID
        firstName
        lastName
        email
        phone
        billingAddress
        city
        country
        postalCode
        company
        county
        isDisabled
        farms {
          nextToken
          __typename
        }
        issues {
          nextToken
          __typename
        }
        damages {
          nextToken
          __typename
        }
        diseases {
          nextToken
          __typename
        }
        packages {
          nextToken
          __typename
        }
        droneRequests {
          nextToken
          __typename
        }
        weatherStation {
          nextToken
          __typename
        }
        packageRequests {
          nextToken
          __typename
        }
        planetRequests {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      requesterID
      status
      active
      expirationDate
      areaLimit
      areaRequested
      isAreaRequest
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createDroneRequest = /* GraphQL */ `
  mutation CreateDroneRequest(
    $input: CreateDroneRequestInput!
    $condition: ModelDroneRequestConditionInput
  ) {
    createDroneRequest(input: $input, condition: $condition) {
      id
      farmer {
        id
        customID
        firstName
        lastName
        email
        phone
        billingAddress
        city
        country
        postalCode
        company
        county
        isDisabled
        farms {
          nextToken
          __typename
        }
        issues {
          nextToken
          __typename
        }
        damages {
          nextToken
          __typename
        }
        diseases {
          nextToken
          __typename
        }
        packages {
          nextToken
          __typename
        }
        droneRequests {
          nextToken
          __typename
        }
        weatherStation {
          nextToken
          __typename
        }
        packageRequests {
          nextToken
          __typename
        }
        planetRequests {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      farmerID
      farmDetails {
        id
        farmer {
          id
          customID
          firstName
          lastName
          email
          phone
          billingAddress
          city
          country
          postalCode
          company
          county
          isDisabled
          createdAt
          updatedAt
          __typename
        }
        active
        farmerID
        name
        crop
        cropUse
        Earlyness
        Protection
        area
        TotalYield
        YieldPerha
        sowingDate
        harvestDate
        soilTexture
        drainage
        Management
        ownership
        cadastralSheet
        cadastralMunicipality
        cadastralParticle
        slope
        revenue
        notes
        startDate
        spatialReference
        byoc
        subscriptionID
        rings
        damages {
          nextToken
          __typename
        }
        diseases {
          nextToken
          __typename
        }
        operations {
          nextToken
          __typename
        }
        droneRequests {
          nextToken
          __typename
        }
        issues {
          nextToken
          __typename
        }
        SoilAnalysis {
          nextToken
          __typename
        }
        WeatherStation {
          nextToken
          __typename
        }
        planetRequests {
          nextToken
          __typename
        }
        gmail
        createdAt
        updatedAt
        __typename
      }
      farmDetailsID
      date
      fieldName
      crop
      area
      note
      status
      hasUpload
      rings
      gmail
      droneData {
        items {
          id
          droneRequestID
          farmerID
          variety
          link
          gmail
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateDroneRequest = /* GraphQL */ `
  mutation UpdateDroneRequest(
    $input: UpdateDroneRequestInput!
    $condition: ModelDroneRequestConditionInput
  ) {
    updateDroneRequest(input: $input, condition: $condition) {
      id
      farmer {
        id
        customID
        firstName
        lastName
        email
        phone
        billingAddress
        city
        country
        postalCode
        company
        county
        isDisabled
        farms {
          nextToken
          __typename
        }
        issues {
          nextToken
          __typename
        }
        damages {
          nextToken
          __typename
        }
        diseases {
          nextToken
          __typename
        }
        packages {
          nextToken
          __typename
        }
        droneRequests {
          nextToken
          __typename
        }
        weatherStation {
          nextToken
          __typename
        }
        packageRequests {
          nextToken
          __typename
        }
        planetRequests {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      farmerID
      farmDetails {
        id
        farmer {
          id
          customID
          firstName
          lastName
          email
          phone
          billingAddress
          city
          country
          postalCode
          company
          county
          isDisabled
          createdAt
          updatedAt
          __typename
        }
        active
        farmerID
        name
        crop
        cropUse
        Earlyness
        Protection
        area
        TotalYield
        YieldPerha
        sowingDate
        harvestDate
        soilTexture
        drainage
        Management
        ownership
        cadastralSheet
        cadastralMunicipality
        cadastralParticle
        slope
        revenue
        notes
        startDate
        spatialReference
        byoc
        subscriptionID
        rings
        damages {
          nextToken
          __typename
        }
        diseases {
          nextToken
          __typename
        }
        operations {
          nextToken
          __typename
        }
        droneRequests {
          nextToken
          __typename
        }
        issues {
          nextToken
          __typename
        }
        SoilAnalysis {
          nextToken
          __typename
        }
        WeatherStation {
          nextToken
          __typename
        }
        planetRequests {
          nextToken
          __typename
        }
        gmail
        createdAt
        updatedAt
        __typename
      }
      farmDetailsID
      date
      fieldName
      crop
      area
      note
      status
      hasUpload
      rings
      gmail
      droneData {
        items {
          id
          droneRequestID
          farmerID
          variety
          link
          gmail
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteDroneRequest = /* GraphQL */ `
  mutation DeleteDroneRequest(
    $input: DeleteDroneRequestInput!
    $condition: ModelDroneRequestConditionInput
  ) {
    deleteDroneRequest(input: $input, condition: $condition) {
      id
      farmer {
        id
        customID
        firstName
        lastName
        email
        phone
        billingAddress
        city
        country
        postalCode
        company
        county
        isDisabled
        farms {
          nextToken
          __typename
        }
        issues {
          nextToken
          __typename
        }
        damages {
          nextToken
          __typename
        }
        diseases {
          nextToken
          __typename
        }
        packages {
          nextToken
          __typename
        }
        droneRequests {
          nextToken
          __typename
        }
        weatherStation {
          nextToken
          __typename
        }
        packageRequests {
          nextToken
          __typename
        }
        planetRequests {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      farmerID
      farmDetails {
        id
        farmer {
          id
          customID
          firstName
          lastName
          email
          phone
          billingAddress
          city
          country
          postalCode
          company
          county
          isDisabled
          createdAt
          updatedAt
          __typename
        }
        active
        farmerID
        name
        crop
        cropUse
        Earlyness
        Protection
        area
        TotalYield
        YieldPerha
        sowingDate
        harvestDate
        soilTexture
        drainage
        Management
        ownership
        cadastralSheet
        cadastralMunicipality
        cadastralParticle
        slope
        revenue
        notes
        startDate
        spatialReference
        byoc
        subscriptionID
        rings
        damages {
          nextToken
          __typename
        }
        diseases {
          nextToken
          __typename
        }
        operations {
          nextToken
          __typename
        }
        droneRequests {
          nextToken
          __typename
        }
        issues {
          nextToken
          __typename
        }
        SoilAnalysis {
          nextToken
          __typename
        }
        WeatherStation {
          nextToken
          __typename
        }
        planetRequests {
          nextToken
          __typename
        }
        gmail
        createdAt
        updatedAt
        __typename
      }
      farmDetailsID
      date
      fieldName
      crop
      area
      note
      status
      hasUpload
      rings
      gmail
      droneData {
        items {
          id
          droneRequestID
          farmerID
          variety
          link
          gmail
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createDroneUploads = /* GraphQL */ `
  mutation CreateDroneUploads(
    $input: CreateDroneUploadsInput!
    $condition: ModelDroneUploadsConditionInput
  ) {
    createDroneUploads(input: $input, condition: $condition) {
      id
      droneRequest {
        id
        farmer {
          id
          customID
          firstName
          lastName
          email
          phone
          billingAddress
          city
          country
          postalCode
          company
          county
          isDisabled
          createdAt
          updatedAt
          __typename
        }
        farmerID
        farmDetails {
          id
          active
          farmerID
          name
          crop
          cropUse
          Earlyness
          Protection
          area
          TotalYield
          YieldPerha
          sowingDate
          harvestDate
          soilTexture
          drainage
          Management
          ownership
          cadastralSheet
          cadastralMunicipality
          cadastralParticle
          slope
          revenue
          notes
          startDate
          spatialReference
          byoc
          subscriptionID
          rings
          gmail
          createdAt
          updatedAt
          __typename
        }
        farmDetailsID
        date
        fieldName
        crop
        area
        note
        status
        hasUpload
        rings
        gmail
        droneData {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      droneRequestID
      farmerID
      variety
      link
      gmail
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateDroneUploads = /* GraphQL */ `
  mutation UpdateDroneUploads(
    $input: UpdateDroneUploadsInput!
    $condition: ModelDroneUploadsConditionInput
  ) {
    updateDroneUploads(input: $input, condition: $condition) {
      id
      droneRequest {
        id
        farmer {
          id
          customID
          firstName
          lastName
          email
          phone
          billingAddress
          city
          country
          postalCode
          company
          county
          isDisabled
          createdAt
          updatedAt
          __typename
        }
        farmerID
        farmDetails {
          id
          active
          farmerID
          name
          crop
          cropUse
          Earlyness
          Protection
          area
          TotalYield
          YieldPerha
          sowingDate
          harvestDate
          soilTexture
          drainage
          Management
          ownership
          cadastralSheet
          cadastralMunicipality
          cadastralParticle
          slope
          revenue
          notes
          startDate
          spatialReference
          byoc
          subscriptionID
          rings
          gmail
          createdAt
          updatedAt
          __typename
        }
        farmDetailsID
        date
        fieldName
        crop
        area
        note
        status
        hasUpload
        rings
        gmail
        droneData {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      droneRequestID
      farmerID
      variety
      link
      gmail
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteDroneUploads = /* GraphQL */ `
  mutation DeleteDroneUploads(
    $input: DeleteDroneUploadsInput!
    $condition: ModelDroneUploadsConditionInput
  ) {
    deleteDroneUploads(input: $input, condition: $condition) {
      id
      droneRequest {
        id
        farmer {
          id
          customID
          firstName
          lastName
          email
          phone
          billingAddress
          city
          country
          postalCode
          company
          county
          isDisabled
          createdAt
          updatedAt
          __typename
        }
        farmerID
        farmDetails {
          id
          active
          farmerID
          name
          crop
          cropUse
          Earlyness
          Protection
          area
          TotalYield
          YieldPerha
          sowingDate
          harvestDate
          soilTexture
          drainage
          Management
          ownership
          cadastralSheet
          cadastralMunicipality
          cadastralParticle
          slope
          revenue
          notes
          startDate
          spatialReference
          byoc
          subscriptionID
          rings
          gmail
          createdAt
          updatedAt
          __typename
        }
        farmDetailsID
        date
        fieldName
        crop
        area
        note
        status
        hasUpload
        rings
        gmail
        droneData {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      droneRequestID
      farmerID
      variety
      link
      gmail
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createPlanetRequest = /* GraphQL */ `
  mutation CreatePlanetRequest(
    $input: CreatePlanetRequestInput!
    $condition: ModelPlanetRequestConditionInput
  ) {
    createPlanetRequest(input: $input, condition: $condition) {
      id
      farmer {
        id
        customID
        firstName
        lastName
        email
        phone
        billingAddress
        city
        country
        postalCode
        company
        county
        isDisabled
        farms {
          nextToken
          __typename
        }
        issues {
          nextToken
          __typename
        }
        damages {
          nextToken
          __typename
        }
        diseases {
          nextToken
          __typename
        }
        packages {
          nextToken
          __typename
        }
        droneRequests {
          nextToken
          __typename
        }
        weatherStation {
          nextToken
          __typename
        }
        packageRequests {
          nextToken
          __typename
        }
        planetRequests {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      farmerID
      farmDetails {
        id
        farmer {
          id
          customID
          firstName
          lastName
          email
          phone
          billingAddress
          city
          country
          postalCode
          company
          county
          isDisabled
          createdAt
          updatedAt
          __typename
        }
        active
        farmerID
        name
        crop
        cropUse
        Earlyness
        Protection
        area
        TotalYield
        YieldPerha
        sowingDate
        harvestDate
        soilTexture
        drainage
        Management
        ownership
        cadastralSheet
        cadastralMunicipality
        cadastralParticle
        slope
        revenue
        notes
        startDate
        spatialReference
        byoc
        subscriptionID
        rings
        damages {
          nextToken
          __typename
        }
        diseases {
          nextToken
          __typename
        }
        operations {
          nextToken
          __typename
        }
        droneRequests {
          nextToken
          __typename
        }
        issues {
          nextToken
          __typename
        }
        SoilAnalysis {
          nextToken
          __typename
        }
        WeatherStation {
          nextToken
          __typename
        }
        planetRequests {
          nextToken
          __typename
        }
        gmail
        createdAt
        updatedAt
        __typename
      }
      farmDetailsID
      status
      gmail
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updatePlanetRequest = /* GraphQL */ `
  mutation UpdatePlanetRequest(
    $input: UpdatePlanetRequestInput!
    $condition: ModelPlanetRequestConditionInput
  ) {
    updatePlanetRequest(input: $input, condition: $condition) {
      id
      farmer {
        id
        customID
        firstName
        lastName
        email
        phone
        billingAddress
        city
        country
        postalCode
        company
        county
        isDisabled
        farms {
          nextToken
          __typename
        }
        issues {
          nextToken
          __typename
        }
        damages {
          nextToken
          __typename
        }
        diseases {
          nextToken
          __typename
        }
        packages {
          nextToken
          __typename
        }
        droneRequests {
          nextToken
          __typename
        }
        weatherStation {
          nextToken
          __typename
        }
        packageRequests {
          nextToken
          __typename
        }
        planetRequests {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      farmerID
      farmDetails {
        id
        farmer {
          id
          customID
          firstName
          lastName
          email
          phone
          billingAddress
          city
          country
          postalCode
          company
          county
          isDisabled
          createdAt
          updatedAt
          __typename
        }
        active
        farmerID
        name
        crop
        cropUse
        Earlyness
        Protection
        area
        TotalYield
        YieldPerha
        sowingDate
        harvestDate
        soilTexture
        drainage
        Management
        ownership
        cadastralSheet
        cadastralMunicipality
        cadastralParticle
        slope
        revenue
        notes
        startDate
        spatialReference
        byoc
        subscriptionID
        rings
        damages {
          nextToken
          __typename
        }
        diseases {
          nextToken
          __typename
        }
        operations {
          nextToken
          __typename
        }
        droneRequests {
          nextToken
          __typename
        }
        issues {
          nextToken
          __typename
        }
        SoilAnalysis {
          nextToken
          __typename
        }
        WeatherStation {
          nextToken
          __typename
        }
        planetRequests {
          nextToken
          __typename
        }
        gmail
        createdAt
        updatedAt
        __typename
      }
      farmDetailsID
      status
      gmail
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deletePlanetRequest = /* GraphQL */ `
  mutation DeletePlanetRequest(
    $input: DeletePlanetRequestInput!
    $condition: ModelPlanetRequestConditionInput
  ) {
    deletePlanetRequest(input: $input, condition: $condition) {
      id
      farmer {
        id
        customID
        firstName
        lastName
        email
        phone
        billingAddress
        city
        country
        postalCode
        company
        county
        isDisabled
        farms {
          nextToken
          __typename
        }
        issues {
          nextToken
          __typename
        }
        damages {
          nextToken
          __typename
        }
        diseases {
          nextToken
          __typename
        }
        packages {
          nextToken
          __typename
        }
        droneRequests {
          nextToken
          __typename
        }
        weatherStation {
          nextToken
          __typename
        }
        packageRequests {
          nextToken
          __typename
        }
        planetRequests {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      farmerID
      farmDetails {
        id
        farmer {
          id
          customID
          firstName
          lastName
          email
          phone
          billingAddress
          city
          country
          postalCode
          company
          county
          isDisabled
          createdAt
          updatedAt
          __typename
        }
        active
        farmerID
        name
        crop
        cropUse
        Earlyness
        Protection
        area
        TotalYield
        YieldPerha
        sowingDate
        harvestDate
        soilTexture
        drainage
        Management
        ownership
        cadastralSheet
        cadastralMunicipality
        cadastralParticle
        slope
        revenue
        notes
        startDate
        spatialReference
        byoc
        subscriptionID
        rings
        damages {
          nextToken
          __typename
        }
        diseases {
          nextToken
          __typename
        }
        operations {
          nextToken
          __typename
        }
        droneRequests {
          nextToken
          __typename
        }
        issues {
          nextToken
          __typename
        }
        SoilAnalysis {
          nextToken
          __typename
        }
        WeatherStation {
          nextToken
          __typename
        }
        planetRequests {
          nextToken
          __typename
        }
        gmail
        createdAt
        updatedAt
        __typename
      }
      farmDetailsID
      status
      gmail
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createWeatherStation = /* GraphQL */ `
  mutation CreateWeatherStation(
    $input: CreateWeatherStationInput!
    $condition: ModelWeatherStationConditionInput
  ) {
    createWeatherStation(input: $input, condition: $condition) {
      id
      farmDetails {
        id
        farmer {
          id
          customID
          firstName
          lastName
          email
          phone
          billingAddress
          city
          country
          postalCode
          company
          county
          isDisabled
          createdAt
          updatedAt
          __typename
        }
        active
        farmerID
        name
        crop
        cropUse
        Earlyness
        Protection
        area
        TotalYield
        YieldPerha
        sowingDate
        harvestDate
        soilTexture
        drainage
        Management
        ownership
        cadastralSheet
        cadastralMunicipality
        cadastralParticle
        slope
        revenue
        notes
        startDate
        spatialReference
        byoc
        subscriptionID
        rings
        damages {
          nextToken
          __typename
        }
        diseases {
          nextToken
          __typename
        }
        operations {
          nextToken
          __typename
        }
        droneRequests {
          nextToken
          __typename
        }
        issues {
          nextToken
          __typename
        }
        SoilAnalysis {
          nextToken
          __typename
        }
        WeatherStation {
          nextToken
          __typename
        }
        planetRequests {
          nextToken
          __typename
        }
        gmail
        createdAt
        updatedAt
        __typename
      }
      farmDetailsID
      api
      farmer {
        id
        customID
        firstName
        lastName
        email
        phone
        billingAddress
        city
        country
        postalCode
        company
        county
        isDisabled
        farms {
          nextToken
          __typename
        }
        issues {
          nextToken
          __typename
        }
        damages {
          nextToken
          __typename
        }
        diseases {
          nextToken
          __typename
        }
        packages {
          nextToken
          __typename
        }
        droneRequests {
          nextToken
          __typename
        }
        weatherStation {
          nextToken
          __typename
        }
        packageRequests {
          nextToken
          __typename
        }
        planetRequests {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      farmerID
      gmail
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateWeatherStation = /* GraphQL */ `
  mutation UpdateWeatherStation(
    $input: UpdateWeatherStationInput!
    $condition: ModelWeatherStationConditionInput
  ) {
    updateWeatherStation(input: $input, condition: $condition) {
      id
      farmDetails {
        id
        farmer {
          id
          customID
          firstName
          lastName
          email
          phone
          billingAddress
          city
          country
          postalCode
          company
          county
          isDisabled
          createdAt
          updatedAt
          __typename
        }
        active
        farmerID
        name
        crop
        cropUse
        Earlyness
        Protection
        area
        TotalYield
        YieldPerha
        sowingDate
        harvestDate
        soilTexture
        drainage
        Management
        ownership
        cadastralSheet
        cadastralMunicipality
        cadastralParticle
        slope
        revenue
        notes
        startDate
        spatialReference
        byoc
        subscriptionID
        rings
        damages {
          nextToken
          __typename
        }
        diseases {
          nextToken
          __typename
        }
        operations {
          nextToken
          __typename
        }
        droneRequests {
          nextToken
          __typename
        }
        issues {
          nextToken
          __typename
        }
        SoilAnalysis {
          nextToken
          __typename
        }
        WeatherStation {
          nextToken
          __typename
        }
        planetRequests {
          nextToken
          __typename
        }
        gmail
        createdAt
        updatedAt
        __typename
      }
      farmDetailsID
      api
      farmer {
        id
        customID
        firstName
        lastName
        email
        phone
        billingAddress
        city
        country
        postalCode
        company
        county
        isDisabled
        farms {
          nextToken
          __typename
        }
        issues {
          nextToken
          __typename
        }
        damages {
          nextToken
          __typename
        }
        diseases {
          nextToken
          __typename
        }
        packages {
          nextToken
          __typename
        }
        droneRequests {
          nextToken
          __typename
        }
        weatherStation {
          nextToken
          __typename
        }
        packageRequests {
          nextToken
          __typename
        }
        planetRequests {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      farmerID
      gmail
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteWeatherStation = /* GraphQL */ `
  mutation DeleteWeatherStation(
    $input: DeleteWeatherStationInput!
    $condition: ModelWeatherStationConditionInput
  ) {
    deleteWeatherStation(input: $input, condition: $condition) {
      id
      farmDetails {
        id
        farmer {
          id
          customID
          firstName
          lastName
          email
          phone
          billingAddress
          city
          country
          postalCode
          company
          county
          isDisabled
          createdAt
          updatedAt
          __typename
        }
        active
        farmerID
        name
        crop
        cropUse
        Earlyness
        Protection
        area
        TotalYield
        YieldPerha
        sowingDate
        harvestDate
        soilTexture
        drainage
        Management
        ownership
        cadastralSheet
        cadastralMunicipality
        cadastralParticle
        slope
        revenue
        notes
        startDate
        spatialReference
        byoc
        subscriptionID
        rings
        damages {
          nextToken
          __typename
        }
        diseases {
          nextToken
          __typename
        }
        operations {
          nextToken
          __typename
        }
        droneRequests {
          nextToken
          __typename
        }
        issues {
          nextToken
          __typename
        }
        SoilAnalysis {
          nextToken
          __typename
        }
        WeatherStation {
          nextToken
          __typename
        }
        planetRequests {
          nextToken
          __typename
        }
        gmail
        createdAt
        updatedAt
        __typename
      }
      farmDetailsID
      api
      farmer {
        id
        customID
        firstName
        lastName
        email
        phone
        billingAddress
        city
        country
        postalCode
        company
        county
        isDisabled
        farms {
          nextToken
          __typename
        }
        issues {
          nextToken
          __typename
        }
        damages {
          nextToken
          __typename
        }
        diseases {
          nextToken
          __typename
        }
        packages {
          nextToken
          __typename
        }
        droneRequests {
          nextToken
          __typename
        }
        weatherStation {
          nextToken
          __typename
        }
        packageRequests {
          nextToken
          __typename
        }
        planetRequests {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      farmerID
      gmail
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAcademy = /* GraphQL */ `
  mutation CreateAcademy(
    $input: CreateAcademyInput!
    $condition: ModelAcademyConditionInput
  ) {
    createAcademy(input: $input, condition: $condition) {
      id
      title
      description
      image
      video
      category
      subcategory
      tags
      content
      published
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAcademy = /* GraphQL */ `
  mutation UpdateAcademy(
    $input: UpdateAcademyInput!
    $condition: ModelAcademyConditionInput
  ) {
    updateAcademy(input: $input, condition: $condition) {
      id
      title
      description
      image
      video
      category
      subcategory
      tags
      content
      published
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAcademy = /* GraphQL */ `
  mutation DeleteAcademy(
    $input: DeleteAcademyInput!
    $condition: ModelAcademyConditionInput
  ) {
    deleteAcademy(input: $input, condition: $condition) {
      id
      title
      description
      image
      video
      category
      subcategory
      tags
      content
      published
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createNews = /* GraphQL */ `
  mutation CreateNews(
    $input: CreateNewsInput!
    $condition: ModelNewsConditionInput
  ) {
    createNews(input: $input, condition: $condition) {
      id
      content
      image
      title
      description
      published
      status
      postedBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateNews = /* GraphQL */ `
  mutation UpdateNews(
    $input: UpdateNewsInput!
    $condition: ModelNewsConditionInput
  ) {
    updateNews(input: $input, condition: $condition) {
      id
      content
      image
      title
      description
      published
      status
      postedBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteNews = /* GraphQL */ `
  mutation DeleteNews(
    $input: DeleteNewsInput!
    $condition: ModelNewsConditionInput
  ) {
    deleteNews(input: $input, condition: $condition) {
      id
      content
      image
      title
      description
      published
      status
      postedBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
