import React, { useEffect, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import { Helmet } from 'react-helmet';
import { AiFillCaretLeft, AiFillCaretRight, AiOutlineCloudDownload } from 'react-icons/ai';
import FarmRegistration from '../farms/FarmRegistration';
import FarmerTopBar from '../../components/topbar/Topbar';
import { IoAddOutline } from 'react-icons/io5';
import PointMap from '../farms/myFields/PointMap';
import InputComponent from './DroneInputs';
import { fetchFarmDetails } from '../../fetches/FetchFarmDetails';
import MyFarms from '../farms/myFields/MyFarms';
import { useTranslation } from 'react-i18next'

//memoize MyFarms and MapImage
const MyFarmsMemoized = React.memo(MyFarms);

const DroneRequest = ({close, onUpdate}) => {
    const [leftWidth, setLeftWidth] = useState(50);
  const [rightWidth, setRightWidth] = useState(50);
  const [farmDetails, setFarmDetails] = useState([]);
  const [selectedCoordinates, setSelectedCoordinates] = useState(null);
  const [clickedCoordinates, setClickedCoordinates] = useState(null); // New state for clicked coordinates
  
  const [rings, setRings] = useState([]);
  const [formattedRings, setFormattedRings] = useState([]);
  const { t } = useTranslation()
  
  // Pass the rings and map to extract latitude and longitude
  useEffect(() => {
    if (!rings.length) {
      return;
    }
  
    const parsedRings = JSON.parse(rings);
    const coordinates = JSON.parse(parsedRings[0]); // Access the nested rings array
    const formattedRings = coordinates.map((item) => [item.latitude, item.longitude]);
    setFormattedRings(formattedRings);
  }, [rings]);
  
  console.log('formattedRings:', formattedRings);
  
  console.log(`rings: ${rings}`);


  const handleRightArrowClick = () => {
    setLeftWidth(leftWidth +10);
    setRightWidth(rightWidth - 10);
  };

  const handleLeftArrowClick = () => {
    setLeftWidth(leftWidth - 10);
    setRightWidth(rightWidth + 10);
  };

  //fetch farm details
  React.useEffect(() => {
    async function fetchData() {
      const farmData = await fetchFarmDetails();
      setFarmDetails(farmData);
    }
    fetchData();
  }, []);


  const handlePointClick = (coordinates) => {
    setClickedCoordinates(coordinates);
  };


  return (
    <div className='home-conainer'>
      {/* <FarmerTopBar /> */}
      <Helmet>
        <link
          rel="stylesheet"
          href="https://js.arcgis.com/4.26/esri/themes/light/main.css"
        />
      </Helmet>
      
      <section className='mymap-wrapper'>
        

      <div className='left-myMap' style={{position:'relative', padding:'10px' }}>
        <section className="my-fields-header">
       
        <InputComponent farmDetails={farmDetails} setPoly={setRings} close={close} onUpdate={onUpdate} />
     
        {/* <div>
          <button style={{ marginRight: '5px' }}>
            <AiOutlineCloudDownload />
          </button>
          <button>
            <IoAddOutline />
          </button>
        </div> */}
      </section>
        <div className='center-buttons'>
          <button onClick={handleLeftArrowClick}><AiFillCaretLeft /></button>
          <button onClick={handleRightArrowClick}><AiFillCaretRight /></button>
        </div>
        {/* <h3>Drone Request</h3> */}
        </div>


        <div className='right-myMap imagery' style={{ position:'relative'}}>
          <MyFarmsMemoized selectedPolygon={formattedRings}  handlePointClick={handlePointClick} />
        </div>
      </section>
    </div>
  );
};

export default DroneRequest;
