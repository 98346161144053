import React, { useEffect, useRef } from 'react';


const  SentinelImageLayer = ({accessToken, timeRange, coordinates, byoc}) => {
const [trueColor, setTrueColor] = React.useState(null);
  // const accessToken="eyJraWQiOiJzaCIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiI3MTQ3ZjE0OC0xMTVjLTQ3MzEtOGE3Zi1iYmFkNjcwNDk4YWUiLCJhdWQiOiI2ZGFmZTc2OC0yYjcyLTRhNGEtYTY1MC0xOWVkNWExNTk4MzQiLCJqdGkiOiJhYTZiYzY0ZS0xZGMwLTQxZWEtYmE3MC04MTQxZjEzMzJiNTYiLCJleHAiOjE2OTYwMTU3MjEsIm5hbWUiOiJBR1JPR0lTIFRFQ0giLCJlbWFpbCI6ImNvbnRhY3RAYWdyb2dpcy5ybyIsImdpdmVuX25hbWUiOiJBR1JPR0lTIiwiZmFtaWx5X25hbWUiOiJURUNIIiwic2lkIjoiOWUxZDY2MGQtMWNhOS00NGZmLTlkMjEtM2QyNzA1MDhkMjY1IiwiZGlkIjoxLCJhaWQiOiI5YzllOTY4NS0yZjFjLTQ1OTUtODJmYy01YjI0MGIwZmM2NDgiLCJhY2NvdW50IjoiOWM5ZTk2ODUtMmYxYy00NTk1LTgyZmMtNWIyNDBiMGZjNjQ4IiwiZCI6eyIxIjp7InJhIjp7InJhZyI6MX0sInQiOjEyMDAwfX19.KZrat1gFO1MBkiDizNigPWkbrV6VsyYM56doz7EXMnNI9PYSOf4_BtEK2nnWosQRZOISPt7fNMWUi6NDcCk6VORTkZej35R3dNGnr_ImfKQvWo_EmCYmuHLtT0xzH6GuHWENaaNRgn7a34mxQ0SvL7-OJdbTE9-wPPcWRA6S4toNUgKvFyrkTuqm9DYSdxEccPcWSclJpAGshTc6LMFzG_bVWCQFb-F-ZKvPNr1JrCWNgXOYU_Zp8Ay9RUwRfQUhzPu2jzJ8eP3G2t5xGyDfKCIVizENxfdy0CyiSSP1xE6EHBrzaToS6tBt9iMBs4L4aBZGXWRS-0zqOHMzB6gLpQ"

  


  useEffect(() => {
 

    fetch('https://services.sentinel-hub.com/api/v1/process', {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        input: {
          bounds: {
            properties: {
              crs: "http://www.opengis.net/def/crs/OGC/1.3/CRS84"
            },
            geometry: {
              type: "Polygon",
              coordinates: [coordinates]
            }
          },
          data: [{
            type: `byoc-${byoc}`,
            dataFilter: {
              timeRange:   { from: timeRange.from, to: timeRange.to }
            }
          }]
        },
        output: { responses: [{ identifier: 'default', format: { type: 'image/png' } }], },

        evalscript:
        `  
        //VERSION=3
        //True Color
        
        function setup() {
          return {
            input: ["Blue", "Green", "Red"],
            output: { bands: 3 }
          };
        }
        
        function evaluatePixel(sample) {
          return [sample.Red / 3000, sample.Green / 3000, sample.Blue / 3000];
        }
        `
      })
    })
    .then(response => response.blob())
    .then(blob => URL.createObjectURL(blob))
    .then(url => setTrueColor(url))
 
  }, [accessToken, timeRange])

  return (
    <div>
      <img src={trueColor} alt="trueColor" />
    </div>
  );
}




export default SentinelImageLayer;
