import React from 'react';
import { LineChart, Line, AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import moment from 'moment';

const RevenueGraph = () => {
  // Dummy data for revenue
  const revenueData = [
    { week: 1, revenue: 1000 },
    { week: 2, revenue: 1200 },
    { week: 3, revenue: 800 },
    { week: 4, revenue: 1500 },
    { week: 5, revenue: 1100 },
    { week: 6, revenue: 1300 },
  ];

  // Get today's date
  const today = moment().format('DD MMM');

  // Calculate the date for each week
  const weekDates = [];
  for (let i = 5; i >= 0; i--) {
    const date = moment().subtract(i, 'weeks').format('DD MMM');
    weekDates.push(date);
  }

  return (
    <ResponsiveContainer width="100%" height={300} margin={{ right: 20 }}>
      <AreaChart data={revenueData}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="week"
          type="category"
          interval={0}
          tick={({ x, y, payload }) => (
            <g transform={`translate(${x},${y})`}>
              <text x={0} y={0} dy={16} textAnchor="middle" fill="#666">
                {weekDates[payload.value - 1]}
              </text>
            </g>
          )}
        />
        <YAxis />
        <Tooltip />
        <defs>
          <linearGradient id="graphFill" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor="#F6F9FC" stopOpacity={0.9} />
            <stop offset="100%" stopColor="#FFFFFF" stopOpacity={0.6} />
          </linearGradient>
        </defs>
        <Area
          type="monotone"
          dataKey="revenue"
          stroke="#2196f3"
          fill="url(#graphFill)"
          strokeWidth={2}
          dot={{ r: 5, strokeWidth: 2, fill: '#2196f3' }}
        />
        <Line type="monotone" dataKey="revenue" stroke="#2196f3" strokeWidth={2} dot={false} />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default RevenueGraph;
