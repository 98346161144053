// farmDetailsAPI.js

import { API, graphqlOperation } from "aws-amplify";
import {
  listAcademies,
  listDamages,
  listDiseases,
  listDroneRequests,
  listDroneUploads,
  listFarmDetails,
  listFarmers,
  listIssues,
  listNews,
  listOperations,
  listPackageRequests,
} from "../../graphql/queries";

const farmerID = localStorage.getItem("farmerID");
export async function fetchFarmDetails() {
  const farmerID = localStorage.getItem("farmerID");
  const cachedData = localStorage.getItem("farmDetails");

  try {
    {
      const farmDetails = await API.graphql(
        graphqlOperation(listFarmDetails, {
          filter: {
            farmerID: {
              eq: farmerID,
            },
          },
        })
      );
      const farmDetailsData = farmDetails.data.listFarmDetails.items.filter(
        (item) => item.active === true
      );
      return farmDetailsData;
    }
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function fetchIssuesDetails() {
  const farmerID = localStorage.getItem("farmerID");

  try {
    const issues = await API.graphql(
      graphqlOperation(listIssues, {
        filter: {
          farmerID: {
            eq: farmerID,
          },
        },
      })
    );
    //only fetch those whose farmDetails.active is true , i.e. only active farms
    //active farms are those whose farmDetails.active is true
    const deleteNull = issues.data.listIssues.items.filter(
      (item) => item.farmDetails !== null
    );

    const issuesData = deleteNull.filter(
      (item) => item.farmDetails.active === true
    );
    return issuesData;
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function fetchDiseaseDetails() {
  const farmerID = localStorage.getItem("farmerID");

  try {
    const issues = await API.graphql(
      graphqlOperation(listDiseases, {
        filter: {
          farmerID: {
            eq: farmerID,
          },
        },
      })
    );
    console.log(issues);
    //delete issues with farmdetails as null
    const deleteNull = issues.data.listDiseases.items.filter(
      (item) => item.farmDetails !== null
    );

    const diseaseData = deleteNull.filter(
      (item) => item.farmDetails.active === true
    );

    return diseaseData;
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function fetchDamagesDetails() {
  const farmerID = localStorage.getItem("farmerID");

  try {
    const damages = await API.graphql(
      graphqlOperation(listDamages, {
        filter: {
          farmerID: {
            eq: farmerID,
          },
        },
      })
    );
    const deleteNull = damages.data.listDamages.items.filter(
      (item) => item.farmDetails !== null
    );

    const damagesData = deleteNull.filter(
      (item) => item.farmDetails.active === true
    );
    return damagesData;
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function fetchOperations() {
  const farmerID = localStorage.getItem("farmerID");

  try {
    const operations = await API.graphql(
      graphqlOperation(listOperations, {
        filter: {
          farmerID: {
            eq: farmerID,
          },
        },
      })
    );
    const operationsData = operations.data.listOperations.items;
    return operationsData;
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function fetchDeseases() {
  const farmerID = localStorage.getItem("farmerID");

  try {
    const deseases = await API.graphql(graphqlOperation(listDiseases));
    const deseasesData = deseases.data.listDeseases.items;
    return deseasesData;
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function fetchDroneData() {
  const farmerID = localStorage.getItem("farmerID");

  try {
    const droneData = await API.graphql(
      graphqlOperation(listDroneUploads, {
        filter: {
          farmerID: {
            eq: farmerID,
          },
        },
      })
    );
    const droneDataData = droneData.data.listDroneUploads.items;
    return droneDataData;
  } catch (error) {
    console.log(error);
    return [];
  }
}

//fetch all farms for admin
export async function fetchAllFarmDetails() {
  try {
    const farmDetails = await API.graphql(graphqlOperation(listFarmDetails));
    const farmDetailsData = farmDetails.data.listFarmDetails.items;
    return farmDetailsData;
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function fetchAllFarmers() {
  try {
    const farmerDetails = await API.graphql(graphqlOperation(listFarmers));
    const farmerDetailsData = farmerDetails.data.listFarmers.items;
    return farmerDetailsData;
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function fetchRequests() {
  try {
    const farmDetails = await API.graphql(graphqlOperation(listDroneRequests));
    const fetchRequestsData = farmDetails.data.listDroneRequests.items;
    return fetchRequestsData;
  } catch (error) {
    console.log(error);
    return [];
  }
}

//fetch request for single farmer
export async function fetchRequestsForFarmer() {
  try {
    const farmDetails = await API.graphql(
      graphqlOperation(listDroneRequests, {
        filter: {
          farmerID: {
            eq: localStorage.getItem("farmerID"),
          },
        },
      })
    );
    const fetchRequestsData = farmDetails.data.listDroneRequests.items.filter(
      (item) => item.farmDetails.active
    );
    return fetchRequestsData;
  } catch (error) {
    console.log(error);
  }
}

export const fetchPlan = async () => {
  try {
    const packagesRequestData = await API.graphql(
      graphqlOperation(listPackageRequests, {
        filter: {
          requesterID: {
            eq: localStorage.getItem("farmerID"),
          },
        },
      })
    );
    const packagesRequestDataData =
      packagesRequestData.data.listPackageRequests.items.filter(
        (item) => item.package !== null
      );
    return packagesRequestDataData;
  } catch (error) {
    console.log(error);
  }
};

//fetch user's active plan
export const fetchActivePlan = async () => {
  try {
    const packagesRequestData = await API.graphql(
      graphqlOperation(listPackageRequests, {
        filter: {
          requesterID: {
            eq: localStorage.getItem("farmerID"),
          },
        },
      })
    );
    //set plans whose status is approved
    const approvedPlans =
      packagesRequestData.data.listPackageRequests.items.filter(
        (item) =>
          item.status === "approved" &&
          item.package.id !== "13af25ab-8d3a-4256-9c56-df5fed9e95a6" &&
          item.package !== null
      );

    return approvedPlans;
  } catch (error) {
    console.log(error);
  }
};

//fetch package requests for admin
export async function fetchPackageRequests() {
  try {
    const packagesRequestData = await API.graphql(
      graphqlOperation(listPackageRequests)
    );
    const packagesRequestDataData =
      packagesRequestData.data.listPackageRequests.items;
    return packagesRequestDataData;
  } catch (error) {
    console.log(error);
  }
}

//fetch courses
export async function fetchAcademies() {
  try {
    const packagesData = await API.graphql(graphqlOperation(listAcademies));
    const packagesDataData = packagesData.data.listAcademies.items;
    return packagesDataData;
  } catch (error) {
    console.log(error);
  }
}

export async function fetchNews() {
  try {
    const newsData = await API.graphql(graphqlOperation(listNews));
    const newsDataData = newsData.data.listNews.items;
    return newsDataData;
  } catch (error) {
    console.log(error);
  }
}
