import { useCallback, useRef, useState } from "react";
import { Camera, CameraResultType } from '@capacitor/camera'
import AWS from "aws-sdk";
import './cam.css'
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const CustomWebcam = ({getImageKey}) => {
    const [imgSrc, setImgSrc] = useState(null);
    const [progress, setProgress] = useState(0);

    const { t }= useTranslation();

    const capture = (e) => {
        e.preventDefault();
        setImgSrc(e.target.files[0]);

    }

    const takePicture = async () => {
        const image = await Camera.getPhoto({
          quality: 90,
          allowEditing: false,
          resultType: CameraResultType.Uri
        });
        setImgSrc(image.webPath);
      }
    

      const handleSubmit = async (e) => {
        e.preventDefault();
    
       const S3_BUCKET = "officialagrogis";
    
        // S3 Region
        const REGION = "us-east-1";
    
        // S3 Credentials
        AWS.config.update({
          accessKeyId: "AKIAZZNZN45OHZL2UDR3",
          secretAccessKey: "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",
        });
        const s3 = new AWS.S3({
          params: { Bucket: S3_BUCKET },
          region: REGION,
        });



  getImageKey( `public/DAMAGES/${imgSrc.name}`);

  const params = {
    Bucket: S3_BUCKET,
    Key: `public/DAMAGES/${imgSrc.name}`,
    Body: imgSrc,
  };

    
        // Uploading file to s3
    
        var upload = s3
          .putObject(params)
          .on("httpUploadProgress", (evt) => {
            // File uploading progress
            const percentUploaded = (evt.loaded / evt.total) * 100;
            const progressBar = document.getElementById("progress-bar");
            progressBar.style.width = percentUploaded + "%";
    
            setProgress(Math.round((evt.loaded / evt.total) * 100));
            console.log(
              "Uploading " + parseInt((evt.loaded * 100) / evt.total) + "%"
            );
          })
          .promise();

          console.log('uploaded');
    
        await upload.then((err, data) => {
          console.log(err);
          // Fille successfully uploaded
        });
    
      }
    
  return (
   <div className="webcam-container" style={{marginBottom: '20px'}}>
   
   {
    imgSrc ? <img src={URL.createObjectURL(imgSrc)} alt=""/> : null
   }
   {
    imgSrc ? (
        <div class="progress-container">
        <div class="progress-bar" id="progress-bar"></div>
      </div>
    ) : null
   }
 
                  {
                   imgSrc ? (progress === 100 ? <p>Uploaded</p> : null) : null
                  }
    <br/>
    <label htmlFor="input-file" style={{cursor: 'pointer', margin:'0 10px 0 0'}} className="custom-file-upload">
      {t('Take picture')}</label>
   <input
    type="file"
    accept="image/*"
    id="input-file"
    capture="environment"
    onChange={capture}
    style={{ display: "none" }}
  />

  {/* <button onClick={takePicture}>Take picture</button> */}

{
    imgSrc ? <button style={{marginBottom: '10px'}} onClick={handleSubmit}>Upload selected photo</button> : null
}    </div>
  );
};

export default CustomWebcam;