import { API, graphqlOperation } from 'aws-amplify';
import React, { useEffect, useState } from 'react'
import { listPackageRequests } from '../../../../graphql/queries';
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom';
import { updatePackageRequest } from '../../../../graphql/mutations';
import { toast } from 'react-toastify';

const ActivePlan = ({activePlan}) => {
  const { t } = useTranslation()
  const navigate = useNavigate();
  const [plan, setPlan] = useState([]);
  const [loading, setLoading] = useState(true);
  const [updates, setUpdates] = useState(false);

  const fetchPlan = async () => {
    try {
        const packagesRequestData = await API.graphql(graphqlOperation(listPackageRequests, {
            filter: {
                requesterID: {
                    eq: localStorage.getItem('farmerID'),
                },
            },
        }));
         //set plans whose status is approved
         const approvedPlans = packagesRequestData.data.listPackageRequests.items.filter(
          (item) => item.status === 'approved' && item.package !== null
      );
      setPlan(approvedPlans);
        setLoading(false);
     
    } catch (error) {
        console.log(error);
    }
}

  useEffect(() => {
    fetchPlan();
  } , [updates])

      //check requests whose expiration date has been ecxeeded by comparing the expiration date with current day.
      const plansToBeTerminated = plan.filter((plan) => plan.expirationDate < new Date().toISOString().slice(0, 10) && plan.status === 'approved');

      // Terminate plans whose expiration date has been exceeded
      useEffect(() => {
          plansToBeTerminated.forEach(async (plan) => {
              try {
                  await API.graphql(graphqlOperation(updatePackageRequest, { input: { id: plan.id, status: 'terminated', active: false } }))
                  toast.success('Your plan has expired. Please renew your plan.')
                  setUpdates(!updates);
              } catch (error) {
                  console.log(error);
              }
          })
      }, [plansToBeTerminated]);
  

  console.log(plan)

  //toast a message to upgrade plan
  useEffect(() => {
    //if plan 'AGROGIS Freemium' is active, toast a message to upgrade plan
    if (plan.some(item => item.package.title === 'AGROGIS Freemium')) {
      const timeoutId = setTimeout(() => {
      //tell user to upgrade plan to access more features
      toast.info(() => (
        <div className="custom-toast">
        <h3>Upgrade your plan to access more features</h3>
        <button className='orange-button'
        onClick={() => navigate('/farmer/account/subscription')}
        >Upgrade plan</button>
        </div>
      ), { autoClose: false });
    }, 10000)
    return () => clearTimeout(timeoutId);
    }
  } , [plan])

  return (
    <>
    {
      plan.map((item) => (
        <div className='home-active-plan'>
        <div className='home-active-plan-description'>
          {
            item.package.title === 'Virtual Weather Station' ?  <h3>{t('addon plan')}</h3> : <h3>Your plan</h3>
          }
            <h2>{item.package.title}</h2>
            <p>{t('active until')} {item.expirationDate ? item.expirationDate: '12-01-2024'}</p>
            <button className='orange-button'
            onClick={() => navigate('/farmer/account/subscription')}
            > {t('see other plans')}</button>
        </div>
        <div className='home-active-plan-image'>
        <img src={`https://officialagrogis.s3.amazonaws.com/${item.package.image}`} alt="plan" />
        </div>
    </div>
      ))
    }
    
    </>
    
  )
}

export default ActivePlan