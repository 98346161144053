import { API, graphqlOperation } from 'aws-amplify'
import React, { useEffect, useState } from 'react'
import { createAdmin, deleteAdmin } from '../../../../graphql/mutations'
import TopBar from '../topbar/Topbar'
import { toast } from 'react-toastify'
import { set } from 'date-fns'
import { listAdmins } from '../../../../graphql/queries'

const AddAdmin = () => {
    const [email, setEmail] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [admins, setAdmins] = useState([])
    const [updates, setUpdates] = useState(false)
    const addAdmin = async () => {
        try {
            await API.graphql(graphqlOperation(createAdmin, {
                input: {
                    email: email,
                    firstName: firstName,
                    lastName: lastName,
                }
            }))

            toast.success('Admin added successfully', {
                position: toast.POSITION.TOP_CENTER
            })
            setEmail('')
            setFirstName('')
            setLastName('')
            setUpdates(!updates)
        } catch (error) {
            console.log(error)
        }
    }

    const deleteAdministrator = async (id) => {
        try {
            await API.graphql(graphqlOperation(deleteAdmin, {
                input: {
                    id: id
                }
            }))
            toast.success('Admin deleted successfully', {
                position: toast.POSITION.TOP_CENTER
            })
            setUpdates(!updates)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(()=> {
        fetchAdmins()
    }, [updates])
    

    const fetchAdmins = async () => {
        try {
           const admis = await API.graphql(graphqlOperation(listAdmins));
            setAdmins(admis.data.listAdmins.items);

        } catch (error) {
            console.log(error)
        }
    }
    return (
        <div className='home-container'>
            <TopBar />
            <div className='packages-container'>

                <div className='package-sales-wrapper'>
                    <section className='add-package'>
                        <h1>Add Admin</h1>
                        <div className='form-group'>
                            <input
                                type="text"
                                placeholder="Enter First Name"
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                            />
                        </div>
                        <div className='form-group'>
                            <input
                                type="text"
                                placeholder="Enter Last Name"
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                            />
                        </div>
                        <div className='form-group'>
                            <input
                                type="text"
                                placeholder="Enter Email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>

                        <button 
                        className='signup-button'
                        onClick={addAdmin}>Add Admin</button>
                    </section>

                    <section className=''>
                        {
                            admins.map((admin, index) => (
                                <div className='package' key={index}>
                                    <div className='package-details'>
                                        <h3>{admin.firstName} {admin.lastName}</h3>
                                        <p>{admin.email}</p>
                                    </div>
                                    <div className='package-actions'>
                                        <button 
                                        className='delete-button'
                                        onClick={() => deleteAdministrator(admin.id)}>Delete</button>
                                    </div>
                                </div>
                            ))
                        }
                    </section>
                </div>
                </div>
            </div>
            )
}

            export default AddAdmin