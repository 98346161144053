import React, { useEffect, useRef, useState } from "react";
import { loadModules } from "esri-loader";
import { Helmet } from "react-helmet";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../../../../lib/init-firebase";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const DrawingMap = ({
  handleGeometry,
  farm,
  totalArea,
  selectedPlan,
  areaLimit,
}) => {
  const mapViewRef = useRef(null);
  const [polygonData, setPolygonData] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [existingArea, setExistingArea] = useState(0);
  const [landArea, setLandArea] = useState(0);
  const [geometry, setGeometry] = useState(null); // Add the 'geometry' state
  const [defPackage, setPackageName] = useState(
    localStorage.getItem("packageName")
  );
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const { t } = useTranslation();
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const style = {
    height: windowWidth < 770 ? "50vh" : "100vh",
    width: "100%",
    position: "relative",
  };

  useEffect(() => {
    loadModules(
      [
        "esri/Map",
        "esri/views/MapView",
        "esri/geometry/support/webMercatorUtils",
        "esri/widgets/Sketch",
        "esri/widgets/Search",
        "esri/layers/GraphicsLayer",
        "esri/geometry/Point",
        "esri/geometry/geometryEngine",
        "esri/geometry/geometryEngineAsync", // Add this import statement
      ],
      { css: true }
    )
      .then(
        ([
          Map,
          MapView,
          webMercatorUtils,
          Sketch,
          Search,
          GraphicsLayer,
          geometryEngine,
          geometryEngineAsync,
        ]) => {
          const map = new Map({
            basemap: "hybrid",
          });

          const view = new MapView({
            container: mapViewRef.current,
            map: map,
            center: [26.015659, 44.552808],
            zoom: 12,
          });

          const graphicsLayer = new GraphicsLayer();
          map.add(graphicsLayer);

          let sketch;

          view.when(() => {
            sketch = new Sketch({
              layer: graphicsLayer,
              view: view,
              availableCreateTools: ["polygon"],
            });

            sketch.on("create", (event) => {
              const polygonGeometry = event.graphic.geometry;
              const geographicPolygonGeometry =
                webMercatorUtils.webMercatorToGeographic(polygonGeometry);
              const polygonJson = geographicPolygonGeometry.toJSON();

              const area = geometryEngineAsync.geodesicArea(
                geographicPolygonGeometry,
                "square-meters"
              );
              const areaInHectares = area / 10000;

              if (areaInHectares + totalArea <= areaLimit) {
                polygonJson.area = areaInHectares;
                console.log(areaInHectares + totalArea);
                setLandArea(areaInHectares);
                setErrorMessage(""); // Reset the error message

                setPolygonData(polygonJson);
              } else {
                setErrorMessage(t("subscriptionLimitExceeded"));
              }
            });

            view.ui.add(sketch, "top-right");

            const searchWidget = new Search({
              view: view,
            });

            view.ui.add(searchWidget, {
              position: "top-right",
              index: 0,
            });
          });
        }
      )
      .catch((error) => {
        console.error("Error loading the ArcGIS API for JavaScript:", error);
      });
  }, [selectedPlan]);

  //prop from parent to send the data to the parent
  useEffect(() => {
    handleGeometry(polygonData);
  }, [polygonData]);

  return (
    <div style={{ position: "relative" }}>
      <Helmet>
        <link
          rel="stylesheet"
          href="https://js.arcgis.com/4.26/esri/themes/light/main.css"
        />
      </Helmet>
      <div ref={mapViewRef} style={style}></div>

      {errorMessage ? (
        <div
          style={{
            width: "400px",
            position: "absolute",
            bottom: "13px",
            left: "10px",
            backgroundColor: "rgb(138 2 2)",
            color: "white",
            padding: "5px",
          }}
        >
          {errorMessage}
          <Link style={{ color: "#339475" }} to="/farmer/account/subscription">
            {t("upgrade")}
          </Link>
        </div>
      ) : (
        <div
          style={{
            position: "absolute",
            bottom: "17px",
            left: "0px",
            backgroundColor: "white",
            padding: "5px",
          }}
        >
          {t("area Used")}: {(totalArea + landArea).toFixed(2)} / {areaLimit}{" "}
          hectares
          {errorMessage}
        </div>
      )}
    </div>
  );
};

export default DrawingMap;
