import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { Helmet } from 'react-helmet'
import {
  AiFillCaretLeft,
  AiFillCaretRight,
  AiOutlineCloudDownload,
} from 'react-icons/ai'
import FarmRegistration from '../farms/FarmRegistration'
import FarmerTopBar from '../../components/topbar/Topbar'
import MyFarms from '../farms/myFields/MyFarms'
import IssueList from '../issues/IssueList'
import { IoAddOutline } from 'react-icons/io5'
import PointMap from '../farms/myFields/PointMap'
import { toast } from 'react-toastify'
import { MapContainer, TileLayer, ImageOverlay } from 'react-leaflet'
import SoilAnalysisList from './SoilAnalysisList'
import { API, graphqlOperation } from 'aws-amplify'
import { createSoilAnalysis } from '../../../graphql/mutations'
import { fetchFarmDetails } from '../../fetches/FetchFarmDetails'
import { useTranslation } from 'react-i18next'

const SoilAnalysis = () => {
  const { t } = useTranslation()
  const [leftWidth, setLeftWidth] = useState(50)
  const [rightWidth, setRightWidth] = useState(50)
  const [showInput, setShowInput] = useState(false)
  const [farms, setFarms] = useState([])
  const [fieldName, setFieldName] = useState('')
  const [fieldID, setFieldID] = useState('')
  const [rings, setRings] = useState([])
  const [formattedRings, setFormattedRings] = useState([])
  const [clickedCoordinates, setClickedCoordinates] = useState(null) // New state for clicked coordinates
  const [formData, setFormData] = useState({
    sand: null,
    clay: null,
    silt: null,
    ph: null,
    totalNitrogen: null,
    assimilablePhosphorus: null,
    totalOrganicCarbon: null,
    cToNRatio: null,
    exchangeablePotassium: null,
    cationExchangeCapacity: null,
    bulkDensity: null,
    skeleton: null,
    depth: null,
    farmDetailsID: fieldID,
  })

  const handleInputChange = (event) => {
    const { name, value } = event.target
    setFormData({
      ...formData,
      [name]: value,
    })
  }

  const handleFieldSelect = (e) => {
    const selectedValue = JSON.parse(e.target.value)
    if (!selectedValue) {
      console.log('Invalid selected value')
      return
    }

    setFieldName(selectedValue.name)
    setFieldID(selectedValue.id)
    setRings(JSON.stringify(selectedValue.rings))
  }

  useEffect(() => {
    if (!rings.length) {
      return
    }

    const parsedRings = JSON.parse(rings)
    const coordinates = JSON.parse(parsedRings[0]) // Access the nested rings array
    const formattedRings = coordinates.map((item) => [
      item.latitude,
      item.longitude,
    ])
    setFormattedRings(formattedRings)
  }, [rings])

  console.log('formattedRings:', formattedRings)

  const handlePointClick = (coordinates) => {
    setClickedCoordinates(coordinates)
  }

  const handleRightArrowClick = () => {
    setLeftWidth(leftWidth + 10)
    setRightWidth(rightWidth - 10)
  }

  const handleLeftArrowClick = () => {
    setLeftWidth(leftWidth - 10)
    setRightWidth(rightWidth + 10)
  }

  useEffect(() => {
    async function fetchData() {
      const farmDetailsData = await fetchFarmDetails()
      setFarms(farmDetailsData)
      console.log(farmDetailsData)
    }

    fetchData()
  }, [])

  const handleSubmit = async (event) => {
    event.preventDefault()
    const {
      sand,
      clay,
      silt,
      ph,
      totalNitrogen,
      assimilablePhosphorus,
      totalOrganicCarbon,
      cToNRatio,
      exchangeablePotassium,
      cationExchangeCapacity,
      bulkDensity,
      skeleton,
      depth,
    } = formData
    if (!fieldID) {
      toast.error('Please fill in all fields')
      return
    }
    await API.graphql(graphqlOperation(createSoilAnalysis, { input: formData }))
    toast.success('Soil analysis added successfully')
    setShowInput(false)
  }

  return (
    <div className="home-container">
      <FarmerTopBar />
      <Helmet>
        <link
          rel="stylesheet"
          href="https://js.arcgis.com/4.26/esri/themes/light/main.css"
        />
      </Helmet>

      <section className="mymap-wrapper">
        <div
          style={{
            width: `${leftWidth}%`,
            position: 'relative',
            padding: '10px',
          }}
        >
          <section className="my-fields-header">
            <h3>{t('soil analysis')}</h3>
            <div>
              <button style={{ marginRight: '5px' }}>
                <AiOutlineCloudDownload />
              </button>
              <button>
                <IoAddOutline onClick={() => setShowInput(true)} />
              </button>
            </div>
          </section>
          <div className="center-buttons">
            <button onClick={handleLeftArrowClick}>
              <AiFillCaretLeft />
            </button>
            <button onClick={handleRightArrowClick}>
              <AiFillCaretRight />
            </button>
          </div>

          <section>
            <SoilAnalysisList />
          </section>

          {showInput ? (
            <section className="farmpopup">
              <h2>{t('soil Analysis Form')}</h2>
              <form className="soil-form" onSubmit={handleSubmit}>
                <div className="form-group">
                  <label htmlFor="farm">{t('farm')}</label>
                  <select id="farm" name="farm" onChange={handleFieldSelect}>
                    <option value="">{t('select Farm')}</option>
                    {farms &&
                      farms.map((farm) => (
                        <option key={farm.id} value={JSON.stringify(farm)}>
                          {farm.name}
                        </option>
                      ))}
                  </select>
                </div>
                <section className="input-section">
                  <div className="form-group">
                    <label htmlFor="sand">{t('sand')} (%)</label>
                    <div className="input-with-addon">
                      <input
                        type="number"
                        id="sand"
                        name="sand"
                        value={formData.sand}
                        onChange={handleInputChange}
                      />
                      <span className="addon">%</span>
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="clay">{t('clay')} (%)</label>
                    <div className="input-with-addon">
                      <input
                        type="number"
                        id="clay"
                        name="clay"
                        value={formData.clay}
                        onChange={handleInputChange}
                      />
                      <span className="addon">%</span>
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="silt">{t('silt')} (%)</label>
                    <div className="input-with-addon">
                      <input
                        type="number"
                        id="silt"
                        name="silt"
                        value={formData.silt}
                        onChange={handleInputChange}
                      />
                      <span className="addon">%</span>
                    </div>
                  </div>
                </section>

                <section className="input-section">
                  <div className="form-group">
                    <label htmlFor="ph">{t('pH')}</label>
                    <input
                      type="number"
                      step="0.1"
                      id="ph"
                      name="ph"
                      value={formData.ph}
                      onChange={handleInputChange}
                    />
                  </div>
                </section>

                <section className="input-section">
                  <div className="form-group">
                    <label htmlFor="totalNitrogen">
                      {t('total Nitrogen')} (N)
                    </label>
                    <div className="input-with-addon">
                      <input
                        type="number"
                        id="totalNitrogen"
                        name="totalNitrogen"
                        value={formData.totalNitrogen}
                        onChange={handleInputChange}
                      />
                      <span className="addon">g/kg</span>
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="assimilablePhosphorus">
                      {t('assimilable Phosphorus')} (P)
                    </label>
                    <div className="input-with-addon">
                      <input
                        type="number"
                        id="assimilablePhosphorus"
                        name="assimilablePhosphorus"
                        value={formData.assimilablePhosphorus}
                        onChange={handleInputChange}
                      />
                      <span className="addon">mg/kg</span>
                    </div>
                  </div>
                </section>

                <section className="input-section">
                  <div className="form-group">
                    <label htmlFor="totalOrganicCarbon">
                      {t('total Organic Carbon')} (C)
                    </label>
                    <div className="input-with-addon">
                      <input
                        type="number"
                        id="totalOrganicCarbon"
                        name="totalOrganicCarbon"
                        value={formData.totalOrganicCarbon}
                        onChange={handleInputChange}
                      />
                      <span className="addon">g/kg</span>
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="cToNRatio">C/N {t('ratio')}</label>
                    <input
                      type="number"
                      step="0.1"
                      id="cToNRatio"
                      name="cToNRatio"
                      value={formData.cToNRatio}
                      onChange={handleInputChange}
                    />
                  </div>
                </section>

                <section className="input-section">
                  <div className="form-group">
                    <label htmlFor="exchangeablePotassium">
                      {t('exchangeable Potassium')} (K)
                    </label>
                    <div className="input-with-addon">
                      <input
                        type="number"
                        id="exchangeablePotassium"
                        name="exchangeablePotassium"
                        value={formData.exchangeablePotassium}
                        onChange={handleInputChange}
                      />
                      <span className="addon">mg/kg</span>
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="cationExchangeCapacity">
                      {t('cation Exchange Capacity')} (CEC)
                    </label>
                    <div className="input-with-addon">
                      <input
                        type="number"
                        id="cationExchangeCapacity"
                        name="cationExchangeCapacity"
                        value={formData.cationExchangeCapacity}
                        onChange={handleInputChange}
                      />
                      <span className="addon">Meq/100g</span>
                    </div>
                  </div>
                </section>

                <section className="input-section">
                  <div className="form-group">
                    <label htmlFor="bulkDensity">{t('bulk Density')}</label>
                    <div className="input-with-addon">
                      <input
                        type="number"
                        id="bulkDensity"
                        name="bulkDensity"
                        value={formData.bulkDensity}
                        onChange={handleInputChange}
                      />
                      <span className="addon">
                        g/cm<sup>3</sup>
                      </span>
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="skeleton">{t('skeleton')}</label>
                    <div className="input-with-addon">
                      <input
                        type="number"
                        id="skeleton"
                        name="skeleton"
                        value={formData.skeleton}
                        onChange={handleInputChange}
                      />
                      <span className="addon">%</span>
                    </div>
                  </div>
                </section>

                <div className="form-group">
                  <label htmlFor="depth">{t('depth')}</label>
                  <div className="input-with-addon">
                    <input
                      type="number"
                      id="depth"
                      name="depth"
                      value={formData.depth}
                      onChange={handleInputChange}
                    />
                    <span className="addon">cm</span>
                  </div>
                </div>

                <section className="farmpopup__buttons">
                  <button type="submit">{t('submit')}</button>
                  <button type="reset">{t('reset')}</button>
                  <button onClick={() => setShowInput(false)}>
                    {t('cancel')}
                  </button>
                </section>
              </form>
            </section>
          ) : null}
        </div>

        <div style={{ width: `${rightWidth}%` }}>
          <PointMap
            handlePointClick={handlePointClick}
            selectedPolygon={formattedRings}
          />
        </div>
      </section>
    </div>
  )
}

export default SoilAnalysis
