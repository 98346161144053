import React from 'react';
import { FaBookOpen } from 'react-icons/fa'; // import the book open icon from react-icons

function NoNewsComponent({h2Tag, pTag, icon}) {
  return (
    <div style={{ textAlign: 'center', padding: '1em' }}>
        {icon}
      <h2>{h2Tag}</h2>
      <p>{pTag}</p>
    </div>
  );
}

export default NoNewsComponent;
