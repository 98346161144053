// TranslationContext.js
import React, { createContext, useContext, useState } from 'react';

const TranslationContext = createContext();

export const TranslationProvider = ({ children }) => {
  const [language, setLanguage] = useState('en'); // Default language is English

  const changeLang = (newLanguage) => {
    setLanguage(newLanguage);
  };

  return (
    <TranslationContext.Provider value={{ language, changeLang }}>
      {children}
    </TranslationContext.Provider>
  );
};

export const useTranslation = () => {
  const context = useContext(TranslationContext);
  if (!context) {
    throw new Error('useTranslation must be used within a TranslationProvider');
  }
  return context;
};
