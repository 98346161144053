import React, { useState } from 'react'
import { updatePackageRequest } from '../../../../graphql/mutations'
import { API, graphqlOperation } from 'aws-amplify'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

const AreaRequest = ({ requestID, updateParentState, onClosed }) => {
  const [area, setArea] = useState('')
  const { t } = useTranslation()

  const handleAreaRequest = async () => {
    try {
      await API.graphql(
        graphqlOperation(updatePackageRequest, {
          input: {
            id: requestID,
            areaRequested: area,
            isAreaRequest: true,
            status: 'areaRequest',
          },
        })
      )
      toast.success(t('areRequestSuccess'))
      onClosed()
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div>
      <h3>{t('enter area to request')}</h3>
      <input
        type="text"
        placeholder="Enter area in ha"
        className="search-input"
        value={area}
        onChange={(e) => setArea(e.target.value)}
      />

      <button className="package-button" onClick={handleAreaRequest}>
        {t('request')}
      </button>
    </div>
  )
}

export default AreaRequest
