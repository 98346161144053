import React, { useEffect } from 'react'
import TopBar from '../../topbar/Topbar'
import AWS from "aws-sdk";

import { useState } from 'react'
import { API, graphqlOperation, Storage } from 'aws-amplify'
import { createAcademy, createPackages, deleteAcademy, deletePackages, updateAcademy, updatePackages } from '../../../../../graphql/mutations'
import { toast } from 'react-toastify'
import { listAcademies, listPackages } from '../../../../../graphql/queries'
import { AiOutlineDown, AiOutlineUp } from 'react-icons/ai'
const Sales = () => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [price, setPrice] = useState('');
  const [features, setFeatures] = useState([]);
  const [newFeature, setNewFeature] = useState('');
  const [update, setUpdate] = useState(false);
  const [packages, setPackages] = useState([]);
  const [packageImage, setPackageImage] = useState('');
  const [image, setImage] = useState('');
  const [showInputs, setShowInputs] = useState(false);
  const [currentId, setCurrentId] = useState(null);
  const [isEdditing, setIsEdditing] = useState(false);
  const [progress, setProgress] = useState(0);
    const [category, setCategory] = useState('');
    const [video, setVideo] = useState('');


  const handleAddFeature = () => {
    if (newFeature.trim() !== '') {
      setFeatures([...features, newFeature]);
      setNewFeature('');
    }
  };


  const handleRemoveFeature = (index) => {
    const updatedFeatures = [...features];
    updatedFeatures.splice(index, 1);
    setFeatures(updatedFeatures);
  };



  //send data to backend
  const handleSubmit = async (e) => {
    e.preventDefault();

   const S3_BUCKET = "officialagrogis";

    // S3 Region
    const REGION = "us-east-1";

    // S3 Credentials
    AWS.config.update({
      accessKeyId: "AKIAZZNZN45OHZL2UDR3",
      secretAccessKey: "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",
    });
    const s3 = new AWS.S3({
      params: { Bucket: S3_BUCKET },
      region: REGION,
    });

    // Files Parameters
    if (packageImage.name === undefined) {
      return toast.error('Please select an image');
    }

    const params = {
      Bucket: S3_BUCKET,
      Key: `public/ACADEMY/${title}.${packageImage.name}`,
      Body: packageImage,
    };

    // Uploading file to s3

    var upload = s3
      .putObject(params)
      .on("httpUploadProgress", (evt) => {
        // File uploading progress
        const percentUploaded = (evt.loaded / evt.total) * 100;
        const progressBar = document.getElementById("progress-bar");
        progressBar.style.width = percentUploaded + "%";

        setProgress(Math.round((evt.loaded / evt.total) * 100));
        console.log(
          "Uploading " + parseInt((evt.loaded * 100) / evt.total) + "%"
        );
      })
      .promise();

    await upload.then((err, data) => {
      console.log(err);
      // Fille successfully uploaded
    });

    function convertToEmbedLink(url) {
      if (url.includes('embed')) {
          return url;
      } else {
          const videoId = url.split('/')[3].split('?')[0];
          const queryParams = url.split('?')[1];
          return `https://www.youtube.com/embed/${videoId}?${queryParams}`;
      }
  }
  

    //create a key that accepts png
    const data = {
      title,
      description,
      tags: features,
      image: `public/ACADEMY/${title}.${packageImage.name}`,
      category,
        video: convertToEmbedLink(video)
    };

    console.log(data);

    try {
      //send data to backend
      await API.graphql(graphqlOperation(createAcademy, { input: data }));
      toast.success('Course added successfully');
      setUpdate(!update);

      //set inputs to empty
        setTitle('');
        setDescription('');
        setFeatures([]);
        setCategory('');
        setVideo('');
        setPackageImage('');
        setImage('');
        setNewFeature('');
        
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchPackages();
  }, [update]);


  //fetch data from backend
  const fetchPackages = async () => {
    try {
      const packagesData = await API.graphql(graphqlOperation(listAcademies));
      setPackages(packagesData.data.listAcademies.items);
      console.log(packagesData);
    } catch (error) {
      console.log(error);
    }
  }

  const handleEdit = async (id) => {
    setCurrentId(id);
    setIsEdditing(true);
    setShowInputs(true);


    //set the data to the input fields
    const packageData = packages.find((packageData) => packageData.id === id);
    setTitle(packageData.title);
    setDescription(packageData.description);
    setFeatures(packageData.tags);
    setCategory(packageData.category);
    setVideo(packageData.video);
    setImage(`${packageData.image}`);


  }



  //update package
  const updatePackage = async (id) => {
    const S3_BUCKET = "officialagrogis";

    // S3 Region
    const REGION = "us-east-1";

    // S3 Credentials
    AWS.config.update({
      accessKeyId: "AKIAZZNZN45OHZL2UDR3",
      secretAccessKey: "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",
    });
    const s3 = new AWS.S3({
      params: { Bucket: S3_BUCKET },
      region: REGION,
    });


    const params = {
      Bucket: S3_BUCKET,
      Key: `public/ACADEMY/${title}.${packageImage.name}`,
      Body: packageImage,
    };

    // Uploading file to s3

    var upload = s3
      .putObject(params)
      .on("httpUploadProgress", (evt) => {
        // File uploading progress
        const percentUploaded = (evt.loaded / evt.total) * 100;
        const progressBar = document.getElementById("progress-bar");
        progressBar.style.width = percentUploaded + "%";

        setProgress(Math.round((evt.loaded / evt.total) * 100));
        console.log(
          "Uploading " + parseInt((evt.loaded * 100) / evt.total) + "%"
        );
      })
      .promise();

    await upload.then((err, data) => {
      console.log(err);
      // Fille successfully uploaded
    });

    function convertToEmbedLink(url) {
      if (url.includes('embed')) {
          return url;
      } else {
          const videoId = url.split('/')[3].split('?')[0];
          const queryParams = url.split('?')[1];
          return `https://www.youtube.com/embed/${videoId}?${queryParams}`;
      }
  }
  
    try {
       {
      await API.graphql(graphqlOperation(updateAcademy, {
        input: {
          id: id,
          image,
          title,
          description,
          tags:features,
          category,
            video: convertToEmbedLink(video)
        }
      }));
    } 
      toast.success('Package updated successfully');
      setUpdate(!update);
      setShowInputs(false);

      //set inputs to empty
      setTitle('');
      setDescription('');
      setFeatures([]);
      setCategory('');
      setVideo('');
      setPackageImage('');
      setImage('');
      setNewFeature('');
      setIsEdditing(false);

    } catch (error) {
      console.log(error);
    }
  }

  //delete package
  const deletePackage = async (id) => {
    try {
      await API.graphql(graphqlOperation(deleteAcademy, { input: { id } }));
      toast.success('Package deleted successfully');
      setUpdate(!update);
    } catch (error) {
      console.log(error);
    }
  }


  return (
    <div className='home-container'>
      <TopBar />
      <div className='packages-container'>

        <div className='package-sales-wrapper'>
          <section className='add-package'>
            <h2 >Add Tutorial </h2>
        
              <div className='add-package-wrapper'>
                <div className='add-package-item'>
                  <h3>Title</h3>
                  <input type="text" placeholder='Course Title' value={title} onChange={(e) => setTitle(e.target.value)} required />
                </div>


             
                <div className='add-package-item'>
                  <h3>Description</h3>
                  <textarea type='text' placeholder='Description' value={description} onChange={(e) => setDescription(e.target.value)} required />
                </div>

                <div className='add-package-item'>
                  <h3>Category</h3>
                  <input type="text" placeholder='Category Name' value={category} onChange={(e) => setCategory(e.target.value)} required />
                </div>

                <div className='add-package-item'>
                  <h3>Video</h3>
                  <input type="text" placeholder='Video Link' value={video} onChange={(e) => setVideo(e.target.value)} required />
                </div>

                <div>
                  <label>Topics:</label>
                  {
                    features === null && (
                      <h3>No features available</h3>
                    )
                  }
                  {features && features.map((feature, index) => (
                    <div key={index}>
                      {feature}
                      <button type="button" onClick={() => handleRemoveFeature(index)}>
                        X
                      </button>
                    </div>
                  ))}
                  <div className='add-package-item'>
                    <input

                      type="text"
                      value={newFeature}
                      onChange={(e) => setNewFeature(e.target.value)}
                      placeholder="Enter Topic"
                    />
                    <button type="button" onClick={handleAddFeature}>
                      Add
                    </button>
                  </div>
                </div>
                <div className='add-package-item'>
                  <h3>Image</h3>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => setPackageImage(e.target.files[0])}
                  />
                  <div class="progress-container">
                    <div class="progress-bar" id="progress-bar"></div>
                  </div>

                </div>


                <div className='add-package-item'>
                  {
                    isEdditing ? (
                      <button
                        onClick={() => updatePackage(currentId)}
                      >
                        Update
                      </button>
                    ) : (
                      <button onClick={handleSubmit}>Add Tutorial</button>
                    )
                  }
                </div>
               
              </div>
           

          

          </section>

          <section className=''>
            <h2>Tutorials Uploaded</h2>
            <div className='package-sales-plans'>
              {
                packages.length === 0 && (
                  <h3>No packages available</h3>
                )

              }
              {packages.map((plan, index) => (
                <div key={index} className='package-card'>
                  <div className='package-image' style={{height:'150px'}}>
                    <img src={`https://officialagrogis.s3.amazonaws.com/${plan.image}`} alt="" />
                    <div className='package-name plans'>
                      <p>{plan.title}</p>
                    </div>
                  </div>
                  {/* <h2 className="package-title">{plan.title}</h2> */}
                  <p className="package-description">{plan.description}</p>
                  <h4 className="package-description">{plan.category}</h4>
                  {/* <ul className='package-features' style={{height:"auto"}}>
                    {
                      !plan.tags && (
                        <h3>No features available</h3>
                      )
                    }
                    {plan.tags && plan.tags.map((feature, idx) => (
                      <li key={idx}>{feature}</li>
                    ))}
                  </ul> */}
                  <div className='package-bottom'>
                    <button className='package-button'
                      onClick={() => handleEdit(plan.id)}
                    >Edit</button>
                    <button className='package-button' onClick={() => deletePackage(plan.id)}>Delete</button>
                  </div>
                </div>
              ))}
            </div>

          </section>
        </div>
      </div>
    </div>
  )
}

export default Sales