import React, { useEffect, useRef, useState } from 'react';
import { loadModules } from 'esri-loader';
import { useTranslation } from 'react-i18next'

const HomeMap = ({ selectedPolygon, handlePointClick, selectedIssueCoordinates, polygons, displayPoint }) => {
  const mapViewRef = useRef(null);
  const [mapType, setMapType] = useState('hybrid');
  const [hoveredFarm, setHoveredFarm] = useState(null);



  useEffect(() => {
    loadModules(
      [
        'esri/Map',
        'esri/views/MapView',
        'esri/Graphic',
        'esri/layers/GraphicsLayer',
        'esri/geometry/Point',
        'esri/widgets/Search',
      ],
      { css: true }
    ).then(([Map, MapView, Graphic, GraphicsLayer, Point, Search]) => {
      const map = new Map({
        basemap: mapType,
      });

      const view = new MapView({
        container: mapViewRef.current,
        map: map,
        center: [26.015659, 44.552808],
        zoom: 10,
      });


      const graphicsLayer = new GraphicsLayer();
      map.add(graphicsLayer);



      const testCoordinates = [
        [
          36.936980196189914,
          -0.358368571266233
        ],
        [
          36.944533296775845,
          -0.35802525522150386
        ],
        [
          36.93835348720554,
          -0.3648915736571745
        ],
        [
          36.936980196189914,
          -0.358368571266233
        ],
       
      ];

      if (!polygons) {
        const polygon = {
          type: 'polygon',
          rings: testCoordinates,
        };

        const polygonGraphic = new Graphic({
          geometry: polygon,
          symbol: {
            type: 'simple-fill',
            color: [255, 255, 255, 0.4],
            outline: {
              color: [255, 255, 255],
              width: 2,
            },
          },
        });

        graphicsLayer.add(polygonGraphic);

        view.when(() => {
          view.goTo(polygonGraphic.geometry);
        });
      }

      // Define an array of colors
const colors = [
  
  [255, 255, 0, 0.4],  // Yellow


  
  // Add more colors as needed
];

      if (polygons && polygons.length > 0) {
        polygons.forEach((farm, index) => {
          const rings = JSON.parse(farm.rings);

          const polygon = {
            type: 'polygon',
            rings: rings.map((item) => [item.latitude, item.longitude]),
          };

          const coclorIndex = index % colors.length;
          const color = colors[coclorIndex];

          const polygonGraphic = new Graphic({
            geometry: polygon,
            symbol: {
              type: 'simple-fill',
              color: color,
              outline: {
                color: [255, 255, 255],
                width: 1,
              },
            },
          });

      

          graphicsLayer.add(polygonGraphic);

          view.when(() => {
            view.goTo(polygonGraphic.geometry);
          });
        });
      }

      if (selectedPolygon && selectedPolygon.length >= 3) {
        const polygonCoordinates = selectedPolygon.map((point) => [point[0], point[1]]);
        const polygon = {
          type: 'polygon',
          rings: polygonCoordinates.length >= 3 ? [polygonCoordinates] : testCoordinates,
        };

        const polygonGraphic = new Graphic({
          geometry: polygon,
          symbol: {
            type: 'simple-fill',
            color: [255, 255, 255, 0.4],
            outline: {
              color: [255, 255, 255],
              width: 2,
            },
          },
        });

        graphicsLayer.add(polygonGraphic);

        view.when(() => {
          view.goTo(polygonGraphic.geometry);
        });
      }


      // Add the search widget
      const searchWidget = new Search({
        view: view,
      });

      view.ui.add(searchWidget, 'top-right');

      // Create a symbol for the point
      const dotSymbol = {
        type: 'simple-marker',
        color: [255, 255, 255],
        size: 10,
      };

      // Create a graphics layer to display the clicked points
      const clickedPointsLayer = new GraphicsLayer();
      map.add(clickedPointsLayer);

      // Event handler for map click
      const handleClick = (event) => {
        const clickedPoint = new Point({
          longitude: event.mapPoint.longitude,
          latitude: event.mapPoint.latitude,
        });

        const clickedPointGraphic = new Graphic({
          geometry: clickedPoint,
          symbol: dotSymbol,
        });

        clickedPointsLayer.removeAll(); // Clear previous clicked points
        clickedPointsLayer.add(clickedPointGraphic);

        // Get the coordinates of the clicked point
        const coordinates = {
          latitude: clickedPoint.latitude,
          longitude: clickedPoint.longitude,
        };
        handlePointClick(coordinates);
      };

      // Add the click event listener to the view
      view.on('click', handleClick);



    }).catch((error) => {
      console.error('Error loading the ArcGIS API for JavaScript:', error);
    });
  }, [selectedPolygon, mapType, polygons, displayPoint]);

  const handleMapTypeChange = (event) => {
    setMapType(event.target.value);
  };
 

  return( <div ref={mapViewRef} style={{ width: '100%', height: '100%', position:'relative' }}>
 

  </div>)
};

export default HomeMap;
