import React, { useEffect } from 'react';
import './courses.css';
import FarmerTopBar from '../../components/topbar/Topbar';
import { Link } from 'react-router-dom';
import { API, graphqlOperation } from 'aws-amplify';
import { listAcademies, listPackages } from '../../../graphql/queries';
import { useTranslation } from 'react-i18next'

const Courses = () => {
    // const courses = [
    //     {
    //       image: 'https://images.unsplash.com/photo-1492117410584-db7b8a2e1460?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1032&q=80',
    //       title: 'Introduction to Organic Farming',
    //       description: 'Learn the basics of organic farming and sustainable agriculture practices to improve farm products.',
    //       duration: 20,
    //       lectures: 10,
    //       level: 'Beginner',
    //       price: 49.99,
    //     },
    //     {
    //         image: 'https://images.unsplash.com/photo-1422651973727-50f085c0b26f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=867&q=80',
    //         title: 'Advanced Crop Management',
    //       description: 'Explore advanced techniques for crop management, including pest control and soil fertility.',
    //       duration: 40,
    //       lectures: 15,
    //       level: 'Intermediate',
    //       price: 79.99,
    //     },
    //     {
    //         image: 'https://images.unsplash.com/photo-1604300721398-3f58fdf81780?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80',
    //         title: 'Livestock Management and Care',
    //       description: 'Gain knowledge about livestock management, breeding, and health care practices.',
    //       duration: 30,
    //       lectures: 12,
    //       level: 'Intermediate',
    //       price: 64.99,
    //     },
    //     {
    //         image: 'https://images.unsplash.com/photo-1440428099904-c6d459a7e7b5?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80',
    //         title: 'Precision Agriculture and Technology',
    //       description: 'Learn about the integration of technology and data analysis in modern farming practices.',
    //       duration: 50,
    //       lectures: 20,
    //       level: 'Expert',
    //       price: 99.99,
    //     },
    //     {
    //         image: 'https://images.unsplash.com/photo-1466692476868-aef1dfb1e735?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80',
    //         title: 'Sustainable Soil Management',
    //       description: 'Discover methods to maintain soil health, reduce erosion, and promote sustainable soil practices.',
    //       duration: 25,
    //       lectures: 8,
    //       level: 'Beginner',
    //       price: 54.99,
    //     },
    //     {
    //         image: 'https://images.unsplash.com/photo-1514864151880-d1bef4892f29?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80',
    //         title: 'Agribusiness and Marketing Strategies',
    //       description: 'Learn about effective marketing strategies and business management in the agricultural industry.',
    //       duration: 35,
    //       lectures: 18,
    //       level: 'Intermediate',
    //       price: 74.99,
    //     },
    //     {
    //         image: 'https://images.unsplash.com/photo-1495107334309-fcf20504a5ab?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80',
    //         title: 'Greenhouse Management Techniques',
    //       description: 'Explore greenhouse production methods, climate control, and plant propagation in controlled environments.',
    //       duration: 45,
    //       lectures: 16,
    //       level: 'Intermediate',
    //       price: 89.99,
    //     },
    //     {
    //         image: 'https://images.unsplash.com/photo-1471193945509-9ad0617afabf?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80',
    //         title: 'Permaculture Design and Implementation',
    //       description: 'Learn about permaculture principles, design techniques, and how to create sustainable ecosystems.',
    //       duration: 60,
    //       lectures: 22,
    //       level: 'Expert',
    //       price: 119.99,
    //     },
    //     {
    //         image: 'https://images.unsplash.com/photo-1510844355160-2fb07bf9af75?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80',
    //         title: 'Aquaponics Fundamentals',
    //       description: 'Discover the combination of aquaculture and hydroponics to create an efficient, sustainable farming system.',
    //       duration: 40,
    //       lectures: 14,
    //       level: 'Intermediate',
    //       price: 79.99,
    //     },
    //     {
    //         image: 'https://images.unsplash.com/photo-1506519056028-d18449e82c6f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=725&q=80',
    //         title: 'Farm Equipment and Machinery Maintenance',
    //       description: 'Learn how to maintain and troubleshoot common farm equipment and machinery for optimal performance.',
    //       duration: 30,
    //       lectures: 10,
    //       level: 'Beginner',
    //       price: 59.99,
    //     },
    //   ];

  const [courses, setCourses] = React.useState([]);
  
  const { t } = useTranslation()

  useEffect(() => {
    fetchPackages();
  }, []);


        const fetchPackages = async () => {
    try {
      const packagesData = await API.graphql(graphqlOperation(listAcademies));
      setCourses(packagesData.data.listAcademies.items);
      console.log(packagesData);
    } catch (error) {
      console.log(error);
    }
  }


  return (
    <div className="home-container courses-container">
        <FarmerTopBar />
        <div className='pages-container'>
        <h2 className='news-title'>{t('tutorials')}</h2>
      {/* <h1>Available Courses</h1> */}
      {
          courses.length === 0 && <h2>{t('no courses available')}</h2>
        }
      <div className="course-list">
       
        {courses.map((course, index) => (
          <Link to={`details/${course.id}`} className='news-link'>
          <div className="course-card" key={index}>
            <div className='course-image'>
            <img src={`https://officialagrogis.s3.amazonaws.com/${course.image}`} alt={course.title} />
            </div>
            <div className="course-details">
              <h2 className="course-title">{course.title}</h2>
              <p className="course-description">{course.description}</p>
              {/* <div className="course-info">
                <div className="info-item">
                  <span className="info-label">Duration:</span>
                  <span className="info-value">{course.duration} hours</span>
                </div>
                <div className="info-item">
                  <span className="info-label">Lectures:</span>
                  <span className="info-value">{course.lectures}</span>
                </div>
                <div className="info-item">
                  <span className="info-label">Level:</span>
                  <span className="info-value">{course.level}</span>
                </div>
                <div className="info-item">
                  <span className="info-label">Price:</span>
                  <span className="info-value">${course.price}</span>
                </div>
              </div> */}
            </div>
          </div>
          </Link>
        ))}
      </div>
      </div>
    </div>
  );
};

export default Courses;
