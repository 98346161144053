import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import FarmerTopBar from '../../components/topbar/Topbar';
import { Helmet } from 'react-helmet';
import { AiFillCaretLeft, AiFillCaretRight } from 'react-icons/ai';
import FarmRegistration from '../farms/FarmRegistration';
import WeatherList from './WeatherList';
import MyFarms from '../farms/myFields/MyFarms';
import PointMap from '../farms/myFields/PointMap';
import axios from 'axios';
import FinalWeatherList from './FinalWeatherList';
import { API, graphqlOperation } from 'aws-amplify';
import { listPackageRequests } from '../../../graphql/queries';
import { Link } from 'react-router-dom';
import FeatureAlert from '../subscriptions/alerts/SubsAlert';
import { fetchPlan } from '../../fetches/FetchFarmDetails';
import Meteoblue from './latest/Meteoblue';

import { useTranslation } from 'react-i18next'

const FinalWeatherListMemoized = React.memo(FinalWeatherList);

const WeatherMap = () => {
  const { t } = useTranslation()
    const [leftWidth, setLeftWidth] = useState(50);
  const [rightWidth, setRightWidth] = useState(50);
  const [plan, setPlan] = useState([]);
  const [shouldDisplayWeather, setShouldDisplayWeather] = useState(false);
  const [loading, setLoading] = useState(true);
  const [selectedCoordinates, setSelectedCoordinates] = useState(null);
  const [clickedCoordinates, setClickedCoordinates] = useState(null); // New state for clicked coordinates

  const handleRightArrowClick = () => {
    setLeftWidth(leftWidth +10);
    setRightWidth(rightWidth - 10);
  };

  const handleLeftArrowClick = () => {
    setLeftWidth(leftWidth - 10);
    setRightWidth(rightWidth + 10);
    
  };

  const fetchWeatherData = async () =>{
    axios.get('https://weather.visualcrossing.com/VisualCrossingWebServices/rest/services/timeline/-0.400768,36.9687000?unitGroup=us&key=UYBXYCMRF9SMZEM76ENZQQYAM')
    .then((response) => {
      console.log(response.data);
    }
    ).catch((error) => {
      console.log(error);
    }
    )    
  }

  const handlePointClick = (coordinates) => {
    setClickedCoordinates(coordinates);
  };

  useEffect(() => {
    async function fetchData() {
      const issuesData = await fetchPlan();
      setPlan(issuesData);
    }
    fetchData();
  }, []);

useEffect(() => {
  const hasApprovedMeteobluePlan = plan.some(
      p => p.package.title === 'Virtual Weather Station' && p.status === 'approved'
  );

  setShouldDisplayWeather(hasApprovedMeteobluePlan);
}, [plan]);




  return (
    <div className='home-container'>
      <FarmerTopBar />
      <Helmet>
        <link
          rel="stylesheet"
          href="https://js.arcgis.com/4.26/esri/themes/light/main.css"
        />
      </Helmet>

      {/* <button onClick={fetchWeatherData}>Fetch</button> */}
     
      <section className='px-2 mymap-wrapper'>
        

        <div className='left-myMap' style={{ position:'relative', width: '100%' }}>
        <h2 style={{ margin: '10px '  }}>{t('weather Forecast')}</h2>
        {/* <div className='center-buttons'>
          <button onClick={handleLeftArrowClick}><AiFillCaretLeft /></button>
          <button onClick={handleRightArrowClick}><AiFillCaretRight /></button>
        </div> */}
        {
          shouldDisplayWeather ? (

            <FinalWeatherListMemoized shouldDisplayWeather={shouldDisplayWeather} />
            // <Meteoblue />
          ) : loading && shouldDisplayWeather ?(
            <p>{t('loading')}...</p>
          ) :(
          <FeatureAlert />
          )
        }
        </div>

        {/* <div className='right-myMap' style={{ height: '100vh',  }}>
          <PointMap handlePointClick={handlePointClick} />
        </div> */}
      </section>
    </div>
  );
};

export default WeatherMap;
