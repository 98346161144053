import React, { useEffect, useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Cell } from 'recharts';

const data = [
  { name: 'Lowest vigor', value: 0, color: '#DDDDDD' },
  { name: 'Low Vigor', value: 0, color: '#91BF52' },
  { name: 'Medium vigor', value: 0, color: '#B0C261' },
  { name: 'High Vigor', value: 0, color: '#019901' },
  { name: 'Highest vigor', value: 0, color: '#228B22' },
];

const generateRandomValue = () => {
  return Math.random() * 2 - 1;
};

const calculatePercentages = (values) => {
  const total = values.reduce((sum, value) => sum + value, 0);
  return values.map(value => (value / total) * 100);
};

const Graph = ({ selectedDate }) => {
  const [chartData, setChartData] = useState(data);

  useEffect(() => {
    const totalCount = 40;
    const values = [0, 0, 0, 0, 0];

    for (let i = 0; i < totalCount; i++) {
      const randomValue = generateRandomValue();
      if (randomValue >= -1 && randomValue < -0.9) {
        values[0] += 1;
      } else if (randomValue >= -0.9 && randomValue < -0.6) {
        values[1] += 1;
      } else if (randomValue >= -0.6 && randomValue < 0.0) {
        values[2] += 1;
      } else if (randomValue >= 0.0 && randomValue < 0.2) {
        values[3] += 1;
      } else if (randomValue >= 0.2 && randomValue <= 1) {
        values[4] += 1;
      }
    }

    const percentages = calculatePercentages(values);

    const updatedData = chartData.map((entry, index) => ({
      ...entry,
      value: (percentages[index])
    }));

    setChartData(updatedData);
  }, [selectedDate]);

  return (
    <BarChart width={500} height={300} data={chartData}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" tick={{ fill: 'white' }} />
      <YAxis tick={{ fill: 'white' }} />
      <Tooltip wrapperStyle={{ backgroundColor: 'transparent' }} />
      <Legend />
      <Bar dataKey="value">
    {chartData.map((entry, index) => (
      <Cell key={`cell-${index}`} fill={entry.color} />
    ))}
  </Bar>    </BarChart>
  );
};

export default Graph;
