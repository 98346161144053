import React, { useEffect, useState } from 'react';
import { GiFarmTractor } from 'react-icons/gi';
import './overview.css'; // Assuming you have a separate CSS file for this component
import TopBar from '../../topbar/Topbar';
import { BiMapAlt } from 'react-icons/bi';
import {BiTimeFive} from 'react-icons/bi';
import {BsCurrencyEuro} from 'react-icons/bs';
import {MdOutlineManageAccounts} from 'react-icons/md';
import { fetchAllFarmDetails, fetchAllFarmers, fetchFarmDetails } from '../../../../../fARMER/fetches/FetchFarmDetails';
import CropAreaGraph from './charts/Pie';
import RevenueChart from './charts/RevenueChart';
const YourComponent = () => {
  const [farms, setfarms] = useState([]);
  const [farmers, setfarmers] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const farmDetailsData = await fetchAllFarmDetails();
      setfarms(farmDetailsData);
    }

    fetchData();
  }, []);

  //count total area of all farms
  const totalArea = () =>{
    let totalArea = 0;
    farms.forEach(farm => {
      totalArea += farm.area;
    });
    return totalArea.toFixed(2);
  }


  useEffect(() => {
    async function fetchData() {
      const farmerDetailsData = await fetchAllFarmers();
      setfarmers(farmerDetailsData);
    }
    fetchData();
  }, []);

  //count total number of farms
  const totalFarms = () =>{
    return farms.length;
  }

  //count total number of farmers
  const totalFarmers = () =>{
    return farmers.length;
  }

  return (
    <div className='home-container'>
        <TopBar />
    <div className='overview-container'>
      <h1>Overview</h1>
      <div className='overview-wrapper'>
        <div className='overview-item'>
          <GiFarmTractor />
          <h2>Total Sales</h2>
          <h1>10</h1>
          <p>+32% from last week</p>
        </div>
        <div className='overview-item'>
          <BiMapAlt />
          <h2>Farms Mapped</h2>
          <h1>{totalFarms()}</h1>
          <p>+18% from last week</p>
        </div>
        <div className='overview-item'>
          <BiTimeFive />
          <h2>Total Area Mapped</h2>
          <h1>{totalArea()} ha</h1>
          <p>+28% from last week</p>
        </div>

        
        <div className='overview-item'>
          <GiFarmTractor />
          <h2>Number of farmers</h2>
          <h1>{totalFarmers()}</h1>
          <p>Registered farmers</p>
        </div>
      </div>
    </div>
    <section className='revenue-details'>
      <div className='revenue-package'>
        <header>
          <h4>Farm Mappings</h4>
        </header>
        <div className='revenue-item'>
          <CropAreaGraph farmDetailsData={farms} />
        </div>
      </div>
      <div className='revenue-chart'>
        <header>
          <h4>Last 6 weeks (Revenue)</h4>
        </header>
        <RevenueChart />
      </div>
    </section>
    </div>
  );
};

export default YourComponent;
