import React, { useEffect } from 'react'
import TopBar from '../../topbar/Topbar'
import AWS from "aws-sdk";
import './sales.css'

import { v4 as uuid } from 'uuid'
import { packagePlans } from '../../../../../fARMER/account/subscriptions/Subscriptions'
import { useState } from 'react'
import { API, graphqlOperation, Storage } from 'aws-amplify'
import { createPackages, deletePackages, updatePackages } from '../../../../../graphql/mutations'
import { toast } from 'react-toastify'
import { listPackages } from '../../../../../graphql/queries'
import Requests from './Requests'
import { AiOutlineDown, AiOutlineUp } from 'react-icons/ai'
const Sales = () => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [price, setPrice] = useState('');
  const [features, setFeatures] = useState([]);
  const [newFeature, setNewFeature] = useState('');
  const [update, setUpdate] = useState(false);
  const [packages, setPackages] = useState([]);
  const [packageImage, setPackageImage] = useState('');
  const [image, setImage] = useState('');
  const [showInputs, setShowInputs] = useState(false);
  const [currentId, setCurrentId] = useState(null);
  const [isEdditing, setIsEdditing] = useState(false);
  const [progress, setProgress] = useState(0);
  const [filterValue, setFilterValue] = useState(''); // State to hold the filter value
  const [showMessage, setShowMessage] = useState(false);

  const handleFilterChange = (event) => {
    setFilterValue(event.target.value); // Update filter value based on user input
  };



  const handleAddFeature = () => {
    if (newFeature.trim() !== '') {
      setFeatures([...features, newFeature]);
      setNewFeature('');
    }
  };


  const handleRemoveFeature = (index) => {
    const updatedFeatures = [...features];
    updatedFeatures.splice(index, 1);
    setFeatures(updatedFeatures);
  };



  //send data to backend
  const handleSubmit = async (e) => {
    e.preventDefault();

    const S3_BUCKET = "officialagrogis";

    // S3 Region
    const REGION = "us-east-1";

    // S3 Credentials
    AWS.config.update({
      accessKeyId: "AKIAZZNZN45OHZL2UDR3",
      secretAccessKey: "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",
    });
    const s3 = new AWS.S3({
      params: { Bucket: S3_BUCKET },
      region: REGION,
    });

    // Files Parameters

    const params = {
      Bucket: S3_BUCKET,
      Key: `public/PACKAGES/${title}.${packageImage.name}`,
      Body: packageImage,
    };

    // Uploading file to s3

    var upload = s3
      .putObject(params)
      .on("httpUploadProgress", (evt) => {
        const percentUploaded = (evt.loaded / evt.total) * 100;
        const progressBar = document.getElementById("progress-bar");
        progressBar.style.width = percentUploaded + "%";
        // File uploading progress
        setProgress(Math.round((evt.loaded / evt.total) * 100));
        console.log(
          "Uploading " + parseInt((evt.loaded * 100) / evt.total) + "%"
        );
      })
      .promise();

    await upload.then((err, data) => {
      console.log(err);
      // Fille successfully uploaded
    });

    //create a key that accepts png
    const data = {
      title,
      description,
      price: parseFloat(price),
      features,
      image: `public/PACKAGES/${title}.${packageImage.name}`,
    };

    console.log(data);

    try {
      //send data to backend
      await API.graphql(graphqlOperation(createPackages, { input: data }));
      toast.success('Package added successfully');
      setUpdate(!update);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchPackages();
  }, [update]);


  //fetch data from backend
  const fetchPackages = async () => {
    try {
      const packagesData = await API.graphql(graphqlOperation(listPackages));
      setPackages(packagesData.data.listPackages.items);
      setShowMessage(packages.filter(item => item.title === 'Virtual Weather Station' &&
      //first feature is 'bj'
      item.features[0] === 'bj' ))
      console.log(packagesData);
    } catch (error) {
      console.log(error);
    }
  }


  const handleEdit = async (id) => {
    setCurrentId(id);
    setIsEdditing(true);
    setShowInputs(true);


    //set the data to the input fields
    const packageData = packages.find((packageData) => packageData.id === id);
    setTitle(packageData.title);
    setDescription(packageData.description);
    setPrice(packageData.price);
    setFeatures(packageData.features);
    // setImage(packageData.image);


  }



  //update package
  const updatePackage = async (id) => {
    const S3_BUCKET = "officialagrogis";

    // S3 Region
    const REGION = "us-east-1";

    // S3 Credentials
    AWS.config.update({
      accessKeyId: "AKIAZZNZN45OHZL2UDR3",
      secretAccessKey: "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",
    });
    const s3 = new AWS.S3({
      params: { Bucket: S3_BUCKET },
      region: REGION,
    });

    // Files Parameters
    if (packageImage.name === undefined) {
      return toast.error('Please select an image');
    }

    const params = {
      Bucket: S3_BUCKET,
      Key: `public/PACKAGES/${title}.${packageImage.name}`,
      Body: packageImage,
    };

    // Uploading file to s3
    // https://officialagrogis.s3.amazonaws.com/public/PACKAGES/d69c6a4e-6099-4806-813f-e344a1365b4a
    // https://officialagrogis.s3.amazonaws.com/public/PACKAGES/AGROGIS+Freemium.freemium2.jpg

    var upload = s3
      .putObject(params)
      .on("httpUploadProgress", (evt) => {
        // File uploading progress
        const percentUploaded = (evt.loaded / evt.total) * 100;
        const progressBar = document.getElementById("progress-bar");
        progressBar.style.width = percentUploaded + "%";

        setProgress(Math.round((evt.loaded / evt.total) * 100));
        console.log(
          "Uploading " + parseInt((evt.loaded * 100) / evt.total) + "%"
        );
      })
      .promise();

    await upload.then((err, data) => {
      console.log(err);
      // Fille successfully uploaded
    });
    //create a key that accepts multiple types of image types

    try {
      await API.graphql(graphqlOperation(updatePackages, {
        input: {
          id: id,
          image: `public/PACKAGES/${title}.${packageImage.name}`,
          title,
          description,
          price: parseFloat(price),
          features
        }
      }));
      toast.success('Package updated successfully');
      setUpdate(!update);
      setShowInputs(false);
    } catch (error) {
      console.log(error);
    }
  }

  //delete package
  const deletePackage = async (id) => {
      // Ask for confirmation before proceeding
      const confirmDelete = window.confirm('Are you sure you want to delete the request?, this action might impact the user');

      if (!confirmDelete) {
          return; // If the user cancels the confirmation, exit the function
      }
    try {
      await API.graphql(graphqlOperation(deletePackages, { input: { id } }));
      toast.success('Package deleted successfully');
      setUpdate(!update);
    } catch (error) {
      console.log(error);
    }
  }


  return (
    <div className='home-container'>
      <TopBar  filterValue={filterValue} handleFilterChange={handleFilterChange} />
      <div className='packages-container'>

        <div className='package-sales-wrapper'>
          <section className='add-package'>
            <h2 onClick={() => setShowInputs(!showInputs)}>Add Package {showInputs ? <AiOutlineUp /> : <AiOutlineDown onClick={() => setShowInputs(!showInputs)} />}</h2>
            {
              showInputs &&
              <div className='add-package-wrapper'>
                <div className='add-package-item'>
                  <h3>Package Name</h3>
                  <input type="text" placeholder='Package Name' value={title} onChange={(e) => setTitle(e.target.value)} required />
                </div>
                <div className='add-package-item'>
                  <h3>Package Price</h3>
                  <input type='number' placeholder='Package Price' value={price} onChange={(e) => setPrice(e.target.value)} />
                </div>
                <div className='add-package-item'>
                  <h3>Package Description</h3>
                  <textarea type='text' placeholder='Package Description' value={description} onChange={(e) => setDescription(e.target.value)} required />
                </div>
                <div>
                  <label>Features:</label>
                  {
                    features === null && (
                      <h3>No features available</h3>
                    )
                  }
                  <ul>
                    {features && features.map((feature, index) => (
                      <li key={index}>
                        {feature}
                        <button type="button" onClick={() => handleRemoveFeature(index)}>
                          Remove
                        </button>
                      </li>
                    ))}
                  </ul>
                  <div className='add-package-item'>
                    <input

                      type="text"
                      value={newFeature}
                      onChange={(e) => setNewFeature(e.target.value)}
                      placeholder="Enter a new feature"
                    />
                    <button type="button" onClick={handleAddFeature}>
                      Add
                    </button>
                  </div>
                </div>
                <div className='add-package-item'>
                  <h3>Package Image</h3>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => setPackageImage(e.target.files[0])}
                  />
                  <div class="progress-container">
                    <div class="progress-bar" id="progress-bar"></div>
                  </div>

                </div>


                <div className='add-package-item'>
                  {
                    isEdditing ? (
                      <button
                        onClick={() => updatePackage(currentId)}
                      >
                        Update
                      </button>
                    ) : (
                      <button onClick={handleSubmit}>Add Package</button>
                    )
                  }
                </div>
               
              </div>
            }

            <Requests  filterValue={filterValue} />

          </section>

          <section className=''>
            <h2>Package Plans</h2>
            <div className='package-sales-plans'>
              {
                packages.length === 0 && (
                  <h3>No packages available</h3>
                )

              }
              {packages.map((plan, index) => (
                <div key={index} className='package-card'>
                  <div className='package-image'>
                    <img src={`https://officialagrogis.s3.amazonaws.com/${plan.image}`} alt="" />
                    <div className='package-name plans'>
                      <p>{plan.title}</p>
                    </div>
                  </div>
                  {/* <h2 className="package-title">{plan.title}</h2> */}
                  {/* <p className="package-description">{plan.description}</p> */}
                  <ul className='package-features'>
                    {
                      !plan.features && (
                        <h3>No features available</h3>
                      )
                    }
                    {plan.features && plan.features.map((feature, idx) => (
                      <li key={idx}>{feature}</li>
                    ))}
                  </ul>
                  <div className='package-bottom'>
                    <h3 className='package-price'>€ {plan.price}</h3>
                    <button className='package-button'
                      onClick={() => handleEdit(plan.id)}
                    >Edit</button>
                    <button className='package-button' onClick={() => deletePackage(plan.id)}>Delete</button>
                  </div>
                </div>
              ))}
            </div>

          </section>
        </div>
      </div>
    </div>
  )
}

export default Sales