import { BiHelpCircle } from "react-icons/bi";
import { useTranslation } from 'react-i18next'

const getColorForRecommendation = (recommendation) => {
    switch (recommendation) {
      case 0:
        return 'green';
      case 1:
        return 'yellow';
      case 2:
        return 'red';
      default:
        return 'white';
    }
};

const SowingData = ({weatherData}) => {
  const { t } = useTranslation()
    const data = weatherData.data_1h;

    // Extract only the portion of the data you need
    const relevantData = {
        time: data.time.slice(6, 19),
        sowing_maize: data.sowing_maize.slice(6, 19),
    };

    const cropTypes = ['sowing_maize'];
    
    const formatTime = (timeString) => {
        // Time string is in the format "YYYY-MM-DD hh:mm"
        // Split it into an array ['YYYY-MM-DD', 'hh:mm']
        const parts = timeString.split(' ');
        // Return just the 'hh:mm' part
        return parts[1];
      };

    return (
        <>

        <table>
            <thead>
                <tr>
                    <th>{t('time')}</th>
                    {cropTypes.map(cropType => (
                        <th key={cropType}>
                        {t('sowing Index')} 
                        <span className="tooltip">
                          <BiHelpCircle />
                          <span className="tooltiptext">
                            <div>0: {t('suitable period for application')} <span style={{backgroundColor: 'green'}}> (green) </span></div>
                            <div>1: {t('less suitable period for application')} <span style={{backgroundColor: 'yellow'}}> (yellow) </span></div>
                            <div>2: {t('unsuitable period for application')} <span style={{backgroundColor: 'red'}}> (red) </span></div>
                          </span>
                        </span>
                      </th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {relevantData.time && relevantData.time.map((time, index) => (
                    <tr key={time}>
                        <td>{formatTime(time)}</td>
                        {cropTypes.map(cropType => (
                            <td
                                key={cropType}
                                style={{
                                    backgroundColor: getColorForRecommendation(
                                        relevantData[cropType][index]
                                    ),
                                    padding : ' 0px 10px '
                                }}
                            >
                                {relevantData[cropType][index]}
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
        </>
    );
};

export default SowingData;
