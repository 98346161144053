import React from 'react'

const LeftContainer = () => {
  return (
    <section className='left-container'>
        <img src={'/Logo/PNG/simbol-dark.png'} alt='logo' />
    </section>
  )
}

export default LeftContainer