import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next'

const GeolocationComponent = ({getCoordinates}) => {
  
  const { t } = useTranslation()
  const [location, setLocation] = useState({
    latitude: null,
    longitude: null,
  });

  useEffect(() => {
    getCoordinates(location);
  }, [location]);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setLocation({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
      });
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  }, []);

  return (
    <div>
      <label>{t('geoLocation')}</label>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        {
          location.latitude && location.longitude ? (
        <>
      <p style={{color:'white'}}>{t('latitude')}: {location.latitude}</p>
      <p style={{color:'white'}}>{t('longitude')}: {location.longitude}</p>
        </>
          ) : (
            <p style={{color:'white'}}>{t('Allow permisson to access location')}</p>
          )
        }
      </div>
     
    </div>
  );
};

export default GeolocationComponent;
