import React from 'react'
import NoDataComponent from '../../null/NullData'

const SoilAnalysisList = () => {
  return (
    <div>
        <NoDataComponent/>
    </div>
  )
}

export default SoilAnalysisList