import React, { useEffect, useMemo, useState } from "react";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";

import ReactDOM from "react-dom";
import FarmerTopBar from "../../components/topbar/Topbar";
import "./farm.css";
import { Helmet } from "react-helmet";
import { AiFillCaretLeft, AiFillCaretRight } from "react-icons/ai";
import FarmRegistration from "./FarmRegistration";
import { AiOutlineCloudDownload } from "react-icons/ai";
import { GoLocation } from "react-icons/go";

import { IoAddOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import DrawingMap from "./drawer/drawing";
import MyFarms from "./myFields/MyFarms";
import { onSnapshot } from "firebase/firestore";
import { polygonCollection } from "../../../lib/firestoreCollections";
import { FarmPopup } from "./FarmPopup";
import { API, graphqlOperation } from "aws-amplify";
import { listFarmDetails } from "../../../graphql/queries";
import FetchingData from "../../null/FetchingData";
import NoDataComponent from "../../null/NullData";
import MapImage from "./myFields/MapImage";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const MyFarmsMemoized = React.memo(MyFarms);
const MapImageMemoized = React.memo(MapImage);

const Fields = () => {
  const [polygons, setPolygons] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedPolygon, setSelectedPolygon] = useState(null);
  const [selectedFarm, setSelectedFarm] = useState(null);
  const [geometry, setGeometry] = useState(null);
  const [clickedCoordinates, setClickedCoordinates] = useState(null); // New state for clicked coordinate
  const [filterValue, setFilterValue] = useState("");
  const { t } = useTranslation();

  const handleFilterChange = (event) => {
    setFilterValue(event.target.value); // Update filter value based on user input
  };

  useEffect(() => {
    // const cachedData = sessionStorage.getItem("farmDetails");
    // if (cachedData) {
    //   setPolygons(JSON.parse(cachedData));
    //   setLoading(false);
    // } else {
    fetchFarmDetails();
  }, [selectedFarm]);

  async function fetchFarmDetails() {
    const farmerID = localStorage.getItem("farmerID");

    try {
      const farmDetails = await API.graphql(
        graphqlOperation(listFarmDetails, {
          filter: {
            farmerID: {
              eq: farmerID,
            },
            active: {
              eq: !false,
            },
          },
        })
      );
      const farmDetailsData = farmDetails.data.listFarmDetails.items;
      setPolygons(farmDetailsData);
      sessionStorage.setItem("farmDetails", JSON.stringify(farmDetailsData));
      console.log(farmDetailsData);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  console.log(polygons);

  const awsData = [
    {
      L: [
        {
          M: {
            latitude: { N: "36.946442442861226" },
            longitude: { N: "-0.38337338998298015" },
          },
        },
        {
          M: {
            latitude: { N: "36.95674212547839" },
            longitude: { N: "-0.3906211506166058" },
          },
        },
        {
          M: {
            latitude: { N: "36.94720538464265" },
            longitude: { N: "-0.3913840746326855" },
          },
        },
        {
          M: {
            latitude: { N: "36.946442442861226" },
            longitude: { N: "-0.38337338998298015" },
          },
        },
      ],
    },
  ];
  const formattedData = useMemo(() => {
    return polygons.map((polygon) => {
      if (polygon.rings === undefined) {
        return [];
      }
      const { rings } = polygon;
      const formattedRings = JSON.parse(rings).map((item) => {
        const { latitude, longitude } = item;
        return [latitude, longitude];
      });
      return formattedRings;
    });
  }, [polygons]);

  console.log(formattedData);

  const navigate = useNavigate();
  const [leftWidth, setLeftWidth] = useState(50);
  const [rightWidth, setRightWidth] = useState(50);

  const handleRightArrowClick = () => {
    setLeftWidth(leftWidth + 10);
    setRightWidth(rightWidth - 10);
  };

  const handleLeftArrowClick = () => {
    setLeftWidth(leftWidth - 10);
    setRightWidth(rightWidth + 10);
  };

  const handlePointClick = (coordinates) => {
    setClickedCoordinates(coordinates);
  };

  const handleFarmUpdate = (newFarms) => {
    const newPolygons = polygons.map((polygon) => {
      if (polygon.id === newFarms) {
        return newFarms;
      }
      return polygon;
    });
  };

  function generateExcelData(data) {
    // Fields to exclude
    const excludedFields = [
      "damages",
      "diseases",
      "droneRequests",
      "farmer",
      "issues",
      "operations",
      "createdAt",
      "updatedAt",
      "__typename",
      "id",
      "farmerID",
      "active",
    ];

    // Filter out excluded fields from the header
    const header = Object.keys(data[0]).filter(
      (field) => !excludedFields.includes(field)
    );

    const rows = data.map((item) => {
      // Create a new object without the excluded fields
      const rowData = { ...item };
      excludedFields.forEach((field) => delete rowData[field]);
      return Object.values(rowData);
    });

    return [header, ...rows];
  }

  function handleDownloadClick(data) {
    const excelData = generateExcelData(data);
    const ws = XLSX.utils.aoa_to_sheet(excelData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "buffer" });
    const excelBlob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(excelBlob, "farms.xlsx");
  }

  return (
    <div className="home-container">
      <FarmerTopBar
        filterValue={filterValue}
        handleFilterChange={handleFilterChange}
      />
      <Helmet>
        <link
          rel="stylesheet"
          href="https://js.arcgis.com/4.26/esri/themes/light/main.css"
        />
      </Helmet>

      <section className="mymap-wrapper">
        <div className="left-myMap" style={{ position: "relative" }}>
          <section className="my-fields-header">
            <h2 style={{ margin: 0 }}>{t("myField")}</h2>

            <div>
              <button style={{ marginRight: "5px" }}>
                <AiOutlineCloudDownload
                  onClick={() => handleDownloadClick(polygons)}
                />
              </button>
              <button onClick={() => navigate("/myfarm/create")}>
                <IoAddOutline />
              </button>
            </div>
          </section>
          {!polygons.length && (
            <div className="no-issues" style={{ width: "100%" }}>
              <NoDataComponent />
            </div>
          )}
          <section className="myfield-container">
            {/* <p>You have not created any fields yet</p> */}

            {formattedData
              .filter((polygon, index) =>
                polygons[index].name
                  .toLowerCase()
                  .includes(filterValue.toLowerCase())
              )
              .map((polygon, index) => (
                <div key={index} className="polygon-item">
                  <div style={{ width: "100%" }}>
                    <MapImageMemoized selectedPolygon={polygon} />
                  </div>
                  <div className="package-name plans">
                    <p>{polygons[index].area.toFixed(2)} ha</p>
                  </div>
                  <div
                    className="location-icon"
                    onClick={() => {
                      setSelectedPolygon(polygon);
                    }}
                  >
                    <GoLocation />
                  </div>

                  <div className="polygon-info">
                    <p>
                      {t("farm")}: <strong>{polygons[index].name}</strong>
                    </p>
                    <p>
                      {t("crop")}: <strong>{polygons[index].crop}</strong>
                    </p>

                    <button
                      onClick={() => {
                        setSelectedFarm(polygons[index]);
                        setSelectedPolygon(polygon);
                      }}
                    >
                      {t("seemore")}
                    </button>
                  </div>
                </div>
              ))}
          </section>
          {selectedFarm && (
            <div className="farmpopup myfarms">
              <FarmPopup
                farm={selectedFarm}
                onUpdateFarm={handleFarmUpdate}
                onClose={() => setSelectedFarm(null)}
              />
            </div>
          )}
        </div>

        <div className="right-myMap">
          <MyFarms
            selectedPolygon={selectedPolygon}
            handlePointClick={handlePointClick}
          />
        </div>
      </section>
    </div>
  );
};

export default Fields;
