import React, { useState } from 'react'
import NoDataComponent from '../../null/NullData'
import { listWeatherStations } from '../../../graphql/queries';
import { API, graphqlOperation } from 'aws-amplify';

const StationsList = () => {
  const [stations, setStations] = useState([]);
  const [loading, setLoading] = useState(true);
  React.useEffect(() => {
    fetchStations();
  }, []);
  const fetchStations = async () => {
    try {
      const packagesRequestData = await API.graphql(graphqlOperation(listWeatherStations, {
        filter: {
          farmerID: {
            eq: localStorage.getItem('farmerID'),
          },
        },
      }));
      setStations(packagesRequestData.data.listWeatherStations.items);

      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  }

  

  return (
    <div style={{ display: 'grid', placeItems: 'center', }}>
      {
        //check if the package object is not null
        stations.length >= 1 ? (
          <div className="package-wrapper">
           <NoDataComponent />
          </div>
        ) : stations.length === 0 ? (
          <NoDataComponent />
        ) : null
      }
    </div>
  )
}

export default StationsList