import React, { useEffect, useState } from "react";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { Filesystem, Directory } from "@capacitor/filesystem";

import { AiOutlineCloudDownload } from "react-icons/ai";
import { IoAddOutline } from "react-icons/io5";
import { issuesCollection } from "../../../lib/firestoreCollections";
import { addDoc, doc, onSnapshot } from "firebase/firestore";
import "./issues.css";
import { API, graphqlOperation } from "aws-amplify";
import {
  createIssues,
  deleteIssues,
  updateIssues,
} from "../../../graphql/mutations";
import { listIssues } from "../../../graphql/queries";
import { GiCancel, GiCheckMark } from "react-icons/gi";
import { TiTick } from "react-icons/ti";
import NoDataComponent from "../../null/NullData";
import { toast } from "react-toastify";
import { farmerID } from "../../../userid";
import { fetchIssuesDetails } from "../../fetches/FetchFarmDetails";
import CustomWebcam from "../assets/camera/CustomWebCam";
import GeolocationComponent from "../assets/geoLocator/GeoLocator";
import { useTranslation } from "react-i18next";
import { Capacitor } from "@capacitor/core";
import { FileOpener } from "@capacitor-community/file-opener";

const IssueList = ({
  clickedCoordinates,
  handlePointClick,
  farmDetails,
  onCoordinatesClick,
  setPoly,
}) => {
  const [showInput, setShowInput] = useState(false);
  const [reportDate, setReportDate] = useState("");
  const [issueType, setIssueType] = useState("");
  const [issueNote, setIssueNote] = useState("");
  const [fieldName, setFieldName] = useState("");
  const [fieldID, setFieldID] = useState("");
  const [status, setStatus] = useState("");
  const [coordinates, setCoordinates] = useState([]);
  const [selectedRings, setSelectedRings] = useState([]);
  const [resolved, setResolved] = useState(false);
  const [issues, setIssues] = useState([]);
  const [loading, setLoading] = useState(true);
  const [update, setUpdate] = useState(false);
  const [rings, setRings] = useState("");
  const [image, setImage] = useState("");
  const [selectedIssueType, setSelectedIssueType] = useState("All");
  const [selectedStatus, setSelectedStatus] = useState("All");
  const [showImage, setShowImage] = useState({});
  const [location, setLocation] = useState({
    latitude: null,
    longitude: null,
  });
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 770);
  const { t } = useTranslation();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleFieldSelect = (e) => {
    const selectedValue = JSON.parse(e.target.value);
    if (!selectedValue) {
      console.log("Invalid selected value");
      return;
    }
    onCoordinatesClick(null);

    setFieldName(selectedValue.name);
    setFieldID(selectedValue.id);
    console.log("selectedValue", selectedValue);

    setPoly(JSON.stringify(selectedValue.rings));
  };

  console.log(selectedRings);
  console.log(fieldName);

  useEffect(() => {
    async function fetchData() {
      const issuesData = await fetchIssuesDetails();
      //remove those whose farmDetails.active is false

      setIssues(issuesData);
      setPoly(JSON.stringify(issuesData[0].farmDetails.rings));
      onCoordinatesClick(issuesData[0].coordinates);
      //check if farmDetails field is null, if null delete the issue
      const nullIssues = issuesData.filter((item) => item.farmDetails === null);
      nullIssues.forEach(async (item) => {
        await deleteIssue(item.id);
      });
    }
    fetchData();
  }, [update]);

  console.log(issues);

  const filteredIssues = issues.filter((issue) => {
    const isIssueTypeMatch =
      selectedIssueType === "All" || issue.type === selectedIssueType;
    const isStatusMatch =
      selectedStatus === "All" || issue.status === selectedStatus;
    return isIssueTypeMatch && isStatusMatch;
  });

  const getImageKey = (image) => {
    console.log(image);
    setImage(image);
    toast.success(`image: ${image}`);
  };

  const getCoordinates = (coordinates) => {
    console.log(coordinates);
    setLocation(coordinates);
  };

  const handleSubmit = async () => {
    if (!fieldID) {
      toast.error("Please select a field");
      return;
    }
    try {
      await API.graphql(
        graphqlOperation(createIssues, {
          input: {
            date: reportDate,
            type: issueType,
            notes: issueNote,
            status: "pending",
            farmerID: farmerID,
            fieldName: fieldName,
            farmDetailsID: fieldID,
            coordinates: isMobile
              ? JSON.stringify(location)
              : JSON.stringify(clickedCoordinates),
            image: image,
            gmail: localStorage.getItem("farmerEmail"),
          },
        })
      );
      toast.success(t("issueReported"));
      setUpdate(!update);
      setShowInput(false);
    } catch (error) {
      console.log(error);
    }
  };

  const updateStatus = async (id) => {
    try {
      await API.graphql(
        graphqlOperation(updateIssues, {
          input: { id: id, status: "resolved" },
        })
      );
      toast.success(t("toastMessages3.statusUpdated"));
      setUpdate(!update);
    } catch (error) {
      console.log(error);
    }
  };

  //delete issue
  const deleteIssue = async (id) => {
    try {
      await API.graphql(graphqlOperation(deleteIssues, { input: { id: id } }));
      toast.success(t("issuedeleted"));
      const newIssues = issues.filter((issue) => issue.id !== id);
      setIssues(newIssues);
    } catch (error) {
      console.log(error);
    }
  };

  const handleCardClick = (coordinates) => {
    setCoordinates(coordinates);
  };

  const handleSelectedCoordinates = (coordinates, rings) => (event) => {
    onCoordinatesClick(coordinates);
    setPoly(JSON.stringify(rings));
  };

  function generateExcelData(data) {
    // Fields to exclude
    const excludedFields = [
      "createdAt",
      "updatedAt",
      "__typename",
      "id",
      "farmerID",
      "farmDetailsID",
      "gmail",
      "farmer",
      "farmDetails",
    ];

    // Filter out excluded fields from the header
    const header = Object.keys(data[0]).filter(
      (field) => !excludedFields.includes(field)
    );

    const rows = data.map((item) => {
      // Create a new object without the excluded fields
      const rowData = { ...item };
      excludedFields.forEach((field) => delete rowData[field]);
      return Object.values(rowData);
    });

    return [header, ...rows];
  }

  async function handleDownloadClick(data) {
    const excelData = generateExcelData(data);
    const ws = XLSX.utils.aoa_to_sheet(excelData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const fileName = "issues.xlsx";

    if (Capacitor.isNativePlatform()) {
      // Use Capacitor's Filesystem API and File Opener plugin for mobile
      const base64Data = Buffer.from(excelBuffer).toString("base64");
      const result = await Filesystem.writeFile({
        path: fileName,
        data: base64Data,
        directory: Directory.Documents,
        encoding: "base64",
      });

      await FileOpener.open({
        path: result.uri,
        mode: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
    } else {
      // Use FileSaver.js's saveAs function for web
      const excelBlob = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(excelBlob, fileName);
    }
  }

  return (
    <div style={{}}>
      <section className="my-fields-header">
        <div className="sort-items">
          <h2 style={{ marginTop: 5, marginBottom: 5 }}>{t("my Issues")}</h2>

          <div className="sort-tabs">
            <section>
              <p>{t("type")}</p>
              <select
                name="issue"
                id="issue"
                onChange={(e) => setSelectedIssueType(e.target.value)}
              >
                <option>{t("all")}</option>
                <option value="Dead plants">{t("dead plants")}</option>
                <option value="Pest infestation">
                  {t("pest infestation")}
                </option>
                <option value="Crop damage">
                  {t("crop damage (e.g., hail, storms)")}
                </option>
                <option value="Nutrient deficiency in soil">
                  {t("nutrient deficiency in soil")}
                </option>
                <option value="Weed">{t("weed")}</option>
                <option value="Broken wire">{t("broken wire")}</option>
                <option value="Irrigation system malfunction">
                  {t("irrigation system malfunction")}
                </option>
                <option value="Plant diseases">{t("plant diseases")}</option>
                <option value="Waterlogging">
                  {t("waterlogging (excessive water accumulation in soil)")}
                </option>
                <option value="Salinity">{t("salinity in Soil")}</option>
                <option value="Soil Erosion">{t("soil Erosion")}</option>
                <option value="Soil Compaction">{t("soil Compaction")}</option>
                <option value="Animal Damage">
                  {t("animal Damage (e.g., rodents)")}
                </option>
                <option value="Fertilizer or Pesticide Application Issues">
                  {t("fertilizer or Pesticide Application Issues")}
                </option>
                <option value="Broken Pole">{t("broken Pole")}</option>
                <option value="Soil or Water Pollution">
                  {t("soil or Water Pollution")}
                </option>
                <option value="Equipment Malfunction">
                  {t("equipment Malfunction")}
                </option>
                <option value="Inefficient Water Usage">
                  {t("inefficient Water Usage")}
                </option>
                <option value="Crop Maturity Assessment">
                  {t("crop Maturity Assessment")}
                </option>
                <option value="Harvest Quality Assessment">
                  {t("harvest Quality Assessment")}
                </option>
                <option value="Storage Facility Issues">
                  {t("storage Facility Issues")}
                </option>
                <option value="Equipment Maintenance Needs">
                  {t("equipment Maintenance Needs")}
                </option>
                <option value="Energy or Power Supply Problems">
                  {t("energy or Power Supply Problems")}
                </option>
                <option value="Weather-Related Damage or Risks">
                  {t("weather-Related Damage or Risks")}
                </option>
                <option value="Livestock Health Concerns">
                  {t("livestock Health Concerns")}
                </option>
                <option value="Compliance with Regulatory Standards">
                  {t("compliance with Regulatory Standards")}
                </option>
                <option value="Crop Rotation Planning">
                  {t("crop Rotation Planning")}
                </option>
              </select>
            </section>

            <section>
              <p>{t("status")}</p>
              <select
                name="status"
                id="status"
                onChange={(e) => setSelectedStatus(e.target.value)}
              >
                <option>{t("all")}</option>
                <option value="pending">{t("pending")}</option>
                <option value="resolved">{t("resolved")}</option>
              </select>
            </section>
          </div>
        </div>

        <div className="add-download-buttons">
          <button style={{ marginRight: "5px" }}>
            <AiOutlineCloudDownload
              onClick={async () => await handleDownloadClick(issues)}
            />
          </button>
          <button>
            <IoAddOutline onClick={() => setShowInput(true)} />
          </button>
        </div>
      </section>

      {issues.length === 0 && <NoDataComponent />}
      <section className="issues-section">
        {filteredIssues.map(
          (issue, index) =>
            issue.farmDetails && (
              <div
                key={index}
                style={{
                  margin: "1em 0",
                  borderRadius: "15px",
                  boxShadow: "0 5px 15px rgba(0,0,0,0.1)",
                  backgroundColor: "#fff",
                  padding: "1em",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div
                      onClick={handleSelectedCoordinates(
                        issue.coordinates,
                        issue.farmDetails.rings
                      )}
                      style={{
                        width: "120px",
                        height: "100px",
                        marginRight: "1em",
                        borderRadius: "10px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor:
                          issue.status === "resolved"
                            ? "#008000a3"
                            : "#ff000069",
                        padding: "0 3px",
                      }}
                    >
                      <div
                        style={{
                          fontFamily: "Arial, sans-serif",
                          fontSize: "1.2em",
                          color: "white",
                          textAlign: "center",
                        }}
                      >
                        <p>{issue.type}</p>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        flex: 1,
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <p style={{ margin: "0.5em 0", color: "#333" }}>
                          {t("farm")}:<strong> {issue.farmDetails.name}</strong>
                        </p>
                        <p style={{ marginBottom: "0.5em", color: "#333" }}>
                          {t("report Date")}:<strong> {issue.date}</strong>
                        </p>
                      </div>
                      <p style={{ marginBottom: "0.5em", color: "#333" }}>
                        {t("note")}: {issue.notes}
                      </p>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <p style={{ marginBottom: "0.5em", color: "#333" }}>
                          {t("status")}:{" "}
                          <strong
                            style={{
                              color:
                                (issue.status || resolved === issue.id) ===
                                "resolved"
                                  ? "green"
                                  : "#F67B00",
                            }}
                          >
                            {resolved === issue.id ? "resolved" : issue.status}
                          </strong>
                        </p>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            gap: "1em",
                          }}
                        >
                          <GiCheckMark
                            onClick={() => {
                              updateStatus(issue.id);
                              setResolved(issue.id);
                            }}
                            style={{ color: "green", cursor: "pointer" }}
                          />

                          <GiCancel
                            onClick={() => {
                              deleteIssue(issue.id);
                            }}
                            style={{ color: "red", cursor: "pointer" }}
                          />
                          {issue.status === "Resolved" && (
                            <TiTick
                              style={{ color: "blue", cursor: "pointer" }}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  {issue.image && (
                    <button
                      onClick={() =>
                        setShowImage((prevState) => ({
                          ...prevState,
                          [issue.id]: !prevState[issue.id],
                        }))
                      }
                      style={{
                        width: "100%",
                        height: "40px",
                        backgroundColor: "#F67B00",
                        color: "#fff",
                        border: "none",
                        borderRadius: "5px",
                        marginTop: "1em",
                      }}
                    >
                      {t("view Image")}
                    </button>
                  )}
                  {showImage[issue.id] && (
                    <img
                      src={`https://officialagrogis.s3.amazonaws.com/${issue.image}`}
                      alt={issue.type}
                      style={{
                        width: "100%",
                        height: "auto",
                        marginTop: "1em",
                      }}
                    />
                  )}
                </>
              </div>
            )
        )}
      </section>
      {showInput ? (
        <div className="farmpopup">
          <h2>{t("enter Issue")}</h2>
          <div className="form-group">
            <label htmlFor="issue">{t("report on")}</label>
            <input
              type="date"
              placeholder="Enter issue"
              onChange={(e) => setReportDate(e.target.value)}
            />
          </div>

          <div className="form-group">
            <label htmlFor="issue">{t("field Name")} </label>
            <select name="field" id="field" onChange={handleFieldSelect}>
              <option>{t("select")}</option>

              {farmDetails &&
                farmDetails.map((item, index) => (
                  <option key={index} value={JSON.stringify(item)}>
                    {item.name}
                  </option>
                ))}
            </select>
          </div>

          {isMobile ? (
            <GeolocationComponent getCoordinates={getCoordinates} />
          ) : (
            <div className="form-group">
              <button style={{ marginBottom: "10px" }}>
                {t("pick a point in map")}
              </button>
            </div>
          )}
          {isMobile && <CustomWebcam getImageKey={getImageKey} />}
          <div className="form-group">
            <label htmlFor="issue">{t("type")}</label>
            <select
              name="issue"
              id="issue"
              onChange={(e) => setIssueType(e.target.value)}
            >
              <option>{t("select")}</option>
              <option value="Dead plants">{t("dead plants")}</option>
              <option value="Pest infestation">{t("pest infestation")}</option>
              <option value="Crop damage">
                {t("crop damage (e.g., hail, storms)")}
              </option>
              <option value="Nutrient deficiency in soil">
                {t("nutrient deficiency in soil")}
              </option>
              <option value="Weed">{t("weed")}</option>
              <option value="Broken wire">{t("broken wire")}</option>
              <option value="Irrigation system malfunction">
                {t("irrigation system malfunction")}
              </option>
              <option value="Plant diseases">{t("plant diseases")}</option>
              <option value="Waterlogging">
                {t("waterlogging (excessive water accumulation in soil)")}
              </option>
              <option value="Salinity">{t("salinity in Soil")}</option>
              <option value="Soil Erosion">{t("soil Erosion")}</option>
              <option value="Soil Compaction">{t("soil Compaction")}</option>
              <option value="Animal Damage">
                {t("animal Damage (e.g., rodents)")}
              </option>
              <option value="Fertilizer or Pesticide Application Issues">
                {t("fertilizer or Pesticide Application Issues")}
              </option>
              <option value="Broken Pole">{t("broken Pole")}</option>
              <option value="Soil or Water Pollution">
                {t("soil or Water Pollution")}
              </option>
              <option value="Equipment Malfunction">
                {t("equipment Malfunction")}
              </option>
              <option value="Inefficient Water Usage">
                {t("inefficient Water Usage")}
              </option>
              <option value="Crop Maturity Assessment">
                {t("crop Maturity Assessment")}
              </option>
              <option value="Harvest Quality Assessment">
                {t("harvest Quality Assessment")}
              </option>
              <option value="Storage Facility Issues">
                {t("storage Facility Issues")}
              </option>
              <option value="Equipment Maintenance Needs">
                {t("equipment Maintenance Needs")}
              </option>
              <option value="Energy or Power Supply Problems">
                {t("energy or Power Supply Problems")}
              </option>
              <option value="Weather-Related Damage or Risks">
                {t("weather-Related Damage or Risks")}
              </option>
              <option value="Livestock Health Concerns">
                {t("livestock Health Concerns")}
              </option>
              <option value="Compliance with Regulatory Standards">
                {t("compliance with Regulatory Standards")}
              </option>
              <option value="Crop Rotation Planning">
                {t("crop Rotation Planning")}
              </option>
              <option value="Other">
                {t("other (to cover unforeseen issues)")}
              </option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="issue">{t("note")}</label>
            <textarea
              style={{ color: "#fff" }}
              name="note"
              id="note"
              cols="30"
              rows="10"
              onChange={(e) => setIssueNote(e.target.value)}
            ></textarea>
          </div>
          <div className="farmpopup__buttons">
            <button onClick={() => setShowInput(false)}>{t("cancel")}</button>
            <button onClick={handleSubmit}>{t("submit")}</button>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default IssueList;
