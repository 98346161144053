import React from 'react'
import './nav.css'
// import { AiOutlineHome, AiOutlineInbox, AiOutlineCalendar, AiOutlineUser, AiOutlineSettings, AiOutlineChrome, AiOutlineMedicineBox, AiOutlineUserAdd } from 'react-icons/ai';
import {GrOverview, GrUserAdmin} from 'react-icons/gr'
import {AiOutlineCloudUpload,} from 'react-icons/ai'
import {MdOutlineCrisisAlert, MdOutlineManageAccounts} from 'react-icons/md'
import {HiOutlineDocumentReport} from 'react-icons/hi'
import {TiMessages, TiWeatherSunny} from 'react-icons/ti'
import {FaNewspaper} from 'react-icons/fa'
import {SlDocs} from 'react-icons/sl'
import {TbComponents} from 'react-icons/tb'
import {FiHelpCircle} from 'react-icons/fi'
import { NavLink } from 'react-router-dom'
import { SiHtmlacademy } from 'react-icons/si'
import { IoPlanetOutline } from 'react-icons/io5'
const Sidebar = () => {
  return (
    <div className='sidebar-container'>
       <div className='sidebar-logo'>
       <img src={'/Logo/PNG/fulllogosidebar.png'} alt='logo' />
       </div>
       <div className="tabs">
        <NavLink to='/dashboard' className="tab">
        
             <GrOverview/>
        
          <span>Overview</span>
        </NavLink>
       
        <NavLink to='/sales' className="tab">
          <MdOutlineCrisisAlert />
          <span>Packages</span>
        </NavLink>

        <NavLink to='/planetrequests' className="tab">
          <IoPlanetOutline />
          <span>Planet Requests</span>
        </NavLink>
        <NavLink to='/farms' className="tab">
          <TiMessages />
          <span>Farms</span>
        </NavLink>
        <NavLink to='/accounts' className="tab">
          <MdOutlineManageAccounts />
          <span>Accounts</span>
        </NavLink>
        <NavLink to='/upload' className="tab">
          <AiOutlineCloudUpload />
          <span>Upload Data</span>
        </NavLink>
        {/* <NavLink to='/docs' className="tab">
          <SlDocs />
          <span>Docs</span>
        </NavLink> */}
        <NavLink to='/components' className="tab disabled" disabled>
          <TbComponents />
          <span>Sales</span>
        </NavLink>
        <NavLink  to='/help' className="tab disabled" disabled>
          <HiOutlineDocumentReport />
          <span>Reports</span>
        </NavLink>
        <NavLink  to='/invoice' className="tab">
          <TiMessages />
          <span>Invoices</span>
        </NavLink>
        {/* <NavLink  to='/help' className="tab">
          <TiMessages />
          <span>Messages</span>
        </NavLink> */}

        <NavLink  to='/weather-station' className="tab">
          <TiWeatherSunny />
          <span>Weather Stations</span>
        </NavLink>

        <NavLink  to='/academy' className="tab">
          <SiHtmlacademy />
          <span>Academy Uploads</span>
        </NavLink>

        <NavLink  to='/news' className="tab">
          <FaNewspaper />
          <span>News Uploads</span>
        </NavLink>

        <NavLink  to='/add/admin' className="tab">
          <GrUserAdmin />
          <span>Add Admin</span>
        </NavLink>
      </div>
    </div>
  )
}

export default Sidebar