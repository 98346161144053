import React from 'react';
import { RiLoader5Line } from 'react-icons/ri';
import { GiEmptyMetalBucketHandle } from 'react-icons/gi';
import './Fetch.css';
import { useTranslation } from 'react-i18next'

const FetchingData = () => {
  const { t } = useTranslation()
  return (
    <div className="no-data-animation">
      <div className="animation-container">
        <div className="loading-icon">
          <RiLoader5Line className="loading-spin" />
        </div>
        <div className="text-container">
        
          <p>{t('please wait while we fetch the data for you')}.</p>
        </div>
        <div className="bucket-icon">
          <GiEmptyMetalBucketHandle className="bucket-swing" />
        </div>
      </div>
    </div>
  );
};

export default FetchingData;
