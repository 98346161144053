import React, { useEffect, useMemo, useState } from 'react';
import { MapContainer, Polygon, TileLayer, useMap } from 'react-leaflet';
import { useRef } from 'react';
import 'leaflet/dist/leaflet.css';
import GeoRasterLayer from 'georaster-layer-for-leaflet';
import L from 'leaflet';
import parse_georaster from 'georaster';
import isEqual from 'lodash.isequal';
import * as d3 from 'd3'
import axios from 'axios';
import { useTranslation } from 'react-i18next'


// Custom component to create GeoRasterLayer
const GeoRasterLayerComponent = ({
    georaster,
    polygonCoordinates,
    timeRange,
    setColorPercentages,
    ndwiData,
    msaviData,
    ndreData,
  }) => {
    const map = useMap();
    const layerRef = useRef();
  
    useEffect(() => {
      if (georaster) {
        if (layerRef.current) {
          map.removeLayer(layerRef.current);
          layerRef.current = null;
        }
  
        const layer = new GeoRasterLayer({
          georaster,
          opacity: 1,
          pixelValuesToColorFn: (values) => {
            const ndvi = values[0];
            const ndwi = values[0];
            const msavi = values[0];
            const ndre = values[0];
            let color = 'transparent';

            const ndwiColorScale = d3
            .scaleSequential(d3.interpolateBlues) // Blue color scale
            .domain([-1, 1]); 
          

            const ndviColorScale = d3
.scaleSequential(d3.interpolateRdYlGn) // Use a green-to-red color scale
.domain([0, 1]);


            if (ndwiData) {
              if (ndwi >= -1 && ndwi <= 1) {
                color = ndwiColorScale(ndwi);
              }
            }else if (msaviData) {
              if (msavi >= -1 && msavi <= 1) {
                if (msavi >= 0 && msavi <= 0.0025) {
                  color = 'transparent';
                } else {
                  color = ndviColorScale(msavi);
                }
              }
            }else if (ndreData) {
              if (ndre >= -1 && ndre <= 1) {
                color = ndviColorScale(ndre);
              }
            } else {
              if (ndvi >= 0 && ndvi <= 1) {
                color = ndviColorScale(ndvi);
              }
            }

// color = ndviColorScale(ndvi);


//using manual color scale
// if (ndvi < -1 || ndvi > 1) {
//   color =  'transparent'; // Transparent for values outside -1 to 1
// } else if (ndvi < 0.05) {
//   color =  '#C52F1D'; // Non-vegetated areas (pale yellow)
// } else if (ndvi < 0.1) {
//   color =  '#D0482D'; // Sparse vegetation (light yellow)
// } else if (ndvi < 0.15) {
//   color =  '#D3472B'; // Grasslands or mountainous areas (medium yellow)
// } else if (ndvi < 0.2) {
//   color =  '#E0774D'; // Low vigor in this field (light orange)
// } else if (ndvi < 0.25) {
//   color =  '#E48F5E'; // Low-medium vigor in this field (orange)
// } else if (ndvi < 0.3) {
//   color =  '#F0A573'; // Medium-low vigor in this field (dark orange)
// } else if (ndvi < 0.35) {
//   color =  '#F4C081'; // Medium vigor in this field (green)
// } else if (ndvi < 0.4) {
//   color =  '#FCD894'; // Medium-high vigor in this field (dark green)
// } else if (ndvi < 0.45) {
//   color =  '#FFF2B6'; // High-medium vigor in this field (dark green)
// } else if (ndvi < 0.5) {
//   color =  '#F6F9BB'; // Highest vigor in this field (very dark green)
// } else if (ndvi < 0.55) {
//   color =  '#F6F9BB'; // Highest vigor in this field (very dark green)
// } else if (ndvi < 0.6) {
//   color =  '#C8E392'; // Highest vigor in this field (very dark green)
// } else if (ndvi < 0.65) {
//   color =  '#B1D77E'; // Highest vigor in this field (very dark green)
// } else if (ndvi < 0.7) {
//   color =  '#9DCC6F'; // Highest vigor in this field (very dark green)
// } else if (ndvi < 0.75) {
//   color =  '#86BF60'; // Highest vigor in this field (very dark green)
// } else if (ndvi < 0.8) {
//   color =  '#75B452'; // Highest vigor in this field (very dark green)
// } else if (ndvi < 0.85) {
//   color =  '#5EA63F'; // Highest vigor in this field (very dark green)
// } else if (ndvi < 0.9) {
//   color =  '#4B9D2F'; // Highest vigor in this field (very dark green)
// } else if (ndvi <= 1) {
//   color =  '#389123'; // Highest vigor in this field (very dark green)
// }

  

const ndviColorRanges = [
  { range: [-1, 0.05], color: '#C52F1D' },
  { range: [0.05, 0.10], color: '#C5142A' },
  { range: [0.10, 0.15], color: '#E02D2C' },
  { range: [0.15, 0.20], color: '#EF4C3A' },
  { range: [0.20, 0.25], color: '#FE6C4A' },
  { range: [0.25, 0.30], color: '#FF8D5A' },
  { range: [0.30, 0.35], color: '#FFAB69' },
  { range: [0.35, 0.40], color: '#FFC67D' },
  { range: [0.40, 0.45], color: '#FFE093' },
  { range: [0.45, 0.50], color: '#FFEFAB' },
  { range: [0.50, 0.55], color: '#FDFEC2' },
  { range: [0.55, 0.60], color: '#EAF7AC' },
  { range: [0.60, 0.65], color: '#D5EF94' },
  { range: [0.65, 0.70], color: '#B9E383' },
  { range: [0.70, 0.75], color: '#9BD873' },
  { range: [0.75, 0.80], color: '#77CA6F' },
  { range: [0.80, 0.85], color: '#53BD6B' },
  { range: [0.85, 0.90], color: '#14AA60' },
  { range: [0.90, 0.95], color: '#009755' },
  { range: [0.95, 1.00], color: '#007E47' },
];



setColorPercentages(prevColorPercentages => {
  if(ndreData){
  
    const newColorPercentages = { ...prevColorPercentages };

    if (color !== 'transparent') {
      for (const { range, color } of ndviColorRanges) {
        // Initialize counts to 0 for colors that don't exist in prevColorPercentages
        if (newColorPercentages[color] === undefined) {
          newColorPercentages[color] = 0;
        }
        
        const [min, max] = range;
        if (ndre >= min && ndre <= max) {
          newColorPercentages[color] += 1;
          // break; // Exit the loop after finding the matching range
        }
      }
    }
    
    // Remove any properties with undefined or null values
    for (const color in newColorPercentages) {
      if (newColorPercentages[color] === undefined || newColorPercentages[color] === null) {
        delete newColorPercentages[color];
      }
    }
    

  return newColorPercentages;
}else if(ndwiData){
    const newColorPercentages = { ...prevColorPercentages };
    if (color !== 'transparent') {
      if (ndwi >= -1 && ndwi < -0.3) {
        newColorPercentages['#E0ECF7'] = (newColorPercentages['#E0ECF7'] || 0) + 1;
      }
      else if (ndwi >= -0.3 && ndwi < 0.0) {
        newColorPercentages['#A5CCE4'] = (newColorPercentages['#A5CCE4'] || 0) + 1;
      } else if (ndwi >= 0 && ndwi < 0.2) {
        newColorPercentages['#66ED00'] = (newColorPercentages['#66ED00'] || 0) + 1;
      } else if (ndwi >= 0.2 && ndwi < 1) {
        newColorPercentages['#007E00'] = (newColorPercentages['#007E00'] || 0) + 1;
      } 
    }
    return newColorPercentages;
  }
  else if(msaviData){
    const newColorPercentages = { ...prevColorPercentages };

      if (color !== 'transparent') {
        for (const { range, color } of ndviColorRanges) {
          // Initialize counts to 0 for colors that don't exist in prevColorPercentages
          if (newColorPercentages[color] === undefined) {
            newColorPercentages[color] = 0;
          }
          
          const [min, max] = range;
          if (msavi >= min && msavi <= max) {
            newColorPercentages[color] += 1;
            // break; // Exit the loop after finding the matching range
          }
        }
      }
      
      // Remove any properties with undefined or null values
      for (const color in newColorPercentages) {
        if (newColorPercentages[color] === undefined || newColorPercentages[color] === null) {
          delete newColorPercentages[color];
        }
      }
      

    return newColorPercentages;
    } 
  else {
  const newColorPercentages = { ...prevColorPercentages };   

  if (color !== 'transparent') {
    for (const { range, color } of ndviColorRanges) {
      // Initialize counts to 0 for colors that don't exist in prevColorPercentages
      if (newColorPercentages[color] === undefined) {
        newColorPercentages[color] = 0;
      }
      
      const [min, max] = range;
      if (ndvi >= min && ndvi <= max) {
        newColorPercentages[color] += 1;
      }
    }
  }
  
  // Remove any properties with undefined or null values
  for (const color in newColorPercentages) {
    if (newColorPercentages[color] === undefined || newColorPercentages[color] === null) {
      delete newColorPercentages[color];
    }
  }
  return newColorPercentages;
}
});



          return color;
          },
          resolution: 100,
        });

        layer.addTo(map);
        map.fitBounds(layer.getBounds());
  
        if (polygonCoordinates.length > 0) {
          const polygonCenter = polygonCoordinates.reduce(
            (prev, curr) => [prev[0] + curr[0], prev[1] + curr[1]],
            [0, 0]
          ).map((coordinate) => coordinate / polygonCoordinates.length);
  
          // map.setView([polygonCenter[1], polygonCenter[0]], 14);
  
          const latLngs = polygonCoordinates.map((coord) => [coord[1], coord[0]]);
          const polygon = L.polygon(latLngs, { color: 'yellow', fill: false }).addTo(
            map
          );
        }
  
        layerRef.current = layer;
      }
  
      return () => {
        if (layerRef.current) {
          map.removeLayer(layerRef.current);
        }
      };
    }, [map, timeRange]);
  
    return null;
  };
  
  
// Main component
const PlanetMapComponent = ({ byoc, token, selectedPolygon,  onFilteredColorPercentages , parentFilteredColorPercentages, fetchMetaData, cloudCover, ndmiData,ndreData,ndwiData,msaviData,evapoData, updateData, showedDate, lateShowedDate, subscriptionID, shouldDisplayWeather}) => {
  const [loading, setLoading] = useState(false);
  const [georaster, setGeoraster] = useState(null);
  const [colorPercentages, setColorPercentages] = useState({});
  const [catalogData, setCatalogData] = useState({});
  const [subscriptionData, setSubscriptionData] = useState({});
  const [showSubscriptionInfo, setShowSubscriptionInfo] = useState(false);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const { t } = useTranslation()

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const style = {
    height: windowWidth < 770 ? '50vh' : '100vh',
    width: '100%',
  };

  
  const url = 'https://services.sentinel-hub.com/api/v1/process';

  const timeRange = useMemo(() => {
    if (showedDate && lateShowedDate){
      return { from: lateShowedDate + 'T00:00:00Z',
         to: showedDate + 'T23:59:59Z'
        };
    }else{
      return { from: '2022-05-01T00:00:00Z', to: '2022-05-31T23:59:59Z' };
    }
  }, [showedDate, lateShowedDate]);

  const polygonCoordinates = selectedPolygon ? selectedPolygon.map(point => [point[0], point[1]]) : [];
  console.log(polygonCoordinates);
  let evalscript = ''; 

  const fetchData = async () => {
    const accessToken = token;
    const requestData = new FormData();
   
  
    if(ndwiData){
      evalscript = `//VERSION=3
      function setup() {
        return {
          input: [{ bands: ['Green', 'NIR'] }], // Adjust the band names
          output: [{ id: 'default', bands: 1, sampleType: 'FLOAT32' }],
        };
      }
      
      function evaluatePixel(sample) {
        let ndwi = (sample.Green - sample.NIR) / (sample.Green + sample.NIR); // Adjust the band names
        return { default: [ndwi] };
      }
      `;
    }else if(msaviData){
      evalscript = `//VERSION=3
      function setup() {
        return {
          input: [{ bands: ['NIR', 'Red'] }], // Adjust the band names
          output: [{ id: 'default', bands: 1, sampleType: 'FLOAT32' }],
        };
      }

      function evaluatePixel(sample) {
        let msavi = (2 * sample.NIR + 1 - Math.sqrt(Math.pow((2 * sample.NIR + 1), 2) - 8 * (sample.NIR - sample.Red))) / 2; // Adjust the band names
        return { default: [msavi] };
      }
      `;
    }else if(ndreData){
      evalscript = `//VERSION=3
      function setup() {
        return {
          input: [{ bands: ['NIR', 'Red'] }], // Adjust the band names
          output: [{ id: 'default', bands: 1, sampleType: 'FLOAT32' }],
        };

      }

      function evaluatePixel(sample) {
        let ndre = (sample.NIR - sample.Red) / (sample.NIR + sample.Red); // Adjust the band names
        return { default: [ndre] };
      }
      `;
    }else {

    
     evalscript =`
     //VERSION=3
     function setup() {
       return {
         input: [{ bands: ['NIR', 'Red'] }], // Adjust the band names
         output: [{ id: 'default', bands: 1, sampleType: 'FLOAT32' }],
       };
     }
     
     function evaluatePixel(sample) {
       let ndvi = (sample.NIR - sample.Red) / (sample.NIR + sample.Red); // Adjust the band names
       return { default: [ndvi] };
     }
     `;

    }
    
  
    requestData.append(
      'request',
      JSON.stringify({
        input: {
          bounds: {
            properties: { crs: 'http://www.opengis.net/def/crs/OGC/1.3/CRS84' },
            geometry: {
              type: 'Polygon',
              coordinates: [polygonCoordinates],
            },
          },
          data: [
            {
              type: `byoc-${byoc}`,
              dataFilter: { timeRange: 
                { from: timeRange.from, to: timeRange.to }
             },
            },
          ],
        },
        output: { responses: [{ identifier: 'default', format: { type: 'image/tiff' } }],  },
      })
    );
    requestData.append('evalscript', evalscript);
    requestData.append('config', "3f3ae38b-897b-46a5-8136-0cbb5d8df6a3")
  

    const requestOptions = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      body: requestData,
    };
    try {
      const response = await fetch(url, requestOptions);
      console.log(response);
      console.log('dvfd');
      if (response.ok) {
        const arrayBuffer = await response.arrayBuffer();
        console.log(arrayBuffer);
        const georaster = await parse_georaster(arrayBuffer);
        console.log(georaster);
        console.log('georaster');
        return georaster;
      } else {
        console.log('Error:', response.status, response.statusText);
        return null;
      }
      } catch (error) {
        console.log('Error:', error);
        return null;
      }
    };
  

  useEffect(() => {
    if (!selectedPolygon) return;
  

    // Reset georaster and colorPercentages when selectedDate changes
    setGeoraster(null);
    setColorPercentages({});
  
    if (!token || !polygonCoordinates) return;

    setLoading(true);
    fetchData().then(georaster => {


      setGeoraster(georaster);
      console.log(georaster);

      fetchMetaData()
      setColorPercentages({});
      setLoading(false);
      
    })
    .catch(error => {
      console.log(error);
      setLoading(false);
    }
    )
    .finally(() => setLoading(false));
  }, [selectedPolygon, timeRange, ndmiData,ndreData,ndwiData,msaviData,evapoData, updateData]);

  const filteredColorPercentages = Object.entries(colorPercentages).reduce((filtered, [color, count]) => {
    if (color !== 'transparent') {
      filtered[color] = count;
    }
    return filtered;
  }, {});



  useEffect(() => {
    if (!isEqual(filteredColorPercentages, parentFilteredColorPercentages)) {

      onFilteredColorPercentages(filteredColorPercentages);
    }
  }, [filteredColorPercentages, parentFilteredColorPercentages]);

  useEffect(() => {
    if (!subscriptionID) return;
    const accessToken = token;
    getSubscriptionInfo(accessToken, subscriptionID);
  }, [subscriptionID]);
  


  const getSubscriptionInfo = async (accessToken, subscriptionId) => {
    const url = `https://services.sentinel-hub.com/api/v1/dataimport/subscriptions/${subscriptionId}`
  
    const options = {
      headers: {
        'Authorization': `Bearer ${accessToken}`
      }
    };
  
    try {
      const response = await axios.get(url, options);
      console.log(response.data);
      setSubscriptionData(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const FarmInfo = ()=>{
    return(
      <div className="farm-info"
      style={{
        position: 'absolute',
        top: '30px',
        right: '0',
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        padding: '10px',
        borderRadius: '5px',
        border: '1px solid #ccc',
        fontSize: '14px',
        width: '200px',
        zIndex:99999
      }}>
      <h3>{t('farm Information')}</h3>
      <p>{t('area')}: {subscriptionData.sqkm} km<sup>2</sup></p>
      <p>{t('status')}: {subscriptionData.status}</p>
      <p>{t('created')}: {new Date(subscriptionData.created).toDateString()}</p>
      <p>{t('confirmed')}: {new Date(subscriptionData.confirmed).toDateString()}</p>
      <p>{t('provider')}: {subscriptionData.provider}</p>
      </div>
    )
  }
  
console.log(subscriptionData);

  return (
    <>
         {loading && (
          <div className="loading-image-overlay">
            <div className="loading-text">{t('loading')}...</div>
          </div>
        )}

{
          !georaster && !loading  && shouldDisplayWeather &&(
            <div className="loading-image-overlay">
              <div className="visual-wait">{t('vizualization will appear here upon selecting a farm')}.</div>
            </div>
          )}
       

        {
          georaster && georaster.mins[0] === undefined && (
            <div className="loading-image-overlay">
              <div className="loading-text">{t('no data available for this date')}</div>
            </div>
          )
        }
        
      <MapContainer
        
       center={[44.105634049099756, 26.318226777781327]} zoom={7} style={style}>
   
        <TileLayer
          url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
          attribution="Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community"
        />

        {georaster && (
          <>
            <GeoRasterLayerComponent
              georaster={georaster}
              polygonCoordinates={polygonCoordinates}
              setColorPercentages={setColorPercentages}
              timeRange={timeRange}
              ndwiData= {ndwiData}
              msaviData= {msaviData}
              ndreData= {ndreData}

           
            />
            
          </>
        )}
        <button
        className="subscription-info-button"
        onClick={() => setShowSubscriptionInfo(!showSubscriptionInfo)}
        style={{
          position: 'absolute',
          top: '0',
          right: '0',
          width: '200px',
          height: '30px',
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
          padding: '10px',
          borderRadius: '5px',
          border: '1px solid #ccc',
          fontSize: '14px',
          zIndex:99999
        }}
      >
        {showSubscriptionInfo ? 'Hide' : 'Show'}  {('info')}
      </button>
        {showSubscriptionInfo && subscriptionData && <FarmInfo />}
        {/* {polygonCoordinates.length > 0 && <Polygon positions={polygonCoordinates.map(coord => [coord[1], coord[0]])} color="red" />} */}
      </MapContainer>


    </>
  );
};

export default PlanetMapComponent;
