import React, { useEffect, useState } from 'react'
import FarmerTopBar from '../../components/topbar/Topbar'
import { BiArrowBack } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom'
//import params to get id from url
import { useParams } from 'react-router-dom'
import { listAcademies } from '../../../graphql/queries'
import { API, graphqlOperation } from 'aws-amplify'
import { useTranslation } from 'react-i18next'

const CourseDetails = () => {
  const { t } = useTranslation()
  const { id } = useParams()
  const navigate = useNavigate()

  const [clickedCourse, setClickedCourse] = useState(null)

  const fetchCourse = async () => {
    try {
      const courseData = await API.graphql(
        graphqlOperation(listAcademies, {
          filter: { id: { eq: id } },
        })
      )
      const course = courseData.data.listAcademies.items[0]
      setClickedCourse(course)
    } catch (error) {
      console.log('Error on fetching course', error)
    }
  }

  console.log(clickedCourse)

  useEffect(() => {
    fetchCourse()
  }, [])

  const videoUrl = clickedCourse && clickedCourse.video
  return (
    <div className="home-container">
      <FarmerTopBar />
      <div className="video-page-container">
        <section className="video-description-section">
          <div className="subtopic-list">
          <section className='flex justify-between items-center'>

            <h2>{t('title')}</h2>
            <buttton onClick={()=>navigate(-1)} className='bg-tertiary text-white p-1 rounded cursor-pointer'> {t('close')}</buttton>

            </section>
            <hr />
            {clickedCourse && clickedCourse.title}
          </div>

          <div className="subtopic-list">
            <h2>{t('description')}</h2>
            <hr />
            {clickedCourse && clickedCourse.description}
          </div>

          <div className="subtopic-list">
            <h2>{t('topics')}</h2>
            <hr />

            <ul>
              {clickedCourse &&
                clickedCourse.tags.map((topic, index) => (
                  <li key={index}>{topic}</li>
                ))}
            </ul>
          </div>
        </section>
        <section className="video-details">
          <div className="video-title">
            <h2>{clickedCourse && clickedCourse.title}</h2>
          </div>

          <div className="video-description">
            <p>{clickedCourse && clickedCourse.description}</p>
          </div>
          <div className="video-player">
            <iframe
              width="100%"
              height="480"
              src={videoUrl}
              title="Wheat Harvest at Crossroad Farms in Indiana"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </div>
        </section>
      </div>
      {/* <div className="close-btn" onClick={() => navigate(-1)}>
        <BiArrowBack />
      </div> */}
    </div>
  )
}

export default CourseDetails
