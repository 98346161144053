import React from 'react'
import { API, graphqlOperation } from 'aws-amplify';
import { useState, useEffect } from 'react';

import FarmerTopBar from '../components/topbar/Topbar'
import './news.css'
import { Link } from 'react-router-dom';
import { listNews } from '../../graphql/queries';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // import styles
import { fetchNews } from '../fetches/FetchFarmDetails';
import FetchingData from '../null/FetchingData';
import { useTranslation } from 'react-i18next'


const News = () => {
   const { t } = useTranslation()
const [news, setNews] = useState([]);
const [loading, setLoading] = useState(true);


  useEffect(() => {
    const fetchData = async ()=>{
      const newsData = await fetchNews();
      setNews(newsData);
      setLoading(false);
    }
    fetchData();
    }, []);

  

  
  return (
    <div className='home-container'>
        <FarmerTopBar />
        <div className='pages-container'>
        <h2 className='news-title' style={{paddingLeft:'10px'}}>{t('News Feed')}</h2>
        <section className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4'>
          {
            loading ? (
                <FetchingData />
            ):
            news.length === 0 ? <h2>{t('no news available')}</h2> : null
          }
        {news.map((item) => (
          <Link to={`details/${item.id}`} className=''>
            <div key={item.id} className="overflow-hidden">
              <img src={`https://officialagrogis.s3.amazonaws.com/${item.image}`} alt={item.title} className="news-item__image" />
              <h2 className="news-item__title">{item.title}</h2>
              <p className="news-item__description">{item.description} </p>
            </div>
          </Link>
      ))}
        </section>
        </div>
    </div>
  )
}

export default News