import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import FarmerHome from '../../components/home/FarmerHome'
import FarmerTopBar from '../../components/topbar/Topbar'
import { packagePlans } from './Subscriptions'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { BiArrowBack } from 'react-icons/bi'
import Popup from 'reactjs-popup'
import Paypal from './Paypal'
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

const SelectSub = () => {
  const { t } = useTranslation();
  const navigate = useNavigate()
  const { id } = useParams()
  const singlePackage = packagePlans.find((item) => item.id === parseInt(id));

  //fetch package name from the local storage
  const packageName = localStorage.getItem('packageName')

  // Define the payment details state
  const [showPaymentDetails, setShowPaymentDetails] = useState(false);
  const [terminate, setTerminate] = useState(false)

  const togglePaymentDetails = () => {
    setShowPaymentDetails(!showPaymentDetails);
    //add the name of the package to the local storage
    localStorage.setItem('packageName', singlePackage.title)
    //store the package object in the local storage
    localStorage.setItem('packageObject', JSON.stringify(singlePackage))

  };

  const handleTerminate = () => {
    //remove package name from local storage
    localStorage.removeItem('packageName')
    localStorage.setItem('packageObject', JSON.stringify(singlePackage))


    setTerminate(!terminate)

  }



  return (
    <div className="home-container">
      <FarmerTopBar />
      <div className="close-btn" onClick={() => navigate(-1)}>
        <BiArrowBack />
      </div>
      {/* <h1>Select Subscription</h1> */}
      {singlePackage && (
        <div className="select-package">
          <div className="package-image">
            <img src={singlePackage.image} alt={singlePackage.title} />
          </div>
          <div className="package-details">
            <section style={{ display: 'flex', width: '100%' }}>
              <div
                style={{
                  borderRight: '1px solid rgb(0 0 0 / 29%)',
                  width: '30%',
                  padding: '0 20px 0 0',
                }}
              >
                <h2>{singlePackage.title}</h2>

                <h3>{t('features')}</h3>
                <ul>
                  {singlePackage.features.map((feature, index) => (
                    <li key={index}>{t(feature)}</li>
                  ))}
                </ul>
                <h3>{t('price')}</h3>
                <p>{singlePackage.price}</p>
              </div>
              <div
                className="package-description"
                style={{ width: '70%', padding: '0 0 0 20px' }}
              >
                <h3 style={{ color: 'black' }}>{t('description')}</h3>
                <p>{singlePackage.description}</p>
              </div>
            </section>

            <Popup
              trigger={
                <button className="buy-package">{t('Proceed to pay')}</button>
              }
              modal
              closeOnDocumentClick
              overlayClassName="popup-overlay"
              contentStyle={{
                borderRadius: '10px',
                padding: '20px',
                backgroundColor: '#fff',
                maxWidth: '400px',
                maxHeight: '90vh',
                overflow: 'auto',
              }}
            >
              {showPaymentDetails ? (
                <div>
                  {/* Display the payment success message */}
                  <h2>{t('payment Successful')}!</h2>
                  <p>{t('thank you for your payment')}.</p>
                  {/* You can add any additional content or success message here */}
                </div>
              ) : terminate ? (
                <div>
                  <h2>{t('termination was Successful')}!</h2>
                  <p>
                    {t(
                      'you can choose other suitable plans under manage plans section'
                    )}
                    .
                  </p>
                  {/* {//terminate [ackage subscription here]} */}
                  <Link
                    className="buy-package"
                    to="/farmer/account/subscription"
                  >
                    {t('manage Plan')}
                  </Link>
                </div>
              ) : (
                <div>
                  {packageName === singlePackage.title ? (
                    <div>
                      <h2>{t('payment Successful')}!</h2>
                      <p>{t('you have already purchased this plan')}.</p>
                      {/* {//terminate [ackage subscription here]} */}
                      <button className="buy-package" onClick={handleTerminate}>
                        {t('terminate subscription?')}
                      </button>
                    </div>
                  ) : (
                    <>
                      {/* Display the payment confirmation */}
                      <h2>{t('confirm Payment')}</h2>
                      <p>{t('please confirm your payment details')}.</p>
                      {/* Add any payment confirmation form or details here */}
                      <form>
                        {/* <label htmlFor='cardNumber'>Card Number:</label>
                <input type='text' id='cardNumber' name='cardNumber' />

                <label htmlFor='expiryDate'>Expiry Date:</label>
                <input type='text' id='expiryDate' name='expiryDate' />

                <label htmlFor='cvv'>CVV:</label>
                <input type='text' id='cvv' name='cvv' /> */}

                        {/* <Paypal /> */}

                        <PayPalScriptProvider
                          options={{
                            'client-id':
                              'AVG0xtJVZiJ9gK0MO2oORu3J0OsVkSgTk1ksiRs5q3ca9FGEF_U51lARkJuGQgwtBM6pXXGZOhun5rVZ',
                            currency: 'CAD',
                          }}
                        >
                          <PayPalButtons />
                        </PayPalScriptProvider>

                        <button
                          className="buy-package "
                          onClick={togglePaymentDetails}
                        >
                          {t('pay Now')}
                        </button>
                      </form>
                    </>
                  )}
                </div>
              )}
            </Popup>
          </div>
        </div>
      )}
    </div>
  )
}

export default SelectSub