/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getSoilAnalysis = /* GraphQL */ `
  query GetSoilAnalysis($id: ID!) {
    getSoilAnalysis(id: $id) {
      id
      farmDetails {
        id
        farmer {
          id
          customID
          firstName
          lastName
          email
          phone
          billingAddress
          city
          country
          postalCode
          company
          county
          isDisabled
          createdAt
          updatedAt
          __typename
        }
        active
        farmerID
        name
        crop
        cropUse
        Earlyness
        Protection
        area
        TotalYield
        YieldPerha
        sowingDate
        harvestDate
        soilTexture
        drainage
        Management
        ownership
        cadastralSheet
        cadastralMunicipality
        cadastralParticle
        slope
        revenue
        notes
        startDate
        spatialReference
        byoc
        subscriptionID
        rings
        damages {
          nextToken
          __typename
        }
        diseases {
          nextToken
          __typename
        }
        operations {
          nextToken
          __typename
        }
        droneRequests {
          nextToken
          __typename
        }
        issues {
          nextToken
          __typename
        }
        SoilAnalysis {
          nextToken
          __typename
        }
        WeatherStation {
          nextToken
          __typename
        }
        planetRequests {
          nextToken
          __typename
        }
        gmail
        createdAt
        updatedAt
        __typename
      }
      farmDetailsID
      sand
      clay
      silt
      ph
      totalNitrogen
      assimilablePhosphorus
      totalOrganicCarbon
      cToNRatio
      exchangeablePotassium
      cationExchangeCapacity
      bulkDensity
      skeleton
      depth
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listSoilAnalyses = /* GraphQL */ `
  query ListSoilAnalyses(
    $filter: ModelSoilAnalysisFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSoilAnalyses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        farmDetails {
          id
          active
          farmerID
          name
          crop
          cropUse
          Earlyness
          Protection
          area
          TotalYield
          YieldPerha
          sowingDate
          harvestDate
          soilTexture
          drainage
          Management
          ownership
          cadastralSheet
          cadastralMunicipality
          cadastralParticle
          slope
          revenue
          notes
          startDate
          spatialReference
          byoc
          subscriptionID
          rings
          gmail
          createdAt
          updatedAt
          __typename
        }
        farmDetailsID
        sand
        clay
        silt
        ph
        totalNitrogen
        assimilablePhosphorus
        totalOrganicCarbon
        cToNRatio
        exchangeablePotassium
        cationExchangeCapacity
        bulkDensity
        skeleton
        depth
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMeteoblue = /* GraphQL */ `
  query GetMeteoblue($id: ID!) {
    getMeteoblue(id: $id) {
      id
      cachedData
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listMeteoblues = /* GraphQL */ `
  query ListMeteoblues(
    $filter: ModelMeteoblueFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMeteoblues(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        cachedData
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAdmin = /* GraphQL */ `
  query GetAdmin($id: ID!) {
    getAdmin(id: $id) {
      id
      email
      firstName
      lastName
      phone
      gmail
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAdmins = /* GraphQL */ `
  query ListAdmins(
    $filter: ModelAdminFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAdmins(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        firstName
        lastName
        phone
        gmail
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getFarmer = /* GraphQL */ `
  query GetFarmer($id: ID!) {
    getFarmer(id: $id) {
      id
      customID
      firstName
      lastName
      email
      phone
      billingAddress
      city
      country
      postalCode
      company
      county
      isDisabled
      farms {
        items {
          id
          active
          farmerID
          name
          crop
          cropUse
          Earlyness
          Protection
          area
          TotalYield
          YieldPerha
          sowingDate
          harvestDate
          soilTexture
          drainage
          Management
          ownership
          cadastralSheet
          cadastralMunicipality
          cadastralParticle
          slope
          revenue
          notes
          startDate
          spatialReference
          byoc
          subscriptionID
          rings
          gmail
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      issues {
        items {
          id
          farmerID
          farmDetailsID
          fieldName
          date
          notes
          status
          type
          coordinates
          image
          gmail
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      damages {
        items {
          id
          farmerID
          farmDetailsID
          fieldName
          date
          type
          intensity
          notes
          coordinates
          image
          gmail
          status
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      diseases {
        items {
          id
          farmerID
          farmDetailsID
          date
          type
          status
          notes
          coordinates
          image
          gmail
          intensity
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      packages {
        items {
          id
          title
          description
          price
          features
          image
          farmerID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      droneRequests {
        items {
          id
          farmerID
          farmDetailsID
          date
          fieldName
          crop
          area
          note
          status
          hasUpload
          rings
          gmail
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      weatherStation {
        items {
          id
          farmDetailsID
          api
          farmerID
          gmail
          status
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      packageRequests {
        items {
          id
          packageID
          requesterID
          status
          active
          expirationDate
          areaLimit
          areaRequested
          isAreaRequest
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      planetRequests {
        items {
          id
          farmerID
          farmDetailsID
          status
          gmail
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listFarmers = /* GraphQL */ `
  query ListFarmers(
    $filter: ModelFarmerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFarmers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        customID
        firstName
        lastName
        email
        phone
        billingAddress
        city
        country
        postalCode
        company
        county
        isDisabled
        farms {
          nextToken
          __typename
        }
        issues {
          nextToken
          __typename
        }
        damages {
          nextToken
          __typename
        }
        diseases {
          nextToken
          __typename
        }
        packages {
          nextToken
          __typename
        }
        droneRequests {
          nextToken
          __typename
        }
        weatherStation {
          nextToken
          __typename
        }
        packageRequests {
          nextToken
          __typename
        }
        planetRequests {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getFarmDetails = /* GraphQL */ `
  query GetFarmDetails($id: ID!) {
    getFarmDetails(id: $id) {
      id
      farmer {
        id
        customID
        firstName
        lastName
        email
        phone
        billingAddress
        city
        country
        postalCode
        company
        county
        isDisabled
        farms {
          nextToken
          __typename
        }
        issues {
          nextToken
          __typename
        }
        damages {
          nextToken
          __typename
        }
        diseases {
          nextToken
          __typename
        }
        packages {
          nextToken
          __typename
        }
        droneRequests {
          nextToken
          __typename
        }
        weatherStation {
          nextToken
          __typename
        }
        packageRequests {
          nextToken
          __typename
        }
        planetRequests {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      active
      farmerID
      name
      crop
      cropUse
      Earlyness
      Protection
      area
      TotalYield
      YieldPerha
      sowingDate
      harvestDate
      soilTexture
      drainage
      Management
      ownership
      cadastralSheet
      cadastralMunicipality
      cadastralParticle
      slope
      revenue
      notes
      startDate
      spatialReference
      byoc
      subscriptionID
      rings
      damages {
        items {
          id
          farmerID
          farmDetailsID
          fieldName
          date
          type
          intensity
          notes
          coordinates
          image
          gmail
          status
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      diseases {
        items {
          id
          farmerID
          farmDetailsID
          date
          type
          status
          notes
          coordinates
          image
          gmail
          intensity
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      operations {
        items {
          id
          farmerID
          farmDetailsID
          crop
          operation
          fertilizerType
          fertilizerQuantity
          fertilizerQuantityPerHa
          yieldQuantity
          yieldQuantityPerHa
          irrigationLitres
          irrigationLitresPerHa
          date
          notes
          gmail
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      droneRequests {
        items {
          id
          farmerID
          farmDetailsID
          date
          fieldName
          crop
          area
          note
          status
          hasUpload
          rings
          gmail
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      issues {
        items {
          id
          farmerID
          farmDetailsID
          fieldName
          date
          notes
          status
          type
          coordinates
          image
          gmail
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      SoilAnalysis {
        items {
          id
          farmDetailsID
          sand
          clay
          silt
          ph
          totalNitrogen
          assimilablePhosphorus
          totalOrganicCarbon
          cToNRatio
          exchangeablePotassium
          cationExchangeCapacity
          bulkDensity
          skeleton
          depth
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      WeatherStation {
        items {
          id
          farmDetailsID
          api
          farmerID
          gmail
          status
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      planetRequests {
        items {
          id
          farmerID
          farmDetailsID
          status
          gmail
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      gmail
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listFarmDetails = /* GraphQL */ `
  query ListFarmDetails(
    $filter: ModelFarmDetailsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFarmDetails(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        farmer {
          id
          customID
          firstName
          lastName
          email
          phone
          billingAddress
          city
          country
          postalCode
          company
          county
          isDisabled
          createdAt
          updatedAt
          __typename
        }
        active
        farmerID
        name
        crop
        cropUse
        Earlyness
        Protection
        area
        TotalYield
        YieldPerha
        sowingDate
        harvestDate
        soilTexture
        drainage
        Management
        ownership
        cadastralSheet
        cadastralMunicipality
        cadastralParticle
        slope
        revenue
        notes
        startDate
        spatialReference
        byoc
        subscriptionID
        rings
        damages {
          nextToken
          __typename
        }
        diseases {
          nextToken
          __typename
        }
        operations {
          nextToken
          __typename
        }
        droneRequests {
          nextToken
          __typename
        }
        issues {
          nextToken
          __typename
        }
        SoilAnalysis {
          nextToken
          __typename
        }
        WeatherStation {
          nextToken
          __typename
        }
        planetRequests {
          nextToken
          __typename
        }
        gmail
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getIssues = /* GraphQL */ `
  query GetIssues($id: ID!) {
    getIssues(id: $id) {
      id
      farmer {
        id
        customID
        firstName
        lastName
        email
        phone
        billingAddress
        city
        country
        postalCode
        company
        county
        isDisabled
        farms {
          nextToken
          __typename
        }
        issues {
          nextToken
          __typename
        }
        damages {
          nextToken
          __typename
        }
        diseases {
          nextToken
          __typename
        }
        packages {
          nextToken
          __typename
        }
        droneRequests {
          nextToken
          __typename
        }
        weatherStation {
          nextToken
          __typename
        }
        packageRequests {
          nextToken
          __typename
        }
        planetRequests {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      farmerID
      farmDetails {
        id
        farmer {
          id
          customID
          firstName
          lastName
          email
          phone
          billingAddress
          city
          country
          postalCode
          company
          county
          isDisabled
          createdAt
          updatedAt
          __typename
        }
        active
        farmerID
        name
        crop
        cropUse
        Earlyness
        Protection
        area
        TotalYield
        YieldPerha
        sowingDate
        harvestDate
        soilTexture
        drainage
        Management
        ownership
        cadastralSheet
        cadastralMunicipality
        cadastralParticle
        slope
        revenue
        notes
        startDate
        spatialReference
        byoc
        subscriptionID
        rings
        damages {
          nextToken
          __typename
        }
        diseases {
          nextToken
          __typename
        }
        operations {
          nextToken
          __typename
        }
        droneRequests {
          nextToken
          __typename
        }
        issues {
          nextToken
          __typename
        }
        SoilAnalysis {
          nextToken
          __typename
        }
        WeatherStation {
          nextToken
          __typename
        }
        planetRequests {
          nextToken
          __typename
        }
        gmail
        createdAt
        updatedAt
        __typename
      }
      farmDetailsID
      fieldName
      date
      notes
      status
      type
      coordinates
      image
      gmail
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listIssues = /* GraphQL */ `
  query ListIssues(
    $filter: ModelIssuesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listIssues(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        farmer {
          id
          customID
          firstName
          lastName
          email
          phone
          billingAddress
          city
          country
          postalCode
          company
          county
          isDisabled
          createdAt
          updatedAt
          __typename
        }
        farmerID
        farmDetails {
          id
          active
          farmerID
          name
          crop
          cropUse
          Earlyness
          Protection
          area
          TotalYield
          YieldPerha
          sowingDate
          harvestDate
          soilTexture
          drainage
          Management
          ownership
          cadastralSheet
          cadastralMunicipality
          cadastralParticle
          slope
          revenue
          notes
          startDate
          spatialReference
          byoc
          subscriptionID
          rings
          gmail
          createdAt
          updatedAt
          __typename
        }
        farmDetailsID
        fieldName
        date
        notes
        status
        type
        coordinates
        image
        gmail
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDamages = /* GraphQL */ `
  query GetDamages($id: ID!) {
    getDamages(id: $id) {
      id
      farmer {
        id
        customID
        firstName
        lastName
        email
        phone
        billingAddress
        city
        country
        postalCode
        company
        county
        isDisabled
        farms {
          nextToken
          __typename
        }
        issues {
          nextToken
          __typename
        }
        damages {
          nextToken
          __typename
        }
        diseases {
          nextToken
          __typename
        }
        packages {
          nextToken
          __typename
        }
        droneRequests {
          nextToken
          __typename
        }
        weatherStation {
          nextToken
          __typename
        }
        packageRequests {
          nextToken
          __typename
        }
        planetRequests {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      farmerID
      farmDetails {
        id
        farmer {
          id
          customID
          firstName
          lastName
          email
          phone
          billingAddress
          city
          country
          postalCode
          company
          county
          isDisabled
          createdAt
          updatedAt
          __typename
        }
        active
        farmerID
        name
        crop
        cropUse
        Earlyness
        Protection
        area
        TotalYield
        YieldPerha
        sowingDate
        harvestDate
        soilTexture
        drainage
        Management
        ownership
        cadastralSheet
        cadastralMunicipality
        cadastralParticle
        slope
        revenue
        notes
        startDate
        spatialReference
        byoc
        subscriptionID
        rings
        damages {
          nextToken
          __typename
        }
        diseases {
          nextToken
          __typename
        }
        operations {
          nextToken
          __typename
        }
        droneRequests {
          nextToken
          __typename
        }
        issues {
          nextToken
          __typename
        }
        SoilAnalysis {
          nextToken
          __typename
        }
        WeatherStation {
          nextToken
          __typename
        }
        planetRequests {
          nextToken
          __typename
        }
        gmail
        createdAt
        updatedAt
        __typename
      }
      farmDetailsID
      fieldName
      date
      type
      intensity
      notes
      coordinates
      image
      gmail
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listDamages = /* GraphQL */ `
  query ListDamages(
    $filter: ModelDamagesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDamages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        farmer {
          id
          customID
          firstName
          lastName
          email
          phone
          billingAddress
          city
          country
          postalCode
          company
          county
          isDisabled
          createdAt
          updatedAt
          __typename
        }
        farmerID
        farmDetails {
          id
          active
          farmerID
          name
          crop
          cropUse
          Earlyness
          Protection
          area
          TotalYield
          YieldPerha
          sowingDate
          harvestDate
          soilTexture
          drainage
          Management
          ownership
          cadastralSheet
          cadastralMunicipality
          cadastralParticle
          slope
          revenue
          notes
          startDate
          spatialReference
          byoc
          subscriptionID
          rings
          gmail
          createdAt
          updatedAt
          __typename
        }
        farmDetailsID
        fieldName
        date
        type
        intensity
        notes
        coordinates
        image
        gmail
        status
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDiseases = /* GraphQL */ `
  query GetDiseases($id: ID!) {
    getDiseases(id: $id) {
      id
      farmer {
        id
        customID
        firstName
        lastName
        email
        phone
        billingAddress
        city
        country
        postalCode
        company
        county
        isDisabled
        farms {
          nextToken
          __typename
        }
        issues {
          nextToken
          __typename
        }
        damages {
          nextToken
          __typename
        }
        diseases {
          nextToken
          __typename
        }
        packages {
          nextToken
          __typename
        }
        droneRequests {
          nextToken
          __typename
        }
        weatherStation {
          nextToken
          __typename
        }
        packageRequests {
          nextToken
          __typename
        }
        planetRequests {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      farmerID
      farmDetails {
        id
        farmer {
          id
          customID
          firstName
          lastName
          email
          phone
          billingAddress
          city
          country
          postalCode
          company
          county
          isDisabled
          createdAt
          updatedAt
          __typename
        }
        active
        farmerID
        name
        crop
        cropUse
        Earlyness
        Protection
        area
        TotalYield
        YieldPerha
        sowingDate
        harvestDate
        soilTexture
        drainage
        Management
        ownership
        cadastralSheet
        cadastralMunicipality
        cadastralParticle
        slope
        revenue
        notes
        startDate
        spatialReference
        byoc
        subscriptionID
        rings
        damages {
          nextToken
          __typename
        }
        diseases {
          nextToken
          __typename
        }
        operations {
          nextToken
          __typename
        }
        droneRequests {
          nextToken
          __typename
        }
        issues {
          nextToken
          __typename
        }
        SoilAnalysis {
          nextToken
          __typename
        }
        WeatherStation {
          nextToken
          __typename
        }
        planetRequests {
          nextToken
          __typename
        }
        gmail
        createdAt
        updatedAt
        __typename
      }
      farmDetailsID
      date
      type
      status
      notes
      coordinates
      image
      gmail
      intensity
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listDiseases = /* GraphQL */ `
  query ListDiseases(
    $filter: ModelDiseasesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDiseases(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        farmer {
          id
          customID
          firstName
          lastName
          email
          phone
          billingAddress
          city
          country
          postalCode
          company
          county
          isDisabled
          createdAt
          updatedAt
          __typename
        }
        farmerID
        farmDetails {
          id
          active
          farmerID
          name
          crop
          cropUse
          Earlyness
          Protection
          area
          TotalYield
          YieldPerha
          sowingDate
          harvestDate
          soilTexture
          drainage
          Management
          ownership
          cadastralSheet
          cadastralMunicipality
          cadastralParticle
          slope
          revenue
          notes
          startDate
          spatialReference
          byoc
          subscriptionID
          rings
          gmail
          createdAt
          updatedAt
          __typename
        }
        farmDetailsID
        date
        type
        status
        notes
        coordinates
        image
        gmail
        intensity
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getOperations = /* GraphQL */ `
  query GetOperations($id: ID!) {
    getOperations(id: $id) {
      id
      farmDetails {
        id
        farmer {
          id
          customID
          firstName
          lastName
          email
          phone
          billingAddress
          city
          country
          postalCode
          company
          county
          isDisabled
          createdAt
          updatedAt
          __typename
        }
        active
        farmerID
        name
        crop
        cropUse
        Earlyness
        Protection
        area
        TotalYield
        YieldPerha
        sowingDate
        harvestDate
        soilTexture
        drainage
        Management
        ownership
        cadastralSheet
        cadastralMunicipality
        cadastralParticle
        slope
        revenue
        notes
        startDate
        spatialReference
        byoc
        subscriptionID
        rings
        damages {
          nextToken
          __typename
        }
        diseases {
          nextToken
          __typename
        }
        operations {
          nextToken
          __typename
        }
        droneRequests {
          nextToken
          __typename
        }
        issues {
          nextToken
          __typename
        }
        SoilAnalysis {
          nextToken
          __typename
        }
        WeatherStation {
          nextToken
          __typename
        }
        planetRequests {
          nextToken
          __typename
        }
        gmail
        createdAt
        updatedAt
        __typename
      }
      farmerID
      farmDetailsID
      crop
      operation
      fertilizerType
      fertilizerQuantity
      fertilizerQuantityPerHa
      yieldQuantity
      yieldQuantityPerHa
      irrigationLitres
      irrigationLitresPerHa
      date
      notes
      gmail
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listOperations = /* GraphQL */ `
  query ListOperations(
    $filter: ModelOperationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOperations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        farmDetails {
          id
          active
          farmerID
          name
          crop
          cropUse
          Earlyness
          Protection
          area
          TotalYield
          YieldPerha
          sowingDate
          harvestDate
          soilTexture
          drainage
          Management
          ownership
          cadastralSheet
          cadastralMunicipality
          cadastralParticle
          slope
          revenue
          notes
          startDate
          spatialReference
          byoc
          subscriptionID
          rings
          gmail
          createdAt
          updatedAt
          __typename
        }
        farmerID
        farmDetailsID
        crop
        operation
        fertilizerType
        fertilizerQuantity
        fertilizerQuantityPerHa
        yieldQuantity
        yieldQuantityPerHa
        irrigationLitres
        irrigationLitresPerHa
        date
        notes
        gmail
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPackages = /* GraphQL */ `
  query GetPackages($id: ID!) {
    getPackages(id: $id) {
      id
      title
      description
      price
      features
      image
      paidBy {
        id
        customID
        firstName
        lastName
        email
        phone
        billingAddress
        city
        country
        postalCode
        company
        county
        isDisabled
        farms {
          nextToken
          __typename
        }
        issues {
          nextToken
          __typename
        }
        damages {
          nextToken
          __typename
        }
        diseases {
          nextToken
          __typename
        }
        packages {
          nextToken
          __typename
        }
        droneRequests {
          nextToken
          __typename
        }
        weatherStation {
          nextToken
          __typename
        }
        packageRequests {
          nextToken
          __typename
        }
        planetRequests {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      farmerID
      packageRequests {
        items {
          id
          packageID
          requesterID
          status
          active
          expirationDate
          areaLimit
          areaRequested
          isAreaRequest
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listPackages = /* GraphQL */ `
  query ListPackages(
    $filter: ModelPackagesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPackages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        description
        price
        features
        image
        paidBy {
          id
          customID
          firstName
          lastName
          email
          phone
          billingAddress
          city
          country
          postalCode
          company
          county
          isDisabled
          createdAt
          updatedAt
          __typename
        }
        farmerID
        packageRequests {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPackageRequest = /* GraphQL */ `
  query GetPackageRequest($id: ID!) {
    getPackageRequest(id: $id) {
      id
      packageID
      package {
        id
        title
        description
        price
        features
        image
        paidBy {
          id
          customID
          firstName
          lastName
          email
          phone
          billingAddress
          city
          country
          postalCode
          company
          county
          isDisabled
          createdAt
          updatedAt
          __typename
        }
        farmerID
        packageRequests {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      requestedBy {
        id
        customID
        firstName
        lastName
        email
        phone
        billingAddress
        city
        country
        postalCode
        company
        county
        isDisabled
        farms {
          nextToken
          __typename
        }
        issues {
          nextToken
          __typename
        }
        damages {
          nextToken
          __typename
        }
        diseases {
          nextToken
          __typename
        }
        packages {
          nextToken
          __typename
        }
        droneRequests {
          nextToken
          __typename
        }
        weatherStation {
          nextToken
          __typename
        }
        packageRequests {
          nextToken
          __typename
        }
        planetRequests {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      requesterID
      status
      active
      expirationDate
      areaLimit
      areaRequested
      isAreaRequest
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listPackageRequests = /* GraphQL */ `
  query ListPackageRequests(
    $filter: ModelPackageRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPackageRequests(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        packageID
        package {
          id
          title
          description
          price
          features
          image
          farmerID
          createdAt
          updatedAt
          __typename
        }
        requestedBy {
          id
          customID
          firstName
          lastName
          email
          phone
          billingAddress
          city
          country
          postalCode
          company
          county
          isDisabled
          createdAt
          updatedAt
          __typename
        }
        requesterID
        status
        active
        expirationDate
        areaLimit
        areaRequested
        isAreaRequest
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDroneRequest = /* GraphQL */ `
  query GetDroneRequest($id: ID!) {
    getDroneRequest(id: $id) {
      id
      farmer {
        id
        customID
        firstName
        lastName
        email
        phone
        billingAddress
        city
        country
        postalCode
        company
        county
        isDisabled
        farms {
          nextToken
          __typename
        }
        issues {
          nextToken
          __typename
        }
        damages {
          nextToken
          __typename
        }
        diseases {
          nextToken
          __typename
        }
        packages {
          nextToken
          __typename
        }
        droneRequests {
          nextToken
          __typename
        }
        weatherStation {
          nextToken
          __typename
        }
        packageRequests {
          nextToken
          __typename
        }
        planetRequests {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      farmerID
      farmDetails {
        id
        farmer {
          id
          customID
          firstName
          lastName
          email
          phone
          billingAddress
          city
          country
          postalCode
          company
          county
          isDisabled
          createdAt
          updatedAt
          __typename
        }
        active
        farmerID
        name
        crop
        cropUse
        Earlyness
        Protection
        area
        TotalYield
        YieldPerha
        sowingDate
        harvestDate
        soilTexture
        drainage
        Management
        ownership
        cadastralSheet
        cadastralMunicipality
        cadastralParticle
        slope
        revenue
        notes
        startDate
        spatialReference
        byoc
        subscriptionID
        rings
        damages {
          nextToken
          __typename
        }
        diseases {
          nextToken
          __typename
        }
        operations {
          nextToken
          __typename
        }
        droneRequests {
          nextToken
          __typename
        }
        issues {
          nextToken
          __typename
        }
        SoilAnalysis {
          nextToken
          __typename
        }
        WeatherStation {
          nextToken
          __typename
        }
        planetRequests {
          nextToken
          __typename
        }
        gmail
        createdAt
        updatedAt
        __typename
      }
      farmDetailsID
      date
      fieldName
      crop
      area
      note
      status
      hasUpload
      rings
      gmail
      droneData {
        items {
          id
          droneRequestID
          farmerID
          variety
          link
          gmail
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listDroneRequests = /* GraphQL */ `
  query ListDroneRequests(
    $filter: ModelDroneRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDroneRequests(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        farmer {
          id
          customID
          firstName
          lastName
          email
          phone
          billingAddress
          city
          country
          postalCode
          company
          county
          isDisabled
          createdAt
          updatedAt
          __typename
        }
        farmerID
        farmDetails {
          id
          active
          farmerID
          name
          crop
          cropUse
          Earlyness
          Protection
          area
          TotalYield
          YieldPerha
          sowingDate
          harvestDate
          soilTexture
          drainage
          Management
          ownership
          cadastralSheet
          cadastralMunicipality
          cadastralParticle
          slope
          revenue
          notes
          startDate
          spatialReference
          byoc
          subscriptionID
          rings
          gmail
          createdAt
          updatedAt
          __typename
        }
        farmDetailsID
        date
        fieldName
        crop
        area
        note
        status
        hasUpload
        rings
        gmail
        droneData {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDroneUploads = /* GraphQL */ `
  query GetDroneUploads($id: ID!) {
    getDroneUploads(id: $id) {
      id
      droneRequest {
        id
        farmer {
          id
          customID
          firstName
          lastName
          email
          phone
          billingAddress
          city
          country
          postalCode
          company
          county
          isDisabled
          createdAt
          updatedAt
          __typename
        }
        farmerID
        farmDetails {
          id
          active
          farmerID
          name
          crop
          cropUse
          Earlyness
          Protection
          area
          TotalYield
          YieldPerha
          sowingDate
          harvestDate
          soilTexture
          drainage
          Management
          ownership
          cadastralSheet
          cadastralMunicipality
          cadastralParticle
          slope
          revenue
          notes
          startDate
          spatialReference
          byoc
          subscriptionID
          rings
          gmail
          createdAt
          updatedAt
          __typename
        }
        farmDetailsID
        date
        fieldName
        crop
        area
        note
        status
        hasUpload
        rings
        gmail
        droneData {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      droneRequestID
      farmerID
      variety
      link
      gmail
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listDroneUploads = /* GraphQL */ `
  query ListDroneUploads(
    $filter: ModelDroneUploadsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDroneUploads(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        droneRequest {
          id
          farmerID
          farmDetailsID
          date
          fieldName
          crop
          area
          note
          status
          hasUpload
          rings
          gmail
          createdAt
          updatedAt
          __typename
        }
        droneRequestID
        farmerID
        variety
        link
        gmail
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPlanetRequest = /* GraphQL */ `
  query GetPlanetRequest($id: ID!) {
    getPlanetRequest(id: $id) {
      id
      farmer {
        id
        customID
        firstName
        lastName
        email
        phone
        billingAddress
        city
        country
        postalCode
        company
        county
        isDisabled
        farms {
          nextToken
          __typename
        }
        issues {
          nextToken
          __typename
        }
        damages {
          nextToken
          __typename
        }
        diseases {
          nextToken
          __typename
        }
        packages {
          nextToken
          __typename
        }
        droneRequests {
          nextToken
          __typename
        }
        weatherStation {
          nextToken
          __typename
        }
        packageRequests {
          nextToken
          __typename
        }
        planetRequests {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      farmerID
      farmDetails {
        id
        farmer {
          id
          customID
          firstName
          lastName
          email
          phone
          billingAddress
          city
          country
          postalCode
          company
          county
          isDisabled
          createdAt
          updatedAt
          __typename
        }
        active
        farmerID
        name
        crop
        cropUse
        Earlyness
        Protection
        area
        TotalYield
        YieldPerha
        sowingDate
        harvestDate
        soilTexture
        drainage
        Management
        ownership
        cadastralSheet
        cadastralMunicipality
        cadastralParticle
        slope
        revenue
        notes
        startDate
        spatialReference
        byoc
        subscriptionID
        rings
        damages {
          nextToken
          __typename
        }
        diseases {
          nextToken
          __typename
        }
        operations {
          nextToken
          __typename
        }
        droneRequests {
          nextToken
          __typename
        }
        issues {
          nextToken
          __typename
        }
        SoilAnalysis {
          nextToken
          __typename
        }
        WeatherStation {
          nextToken
          __typename
        }
        planetRequests {
          nextToken
          __typename
        }
        gmail
        createdAt
        updatedAt
        __typename
      }
      farmDetailsID
      status
      gmail
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listPlanetRequests = /* GraphQL */ `
  query ListPlanetRequests(
    $filter: ModelPlanetRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPlanetRequests(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        farmer {
          id
          customID
          firstName
          lastName
          email
          phone
          billingAddress
          city
          country
          postalCode
          company
          county
          isDisabled
          createdAt
          updatedAt
          __typename
        }
        farmerID
        farmDetails {
          id
          active
          farmerID
          name
          crop
          cropUse
          Earlyness
          Protection
          area
          TotalYield
          YieldPerha
          sowingDate
          harvestDate
          soilTexture
          drainage
          Management
          ownership
          cadastralSheet
          cadastralMunicipality
          cadastralParticle
          slope
          revenue
          notes
          startDate
          spatialReference
          byoc
          subscriptionID
          rings
          gmail
          createdAt
          updatedAt
          __typename
        }
        farmDetailsID
        status
        gmail
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getWeatherStation = /* GraphQL */ `
  query GetWeatherStation($id: ID!) {
    getWeatherStation(id: $id) {
      id
      farmDetails {
        id
        farmer {
          id
          customID
          firstName
          lastName
          email
          phone
          billingAddress
          city
          country
          postalCode
          company
          county
          isDisabled
          createdAt
          updatedAt
          __typename
        }
        active
        farmerID
        name
        crop
        cropUse
        Earlyness
        Protection
        area
        TotalYield
        YieldPerha
        sowingDate
        harvestDate
        soilTexture
        drainage
        Management
        ownership
        cadastralSheet
        cadastralMunicipality
        cadastralParticle
        slope
        revenue
        notes
        startDate
        spatialReference
        byoc
        subscriptionID
        rings
        damages {
          nextToken
          __typename
        }
        diseases {
          nextToken
          __typename
        }
        operations {
          nextToken
          __typename
        }
        droneRequests {
          nextToken
          __typename
        }
        issues {
          nextToken
          __typename
        }
        SoilAnalysis {
          nextToken
          __typename
        }
        WeatherStation {
          nextToken
          __typename
        }
        planetRequests {
          nextToken
          __typename
        }
        gmail
        createdAt
        updatedAt
        __typename
      }
      farmDetailsID
      api
      farmer {
        id
        customID
        firstName
        lastName
        email
        phone
        billingAddress
        city
        country
        postalCode
        company
        county
        isDisabled
        farms {
          nextToken
          __typename
        }
        issues {
          nextToken
          __typename
        }
        damages {
          nextToken
          __typename
        }
        diseases {
          nextToken
          __typename
        }
        packages {
          nextToken
          __typename
        }
        droneRequests {
          nextToken
          __typename
        }
        weatherStation {
          nextToken
          __typename
        }
        packageRequests {
          nextToken
          __typename
        }
        planetRequests {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      farmerID
      gmail
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listWeatherStations = /* GraphQL */ `
  query ListWeatherStations(
    $filter: ModelWeatherStationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWeatherStations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        farmDetails {
          id
          active
          farmerID
          name
          crop
          cropUse
          Earlyness
          Protection
          area
          TotalYield
          YieldPerha
          sowingDate
          harvestDate
          soilTexture
          drainage
          Management
          ownership
          cadastralSheet
          cadastralMunicipality
          cadastralParticle
          slope
          revenue
          notes
          startDate
          spatialReference
          byoc
          subscriptionID
          rings
          gmail
          createdAt
          updatedAt
          __typename
        }
        farmDetailsID
        api
        farmer {
          id
          customID
          firstName
          lastName
          email
          phone
          billingAddress
          city
          country
          postalCode
          company
          county
          isDisabled
          createdAt
          updatedAt
          __typename
        }
        farmerID
        gmail
        status
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAcademy = /* GraphQL */ `
  query GetAcademy($id: ID!) {
    getAcademy(id: $id) {
      id
      title
      description
      image
      video
      category
      subcategory
      tags
      content
      published
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAcademies = /* GraphQL */ `
  query ListAcademies(
    $filter: ModelAcademyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAcademies(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        description
        image
        video
        category
        subcategory
        tags
        content
        published
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getNews = /* GraphQL */ `
  query GetNews($id: ID!) {
    getNews(id: $id) {
      id
      content
      image
      title
      description
      published
      status
      postedBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listNews = /* GraphQL */ `
  query ListNews(
    $filter: ModelNewsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNews(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        content
        image
        title
        description
        published
        status
        postedBy
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
