import React,{ useEffect, useState } from 'react'
import { Line } from 'react-chartjs-2';
import MyLineChart from './MuiChart';
import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  LineElement,
  CategoryScale,
} from 'chart.js';
import { t } from 'i18next';

ChartJS.register(
  LinearScale,
  PointElement,
  LineElement,
  CategoryScale,
);


// The evalscript is the same as in your code
const evalscript = `
//VERSION=3
function setup() {
  return {
    input: [{
      bands: [
        "B04",
        "B08",
        "dataMask"
      ]
    }],
    
    output: [
      {
        id: "myData",
        bands: ["NDVI", "NIR", "RED"]
      },
      {
        id: "dataMask",
        bands: 1,
      }
    ]
  }
}

function evaluatePixel(samples) {
   let ndvi = (samples.B08 - samples.B04) / (samples.B08 + samples.B04);
   return {
     myData: [ndvi, samples.B08, samples.B04],
     dataMask: [samples.dataMask]
   }
}
`;



// The headers are the same as in your code

const Advanced = ({selectedPolygon, token, setSelectedFarm}) => {
  const [year, setYear] = useState('2019');
  const [loading, setLoading] = useState(false);
  const [selectedYear, setSelectedYear] = useState(2024); // Initial selected year

  const years = [2019, 2020, 2021, 2022, 2023, 2024]; // Your date options

  const handleChange = (event) => {
    setYear(event.target.value);
  };

     // A state variable to store the response data
  const [sh_statistics, setShStatistics] = useState(null);
console.log(sh_statistics)
console.log(selectedPolygon)

const polygonCoordinates = selectedPolygon ? selectedPolygon.map(point => [point[0], point[1]]) : [];
console.log(polygonCoordinates)
const headers = {
    Authorization: `Bearer ${token}`,
  "Content-Type": "application/json",
  Accept: "application/json",
};

// The url is the same as in your code
const url = "https://services.sentinel-hub.com/api/v1/statistics";


  // The stats_request is the same as in your code, except for the evalscript property
const stats_request = {
    input: {
      bounds: {
        properties: { crs: 'http://www.opengis.net/def/crs/OGC/1.3/CRS84' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            polygonCoordinates,
          ],
        },
      },
      data: [
        {
          type: "sentinel-2-l2a",
          dataFilter: {
            mosaickingOrder: "leastCC",
            maxCloudCoverage: 5,
          },
        },
      ],
    },
    aggregation: {
      timeRange: {
        from: `${year}-01-01T00:00:00Z`,
        to:  `${year}-12-31T00:00:00Z`,
      },
      aggregationInterval: {
        of: "P1D",
      },
      evalscript: evalscript,
      resx: 0.0001 ,
      resy: 0.0001 ,
    },
  }; 

  // A useEffect hook to run the fetch request when the component mounts
  useEffect(() => {
    // A function to make the fetch request using async/await
    async function fetchData() {
      setLoading(true);
      // Try to make the request and handle any errors
      try {
        // Send the POST request with the stats_request as the body
        const response = await fetch(url, {
          method: "POST",
          headers: headers,
          body: JSON.stringify(stats_request),
        });

        // Check if the response is ok (status code 200-299)
        if (!response.ok) {
          // Throw an error with the status text
          throw new Error(response.statusText);
        }

        // Parse the response as JSON
        const data = await response.json();

        // Update the state variable with the data and filter out the data with 0 mean : outputs.data.bands.monthly_max_ndvi.stats.mean
        setShStatistics(data);

        // Update the loading state variable
        setLoading(false);
      
      } catch (error) {
        // Log the error to the console
        console.error(error);
        setLoading(false);
      }
    }

    // Call the fetch function
    fetchData();
  }, [ year ]);

  let lastValidMean = 0;
  let lastValidMax = 0;
  let lastValidMin = 0;
  const data = {
    labels: sh_statistics && sh_statistics.data.map((item) => item.interval.from.split('T')[0]),
    datasets: [
      {
        label: 'NDVI',
        data: sh_statistics && sh_statistics.data.map((item) => {
          const mean = item.outputs.myData.bands.NDVI.stats.mean
          const max = item.outputs.myData.bands.NDVI.stats.max
          const min = item.outputs.myData.bands.NDVI.stats.min
  
          lastValidMean = !isNaN(mean) ? mean : lastValidMean;
          lastValidMax = !isNaN(max) ? max : lastValidMax;
          lastValidMin = !isNaN(min) ? min : lastValidMin;
  
          return lastValidMean; // or choose which one you want to display
        }),
        fill: false,
        backgroundColor: 'transparent',
        borderColor: 'rgba(255, 99, 132, 0.2)',
        pointBorderColor: 'transparent',
        pointBorderWidth: 4,
        tension: 0.5,
      },
    ],
  };

  const options = {
    scales: {
      x: {
        grid: {
          display: false,
        },
        scaleLabel: {
          display: true,
          labelString: 'Date',
          color: 'black',
        }
      },
      y: {
        min: 0.0,
        max: 1,
        ticks: {
          stepSize: 0.1,
          
        },
        grid: {
          borderDash : [3,3],
        },
        scaleLabel: {
          display: true,
          labelString: 'NDVI Mean',
        
        }
      },
    },  

    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: 'NDVI',
      },
    },

    responsive: true,
    maintainAspectRatio: false,
    
  }

  return (
    <div className='line-graph'>
      <section className='flex justify-between items-center'>
      <h2 style={{color:'black'}}>NDVI:  <select className='text-black' value={year} onChange={handleChange}>
        {years.map((year) => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </select></h2> 
        <button className='bg-tertiary text-white' onClick={() => setSelectedFarm(null)}>Back</button>

      </section>
      {/* <div style={{display:'flex', justifyContent:'space-between', width:'200px'}}>
        <button onClick={() => setYear('2019')}>2019</button>
        <button onClick={() => setYear('2020')}>2020</button>
        <button onClick={() => setYear('2021')}>2021</button>
        <button onClick={() => setYear('2022')}>2022</button>
        <button onClick={() => setYear('2023')}>2023</button>
        <button onClick={() => setYear('2024')}>2024</button>
      </div> */}

    

      {
        loading ? <p>Loading...</p> : (
<>
<div style={{ width: '100%', height: '300px' }}>
        {
          sh_statistics && sh_statistics.data && (
            <Line data={data} options={options} />          )
        }
  
    </div>
     
    {sh_statistics && sh_statistics.data && (
  <table>
    <thead>
      <tr>
        <th>Date</th>
        <th>Max</th>
        <th>Mean</th>
        <th>Min</th>
      </tr>
    </thead>
    <tbody>
      {sh_statistics.data.map((stat) => (
        <tr key={stat.interval.from}>
          <td>{stat.interval.from.split('T')[0]}</td>
          <td>{!isNaN(stat.outputs.myData.bands.NDVI.stats.max) ? stat.outputs.myData.bands.NDVI.stats.max.toFixed(4) : lastValidMax.toFixed(4)}</td>
          <td>{!isNaN(stat.outputs.myData.bands.NDVI.stats.mean) ? stat.outputs.myData.bands.NDVI.stats.mean.toFixed(4) : lastValidMean.toFixed(4)}</td>
          <td>{!isNaN(stat.outputs.myData.bands.NDVI.stats.min) ? stat.outputs.myData.bands.NDVI.stats.min.toFixed(4) : lastValidMin.toFixed(4)}</td>
        </tr>
      ))}
    </tbody>
  </table>
)}
</>
        )
      }
     
    

    </div>
  );
  
}

export default Advanced