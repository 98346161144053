
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Helmet } from 'react-helmet';
import { AiFillCaretLeft, AiFillCaretRight, AiOutlineCloudDownload } from 'react-icons/ai';
import FarmRegistration from '../farms/FarmRegistration';
import FarmerTopBar from '../../components/topbar/Topbar';
import MyFarms from '../farms/myFields/MyFarms';
import IssueList from '../issues/IssueList';
import { IoAddOutline } from 'react-icons/io5';
import DamageList from './DamageList';
import { API, graphqlOperation } from 'aws-amplify';
import { listFarmDetails } from '../../../graphql/queries';
import PointMap from '../farms/myFields/PointMap';
import { fetchFarmDetails } from '../../fetches/FetchFarmDetails';



const Damages = () => {
    const [leftWidth, setLeftWidth] = useState(50);
  const [rightWidth, setRightWidth] = useState(50);
  const [clickedCoordinates, setClickedCoordinates] = useState(null); // New state for clicked coordinates
  const [polygons, setPolygons] = useState([])
  const [selectedFarm, setSelectedFarm] = useState(null);
  const [selectedCoordinates, setSelectedCoordinates] = useState(null);
  const [rings, setRings] = useState([]);
  const [formattedRings, setFormattedRings] = useState([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const style = {
    height: windowWidth < 600 ? '50vh' : '100vh',
    width: '100%',
  };


  useEffect(() => {
    if (!rings.length) {
      return;
    }
  
    const parsedRings = JSON.parse(rings);
    const coordinates = JSON.parse(parsedRings[0]); // Access the nested rings array
    const formattedRings = coordinates.map((item) => [item.latitude, item.longitude]);
    setFormattedRings(formattedRings);
  }, [rings]);
  
  // console.log('formattedRings:', formattedRings);
//fetch from store

  useEffect(() => {
    async function fetchData() {
      const farmDetailsData = await fetchFarmDetails();
      setPolygons(farmDetailsData);
      console.log(farmDetailsData);
    }

    fetchData();
  }, []);



  const handleRightArrowClick = () => {
    setLeftWidth(leftWidth +10);
    setRightWidth(rightWidth - 10);
  };

  const handleLeftArrowClick = () => {
    setLeftWidth(leftWidth - 10);
    setRightWidth(rightWidth + 10);
    
  };


  const handlePointClick = (coordinates) => {
    setClickedCoordinates(coordinates);
  };

    //funtion to get the clicked coordinates from child component
    const handleSelectedCoordinates = (coordinates) => {
      // Parse the coordinates because they are stringified
      const parsedCoordinates = JSON.parse(coordinates);
  
      console.log(parsedCoordinates)
      setSelectedCoordinates(parsedCoordinates);
      // Now you have access to the latitude and longitude
   
    };

  return (
    <div className='home-container'>
      <FarmerTopBar />
      <Helmet>
        <link
          rel="stylesheet"
          href="https://js.arcgis.com/4.26/esri/themes/light/main.css"
        />
      </Helmet>
      
      <section className='mymap-wrapper'>
        

      <div className='left-myMap' style={{ position:'relative', padding:'10px' }}>
        <section className="my-fields-header">
        
      </section>
        {/* <div className='center-buttons'>
          <button onClick={handleLeftArrowClick}><AiFillCaretLeft /></button>
          <button onClick={handleRightArrowClick}><AiFillCaretRight /></button>
        </div> */}
         <DamageList  farmDetails= {polygons} clickedCoordinates={clickedCoordinates}  onCoordinatesClick={handleSelectedCoordinates}  setPoly={setRings}  />
        </div>


        <div className='right-myMap' style={{  }}>
          <PointMap  handlePointClick={handlePointClick} selectedIssueCoordinates={clickedCoordinates} displayPoint={selectedCoordinates} selectedPolygon={formattedRings} style={style} />
        </div>
      </section>
    </div>
  );
};

export default Damages;

