import React from 'react';
import { AiOutlineSearch, AiOutlineBell, AiOutlineUser } from 'react-icons/ai';

import {RiHistoryFill} from 'react-icons/ri'
import './topbar.css';

const TopBar = ({filterValue, handleFilterChange}) => {
  return (
    <div className="topbar">
      <div className="search">
        <AiOutlineSearch className="search-icon" />
        <input
        type="text"
        placeholder="Search..."
        value={filterValue}
        onChange={handleFilterChange}
      />
      </div>
      <div className="icons">
      <RiHistoryFill className="icon" />
        <AiOutlineBell className="icon" />
        <AiOutlineUser className="icon" />
      </div>
    </div>
  );
};

export default TopBar;
