import React from 'react';
import { MapContainer, TileLayer } from 'react-leaflet';

const Leaflet = () => {
  return (
    <MapContainer center={[0, 0]} zoom={5} style={{ height: "400px", width: "100%" }}>
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
    </MapContainer>
  );
};

export default Leaflet;
