import React, { useEffect, useState, useMemo } from 'react'
import { MapContainer, Polygon, TileLayer, useMap } from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
import * as d3 from 'd3'
import { useRef } from 'react'
import axios from 'axios'
import GeoRasterLayer from 'georaster-layer-for-leaflet'
import parse_georaster from 'georaster'
import L from 'leaflet'
import isEqual from 'lodash.isequal'
import { useTranslation } from 'react-i18next'

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Cell,
} from 'recharts'
let getColorLabel
export const GraphComponent = ({
  filteredColorPercentages,
  area,
  ndvi,
  msavi,
  ndre,
  ndwi,
  evapo,
  ndmi,
  showGraph,
}) => {
  // const { t } = useTranslation()
  const totalPixels = Object.values(filteredColorPercentages).reduce(
    (total, count) => total + count,
    0
  )
  console.log(totalPixels)
  console.log(filteredColorPercentages) // { '#000': 100, '#FFF': 200, ... }
  getColorLabel = (color) => {
    switch (color) {
      // NDMI
      case '#D7E6F5':
        return t('colorDescriptions.ndmi.dryArea')
      case '#bad2e0':
        return t('colorDescriptions.ndmi.moderatelyDryArea')
      case '#90C2DE':
        return t('colorDescriptions.ndmi.moderateMoisture')
      case '#3A89C1':
        return t('colorDescriptions.ndmi.highMoisture')
      case '#2473B5':
        return t('colorDescriptions.ndmi.veryHighMoisture')

      // NDVI
      case '#E0774D':
        return t('colorDescriptions.ndvi.unhealthyPlant')
      case '#FEE090':
        return t('colorDescriptions.ndvi.moderatelyHealthyPlant')
      case '#9DCC6F':
        return t('colorDescriptions.ndvi.healthyPlant')
      case '#029956':
        return t('colorDescriptions.ndvi.veryHealthyPlant')

      // NDRE
      case '#4B9D2F':
        return t('colorDescriptions.ndre.healthyMatureRipeningCrops')
      case '#5EA63F':
        return t('colorDescriptions.ndre.healthyMatureRipeCrops')
      case '#B1D77E':
        return t('colorDescriptions.ndre.unhealthyPlantOrNotMatureYet')
      case '#FFF2B6':
        return t('colorDescriptions.ndre.bareSoilOrDevelopingCrop')

      // MSAVI
      case '#FCD894':
        return t('colorDescriptions.msavi.bareSoil')
      case '#C6E980':
        return t('colorDescriptions.msavi.seedGerminationStage')
      case '#C8E392':
        return t('colorDescriptions.msavi.leafDevelopmentStage')
      case '#2B9D52':
        return t('colorDescriptions.msavi.applyNDVI')

      // NDWI
      case '#E0ECF7':
        return t('colorDescriptions.ndwi.droughtNonAqueousSurfaces')
      case '#A5CCE4':
        return t('colorDescriptions.ndwi.nonAqueousSurfaces')
      case '#66ED00':
        return t('colorDescriptions.ndwi.floodingHumidity')
      case '#007E00':
        return t('colorDescriptions.ndwi.waterSurface')

      case '#007E47':
        return '0.95 - 1.00'
      case '#009755':
        return '0.90 - 0.95'
      case '#14AA60':
        return '0.85 - 0.90'
      case '#53BD6B':
        return '0.80 - 0.85'
      case '#77CA6F':
        return '0.75 - 0.80'
      case '#9BD873':
        return '0.70 - 0.75'
      case '#B9E383':
        return '0.65 - 0.70'
      case '#D5EF94':
        return '0.60 - 0.65'
      case '#EAF7AC':
        return '0.55 - 0.60'
      case '#FDFEC2':
        return '0.50 - 0.55'
      case '#FFEFAB':
        return '0.45 - 0.50'
      case '#FFE093':
        return '0.40 - 0.45'
      case '#FFC67D':
        return '0.35 - 0.40'
      case '#FFAB69':
        return '0.30 - 0.35'
      case '#FF8D5A':
        return '0.25 - 0.30'
      case '#FE6C4A':
        return '0.20 - 0.25'
      case '#EF4C3A':
        return '0.15 - 0.20'
      case '#E02D2C':
        return '0.10 - 0.15'
      case '#C5142A':
        return '0.05 - 0.10'
      case '#C52F1D':
        return '-1 - 0.05'

      case '#444F89':
        return '0.90 - 1.00'
      case '#57679C':
        return '0.80 - 0.90'
      case '#6B7FAE':
        return '0.70 - 0.80'
      case '#7E97C1':
        return '0.60 - 0.70'
      case '#91B0D4':
        return '0.50 - 0.60'
      case '#A4C8E6':
        return '0.40 - 0.50'
      case '#B8E0F9':
        return '0.30 - 0.40'
      case '#C3E6EF':
        return '0.20 - 0.30'
      case '#CAE2D8':
        return '0.10 - 0.20'
      case '#D2DFC0':
        return '0.00 - 0.10'
      case '#D9DCA9':
        return '-0.10 - 0.00'
      case '#E1D991':
        return '-0.20 - -0.10'
      case '#E8D57A':
        return '-0.30 - -0.20'
      case '#EBC864':
        return '-0.40 - -0.30'
      case '#E6A754':
        return '-0.50 - -0.40'
      case '#E18543':
        return '-0.60 - -0.50'
      case '#DC6432':
        return '-0.70 - -0.60'
      case '#D64321':
        return '-0.80 - -0.70'
      case '#D12111':
        return '-0.90 - -0.80'
      case '#CC0000':
        return '-1.00 - -0.90'

      default:
        return ''
    }
  }

  const { t } = useTranslation()

  const chartData = Object.keys(filteredColorPercentages).map((color) => ({
    color,
    label: getColorLabel(color),
    percentage: (
      ((filteredColorPercentages[color] || 0) / totalPixels) *
      100
    ).toFixed(2),
  }))

  // Calculate the maximum percentage from chartData
  const maxPercentage = Math.max(
    ...chartData.map((entry) => parseFloat(entry.percentage))
  )

  // Calculate the maximum y-axis value rounded up to the nearest multiple of 25
  const maxYAxisValue = Math.ceil(maxPercentage / 5) * 5

  return (
    <div style={{ marginTop: '20px', width: '100%' }} className="indices-graph">
      {showGraph && (
        <BarChart
          //width to be 100% of the parent div

          width={700}
          height={300}
          data={chartData}
          margin={{
            top: 5,
            right: 30,
            left: 0,
            bottom: 40,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="label"
            tick={{ fill: 'white' }}
            //slanted x axis
            angle={-45}
            textAnchor="end"
          />
          <YAxis domain={[0, maxYAxisValue]} tick={{ fill: 'white' }} />
          <Tooltip />
          <Legend />
          <Bar
            dataKey="percentage"
            //dont show x axis label
            label={false}
          >
            {chartData.map((entry, index) => (
              <Cell key={index} fill={entry.color} />
            ))}
          </Bar>
        </BarChart>
      )}

      <div className="legend">
        {ndvi ? (
          <>
            <div className="gradient-bar"></div>
            <div className="gradient-bar-labels">
              <div className="gradient-bar-label">{t('bare soil')}</div>
              <div className="gradient-bar-label">{t('sparce plants')}</div>
              <div className="gradient-bar-label">
                {t('moderately healthy')}
              </div>
              <div className="gradient-bar-label">{t('healthy Plants')}</div>
            </div>
          </>
        ) : msavi ? (
          <>
            <div className="gradient-bar"></div>
            <div className="gradient-bar-labels">
              <div className="gradient-bar-label">{t('bare soil')}</div>
              <div className="gradient-bar-label">{t('seed germination')} </div>
              <div className="gradient-bar-label">{t('leaf development')} </div>
              <div className="gradient-bar-label">{t('use Mid Stage')}</div>
            </div>
          </>
        ) : ndre ? (
          <>
            <div className="gradient-bar"></div>
            <div className="gradient-bar-labels">
              <div className="gradient-bar-label">{t('bare soil')}</div>
              <div className="gradient-bar-label">{t('sparse plants')}</div>
              <div className="gradient-bar-label">
                {t('moderately healthy')}
              </div>
              <div className="gradient-bar-label">
                {t('dense healthy plants')}
              </div>
            </div>
          </>
        ) : ndwi ? (
          <>
            <div className="gradient-bar ndwi"></div>
            <div className="gradient-bar-labels">
              <div className="gradient-bar-label">
                {t('drought, non-aqueous surfaces')}
              </div>
              <div className="gradient-bar-label">
                {t('non-aqueous surfaces')}
              </div>
              <div className="gradient-bar-label">
                {t('flooding, humidity')}
              </div>
              <div className="gradient-bar-label">{t('water surface')}</div>
            </div>
          </>
        ) : ndmi ? (
          <>
            <div className="gradient-bar ndmi"></div>
            <div className="gradient-bar-labels">
              <div className="gradient-bar-label">{t('bare soil')}</div>
             
              <div className="gradient-bar-label">
                {t('mid-low canopy & high water stress')}
              </div>
              <div className="gradient-bar-label">
                {t('average canopy & high water stress')}
              </div>
              <div className="gradient-bar-label">
                {t('mid-high canopy high water stress')}
              </div>
              <div className="gradient-bar-label no-water-stress">
                {t('no water stress and high canopy cover')}
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="gradient-bar evapo"></div>
            <div className="gradient-bar-labels">
              <div className="gradient-bar-label">{t('unhealthy plant')}</div>
              <div className="gradient-bar-label">
                {t('moderately healthy plant')}
              </div>
              <div className="gradient-bar-label">{t('healthy plant')}</div>
              <div className="gradient-bar-label">
                {t('very Healthy Plant')}
              </div>
            </div>
          </>
        )}

        {/* <h3 className="legend-title"> {t('rEFERENCE GUIDE')}</h3> */}
        <div className="legend-items">
          {chartData.map((entry, index) => (
            <div key={index} className="legend-item">
              <div
                className="legend-color"
                style={{ backgroundColor: entry.color }}
              />
              <div className="legend-label">{entry.label}</div>
              <div className="legend-percentage">
                {parseFloat(entry.percentage).toFixed(0)}%
              </div>
              <div className="legend-hectares">
                {((entry.percentage / 100) * area).toFixed(2)} ha
              </div>
            </div>
          ))}
          <div className="legend-item">
            <div
              className="legend-color"
              style={{ backgroundColor: 'transparent' }}
            />
            <div className="legend-label"></div>
          </div>

          <section className="vertical-bar">
            {chartData && (
              <>
                {ndvi ? (
                  <>
                    <div className="vertical-gradient-bar-labels">
                      <div className="vertical-gradient-bar-label">
                        {t('bare soil')}
                      </div>
                      <div className="vertical-gradient-bar-label">
                        {t('sparce plants')}
                      </div>
                      <div className="vertical-gradient-bar-label">
                        {t('moderately healthy')}
                      </div>
                      <div className="vertical-gradient-bar-label">
                        {t('healthy Plants')}
                      </div>
                    </div>
                  </>
                ) : msavi ? (
                  <>
                    <div className="vertical-gradient-bar-labels">
                      <div className="vertical-gradient-bar-label">
                        {t('bare soil')}
                      </div>
                      <div className="vertical-gradient-bar-label">
                        {t('seed germination')}{' '}
                      </div>
                      <div className="vertical-gradient-bar-label">
                        {t('leaf development')}{' '}
                      </div>
                      <div className="vertical-gradient-bar-label">
                        {t('use Mid Stage')}
                      </div>
                    </div>
                  </>
                ) : ndre ? (
                  <>
                    <div className="vertical-gradient-bar-labels">
                      <div className="vertical-gradient-bar-label">
                        {t('bare soil')}
                      </div>
                      <div className="vertical-gradient-bar-label">
                        {t('sparse plants')}
                      </div>
                      <div className="vertical-gradient-bar-label">
                        {t('moderately healthy')}
                      </div>
                      <div className="vertical-gradient-bar-label">
                        {t('dense healthy plants')}
                      </div>
                    </div>
                  </>
                ) : ndwi ? (
                  <>
                    {/* <div className='vertical-gradient-bar-labels'>
        <div className='vertical-gradient-bar-label'>Drought, non-aqueous surfaces</div>
        <div className='vertical-gradient-bar-label'>Non-aqueous surfaces</div>
        <div className='vertical-gradient-bar-label'>Flooding, humidity</div>
        <div className='vertical-gradient-bar-label'>Water surface</div>
      </div> */}
                  </>
                ) : ndmi ? (
                  <>
                    {/* <div className="vertical-gradient-bar-labels">
                      <div className="vertical-gradient-bar-label">
                        {t('bare soil')}
                      </div>
                     
                      <div className="vertical-gradient-bar-label">
                        {t('mid-low canopy & high water stress')}
                      </div>
                      <div className="vertical-gradient-bar-label">
                        {t('average canopy & high water stress')}
                      </div>
                      <div className="vertical-gradient-bar-label">
                        {t('mid-high canopy & high water stress')}
                      </div>
                      <div className="vertical-gradient-bar-label no-water-stress">
                        {t('no water stress and high canopy cover')}
                      </div>
                    </div> */}
                  </>
                ) : (
                  <>
                    <div className="vertical-gradient-bar-labels">
                      <div className="vertical-gradient-bar-label">
                        {t('unhealthy plant')}
                      </div>
                      <div className="vertical-gradient-bar-label">
                        {t('moderately healthy plant')}
                      </div>
                      <div className="vertical-gradient-bar-label">
                        {t('healthy plant')}
                      </div>
                      <div className="vertical-gradient-bar-label">
                        {t('very Healthy Plant')}
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
          </section>
        </div>
      </div>
    </div>
  )
}

// Custom component to create GeoRasterLayer
// Custom component to create GeoRasterLayer
const GeoRasterLayerComponent = ({
  georaster,
  selectedPolygon,
  polygonCoordinates,
  setColorPercentages,
  timeRange,
  ndmiData,
  ndreData,
  ndwiData,
  msaviData,
  evapoData,
}) => {
  const map = useMap()
  const layerRef = useRef()

  useEffect(() => {
    if (georaster) {
      if (layerRef.current) {
        map.removeLayer(layerRef.current)
        layerRef.current = null
      }

      const layer = new GeoRasterLayer({
        georaster,
        opacity: 1,
        pixelValuesToColorFn: (values) => {
          const ndvi = values[0]
          const ndmi = values[0]
          const ndre = values[0]
          const ndwi = values[0]
          const msavi = values[0]
          const evapo = values[0]

          let color = 'transparent'

          // Define your color interpolation scale using D3 here
          const colorScale = d3
            .scaleSequential(d3.interpolateViridis)
            .domain([-1, 1])

          // Define a custom color scale for NDMI (bluish colors)
          const ndmiColorScale = d3
            .scaleLinear()
            .domain([
              -1, -0.9, -0.8, -0.7, -0.6, -0.5, -0.4, -0.3, -0.2, -0.1, 0, 0.1,
              0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1,
            ])
            .range([
              '#CC0000',
              '#D12111',
              '#D64321',
              '#DC6432',
              '#E18543',
              '#E6A754',
              '#EBC864',
              '#E8D57A',
              '#E1D991',
              '#D9DCA9',
              '#D2DFC0',
              '#CAE2D8',
              '#C3E6EF',
              '#B8E0F9',
              '#A4C8E6',
              '#91B0D4',
              '#7E97C1',
              '#6B7FAE',
              '#57679C',
              '#444F89',
            ])

          // Define a custom color scale for NDVI (green to red)
          const ndviColorScale = d3
            .scaleLinear()
            .domain([
              -0.2, 0.05, 0.1, 0.15, 0.2, 0.25, 0.3, 0.35, 0.4, 0.45, 0.5, 0.55,
              0.6, 0.65, 0.7, 0.75, 0.8, 0.85, 0.9, 0.95, 1,
            ])
            .range([
              '#C52F1D',
              '#C5142A',
              '#E02D2C',
              '#EF4C3A',
              '#FE6C4A',
              '#FF8D5A',
              '#FFAB69',
              '#FFC67D',
              '#FFE093',
              '#FFEFAB',
              '#FDFEC2',
              '#EAF7AC',
              '#D5EF94',
              '#B9E383',
              '#9BD873',
              '#77CA6F',
              '#53BD6B',
              '#14AA60',
              '#009755',
              '#007E47',
            ])

          const ndreColorScale = d3
            .scaleSequential(d3.interpolateRdYlGn) // Red to green color scale
            .domain([-1, 1]) // Adjust the domain based on your data range

          const msaviColorScale = d3
            .scaleLinear()
            .domain([-1, 0, 1])
            .range([
              '#C52F1D',
              '#C5142A',
              '#E02D2C',
              '#EF4C3A',
              '#FE6C4A',
              '#FF8D5A',
              '#FFAB69',
              '#FFC67D',
              '#FFE093',
              '#FFEFAB',
              '#FDFEC2',
              '#EAF7AC',
              '#D5EF94',
              '#B9E383',
              '#9BD873',
              '#77CA6F',
              '#53BD6B',
              '#14AA60',
              '#009755',
              '#007E47',
            ])

          const ndwiColorScale = d3
            .scaleLinear()
            .domain([-1, -0.3, 0, 0.2, 1])
            .range(['#CC0000', '#E6A754', '#CAE2D8', '#444F89'])

          const etColorScale = d3
            .scaleSequential(d3.interpolateYlOrRd) // Yellow to red color scale
            .domain([0, 1]) // Adjust the domain based on your ET data range

          if (ndmiData) {
            if (ndmi >= -1 && ndmi <= 1) {
              color = ndmiColorScale(ndmi)
            }
          } else if (ndreData) {
            if (ndre >= -1 && ndre <= 1) {
              color = ndviColorScale(ndre)
            }
          } else if (ndwiData) {
            if (ndwi >= -1 && ndwi <= 1) {
              color = ndwiColorScale(ndwi)
            }
          } else if (msaviData) {
            if (msavi >= -1 && msavi <= 1) {
              if (msavi >= 0 && msavi <= 0.0025) {
                color = 'transparent'
              } else {
                color = ndviColorScale(msavi)
              }
            }
          } else if (evapoData) {
            if (evapo >= -1 && evapo <= 1) {
              if (evapo >= 0 && evapo <= 0.0025) {
                color = 'transparent'
              } else {
                color = ndmiColorScale(evapo)
              }
            }
          } else {
            if (ndvi >= -1 && ndvi <= 1) {
              color = ndviColorScale(ndvi)
            }
          }

          const ndwiColorRanges = [
            { range: [-1, -0.3], color: '#CC0000' },
            { range: [-0.3, 0], color: '#E6A754' },
            { range: [0, 0.2], color: '#CAE2D8' },
            { range: [0.2, 1], color: '#444F89' },
          ]

          const ndmiColorRanges = [
            { range: [-1.0, -0.9], color: '#CC0000' },
            { range: [-0.9, -0.8], color: '#D12111' },
            { range: [-0.8, -0.7], color: '#D64321' },
            { range: [-0.7, -0.6], color: '#DC6432' },
            { range: [-0.6, -0.5], color: '#E18543' },
            { range: [-0.5, -0.4], color: '#E6A754' },
            { range: [-0.4, -0.3], color: '#EBC864' },
            { range: [-0.3, -0.2], color: '#E8D57A' },
            { range: [-0.2, -0.1], color: '#E1D991' },
            { range: [-0.1, 0.0], color: '#D9DCA9' },
            { range: [0.0, 0.1], color: '#D2DFC0' },
            { range: [0.1, 0.2], color: '#CAE2D8' },
            { range: [0.2, 0.3], color: '#C3E6EF' },
            { range: [0.3, 0.4], color: '#B8E0F9' },
            { range: [0.4, 0.5], color: '#A4C8E6' },
            { range: [0.5, 0.6], color: '#91B0D4' },
            { range: [0.6, 0.7], color: '#7E97C1' },
            { range: [0.7, 0.8], color: '#6B7FAE' },
            { range: [0.8, 0.9], color: '#57679C' },
            { range: [0.9, 1.0], color: '#444F89' },
          ]

          const ndviColorRanges = [
            { range: [-1, 0.05], color: '#C52F1D' },
            { range: [0.05, 0.1], color: '#C5142A' },
            { range: [0.1, 0.15], color: '#E02D2C' },
            { range: [0.15, 0.2], color: '#EF4C3A' },
            { range: [0.2, 0.25], color: '#FE6C4A' },
            { range: [0.25, 0.3], color: '#FF8D5A' },
            { range: [0.3, 0.35], color: '#FFAB69' },
            { range: [0.35, 0.4], color: '#FFC67D' },
            { range: [0.4, 0.45], color: '#FFE093' },
            { range: [0.45, 0.5], color: '#FFEFAB' },
            { range: [0.5, 0.55], color: '#FDFEC2' },
            { range: [0.55, 0.6], color: '#EAF7AC' },
            { range: [0.6, 0.65], color: '#D5EF94' },
            { range: [0.65, 0.7], color: '#B9E383' },
            { range: [0.7, 0.75], color: '#9BD873' },
            { range: [0.75, 0.8], color: '#77CA6F' },
            { range: [0.8, 0.85], color: '#53BD6B' },
            { range: [0.85, 0.9], color: '#14AA60' },
            { range: [0.9, 0.95], color: '#009755' },
            { range: [0.95, 1.0], color: '#007E47' },
          ]

          setColorPercentages((prevColorPercentages) => {
            if (ndreData) {
              const newColorPercentages = { ...prevColorPercentages }

              if (color !== 'transparent') {
                for (const { range, color } of ndviColorRanges) {
                  // Initialize counts to 0 for colors that don't exist in prevColorPercentages
                  if (newColorPercentages[color] === undefined) {
                    newColorPercentages[color] = 0
                  }

                  const [min, max] = range
                  if (ndre >= min && ndre <= max) {
                    newColorPercentages[color] += 1
                    // break; // Exit the loop after finding the matching range
                  }
                }
              }

              // Remove any properties with undefined or null values
              for (const color in newColorPercentages) {
                if (
                  newColorPercentages[color] === undefined ||
                  newColorPercentages[color] === null
                ) {
                  delete newColorPercentages[color]
                }
              }

              return newColorPercentages
            } else if (ndmiData) {
              const newColorPercentages = { ...prevColorPercentages }
              if (color !== 'transparent') {
                for (const { range, color } of ndmiColorRanges) {
                  // Initialize counts to 0 for colors that don't exist in prevColorPercentages
                  if (newColorPercentages[color] === undefined) {
                    newColorPercentages[color] = 0
                  }

                  const [min, max] = range
                  if (ndmi >= min && ndmi <= max) {
                    newColorPercentages[color] += 1
                  }
                }
              }

              // Remove any properties with undefined or null values
              for (const color in newColorPercentages) {
                if (
                  newColorPercentages[color] === undefined ||
                  newColorPercentages[color] === null
                ) {
                  delete newColorPercentages[color]
                }
              }

              return newColorPercentages
            } else if (ndwiData) {
              const newColorPercentages = { ...prevColorPercentages }

              if (color !== 'transparent') {
                for (const { range, color } of ndwiColorRanges) {
                  // Initialize counts to 0 for colors that don't exist in prevColorPercentages
                  if (newColorPercentages[color] === undefined) {
                    newColorPercentages[color] = 0
                  }

                  const [min, max] = range
                  if (ndwi >= min && ndwi <= max) {
                    newColorPercentages[color] += 1
                  }
                }
              }

              // Remove any properties with undefined or null values
              for (const color in newColorPercentages) {
                if (
                  newColorPercentages[color] === undefined ||
                  newColorPercentages[color] === null
                ) {
                  delete newColorPercentages[color]
                }
              }
              return newColorPercentages
            } else if (evapoData) {
              const newColorPercentages = { ...prevColorPercentages }
              if (color !== 'transparent') {
                for (const { range, color } of ndmiColorRanges) {
                  // Initialize counts to 0 for colors that don't exist in prevColorPercentages
                  if (newColorPercentages[color] === undefined) {
                    newColorPercentages[color] = 0
                  }

                  const [min, max] = range
                  if (evapo >= min && evapo <= max) {
                    newColorPercentages[color] += 1
                  }
                }
              }

              // Remove any properties with undefined or null values
              for (const color in newColorPercentages) {
                if (
                  newColorPercentages[color] === undefined ||
                  newColorPercentages[color] === null
                ) {
                  delete newColorPercentages[color]
                }
              }
              return newColorPercentages
            } else if (msaviData) {
              const newColorPercentages = { ...prevColorPercentages }

              if (color !== 'transparent') {
                for (const { range, color } of ndviColorRanges) {
                  // Initialize counts to 0 for colors that don't exist in prevColorPercentages
                  if (newColorPercentages[color] === undefined) {
                    newColorPercentages[color] = 0
                  }

                  const [min, max] = range
                  if (msavi >= min && msavi <= max) {
                    newColorPercentages[color] += 1
                    // break; // Exit the loop after finding the matching range
                  }
                }
              }

              // Remove any properties with undefined or null values
              for (const color in newColorPercentages) {
                if (
                  newColorPercentages[color] === undefined ||
                  newColorPercentages[color] === null
                ) {
                  delete newColorPercentages[color]
                }
              }

              return newColorPercentages
            } else {
              const newColorPercentages = { ...prevColorPercentages }

              if (color !== 'transparent') {
                for (const { range, color } of ndviColorRanges) {
                  // Initialize counts to 0 for colors that don't exist in prevColorPercentages
                  if (newColorPercentages[color] === undefined) {
                    newColorPercentages[color] = 0
                  }

                  const [min, max] = range
                  if (ndvi >= min && ndvi <= max) {
                    newColorPercentages[color] += 1
                  }
                }
              }

              // Remove any properties with undefined or null values
              for (const color in newColorPercentages) {
                if (
                  newColorPercentages[color] === undefined ||
                  newColorPercentages[color] === null
                ) {
                  delete newColorPercentages[color]
                }
              }

              return newColorPercentages
            }
          })
          return color
        },
        resolution: 100,
      })

      layer.addTo(map)
      map.fitBounds(layer.getBounds())

      if (polygonCoordinates.length > 0) {
        const polygonCenter = polygonCoordinates
          .reduce(
            (prev, curr) => [prev[0] + curr[0], prev[1] + curr[1]],
            [0, 0]
          )
          .map((coordinate) => coordinate / polygonCoordinates.length)

        // map.setView([polygonCenter[1], polygonCenter[0]], 14)

        const latLngs = polygonCoordinates.map((coord) => [coord[1], coord[0]])
        const polygon = L.polygon(latLngs, {
          color: 'yellow',
          fill: false,
        }).addTo(map)
      }

      layerRef.current = layer
    }

    return () => {
      if (layerRef.current) {
        map.removeLayer(layerRef.current)
      }
    }
  }, [map, timeRange])

  return null
}

// Main component.
const NDVIMap = ({
  token,
  selectedPolygon,
  onFilteredColorPercentages,
  parentFilteredColorPercentages,
  fetchMetaData,
  ndmiData,
  ndreData,
  ndwiData,
  msaviData,
  ndviData,
  lateShowedDate,
  showedDate,
}) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [georaster, setGeoraster] = useState(null)
  const [colorPercentages, setColorPercentages] = useState({})

  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const style = {
    height: windowWidth < 770 ? '50vh' : '100vh',
    width: '100%',
    position: 'relative',
  }

  const url = 'https://services-uswest2.sentinel-hub.com/api/v1/process'
  const timeRange = useMemo(() => {
    if (showedDate && lateShowedDate) {
      return {
        from: lateShowedDate + 'T00:00:00Z',
        to: showedDate + 'T23:59:59Z',
      }
    } else {
      return { from: '2022-05-01T00:00:00Z', to: '2022-05-31T23:59:59Z' }
    }
  }, [showedDate, lateShowedDate])

  const polygonCoordinates = selectedPolygon
    ? selectedPolygon.map((point) => [point[0], point[1]])
    : []
  let evalscript = ''
  const fetchData = async () => {
    const accessToken = token
    const requestData = new FormData()
    if (ndmiData) {
      evalscript = `//VERSION=3
    function setup() {
      return {
        input: [{ bands: ['B05', 'B06'] }], // Using Near Infrared (B05) and SWIR 1 (B06) bands
        output: [{ id: 'default', bands: 1, sampleType: 'FLOAT32' }],
      };
    }
  
    function evaluatePixel(sample) {
      let ndmi = (sample.B05 - sample.B06) / (sample.B05 + sample.B06);
      return { default: [ndmi] };
    }`
    } else if (ndreData) {
      evalscript = `//VERSION=3
    function setup() {
      return {
        input: [{ bands: ['B05', 'B04', 'B03'] }],
        output: [{ id: 'default', bands: 1, sampleType: 'FLOAT32' }],
      };
    }
    
    function evaluatePixel(sample) {
      let ndre = (sample.B05 - sample.B04) / (sample.B05 + sample.B04);
      return { default: [ndre] };
    }`
    } else if (ndwiData) {
      evalscript = `//VERSION=3
    function setup() {
  return {
    input: [{ bands: ['B03', 'B05'] }],
    output: [{ id: 'default', bands: 1, sampleType: 'FLOAT32' }],
  };
}

function evaluatePixel(sample) {
  let ndwi = (sample.B03 - sample.B05) / (sample.B03 + sample.B05);
  return { default: [ndwi] };
}`
    } else if (msaviData) {
      evalscript = `//VERSION=3
    function setup() {
      return {
        input: [{ bands: ['B04', 'B05'] }],
        output: [{ id: 'default', bands: 1, sampleType: 'FLOAT32' }],
      };
    }
    
    function evaluatePixel(sample) {
      let L = 0.2; // L parameter (soil brightness correction factor)
      let msavi = ((2 * sample.B05 + 1) - Math.sqrt((2 * sample.B05 + 1) ** 2 - 8 * (sample.B05 - sample.B04))) / 2 - L;
      return { default: [msavi] };
    }`
    } else {
      evalscript = `//VERSION=3
     function setup() {
      return {
        input: [{ bands: ['B04', 'B05'] }],
        output: [{ id: 'default', bands: 1, sampleType: 'FLOAT32' }],
      };
    }
    
    function evaluatePixel(sample) {
      let ndvi = (sample.B05 - sample.B04) / (sample.B05 + sample.B04);
      return { default: [ndvi] };
    }`
    }

    requestData.append(
      'request',
      JSON.stringify({
        input: {
          bounds: {
            properties: { crs: 'http://www.opengis.net/def/crs/OGC/1.3/CRS84' },
            geometry: {
              type: 'Polygon',
              coordinates: [polygonCoordinates],
            },
          },
          data: [
            {
              type: 'landsat-ot-l2',
              dataFilter: {
                timeRange: { from: timeRange.from, to: timeRange.to },
              },
            },
          ],
        },
        output: {
          responses: [
            { identifier: 'default', format: { type: 'image/tiff' } },
          ],
        },
      })
    )
    requestData.append('evalscript', evalscript)

    const requestOptions = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      body: requestData,
    }

    try {
      const response = await fetch(url, requestOptions)
      if (response.ok) {
        const arrayBuffer = await response.arrayBuffer()
        const georaster = await parse_georaster(arrayBuffer)
        return georaster
      } else {
        console.log('Error:', response.status, response.statusText)
        return null
      }
    } catch (error) {
      console.log('Error:', error)
      return null
    }
  }

  useEffect(() => {
    // Reset georaster and colorPercentages when selectedDate changes
    setGeoraster(null)
    setColorPercentages({})

    if (!token || !polygonCoordinates) return

    setLoading(true)
    fetchData()
      .then((georaster) => {
        setGeoraster(georaster)
        fetchMetaData()
        setColorPercentages({})
        setLoading(false)
      })
      .catch((error) => {
        console.log('Error:', error)
        setLoading(false)
      })
      .finally(() => setLoading(false))
  }, [selectedPolygon, timeRange, ndmiData, ndreData, ndwiData, msaviData])

  const filteredColorPercentages = Object.entries(colorPercentages).reduce(
    (filtered, [color, count]) => {
      if (color !== 'transparent') {
        filtered[color] = count
      }
      return filtered
    },
    {}
  )

  useEffect(() => {
    if (!isEqual(filteredColorPercentages, parentFilteredColorPercentages)) {
      onFilteredColorPercentages(filteredColorPercentages)
    }
  }, [filteredColorPercentages, parentFilteredColorPercentages])

  const validColorCount = Object.values(filteredColorPercentages).reduce(
    (total, count) => total + count,
    0
  )

  const mapKey = useMemo(() => Math.random(), [georaster]) // Generate a new key every time georaster changes

  return (
    <>
      {loading && (
        <div className="loading-image-overlay">
          <div className="loading-text">Loading...</div>
        </div>
      )}

      {!georaster && !loading && (
        <div className="loading-image-overlay">
          <div className="visual-wait">
            {t('vizualization will appear here upon selecting a farm.')}
          </div>
        </div>
      )}

      <MapContainer
        id="map"
        center={[44.53004060621996, 25.958722230021067]}
        zoom={7}
        style={style}
      >
        {/* {loading && (
          <div className="loading-image-overlay">
            <div className="loading-text">Loading...</div>
          </div>
        )} */}
        <TileLayer
          url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
          attribution="Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community"
        />

        {georaster && (
          <>
            <GeoRasterLayerComponent
              georaster={georaster}
              polygonCoordinates={polygonCoordinates}
              setColorPercentages={setColorPercentages}
              timeRange={timeRange}
              ndmiData={ndmiData}
              ndreData={ndreData}
              ndwiData={ndwiData}
              msaviData={msaviData}
              ndviData={ndviData}
            />
          </>
        )}
        {/* {polygonCoordinates.length > 0 && <Polygon positions={polygonCoordinates.map(coord => [coord[1], coord[0]])} color="red" />} */}
      </MapContainer>
    </>
  )
}

export default NDVIMap
