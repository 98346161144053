
import React, { useEffect, useState } from 'react'
import TopBar from '../../topbar/Topbar'
import ReactQuill from 'react-quill';
import { useNavigate } from 'react-router-dom';
import AWS from "aws-sdk";
import { API, graphqlOperation } from 'aws-amplify';
import { toast } from 'react-toastify';
import 'react-quill/dist/quill.snow.css'; // import styles
import { createNews, deleteNews, updateNews } from '../../../../../graphql/mutations';
import { listNews } from '../../../../../graphql/queries';

const CreateNews = () => {
const [value, setValue] = useState('');
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [imageUpload, setImageUpload] = useState('');
    const [progress, setProgress] = useState(0);
    const [update, setUpdate] = useState(false);
    const [news, setNews] = useState([]);
    const [isEdditing, setIsEdditing] = useState(false);
    const [description, setDescription] = useState('');
    const [showInputs, setShowInputs] = useState(false);
    const [currentId, setCurrentId] = useState(null);


    const navigate = useNavigate();

    useEffect(() => {
        fetchNews();
        }, [update]);



    const fetchNews = async () => {
        try {
          const newsData = await API.graphql(graphqlOperation(listNews));
          setNews(newsData.data.listNews.items);
          console.log(newsData);
        } catch (error) {
          console.log(error);
        }
      }

  //send data to backend
  const handleSubmit = async (e) => {
    e.preventDefault();

   const S3_BUCKET = "officialagrogis";

    // S3 Region
    const REGION = "us-east-1";

    // S3 Credentials
    AWS.config.update({
      accessKeyId: "AKIAZZNZN45OHZL2UDR3",
      secretAccessKey: "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",
    });
    const s3 = new AWS.S3({
      params: { Bucket: S3_BUCKET },
      region: REGION,
    });

    // Files Parameters
    if (imageUpload.name === undefined) {
      return toast.error('Please select an image');
    }

    const params = {
      Bucket: S3_BUCKET,
      Key: `public/NEWS/${title}.${imageUpload.name}`,
      Body: imageUpload,
    };

    // Uploading file to s3

    var upload = s3
      .putObject(params)
      .on("httpUploadProgress", (evt) => {
        // File uploading progress
        const percentUploaded = (evt.loaded / evt.total) * 100;
        const progressBar = document.getElementById("progress-bar");
        progressBar.style.width = percentUploaded + "%";

        setProgress(Math.round((evt.loaded / evt.total) * 100));
        console.log(
          "Uploading " + parseInt((evt.loaded * 100) / evt.total) + "%"
        );
      })
      .promise();

    await upload.then((err, data) => {
      console.log(err);
      // Fille successfully uploaded
    });

    //create a key that accepts png
    const data = {
      title,
        description,
     content: value,
      image: `public/NEWS/${title}.${imageUpload.name}`,
      published: true,
    
    };

    console.log(data);

    try {
      //send data to backend
      await API.graphql(graphqlOperation(createNews, { input: data }));
      toast.success('News created successfully');
      setUpdate(!update);
        
    } catch (error) {
      console.log(error);
    }
  }

    const handleEdit = (id) => {
        setCurrentId(id);
        setIsEdditing(true);
        setShowInputs(true);
    
        const newsItem = news.find(item => item.id === id);
        console.log(newsItem);
        setIsEdditing(true);
        setTitle(newsItem.title);
        setDescription(newsItem.description);
        setValue(newsItem.content);
        setContent(newsItem.content);
        setImageUpload(newsItem.image);
        //imitialize image
        

    }


    const deletePackage = async (id) => {
    

         try {
            await API.graphql(graphqlOperation(deleteNews, { input: { id } }));
            toast.success('News deleted successfully');
            setUpdate(!update);
            }
            catch (error) {
                console.log(error);
            }
    }

    const updatePackage = async (id) => {
      const S3_BUCKET = "officialagrogis";

      // S3 Region
      const REGION = "us-east-1";
  
      // S3 Credentials
      AWS.config.update({
        accessKeyId: "AKIAZZNZN45OHZL2UDR3",
        secretAccessKey: "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",
      });
      const s3 = new AWS.S3({
        params: { Bucket: S3_BUCKET },
        region: REGION,
      });
  
  
      const params = {
        Bucket: S3_BUCKET,
        Key: `public/NEWS/${title}.${imageUpload.name}`,
        Body: imageUpload,
      };
  
      // Uploading file to s3
  
      var upload = s3
        .putObject(params)
        .on("httpUploadProgress", (evt) => {
          // File uploading progress
          const percentUploaded = (evt.loaded / evt.total) * 100;
          const progressBar = document.getElementById("progress-bar");
          progressBar.style.width = percentUploaded + "%";
  
          setProgress(Math.round((evt.loaded / evt.total) * 100));
          console.log(
            "Uploading " + parseInt((evt.loaded * 100) / evt.total) + "%"
          );
        })
        .promise();
  
      await upload.then((err, data) => {
        console.log(err);
        // Fille successfully uploaded
      });

        const data = {
            id,
            title,
            description,
            content: value,
            // image: `public/NEWS/${title}.${imageUpload.name}`,
            published: true,
        };

        try {
            await API.graphql(graphqlOperation(updateNews, { input: data }));
            toast.success('News updated successfully');
            setUpdate(!update);
            setIsEdditing(false);
            setShowInputs(false);

            //reset form
            setTitle('');
            setDescription('');
            setValue('');
            setContent('');
            setImageUpload('');
            setCurrentId(null);
            
            }
            catch (error) {
                console.log(error);
            }

    }



  return (
    <div className='home-container'>
      <TopBar />
      <div className='packages-container'>

        <div className='package-sales-wrapper'>
          <section className='add-package'>
            <h2 >Upload News </h2>
            <div className='add-package-item'>
                  <h3>Title</h3>
                  <input type="text" placeholder='News Title' value={title} onChange={(e) => setTitle(e.target.value)} required />
                </div>
                <div className='add-package-item'>
                  <h3>Description</h3>
                  <textarea type='text' placeholder='Short Description' value={description} onChange={(e) => setDescription(e.target.value)} required />
                </div>
            <div className='add-package-item'>
                  <h3>Image</h3>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => setImageUpload(e.target.files[0])}
                  />
                  <div class="progress-container">
                    <div class="progress-bar" id="progress-bar"></div>
                  </div>

                </div>
            <ReactQuill theme="snow" value={value} onChange={setValue} />   

                <div className='add-package-item'>
                  {
                    isEdditing ? (
                      <button
                      onClick={() => updatePackage(currentId)}
                      >
                        Update
                      </button>
                    ) : (
                      <button onClick={handleSubmit}>Add News</button>
                    )
                  }
                </div>   

          </section>

          <section className=''>
            <h2>News Uploaded</h2>
            <div className='package-sales-plans'>
            {news.length === 0 && <h2>No news uploaded</h2>}

              {
                news.map((item, index) => (
                  <div className='package-sales-plan' key={index}>
                    <div className='package-sales-plan-title'>
                      <h3>{item.title}</h3>
                      <ReactQuill theme="snow" value={item.content} readOnly={true} />
                      <img src={`https://officialagrogis.s3.amazonaws.com/${item.image}`} alt={item.title} />
                    </div>
                    
                    <div className='package-bottom'>
                    <button className='package-button'
                      onClick={() => handleEdit(item.id)}
                    >Edit</button>
                    <button className='package-button' onClick={() => deletePackage(item.id)}>Delete</button>
                  </div>
                  </div>
                ))
              }
            </div>

          </section>
        </div>
      </div>
    </div>
  )
}

export default CreateNews