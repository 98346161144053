import React, { useState } from "react";
import cropOptions from "./crops/cropData";
import { MdOutlineCancel } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const FarmRegistration = ({
  handleInputChange,
  handleSend,
  formData,
  formLoading,
}) => {
  const [section, setSection] = useState(1);
  const [selectedCrop, setSelectedCrop] = useState("");
  const [selectedCropUse, setSelectedCropUse] = useState("");
  const { t } = useTranslation();

  const navigate = useNavigate();

  const handleCropChange = (e) => {
    const crop = e.target.value;
    setSelectedCrop(crop);
    setSelectedCropUse("");
  };

  const getCropUseOptions = () => {
    const selectedCropData = cropOptions.find(
      (crop) => crop.name === selectedCrop
    );
    return selectedCropData ? selectedCropData.cropUses : [];
  };

  const handleNext = () => {
    if (section < 4) {
      setSection(section + 1);
    }
  };

  const handlePrevious = () => {
    if (section > 1) {
      setSection(section - 1);
    }
  };

  const handleSubmit = () => {
    // Handle form submission logic
  };

  const handleInput = (e) => {
    const { name, value } = e.target;

    if (name === "crop") {
      setSelectedCrop(value);
      setSelectedCropUse("");
    }

    if (name === "cropUse") {
      setSelectedCropUse(value);
    }
    handleInputChange(name, value);
  };

  console.log(selectedCrop);
  console.log(selectedCropUse);

  return (
    <div className="farm-reg-container">
      <MdOutlineCancel
        className="cancel-btn"
        color="white"
        size={24}
        onClick={() => navigate("/myfarm")}
      />

      <div className="progress">
        <section className="progress-section">
          <div className={`progress-step ${section >= 1 ? "active" : ""}`}>
            1
          </div>
          <p>{t("farm Registration")}</p>
        </section>
        <section className="progress-section">
          <div className={`progress-step ${section >= 2 ? "active" : ""}`}>
            2
          </div>
          <p>{t("crop  Details")}</p>
        </section>
        <section className="progress-section">
          <div className={`progress-step ${section >= 3 ? "active" : ""}`}>
            3
          </div>
          <p>{t("farm details")}</p>
        </section>
        <section className="progress-section">
          <div className={`progress-step ${section >= 4 ? "active" : ""}`}>
            4
          </div>
          <p>{t("revenue")}</p>
        </section>
      </div>
      {section === 1 && (
        <div className="form-section">
          {/* Input fields for section 1 */}
          <form>
            <div className="form-group">
              <label htmlFor="farmName">{t("farm Name")}</label>
              <input
                type="text"
                name="name"
                id="farmName"
                value={formData.name}
                onChange={handleInput}
              />
            </div>
            <section className="input-section">
              <div className="form-group">
                <label htmlFor="crop">{t("crop")}</label>
                <select
                  name="crop"
                  id="crop"
                  value={selectedCrop}
                  onChange={handleInput}
                >
                  <option>{t("select")}</option>
                  {cropOptions.map((crop) => (
                    <option key={crop.name} value={crop.name}>
                      {crop.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="form-group">
                <label htmlFor="farmSize">{t("start Date")}</label>
                <input
                  type="date"
                  name="startDate"
                  id="startdate"
                  value={formData.startDate}
                  onChange={handleInput}
                />
              </div>
            </section>

            <section className="input-section">
              <div className="form-group">
                <label htmlFor="farmType">{t("crop Use")}</label>
                <select
                  name="cropUse"
                  id="cropUse"
                  value={selectedCropUse}
                  onChange={handleInput}
                >
                  <option>Select</option>
                  {getCropUseOptions().map((cropUse) => (
                    <option key={cropUse} value={cropUse}>
                      {cropUse}
                    </option>
                  ))}
                </select>
              </div>
            </section>
          </form>
        </div>
      )}
      {section === 2 && (
        <div className="form-section">
          {/* Input fields for section 2 */}
          <form>
            <section className="input-section">
              <div className="form-group">
                <label htmlFor="farmLocation">{t("earlyness")}</label>
                <select
                  name="Earlyness"
                  id="earlyness"
                  value={formData.Earlyness}
                  onChange={handleInput}
                >
                  <option>{t("select")}</option>
                  <option value="Early">{t("early")}</option>
                  <option value="Medium">{t("medium")}</option>
                  <option value="Late">{t("late")}</option>
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="farmSize">{t("protection")}</label>
                <select
                  name="Protection"
                  id="protection"
                  value={formData.Protection}
                  onChange={handleInput}
                >
                  <option>{t("select")}</option>
                  <option value="Open Field">{t("open Field")}</option>
                  <option value="Semi-protected">{t("semi-protected")}</option>
                  <option value="Protected">{t("protected")}</option>
                </select>
              </div>
            </section>

            <section className="input-section">
              <div className="form-group">
                <label htmlFor="farmType">{t("total yield expected")}</label>
                <div className="input-with-addon">
                  <span className="addon">kg</span>
                  <input
                    type="number"
                    name="TotalYield"
                    id="yield"
                    value={formData.TotalYield}
                    onChange={handleInput}
                  />
                </div>
              </div>

              <div className="form-group">
                <label htmlFor="farmDescription">{t("yield per ha")}</label>
                <div className="input-with-addon">
                  <span className="addon">kg/ha</span>
                  <input
                    type="number"
                    name="YieldPerha"
                    id="yield"
                    value={formData.YieldPerha}
                    onChange={handleInput}
                  />
                </div>
              </div>
            </section>

            <section className="input-section">
              <div className="form-group">
                <label htmlFor="farmType">{t("sowing date")}</label>
                <input
                  type="date"
                  name="sowingDate"
                  id="soeing"
                  value={formData.sowingDate}
                  onChange={handleInput}
                />
              </div>
              <div className="form-group">
                <label htmlFor="farmDescription">
                  {t("expected Harvest Date")}
                </label>
                <input
                  type="date"
                  name="harvestDate"
                  id="harvest"
                  value={formData.harvestDate}
                  onChange={handleInput}
                />
              </div>
            </section>
          </form>
        </div>
      )}
      {section === 3 && (
        <div className="form-section">
          {/* Input fields for section 3 */}
          <form>
            <section className="input-section">
              <div className="form-group">
                <label htmlFor="farmLocation">{t("soil Texture")} </label>
                <select
                  name="soilTexture"
                  id="soilTexture"
                  value={formData.soilTexture}
                  onChange={handleInput}
                >
                  <option>{t("select")}</option>
                  <option value="Clay">{t("clay")}</option>
                  <option value="Sandy">{t("sandy")}</option>
                  <option value="Silty">{t("silty")}</option>
                  <option value="Loamy">{t("loamy")}</option>
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="farmSize">{t("drainage")}</label>
                <select
                  name="drainage"
                  id="drainage"
                  value={formData.drainage}
                  onChange={handleInput}
                >
                  <option>{t("select")}</option>
                  <option value="None">{t("none")}</option>
                  <option value="Sub-surface">{t("sub-surface")}</option>
                  <option value="Surface">{t("surface")}</option>
                </select>
              </div>
            </section>

            {/* <div className='form-group'>
                            <label htmlFor='farmType'>Management Types</label>
                            <input type='text' name='cropUse' id='cropUse'  onChange={handleInput} />
                        </div> */}

            <section className="input-section">
              <div className="form-group">
                <label htmlFor="farmType">{t("management Types")}</label>
                <select
                  name="Management"
                  id="Management"
                  value={formData.Management}
                  onChange={handleInput}
                >
                  <option>Select</option>
                  <option value="Conventional">{t("conventional")}</option>
                  <option value="Organic">{t("organic")}</option>
                  <option value="Integrated">{t("integrated")}</option>
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="farmDescription">{t("slope")}</label>
                <select
                  name="slope"
                  id="slope"
                  value={formData.slope}
                  onChange={handleInput}
                >
                  <option>{t("select")}</option>
                  <option value="Flat">{t("flat")}</option>
                  <option value="Gentle">{t("gentle")}</option>
                  <option value="Moderate">{t("moderate")}</option>
                  <option value="Steep">{t("steep")}</option>
                </select>
              </div>
            </section>

            <div className="form-group">
              <label htmlFor="farmType">{t("land ownership")}</label>
              <select
                name="ownership"
                id="ownership"
                value={formData.ownership}
                onChange={handleInput}
              >
                <option>Select</option>
                <option value="Owned">{t("owned")}</option>
                <option value="Rented">{t("rented")}</option>
                <option value="Leased">{t("leased")}</option>
                <option value="Share cropped">{t("sharecropped")}</option>
              </select>
            </div>

            <section className="input-section">
              <div className="form-group">
                <label htmlFor="farmType">{t("cadastral Sheet")}</label>
                <input
                  type="number"
                  name="cadastralSheet"
                  id="Sheet"
                  value={formData.cadastralSheet}
                  onChange={handleInput}
                />
              </div>
              <div className="form-group">
                <label htmlFor="farmDescription">
                  {t("cadastral Municipality")}
                </label>
                <input
                  type="text"
                  name="cadastralMunicipality"
                  id="Cadastral"
                  value={formData.cadastralMunicipality}
                  onChange={handleInput}
                />
              </div>
              <div className="form-group">
                <label htmlFor="farmDescription">
                  {t("cadastral Particle")}
                </label>
                <input
                  type="number"
                  name="cadastralParticle"
                  id="Cadastral"
                  value={formData.cadastralParticle}
                  onChange={handleInput}
                />
              </div>
            </section>
          </form>
        </div>
      )}

      {section === 4 && (
        <div className="form-section">
          {/* Input fields for section 2 */}
          <form>
            <div className="form-group">
              <label htmlFor="farmLocation">{t("revenue")}</label>
              <div className="input-with-addon">
                <span className="addon">€</span>
                <input
                  style={{ width: "100%" }}
                  type="number"
                  name="revenue"
                  id="Management"
                  value={formData.revenue}
                  onChange={handleInput}
                />
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="farmSize">{t("end Note")}</label>
              <textarea
                type="text"
                name="notes"
                id="notes"
                cols={20}
                rows={10}
                value={formData.notes}
                onChange={handleInput}
              />
            </div>
          </form>
        </div>
      )}
      <div className="button-container">
        {section > 1 && (
          <button className="next-button" onClick={handlePrevious}>
            {t("previous")}
          </button>
        )}
        {section < 4 ? (
          <button className="next-button" onClick={handleNext}>
            {t("next")}
          </button>
        ) : formLoading ? (
          <button
            className="submit-button"
            disabled
            style={{ backgroundColor: "#E3E3E3FF", color: "#67A390FF" }}
          >
            {t("loading")}
          </button>
        ) : (
          <button
            className="submit-button"
            onClick={handleSend}
            style={{ backgroundColor: "#fff", color: "#339475" }}
          >
            {t("submit")}
          </button>
        )}
      </div>
    </div>
  );
};

export default FarmRegistration;
