import React, { useEffect, useState } from 'react';
import { AiOutlineSearch, AiOutlineBell, AiOutlineUser } from 'react-icons/ai';
import Flag from 'react-world-flags'

import { RiHistoryFill } from 'react-icons/ri'
import { BsGlobe2, BsNewspaper } from 'react-icons/bs'
import { SlDocs } from 'react-icons/sl'
import { GrHelp } from 'react-icons/gr'
import { FaHandsHelping, FaRegUser, FaRegUserCircle } from 'react-icons/fa'
import { GrNotification } from 'react-icons/gr'
import { SiHtmlacademy } from 'react-icons/si'
import { BsCart2 } from 'react-icons/bs'
import { IoIosHelp, IoMdGlobe } from 'react-icons/io'
import { CiUser } from "react-icons/ci";
import './topbar.css';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { fetchDamagesDetails, fetchIssuesDetails, fetchPlan } from '../../fetches/FetchFarmDetails';
import { BiChevronRight } from 'react-icons/bi';
import i18n from "i18next";
import { IoNotificationsCircleOutline, IoNotificationsOutline } from 'react-icons/io5';
import { listPackageRequests } from '../../../graphql/queries';
import { API, graphqlOperation } from 'aws-amplify';
import { useTranslation } from 'react-i18next';

const FarmerTopBar = ({ filterValue, handleFilterChange }) => {

  const [isOpen, setIsOpen] = useState(false);
  const [alerts, setAlerts] = useState([]);
  const [issues, setIssues] = useState([]);
  const [damages, setDamages] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [plan, setPlan] = useState([]);
  const [shouldDisplayWeather, setShouldDisplayWeather] = useState(false);
  const [sidebarActive, setSidebarActive] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingPlan, setLoadingPlan] = useState(true);
  const [selectedLang, setSelectedLang] = useState("English")

const { t } = useTranslation();
  const navigate =  useNavigate();

  useEffect(() => {
    async function fetchData() {
      const issuesData = await fetchIssuesDetails();
      setIssues(issuesData);
    }
    fetchData();
  }, []);

  //count issues that are pending
  const countPendingIssues = () => {
    return issues.filter(issue => issue.status === 'pending').length
  }



  useEffect(() => {
    async function fetchData() {
      const damagesData = await fetchDamagesDetails();
      setDamages(damagesData);
    }
    fetchData();
  }, []);

  //count damages that are pending
  const countPendingDamages = () => {
    return damages.filter(damage => damage.intensity === 'pending').length
  }





  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  const fetchAlerts = () => {
    const allAlerts = [];

    // Iterate through localStorage keys
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);

      // Check if the key starts with 'alerts_'
      if (key && key.startsWith('alerts_')) {
        const storedAlerts = localStorage.getItem(key);
        if (storedAlerts) {
          let parsedAlerts = JSON.parse(storedAlerts);

          // Filter out alerts that are older than 24 hours
          parsedAlerts = parsedAlerts.filter(alert => {
            const age = Date.now() - alert.timestamp;
            const maxAge = 24 * 60 * 60 * 1000; // 24 hours in milliseconds
            return age < maxAge;
          });

          // Update the stored alerts
          localStorage.setItem(key, JSON.stringify(parsedAlerts));

          // Aggregate the cleaned alerts
          allAlerts.push(...parsedAlerts);
        }
      }
    }

    // Set the state with all alerts aggregated
    setAlerts(allAlerts);
  };


  // Call fetchAlerts when component mounts
  useEffect(() => {
    fetchAlerts();
  }, []);

  // Function to group alerts by farm name
  const groupAlertsByFarm = (alerts) => {
    return alerts.reduce((groups, alert) => {
      const groupName = alert.farmName;
      if (!groups[groupName]) {
        groups[groupName] = [];
      }
      groups[groupName].push(alert);
      return groups;
    }, {});
  };

  // Group the alerts by farm name
  const groupedAlerts = groupAlertsByFarm(alerts);


  //function to count the number of alerts
  const countAlerts = () => {
    let count = 0;
    alerts.forEach((alert) => {
      if (!alert.read) {
        count++;
      }
    });
    return count;
  };


  const markAsRead = (index) => {
    const updatedAlerts = [...alerts];
    updatedAlerts.splice(index, 1); // Remove the alert from the array
    //remove the item from the local storage
    localStorage.removeItem(`alerts_${alerts[index].farmName}`);
    setAlerts(updatedAlerts); // Update the state

    // Update the local storage with the updated alerts
    localStorage.setItem('alerts', JSON.stringify(updatedAlerts));
  };

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
    toggleDropdown();
  };

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  // useEffect(() => {
  //   async function fetchData() {
  //     const issuesData = await fetchPlan();
  //     setPlan(issuesData);
  //   }
  //   fetchData();
  // }, []);



  // Use the useEffect hook to listen for window resize events
  useEffect(() => {
    function handleResize() {
      // Check the browser's width here and set sidebarActive accordingly
      if (window.innerWidth < 768) {
        setSidebarActive(true);
      } else {
        setSidebarActive(false);
      }
    }

    // Attach the event listener when the component mounts
    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); // Empty dependency array means this effect runs only once, when the component mounts


  useEffect(() => {
    fetchPlan();
    setLoading(false);
    }, []);

  const fetchPlan = async () => {
    try {
        const packagesRequestData = await API.graphql(graphqlOperation(listPackageRequests, {
            filter: {
                requesterID: {
                    eq: localStorage.getItem('farmerID'),
                },
            },
        }));
        //set plans whose status is approved
        const approvedPlans = packagesRequestData.data.listPackageRequests.items.filter(
            (item) => item.status === 'approved' && item.package !== null
        );
        setPlan(approvedPlans);
        setLoading(false);
     
    } catch (error) {
        console.log(error);
    }
}


  useEffect(() => {
    const hasApprovedMeteobluePlan = plan.some(
      p => p.package.title === 'Virtual Weather Station' && p.status === 'approved'
    );

    setShouldDisplayWeather(hasApprovedMeteobluePlan);
    setLoadingPlan(false);
  }, [plan]);

  console.log(plan)


  


  return (
    <div className="topbar">
      {/* <div className="search">
        <AiOutlineSearch className="search-icon" />
        <input
        type="text"
        placeholder="Search..."
        value={filterValue}
        onChange={handleFilterChange}
      />
      </div> */}


      <div className={`icons`}>
        <section className={`tab-icons  ${sidebarActive ? 'active' : ''}`}>
          <div className='topbar-tabs' onClick={toggleDropdown} style={{ position: 'relative' }}>
            <BsGlobe2 className="icon" color='white'  size={20} />
            {/* <span>{selectedLang}</span> */}
            {showDropdown && (
              <div className='lang-switch' >
                <Flag code="RO" height="14" onClick={() => {
                  changeLanguage('fr')
                 setSelectedLang("Română")
                  //reload the page
                }} />
                <button onClick={() => changeLanguage('fr')}>Română</button>
                <Flag code="GB" height="14" onClick={() => {
                  changeLanguage('en')
                  setSelectedLang("English")
                  //reload the page
                }} />
              
                <button onClick={() => changeLanguage('en')}>English</button>

                <Flag code="IT" height="14" onClick={() => {
                  changeLanguage('it')
                  setSelectedLang("Italiano")
                  //reload the page
                }} />

                <button onClick={() => changeLanguage('it')}>Italiano</button>
              </div>
            )}
          </div>



          <div to='/farmer/help' className='topbar-tabs'  onClick={toggleDrawer}>
            <IoNotificationsCircleOutline className="icon" color='white' size={24} />
            <span style={{ color: "white", display: "block" }} className="notification-count">{countAlerts()}</span>
            {/* <span>{t('alerts')}</span> */}
          </div>


          {/* <div to='/farmer/help' className='topbar-tabs'>
            <BsCart2 className="icon" />
            <span style={{color:"white", fontSize:'12px'}} className="notification-count">{countAlerts()}</span>

            <span>Cart</span>
          </div> */}

<div to='/farmer/help' className='topbar-tabs' onClick={()=> navigate('/farmer/account')}>
            <FaRegUserCircle className="icon" color='white' size={20} />
            {/* <span>{t('profile')}</span> */}
          </div>

        </section>

      </div>
      {isOpen && (
        <div className="drawer">
          <div className="drawer-header">Alerts</div>
          <div className="drawer-content">
            {
              !shouldDisplayWeather && (
                <div className="no-alerts">Upgrade plan</div>
              )
            }
            {
              alerts.length === 0 && (
                <div className="no-alerts">No weather alerts</div>
              )
            }
            {Object.keys(groupedAlerts).map(farmName => (
              <div key={farmName} className="farm-group">
                <div className="farm-name">
                  <h3>{farmName}</h3>
                </div>
                {groupedAlerts[farmName].map((alert, index) => (
                  <div key={index} className="alert-item">
                    <div className="alert-message">{alert.message}</div>
                    <div className="alert-time">{alert.time}</div>
                    {/* ... Rest of your alert item UI */}
                    <button className="mark-as-read-button" onClick={() => markAsRead(index)}>Mark as read</button>
                  </div>
                ))}
              </div>

            ))}
            <div className="farm-group">
              <h4 style={{ textDecoration: 'underline' }}>Issue and Damages</h4>
              <div className="alert-item">
                <div className="alert-message">You have {countPendingIssues()} issues unsolved.</div>
                <Link to='/issues' className='view-issues'><BiChevronRight /></Link>
              </div>

              <div className="alert-item">
                <div className="alert-message">You have {countPendingDamages()} pending damages. </div>
                <Link to='/damages' className='view-issues'><BiChevronRight /></Link>
              </div>
            </div>
          </div>
        </div>
      )}

    </div>
  );
};

export default FarmerTopBar;
