import React, { useEffect, useState } from "react";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

import { AiOutlineCloudDownload } from "react-icons/ai";
import { IoAddOutline } from "react-icons/io5";
import { issuesCollection } from "../../../lib/firestoreCollections";
import { addDoc, doc, onSnapshot } from "firebase/firestore";
import { API, graphqlOperation } from "aws-amplify";
import {
  createDamages,
  createIssues,
  deleteDamages,
  updateDamages,
} from "../../../graphql/mutations";
import {
  listDamages,
  listDiseases,
  listIssues,
} from "../../../graphql/queries";
import NoDataComponent from "../../null/NullData";
import { GiCancel, GiCheckMark } from "react-icons/gi";
import { TiTick } from "react-icons/ti";
import { toast } from "react-toastify";
import { farmerID } from "../../../userid";
import { fetchDamagesDetails } from "../../fetches/FetchFarmDetails";
import CustomWebcam from "../assets/camera/CustomWebCam";
import GeolocationComponent from "../assets/geoLocator/GeoLocator";
import { useTranslation } from "react-i18next";

const DamageList = ({
  clickedCoordinates,
  setPoly,
  handlePointClick,
  farmDetails,
  onCoordinatesClick,
}) => {
  const [showInput, setShowInput] = useState(false);
  const [reportDate, setReportDate] = useState("");
  const [damageType, setDamageType] = useState("");
  const [damageNote, setDamageNote] = useState("");
  const [fieldID, setFieldID] = useState("");
  const [intensity, setIntensity] = useState("");
  const [coordinates, setCoordinates] = useState([]);
  const [image, setImage] = useState("");
  const [issues, setIssues] = useState([]);
  const [damages, setDamages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [fieldName, setFieldName] = useState("");
  const [resolved, setResolved] = useState("");
  const [update, setUpdate] = useState(false);
  const [rings, setRings] = useState("");
  const [selectedIssueType, setSelectedIssueType] = useState("All");
  const [selectedStatus, setSelectedStatus] = useState("All");
  const [showImage, setShowImage] = useState({});
  const [location, setLocation] = useState({
    latitude: null,
    longitude: null,
  });
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 770);

  const { t } = useTranslation();
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 770);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleFieldSelect = (e) => {
    const selectedValue = JSON.parse(e.target.value);
    if (!selectedValue) {
      console.log("Invalid selected value");
      return;
    }

    onCoordinatesClick(null);
    setFieldName(selectedValue.name);
    setRings(JSON.parse(selectedValue.rings));
    setFieldID(selectedValue.id);
    setPoly(JSON.stringify(selectedValue.rings));
  };

  //fetch farmer id from local storage

  // console.log(farmDetails);

  useEffect(() => {
    async function fetchData() {
      // const damagesData = await fetchDamagesDetails()
      const farmerID = localStorage.getItem("farmerID");

      try {
        const damagesData = await fetchDamagesDetails();
        //remove those whose farmDetails.active is false

        setIssues(damagesData);
        setPoly(JSON.stringify(damagesData[0].farmDetails.rings));
        onCoordinatesClick(damagesData[0].coordinates);
        //check if farmDetails field is null, if null delete the issue
        const nullIssues = damagesData.filter(
          (item) => item.farmDetails === null
        );
        nullIssues.forEach(async (item) => {
          await deleteIssue(item.id);
        });
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, [update]);

  console.log(issues);

  const getImageKey = (image) => {
    console.log(image);
    setImage(image);
    toast.success(`image: ${image}`);
  };

  const getCoordinates = (coordinates) => {
    console.log(coordinates);
    setLocation(coordinates);
    // toast.success(`coordinates: ${JSON.stringify(location)}`)
  };

  const filteredIssues = issues.filter((issue) => {
    // const isIssueTypeMatch = selectedIssueType === 'All' || issue.type === selectedIssueType;
    const isStatusMatch =
      selectedStatus === "All" || issue.status === selectedStatus;
    return isStatusMatch;
  });

  // console.log(clickedCoordinates);

  const handleSubmit = async () => {
    const farmerID = localStorage.getItem("farmerID");
    if (!fieldID) {
      toast.error("Please select a field");
      return;
    }
    try {
      await API.graphql(
        graphqlOperation(createDamages, {
          input: {
            date: reportDate,
            type: damageType,
            notes: damageNote,
            intensity,
            status: "pending",
            farmDetailsID: fieldID,
            farmerID: farmerID,
            coordinates: isMobile
              ? JSON.stringify(location)
              : JSON.stringify(clickedCoordinates),
            gmail: localStorage.getItem("farmerEmail"),
            fieldName: fieldName,
            image: image,
          },
        })
      );

      toast.success(t("toastMessages3.damageReported"));
      setUpdate(!update);
      setShowInput(false);
    } catch (error) {
      console.log(error);
    }
  };

  const updateStatus = async (id) => {
    try {
      await API.graphql(
        graphqlOperation(updateDamages, {
          input: { id: id, status: "resolved" },
        })
      );
      console.log("status updated");
      toast.success(t("toastMessages3.statusUpdated"));
      setUpdate(!update);
    } catch (error) {
      console.log(error);
    }
  };

  //delete damage
  const deleteIssue = async (id) => {
    try {
      await API.graphql(graphqlOperation(deleteDamages, { input: { id: id } }));
      toast.success(t("toastMessages3.damageDeleted"));
      setUpdate(!update);
    } catch (error) {
      console.log(error);
    }
  };

  const handleCardClick = (coordinates) => {
    setCoordinates(coordinates);
  };

  const handleSelectedCoordinates = (coordinates, rings) => (event) => {
    onCoordinatesClick(coordinates);
    setPoly(JSON.stringify(rings));
  };

  function generateExcelData(data) {
    // Fields to exclude
    const excludedFields = [
      "createdAt",
      "updatedAt",
      "__typename",
      "id",
      "farmerID",
      "farmDetailsID",
      "gmail",
      "farmer",
      "farmDetails",
    ];

    // Filter out excluded fields from the header
    const header = Object.keys(data[0]).filter(
      (field) => !excludedFields.includes(field)
    );

    const rows = data.map((item) => {
      // Create a new object without the excluded fields
      const rowData = { ...item };
      excludedFields.forEach((field) => delete rowData[field]);
      return Object.values(rowData);
    });

    return [header, ...rows];
  }

  function handleDownloadClick(data) {
    const excelData = generateExcelData(data);
    const ws = XLSX.utils.aoa_to_sheet(excelData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "buffer" });
    const excelBlob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(excelBlob, "damages.xlsx");
  }

  return (
    <div style={{}}>
      <section className="my-fields-header">
        <div className="sort-items">
          <h2 style={{ marginTop: 5, marginBottom: 5 }}>{t("damageList")}</h2>
          <div className="sort-tabs">
            <section>
              <p>{t("status")}</p>
              <select
                name="status"
                id="status"
                onChange={(e) => setSelectedStatus(e.target.value)}
              >
                <option>{t("all")}</option>
                <option value="pending">{t("pending")}</option>
                <option value="resolved">{t("resolved")}</option>
              </select>
            </section>
          </div>
        </div>

        <div className="add-download-buttons">
          <button style={{ marginRight: "5px" }}>
            <AiOutlineCloudDownload
              onClick={() => handleDownloadClick(issues)}
            />
          </button>
          <button>
            <IoAddOutline onClick={() => setShowInput(true)} />
          </button>
        </div>
      </section>

      {issues.length === 0 && <NoDataComponent />}

      <section className="issues-section">
        {filteredIssues.map((issue, index) => (
          <div
            key={index}
            style={{
              margin: "1em 0",
              borderRadius: "15px",
              boxShadow: "0 5px 15px rgba(0,0,0,0.1)",
              backgroundColor: "#fff",
              padding: "1em",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {issue.farmDetails && (
              <>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div
                    onClick={handleSelectedCoordinates(
                      issue.coordinates,
                      issue.farmDetails.rings
                    )}
                    style={{
                      width: "120px",
                      height: "100px",
                      backgroundColor:
                        issue.status === "resolved" ? "#008000a3" : "#ff000069",
                      marginRight: "1em",
                      borderRadius: "10px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        fontFamily: "Arial, sans-serif",
                        fontSize: "1.2em",
                        color: "white",
                        textAlign: "center",
                      }}
                    >
                      <p>{issue.type}</p>
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      flex: 1,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <p style={{ margin: "0.5em 0", color: "#333" }}>
                        {t("crop")}:<strong> {issue.farmDetails.crop}</strong>
                      </p>
                      <p style={{ marginBottom: "0.5em", color: "#333" }}>
                        {t("report Date")}:<strong> {issue.date}</strong>
                      </p>
                    </div>
                    <p style={{ marginBottom: "0.5em", color: "#333" }}>
                      {t("note")}: {issue.notes}
                    </p>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <p style={{ marginBottom: "0.5em", color: "#333" }}>
                        {t("status")}:{" "}
                        <strong
                          style={{
                            color:
                              (issue.status || resolved === issue.id) ===
                              "resolved"
                                ? "green"
                                : "#F67B00",
                          }}
                        >
                          {resolved === issue.id ? "resolved" : issue.status}
                        </strong>
                      </p>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          gap: "1em",
                        }}
                      >
                        <GiCheckMark
                          onClick={() => {
                            updateStatus(issue.id);
                            setResolved(issue.id);
                          }}
                          style={{ color: "green", cursor: "pointer" }}
                        />
                        <GiCancel
                          onClick={() => {
                            deleteIssue(issue.id);
                          }}
                          style={{ color: "red", cursor: "pointer" }}
                        />
                        {issue.resolved && (
                          <TiTick
                            style={{ color: "blue", cursor: "pointer" }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {issue.image && (
                  <button
                    onClick={() =>
                      setShowImage((prevState) => ({
                        ...prevState,
                        [issue.id]: !prevState[issue.id],
                      }))
                    }
                    style={{
                      width: "100%",
                      height: "40px",
                      backgroundColor: "#F67B00",
                      color: "#fff",
                      border: "none",
                      borderRadius: "5px",
                      marginTop: "1em",
                    }}
                  >
                    {t("view Image")}
                  </button>
                )}
                {showImage[issue.id] && (
                  <img
                    src={`https://officialagrogis.s3.amazonaws.com/${issue.image}`}
                    alt={issue.type}
                    style={{ width: "100%", height: "auto", marginTop: "1em" }}
                  />
                )}
              </>
            )}
          </div>
        ))}
      </section>
      {showInput ? (
        <div className="farmpopup listing">
          <h2>{t("enter Damage")}</h2>
          <div className="form-group">
            <label htmlFor="issue">{t("report on")}</label>
            <input
              type="date"
              placeholder="Enter issue"
              onChange={(e) => setReportDate(e.target.value)}
            />
          </div>

          <div className="form-group">
            <label htmlFor="issue">{t("field Name")} </label>
            <select name="field" id="field" onChange={handleFieldSelect}>
              <option>{t("select")}</option>

              {farmDetails &&
                farmDetails.map((item, index) => (
                  <option key={index} value={JSON.stringify(item)}>
                    {item.name}
                  </option>
                ))}
            </select>
          </div>

          {isMobile ? (
            <GeolocationComponent getCoordinates={getCoordinates} />
          ) : (
            <div className="form-group">
              <button style={{ marginBottom: "10px" }}>
                {t("selectptOnmap")}
              </button>
            </div>
          )}

          {isMobile && <CustomWebcam getImageKey={getImageKey} />}
          <div className="form-group">
            <label htmlFor="issue">{t("damage")}</label>
            <select
              name="crop"
              id="crop"
              onChange={(e) => setDamageType(e.target.value)}
            >
              <option>{t("select")}</option>
              <option value="Pest Animals">{t("pestandAnimal")}</option>
              <option value="Sunburn">{t("sunburn")}</option>
              <option value="Chemical Damage">{t("chemDamage")}</option>
              <option value="Hail Damage">{t("hailDamage")}</option>
              <option value="Frost Damage">{t("frostDamage")}</option>
              <option value="Fire Damage">{t("fireDamage")}</option>
              <option value="Insect Damage">{t("insectDamage")}</option>
              <option value="Mammal Damage">{t("mammalDamage")}</option>
              <option value="Bird Damage">{t("birdDamage")}</option>
              <option value="Pollution Damage">{t("pollutionDamage")}</option>
              <option value="Drought Damage">{t("droughtDamage")}</option>
              <option value="Wind Damage">{t("windDamage")}</option>
              <option value="Soil Erosion Damage">{t("soilEDamage")}</option>
              <option value="Waterlogging Damage">{t("waterLDamage")}</option>
              <option value="Flood Damage">{t("FloodDamage")}</option>
              <option value="Extreme Temperature Damage">
                {t("extremeTDamage")}
              </option>
              <option value="Excessive Humidity Damage">
                {t("excessHDamage")}
              </option>
              <option value="Mechanical Damage">{t("mechanicalDamage")}</option>
              <option value="Weed Damage">{t("weedDamage")}</option>
              <option value="Fungicide Damage">{t("fungicideDamage")}</option>
              <option value="Pesticide Damage">{t("pesticideDamage")}</option>
              <option value="Improper Pollination Damage">
                {t("improperPDamage")}
              </option>
              <option value="Salinity Damage">{t("salinityDamage")}</option>
              <option value="Water Stress Damage">{t("waterSDamage")}</option>
              <option value="Disease Damage">{"diseaseDamage"}</option>

              <option value="Other">{t("other")}</option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="issue">{t("intensity")}</label>
            <select
              name="intensity"
              id="intensity"
              onChange={(e) => setIntensity(e.target.value)}
            >
              <option>{t("select")}</option>
              <option value="Vey Bad">{t("veryBad")}</option>
              <option value="Bad">{t("bad")}</option>
              <option value="Minor">{t("minor")}</option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="issue">{t("note")}</label>
            <textarea
              style={{ color: "#fff" }}
              name="note"
              id="note"
              cols="30"
              rows="10"
              onChange={(e) => setDamageNote(e.target.value)}
            ></textarea>
          </div>
          <div className="farmpopup__buttons">
            <button onClick={() => setShowInput(false)}>{t("cancel")}</button>
            <button onClick={handleSubmit}>{t("submit")}</button>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default DamageList;
