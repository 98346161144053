import React, { useEffect, useState } from 'react'
import FarmerTopBar from '../../../components/topbar/Topbar'
import PointMap from '../../farms/myFields/PointMap'
import { fetchDroneData } from '../../../fetches/FetchFarmDetails'
import { GoLocation } from 'react-icons/go'
import MapImage from '../../farms/myFields/MapImage'
import { API, graphqlOperation } from 'aws-amplify'
import { listDroneUploads } from '../../../../graphql/queries'
import { toast } from 'react-toastify'
import { createDroneUploads } from '../../../../graphql/mutations'
import { useMemo } from 'react'
import { upload } from '@testing-library/user-event/dist/upload'
import Popup from 'reactjs-popup'
import UploadedServices from './UploadedServices'
import SatelliteMap from '../wms-testing/SatelliteMap'
import WebMapComponent from '../../../__test/PlanetScope'
import { AiFillCaretLeft, AiFillCaretRight, AiOutlineUp } from 'react-icons/ai'
import { BiHelpCircle } from 'react-icons/bi'
import NoDataComponent from '../../../null/NullData'
import { useTranslation } from 'react-i18next'

const MapImageMemoized = React.memo(MapImage)

const DroneService = () => {
  const [leftWidth, setLeftWidth] = useState(50)
  const [rightWidth, setRightWidth] = useState(50)
  const [uploads, setUploads] = useState([])
  const [selectedPolygon, setSelectedPolygon] = useState(null)
  const [selectedFarm, setSelectedFarm] = useState(null)
  const [geometry, setGeometry] = useState(null)
  const [token, setToken] = useState(null)
  const [selectedDate, setSelectedDate] = useState(new Date())
  const [clickedCoordinates, setClickedCoordinates] = useState(null) // New state for clicked coordinate
  const [update, setUpdate] = useState(false)
  const [selectedId, setSelectedId] = useState('')
  const [ndvi, setndvi] = useState(true)
  const [ndmi, setndmi] = useState(null)
  const [ndwi, setndwi] = useState(null)
  const [ndre, setndre] = useState(null)
  const [msavi, setmsavi] = useState(null)
  const [evapo, setevapo] = useState(null)
  const [activeButton, setActiveButton] = useState('MSAVI')
  const { t } = useTranslation()

  useEffect(() => {
    async function fetchData() {
      const uploadSata = await fetchDroneData()
      //convert link field which is currently stringified to an object
      const data = uploadSata.map((item) => {
        const link = JSON.parse(item.link)
        return {
          ...item,
          link,
        }
      })
      setUploads(data)
    }
    fetchData()
  }, [])

  console.log(uploads)
  console.log(selectedPolygon)

  const handleDateSelection = (date) => {
    setSelectedDate(date)
    // Handle the selected date in the parent component
  }

  const getUniqueDates = () => {
    const selectedFarmDates = selectedFarm.link.map((item) => item.date)
    const uniqueDates = [...new Set(selectedFarmDates)]
    return uniqueDates
  }

  const renderDateButtons = () => {
    const uniqueDates = getUniqueDates()

    return uniqueDates.map((date, index) => (
      <button
        key={index}
        onClick={() => {
          setSelectedDate(date)
          setSelectedId(
            selectedFarm.link.find(
              (item) => item.variety === activeButton && item.date === date
            )?.link
          )
        }}
        className={date === selectedDate ? 'selected' : ''}
      >
        {date}
      </button>
    ))
  }

  return (
    <div className="home-container">
      <FarmerTopBar />
      <section className="mymap-wrapper">
        <div
          className="left-myMap drone-services"
          style={{ position: 'relative' }}
        >
          <h1 style={{ margin: '10px ' }}>Drone Service</h1>
          {!uploads.length && <NoDataComponent />}
          <section className="myfield-container" style={{ height: '' }}>
            {uploads.map((polygon, index) => (
              <div key={index} className="polygon-item">
                <div style={{ width: '100%' }}>
                  <MapImageMemoized selectedPolygon={polygon} />
                  {/* <SatelliteMap /> */}
                </div>
                <div className="package-name plans">
                  <p>{polygon.droneRequest.area.toFixed(2)} ha</p>
                </div>
                <div
                  className="location-icon"
                  onClick={() => {
                    setSelectedPolygon(polygon)
                  }}
                  onMouseOver={() => setSelectedPolygon(polygon)}
                >
                  <GoLocation />
                </div>

                <div className="polygon-info">
                  <p>
                    {t('farm')}:{' '}
                    <strong>{polygon.droneRequest.fieldName}</strong>
                  </p>
                  <p>
                    {t('crop')}: <strong>{polygon.droneRequest.crop}</strong>
                  </p>

                  <button
                    onClick={() => {
                      setSelectedFarm(uploads[index])
                      setSelectedId(
                        polygon.link.find(
                          (item) => item.variety === activeButton
                        )?.link
                      )
                    }}
                  >
                    {t('seemore')}
                  </button>
                </div>
              </div>
            ))}
          </section>
          {selectedFarm && (
            <div className="farmpopup">
              <div className="sentinel-header">
                <h2>{selectedFarm.droneRequest.fieldName}</h2>
                <h3>{selectedId}</h3>
              </div>
              <div
                className="parent-component"
                style={{ position: 'relative' }}
              >
                <div className="date-container" id="date-container">
                  <AiFillCaretLeft
                    style={{
                      width: '20px',
                      marginRight: '10px',
                      position: 'absolute',
                      left: '-25px',
                      top: '30%',
                      color: 'white',
                      height: '30px',
                      width: '30px',
                      borderRadius: '50%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      // backgroundColor:'green'
                    }}
                  />
                  {renderDateButtons()}
                  <AiFillCaretRight
                    style={{
                      width: '20px',
                      marginRight: '10px',
                      position: 'absolute',
                      right: '-5.5%',
                      top: '30%',
                      color: 'white',
                      height: '30px',
                      width: '30px',
                      borderRadius: '50%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      // backgroundColor:'green'
                    }}
                  />
                </div>
              </div>
              <div className="sentinel-switch">
                <section className="crop-health">
                  <div className="index-group">
                    <button
                      style={{
                        color:
                          activeButton === 'Othomosaic' ? 'black' : 'white',
                        backgroundColor:
                          activeButton === 'Othomosaic' ? '#fff' : '#339475',
                        marginTop: '20px',
                        border: 'white 1px solid',
                        marginRight: '10px',
                      }}
                      onClick={() => {
                        setActiveButton('Othomosaic')
                        setSelectedId(
                          selectedFarm.link.find(
                            (item) =>
                              item.variety === 'Othomosaic' &&
                              item.date === selectedDate
                          )?.link
                        )
                      }}
                    >
                      {'othomosaic'}
                    </button>
                  </div>
                  <div className="index-group">
                    <button
                      style={{
                        color: activeButton === 'MSAVI' ? 'black' : 'white',
                        backgroundColor:
                          activeButton === 'MSAVI' ? '#fff' : '#339475',
                        marginTop: '20px',
                        border: 'white 1px solid',
                        marginRight: '10px',
                      }}
                      onClick={() => {
                        setActiveButton('MSAVI')
                        setSelectedId(
                          selectedFarm.link.find(
                            (item) =>
                              item.variety === 'MSAVI' &&
                              item.date === selectedDate
                          )?.link
                        )
                      }}
                    >
                      {t('early Stage')}
                    </button>
                  </div>

                  <div className="index-group">
                    <button
                      style={{
                        color: activeButton === 'NDVI' ? 'black' : 'white',
                        backgroundColor:
                          activeButton === 'NDVI' ? '#fff' : '#339475',
                        marginTop: '20px',
                        border: 'white 1px solid',
                        marginRight: '10px',
                      }}
                      onClick={() => {
                        setActiveButton('NDVI')

                        setSelectedId(
                          selectedFarm.link.find(
                            (item) =>
                              item.variety === 'NDVI' &&
                              item.date === selectedDate
                          )?.link
                        )
                      }}
                    >
                      {'mid Stage'}
                    </button>
                  </div>

                  <div className="index-group">
                    <button
                      style={{
                        color: activeButton === 'NDRE' ? 'black' : 'white',
                        backgroundColor:
                          activeButton === 'NDRE' ? '#fff' : '#339475',
                        marginTop: '20px',
                        border: 'white 1px solid',
                        marginRight: '10px',
                      }}
                      onClick={() => {
                        setActiveButton('NDRE')
                        setSelectedId(
                          selectedFarm.link.find(
                            (item) =>
                              item.variety === 'NDRE' &&
                              item.date === selectedDate
                          )?.link
                        )
                      }}
                    >
                      {t('late Stage')}
                    </button>
                  </div>

                  <div className="index-group">
                    <button
                      style={{
                        color: activeButton === 'NDMI' ? 'black' : 'white',
                        backgroundColor:
                          activeButton === 'NDMI' ? '#fff' : '#339475',
                        marginTop: '20px',
                        border: 'white 1px solid',
                        marginRight: '10px',
                      }}
                      onClick={() => {
                        setActiveButton('NDMI')
                        setSelectedId(
                          selectedFarm.link.find(
                            (item) =>
                              item.variety === 'NDMI' &&
                              item.date === selectedDate
                          )?.link
                        )
                      }}
                    >
                      {t('water Stress')}
                    </button>
                  </div>

                  <div className="index-group">
                    <button
                      style={{
                        color: activeButton === 'NDWI' ? 'black' : 'white',
                        backgroundColor:
                          activeButton === 'NDWI' ? '#fff' : '#339475',
                        marginTop: '20px',
                        border: 'white 1px solid',
                        marginRight: '10px',
                      }}
                      onClick={() => {
                        setActiveButton('NDWI')
                        setSelectedId(
                          selectedFarm.link.find(
                            (item) =>
                              item.variety === 'NDWI' &&
                              item.date === selectedDate
                          )?.link
                        )
                      }}
                    >
                      {t('water Logging')}
                    </button>
                  </div>
                </section>
              </div>

              <h3 style={{ margin: '20px 0' }}>
                {' '}
                <span className="tooltip" style={{ paddingBottom: '3px' }}>
                  {t('field Analytics')}{' '}
                  <BiHelpCircle color="white" size={20} />
                  <span
                    className="tooltiptext"
                    style={{
                      width: '200px',
                      fontSize: '12px',
                      textAlign: 'center',
                      left: '-160%',
                      bottom: '100%',
                      padding: '10px',
                    }}
                  >
                    <p style={{ fontSize: '13px' }}>
                      {activeButton === 'NDVI'
                        ? t('explanations.ndvi')
                        : activeButton === 'NDRE'
                        ? t('explanations.ndre')
                        : activeButton === 'MSAVI'
                        ? t('explanations.msavi')
                        : activeButton === 'NDWI'
                        ? t('explanations.ndwi')
                        : activeButton === 'NDMI'
                        ? t('explanations.ndmi')
                        : activeButton === 'Othomosaic'
                        ? t('explanations.othomosaic')
                        : ''}
                    </p>
                  </span>
                </span>
                <span>
                  {' '}
                  <AiOutlineUp color="white" size={20} />{' '}
                </span>{' '}
              </h3>

              {activeButton === 'NDVI' ? (
                <>
                  <div className="gradient-bar"></div>
                  <div className="gradient-bar-labels">
                    <div className="gradient-bar-label">{t('bare soil')}</div>
                    <div className="gradient-bar-label">
                      {t('sparce plants')}
                    </div>
                    <div className="gradient-bar-label">
                      {t('moderately healthy')}
                    </div>
                    <div className="gradient-bar-label">
                      {t('healthy Plants')}
                    </div>
                  </div>
                </>
              ) : activeButton === 'MSAVI' ? (
                <>
                  <div className="gradient-bar"></div>
                  <div className="gradient-bar-labels">
                    <div className="gradient-bar-label">{t('bare soil')}</div>
                    <div className="gradient-bar-label">
                      {t('seed germination')}{' '}
                    </div>
                    <div className="gradient-bar-label">
                      {t('leaf development')}{' '}
                    </div>
                    <div className="gradient-bar-label">
                      {t('use Mid Stage')}
                    </div>
                  </div>
                </>
              ) : activeButton === 'NDRE' ? (
                <>
                  <div className="gradient-bar"></div>
                  <div className="gradient-bar-labels">
                    <div className="gradient-bar-label">{t('bare soil')}</div>
                    <div className="gradient-bar-label">
                      {t('sparse plants')}
                    </div>
                    <div className="gradient-bar-label">
                      {t('moderately healthy')}
                    </div>
                    <div className="gradient-bar-label">
                      {t('dense healthy plants')}
                    </div>
                  </div>
                </>
              ) : activeButton === 'NDWI' ? (
                <>
                  <div className="gradient-bar ndwi"></div>
                  <div className="gradient-bar-labels">
                    <div className="gradient-bar-label">
                      {t('drought, non-aqueous surfaces')}
                    </div>
                    <div className="gradient-bar-label">
                      {'non-aqueous surfaces'}
                    </div>
                    <div className="gradient-bar-label">
                      {'flooding, humidity'}
                    </div>
                    <div className="gradient-bar-label">{'water surface'}</div>
                  </div>
                </>
              ) : activeButton === 'NDMI' ? (
                <>
                  <div className="gradient-bar ndmi"></div>
                  <div className="gradient-bar-labels">
                    <div className="gradient-bar-label">{t('bare soil')}</div>
                    <div className="gradient-bar-label">
                      {t('absent canopy')}
                    </div>
                    <div className="gradient-bar-label">
                      {t('very low canopy')}
                    </div>
                    <div className="gradient-bar-label">
                      {t('low dry canopy')}
                    </div>
                    <div className="gradient-bar-label">
                      {t('mid-low canopy & high water stress')}{' '}
                    </div>
                    <div className="gradient-bar-label">
                      {t('Average canopy & high water stress')}
                    </div>
                    <div className="gradient-bar-label">
                      {t('mid-high canopy high water stress')}
                    </div>
                    <div className="gradient-bar-label no-water-stress">
                      {t('no water stress and high canopy cover')}
                    </div>
                  </div>
                </>
              ) : null}

              <button
                onClick={() => {
                  setSelectedFarm(null)
                }}
                style={{
                  color: 'black',
                  backgroundColor: '#E0E0E0',
                  marginTop: '20px',
                }}
              >
                {t('close')}
              </button>
            </div>
          )}
        </div>
        <div
          className="right-myMap imagery"
          style={{ position: 'relative', height: '100vh' }}
        >
          <WebMapComponent selectedId={selectedId} />
        </div>
      </section>
    </div>
  )
}

export default DroneService
