import { Capacitor } from '@capacitor/core';
import React, { useState, useEffect } from 'react';
import { FaApple, FaGooglePlay } from 'react-icons/fa';
import Popup from 'reactjs-popup';

const AppDownloadPopup = () => {
  const [hasSeenPopup, setHasSeenPopup] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 480);

  useEffect(() => {
    // Function to handle beforeunload event
    const handleBeforeUnload = () => {
      localStorage.setItem('appDownloadSeen', false);
    };

    // Add event listener for beforeunload event
    window.addEventListener('beforeunload', handleBeforeUnload);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    // Update isMobile state on window resize
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 480);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (isMobile && Capacitor.getPlatform() === 'web') {
      // Check if the user has seen the popup before
      const hasSeen = localStorage.getItem('appDownloadSeen') === 'true';

      // If the flag exists, check if it's expired
      if (hasSeen) {
        const cachedTimestamp = localStorage.getItem('appDownloadSeen_timestamp');
        const age = Date.now() - Number(cachedTimestamp);

        // If the timestamp is older than the expiry time, reset the flag
        if (age > 2 * 60 * 60 * 1000) { // 2 hours in milliseconds
          localStorage.removeItem('appDownloadSeen');
          localStorage.removeItem('appDownloadSeen_timestamp');
          setHasSeenPopup(false);
        } else {
          setHasSeenPopup(true);
        }
      } else {
        setHasSeenPopup(true);
        // Set a flag in local storage to indicate the popup has been shown
        localStorage.setItem('appDownloadSeen', true);
        localStorage.setItem('appDownloadSeen_timestamp', Date.now());
      }
    }
  }, [isMobile]);

  if (hasSeenPopup && isMobile && Capacitor.getPlatform() === 'web') {
    return (
      <Popup
        open={true}
        modal
        closeOnDocumentClick
        contentStyle={{
          // Customize popup styles as needed
          width: 'auto',
          maxWidth: '300px',
          padding: '20px',
          border: 'none',
          boxShadow: '0 3px 6px rgba(0, 0, 0, 0.16)',
        }}
      >
        <div className="text-white p-6 rounded-lg shadow-lg">
          <h2 className="text-2xl font-bold mb-2">Download our app!</h2>
          <p className="mb-4">Get the best experience on your mobile device.</p>
          <a href="https://play.google.com/store/apps/details?id=your_app_id" className="text-sm flex items-center justify-center bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded-lg transition duration-200 ease-in-out mb-2">
            <FaGooglePlay className="mr-2 text-white" /> Download for Android
          </a>
          <a href="https://apps.apple.com/app/your_app_id" className="text-sm flex items-center justify-center bg-gray-800 hover:bg-gray-900 text-white font-bold py-2 px-4 rounded-lg transition duration-200 ease-in-out mb-2">
            <FaApple className="mr-2 text-white" /> Download for iOS
          </a>
          <button onClick={() => setHasSeenPopup(false)} className="text-white transition duration-200 ease-in-out">
            No, thanks
          </button>
        </div>
      </Popup>
    );
  }

  return null; // Don't render the popup if it's been shown or not on mobile
};

export default AppDownloadPopup;
