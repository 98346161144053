import React, { useEffect, useRef } from 'react';
import { loadModules } from 'esri-loader';
import { MapContainer, TileLayer } from 'react-leaflet';

const WebMapComponent = ({selectedId}) => {
  const mapViewRef = useRef(null);
console.log(selectedId)
  useEffect(() => {
    loadModules(['esri/views/MapView', 'esri/WebMap'], { css: true }).then(
      ([MapView, WebMap]) => {
        const view = new MapView({
          container: mapViewRef.current,
          map: new WebMap({
            portalItem: {
              id: selectedId,
            },
          }),
          constraints: {
            snapToZoom: false,
          },
        });
      }
    );
  }, [selectedId]);

  return (
    <>
    
    {
      selectedId ? (
        <div
        ref={mapViewRef}
        style={{ height: '100%', width: '100%', position: 'absolute' }}
      />
      ) : (
        <MapContainer center={[44.105634049099756, 26.318226777781327]} zoom={13} scrollWheelZoom={false} style={{ height: '100%', width: '100%', position:'relative' }}>
            <TileLayer
          url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
          attribution="Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community"
        />
        </MapContainer>

      )
    }
    </>
  
  );
};

export default WebMapComponent;
