import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export  const landSat = [
    {
      id: 1,
      area: 10.66,
      crop: "Corn",
   
      image: 'https://images.unsplash.com/photo-1560493676-04071c5f467b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1374&q=80'
    },
    {
      id: 2,
      area: 12.34,
      crop: "Wheat",

      image: 'https://images.unsplash.com/photo-1625246333195-78d9c38ad449?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80'

    },
    {
      id: 3,
      area: 81.23,
      crop: "Rice",
    
      pressure: 1008,
  
      image: 'https://images.unsplash.com/photo-1500382017468-9049fed747ef?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1032&q=80'

    },
    {
      id: 4,
      area: 12.34,
      crop: "Soybeans",
    
  
      image: 'https://images.unsplash.com/photo-1574786198875-49f5d09fe2d2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=579&q=80'

    },
  ];
const FieldList = () => {
  const { t } = useTranslation()

  return (
    <div>
      <section className="weather-container">
        {landSat.map((item, index) => (
             <Link to={`details/${index}`} className='news-link'>
          <div key={index} className="weather-card">
            <div className="weather-card__info">
              <div className="weather-card__image">
                <img src={item.image} alt={item.crop} />
                <div className='crop-name'>
                  <p>{item.crop}</p>
              </div>
              </div>
              
              <div className="weather-card__details">
                <p className="weather-card__description">{t('area')}: {item.area} ha</p>
                <p className="weather-card__description">{t('perimeter')}: 3234km</p>
               
              </div>
            </div>
          </div>
          </Link>
        ))}
      </section>
    </div>
  );
};

export default FieldList;
