import React, { useEffect, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';

import { Helmet } from 'react-helmet';
import { AiFillCaretLeft, AiFillCaretRight } from 'react-icons/ai';

import { AiOutlineCloudDownload } from 'react-icons/ai'
import { GoLocation } from 'react-icons/go'

import { IoAddOutline } from 'react-icons/io5'
import { useNavigate } from 'react-router-dom';

import { API, graphqlOperation } from 'aws-amplify';
import { listFarmDetails } from '../../../../../graphql/queries';
import NoDataComponent from '../../../../../fARMER/null/Wait';
import MyFarms from '../../../../../fARMER/account/farms/myFields/MyFarms';
import MapImage from '../../../../../fARMER/account/farms/myFields/MapImage';
import { FarmPopup } from '../../../../../fARMER/account/farms/FarmPopup';
import TopBar from '../../topbar/Topbar';


const MyFarmsMemoized = React.memo(MyFarms);
const MapImageMemoized = React.memo(MapImage)



const Fields = ({ handleFarmDetails }) => {
  const [polygons, setPolygons] = useState([])
  const [loading, setLoading] = useState(true)
  const [selectedPolygon, setSelectedPolygon] = useState(null);
  const [selectedFarm, setSelectedFarm] = useState(null);
  const [geometry, setGeometry] = useState(null);
  const [clickedCoordinates, setClickedCoordinates] = useState(null); // New state for clicked coordinates
  const [filterValue, setFilterValue] = useState(''); // State to hold the filter value

  const farmerID = JSON.parse(localStorage.getItem('farmer'));
  const handleFilterChange = (event) => {
    setFilterValue(event.target.value); // Update filter value based on user input
  };


  useEffect(() => {
    fetchFarmDetails()
    //provoke props function to get farm details
    setLoading(false)
  }, [])
  


  async function fetchFarmDetails() {
    try {
      const farmDetails = await API.graphql(graphqlOperation(listFarmDetails))
      const farmDetailsData = farmDetails.data.listFarmDetails.items
      // display farms whose active status is true
      const activeFarms = farmDetailsData.filter(farm => farm.active === true && farm.farmer !== null)
      setPolygons(activeFarms)
      // console.log(nullFarmDetails)
    } catch (error) {
      console.log(error);
    }
  }

  //add filter functionality to filter farms by crop type
  const filterFarms = (crop) => {
    const filteredFarms = polygons.filter(farm => farm.crop === crop)
    setPolygons(filteredFarms)
  }
  

  console.log(polygons)

  const formattedData = useMemo(() => {
    return polygons.map((polygon) => {
      const { rings } = polygon;
      const formattedRings = JSON.parse(rings).map((item) => {
        const { latitude, longitude } = item;
        return [latitude, longitude];
      });
      return formattedRings;
    });
  }, [polygons]);





  const navigate = useNavigate()
  const [leftWidth, setLeftWidth] = useState(50);
  const [rightWidth, setRightWidth] = useState(50);

  const handleRightArrowClick = () => {
    setLeftWidth(leftWidth + 10);
    setRightWidth(rightWidth - 10);
  };

  const handleLeftArrowClick = () => {
    setLeftWidth(leftWidth - 10);
    setRightWidth(rightWidth + 10);

  };

  const handlePointClick = (coordinates) => {
    setClickedCoordinates(coordinates);
  };

  const handleFarmUpdate = (newFarms) => {
    const newPolygons = polygons.map((polygon) => {
      if (polygon.id === newFarms) {
        return newFarms;
      }
      return polygon;
    });
 
  };


  const groupedFarms = useMemo(() => {
    
    // Group the farms by farmer's last name
    const groups = formattedData.reduce((groups, polygon, index) => {
      const farmerLastName = polygons[index].farmer ? polygons[index].farmer.lastName : 'N/A';
      const farmerId = polygons[index].farmer ? polygons[index].farmer.email : 'N/A';
      if (!groups[farmerId]) {
        groups[farmerId] = [];
      }
      groups[farmerId].push({ polygon: polygon, farm: polygons[index] });
      return groups;
    }, {});
  
    // Convert the grouped farms into an array and sort by the number of requests
    return Object.entries(groups).sort((a, b) => b[1].length - a[1].length);
  
  }, [formattedData, polygons]);

  console.log(groupedFarms);



  return (
    <div className='home-container'>
      <TopBar filterValue={filterValue} handleFilterChange={handleFilterChange} />
      <Helmet>
        <link
          rel="stylesheet"
          href="https://js.arcgis.com/4.26/esri/themes/light/main.css"
        />
      </Helmet>

      <section className='mymap-wrapper'>


        <div className='left-myMap' style={{ width: `${leftWidth}%`, position: 'relative' }}>
           <div  className='center-buttons'>
            <button onClick={handleLeftArrowClick}><AiFillCaretLeft /></button>
            <button onClick={handleRightArrowClick}><AiFillCaretRight /></button>
          </div>
          <section className='my-fields-header'>

            <h3>Fields</h3>

            <div>
              <button style={{ marginRight: '5px' }}>
                <AiOutlineCloudDownload />
              </button>
              <button onClick={() => navigate('/myfarm/create')}>
                <IoAddOutline />
              </button>
            </div>
          </section>
          {
          !polygons.length &&  (
            <div className='no-issues' style={{width:"100%"}}>
            <NoDataComponent />
            </div>
          )
        }
         <section className='myfield-cont-requests'>
    {/* <p>You have not created any fields yet</p> */}
    
    {
  groupedFarms.filter(([farmerLastName, farms]) => {
    // Check if the farmer's last name includes the filterValue (case-insensitive)
    return farmerLastName.toLowerCase().includes(filterValue.toLowerCase()) || farms.some(({ farm }) => {
      // Check if any of the farms' names include the filterValue (case-insensitive)
      return farm.name.toLowerCase().includes(filterValue.toLowerCase()) || farm.crop.toLowerCase().includes(filterValue.toLowerCase()) || farm.farmer.email.toLowerCase().includes(filterValue.toLowerCase()) || farm.farmer.id.toLowerCase().includes(filterValue.toLowerCase()) || farm.farmer.lastName.toLowerCase().includes(filterValue.toLowerCase()) || farm.farmer.firstName.toLowerCase().includes(filterValue.toLowerCase());
     
    }
    );
  }).map(([farmerLastName, farms]) => (
    <div key={farmerLastName}>
      <h3 style={{margin:'10px 0'}}>{farmerLastName}</h3>
      <div className='requests-object'>
      {farms.map(({ polygon, farm }, index) => (
        <div key={index} className='polygon-item'>
          {/* your previous elements ... */}
          <div style={{ width: '100%',height:'25px' }}>
                  {/* <MapImageMemoized selectedPolygon={polygon} /> */}
                </div>
                <div className='package-name plans'>
                  <p>{farm.area.toFixed(2)} ha</p>
                </div>
                <div className='location-icon' onClick={() => { setSelectedPolygon(polygon) }} >
                  <GoLocation />
                </div>

          <div className='polygon-info'>
            <p>Farm: <strong>{farm.name}</strong></p>
            <p>Crop: <strong>{farm.crop}</strong></p>
            <p>Farmer: <strong>{farm.farmer.lastName}</strong></p>
            <button onClick={() => {
              setSelectedFarm(farm)
              setSelectedPolygon(polygon)
            }}

            >See more</button>
          </div>
        </div>
      ))}
      </div>
    </div>
  ))
}


  </section>
          {selectedFarm && (
            <div className="farmpopup ">
              <FarmPopup
                farm={selectedFarm}
                onUpdateFarm={handleFarmUpdate} 
                onClose={() => setSelectedFarm(null)}
              />
            </div>
          )}
        </div>

        <div style={{ width: `${rightWidth}%` }}>
          <MyFarms selectedPolygon={selectedPolygon} handlePointClick={handlePointClick} />
        </div>
      </section>


    </div>
  );
};

export default Fields;
