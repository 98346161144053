import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { IoClose } from "react-icons/io5";

import { useTranslation } from '../../../../contexts/TranslationContext';
import { useTranslation as reactTranslation } from 'react-i18next'

import { toast } from 'react-toastify';

const API_KEY = 'fbfc0b3747mshf899bf29a782bc0p1692b4jsnb7367c92d93f'

const Cycles = ({farm, pastCycles, close}) => {
const { language } = useTranslation();
const { t } = reactTranslation()
  const source = "en";
  const target = "fr";

  const [translations, setTranslations] = useState({
    ownershipLabel: 'Land ownership',
    revenueLabel: 'Revenue',
     earlinessLabel: 'Earlyness',
    managementLabel: 'Management',
    protectionLabel: 'Protection',
  });

  const translateText = async (text, targetLanguage) => {
    try {
      const response = await axios.post(
        'https://google-translate1.p.rapidapi.com/language/translate/v2',
        {
          source: 'en',
          q: text,
          target: targetLanguage,
        },
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Accept-Encoding': 'application/gzip',
            'X-RapidAPI-Host': 'google-translate1.p.rapidapi.com',
            'X-RapidAPI-Key': API_KEY,
          },
        }
      );

      return response.data.data.translations[0].translatedText;
    } catch (error) {
      console.error('Error translating text:', error);
      return text; // Return the original text in case of an error
    }
  };

  const translateContent = async () => {
    console.log("running here")
    // Define the target language code
    const targetLanguage = language;

    // Translate labels
    const translatedOwnershipLabel = await translateText('Land ownership', targetLanguage);
    const translatedRevenueLabel = await translateText('Revenue', targetLanguage);

    setTranslations({
      ownershipLabel: translatedOwnershipLabel,
      revenueLabel: translatedRevenueLabel,
      earlinessLabel: await translateText('Earlyness', targetLanguage),
      managementLabel: await translateText('Management', targetLanguage),
      protectionLabel: await translateText('Protection', targetLanguage),
    });
  };

  useEffect(() => {
    // Perform translations when the component mounts or when farm/pastCycles change
    translateContent();
  }, []);

    
  function formatDate(dateString) {
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    };
    const formattedDate = new Date(dateString).toLocaleString('en-US', options);
    return formattedDate;
  }


  const containerStyle = {
    backgroundColor: '#f0f0f0',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  };

  const headerStyle = {
    fontSize: '24px',
    fontWeight: 'bold',
    // marginBottom: '16px',
    color: '#333',
  };

  const cycleContainerStyle = {
    backgroundColor: 'white',
    padding: '16px',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    marginBottom: '16px',
  };

  const cropNameStyle = {
    fontSize: '18px',
    fontWeight: 'bold',
    marginBottom: '8px',
    color: '#333',
  };

  const dateStyle = {
    fontSize: '14px',
    color: '#666',
  };


  return (
    <div style={containerStyle}>
    {
      !pastCycles.length > 0 && (
        <>
          <h4 style={headerStyle}>No past cycles at farm {farm}</h4>
        </>
      )
    }
    {pastCycles.length > 0 && (
      <>
        {pastCycles.length > 0 && (
          <>
          <section className='flex justify-between items-center w-full mb-2'>
            <h4 style={headerStyle}>Past cycles at farm {farm}</h4>
            <button className='bg-tertiary text-white' onClick={() => close()}><IoClose color='white' /></button>

          </section>
            {pastCycles.map((cycle, index) => (
              <div className='cycle-item' key={index} style={cycleContainerStyle}>
                <p style={cropNameStyle}><strong>Crop name:</strong> {cycle.crop}</p>
                <p style={dateStyle}><strong>Started on</strong>: {formatDate(cycle.createdAt)}</p>
                <p style={dateStyle}><strong>Ended on: </strong>{formatDate(cycle.updatedAt)}</p>

                <div>
                <p style={dateStyle}><strong>{translations.earlinessLabel}:</strong> {cycle.Earlyness}</p>
                  <p style={dateStyle}><strong>{translations.managementLabel}:</strong> {cycle.Management}</p>
                  <p style={dateStyle}><strong>{translations.protectionLabel}:</strong> {cycle.Protection}</p>
                </div>

                <div>
                  <p style={dateStyle}><strong>Cadastral Municipality:</strong> {cycle.cadastralMunicipality}</p>
                  <p style={dateStyle}><strong>Cadastral Particle:</strong> {cycle.cadastralParticle}</p>
                  <p style={dateStyle}><strong>Cadastral Sheet:</strong> {cycle.cadastralSheet}</p>
                  </div>

                <div>
                  <p style={dateStyle}><strong>Area:</strong> {(cycle.area).toFixed(2)} ha</p>
                  <p style={dateStyle}><strong>Total yield expcted: </strong>{cycle.TotalYield}</p>
                  <p style={dateStyle}><strong>Yield per ha:</strong> {cycle.YieldPerha}</p>
                  </div>

                  <div>
                  <p style={dateStyle}><strong>Sowing date:</strong> {cycle.sowingDate ? formatDate(cycle.sowingDate): ''}</p>
                  <p style={dateStyle}><strong>Expected Harvest Date:</strong> {cycle.harvestDate ? formatDate(cycle.harvestDate): ''}</p>
                  <p style={dateStyle}><strong>Total price:</strong> {cycle.TotalPrice}</p>
                  </div>

                  <div>
                  <p style={dateStyle}><strong>Soil Texture:</strong> {cycle.soilTexture}</p>
                  <p style={dateStyle}><strong>Drainage:</strong> {cycle.drainage}</p>
                  <p style={dateStyle}><strong>Slope:</strong> {cycle.slope}</p>
                  </div>

                  <div>
                  <p style={dateStyle}><strong>{translations.ownershipLabel}:</strong> {cycle.ownership}</p>
                  <p style={dateStyle}><strong>{translations.revenueLabel}:</strong> {cycle.revenue}</p>
                  </div>

              </div>
            ))}

          </>
        )}
      </>
    )}
  </div>
  )
}

export default Cycles