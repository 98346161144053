import React, { useEffect, useState } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { listPackageRequests } from '../../../../../graphql/queries';
import { deletePackageRequest, updatePackageRequest } from '../../../../../graphql/mutations';
import { toast } from 'react-toastify';
import Popup from 'reactjs-popup';
import { AiFillEdit, AiOutlineClose } from 'react-icons/ai';

const Requests = ({filterValue}) => {
    const [requests, setRequests] = useState([]);
    const [loading, setLoading] = useState(true);
    const [updates, setUpdates] = useState(false);
    const [selectedTab, setSelectedTab] = useState('requested'); // Default: 'requested'
    const [expirationDate, setExpirationDate] = useState(null);
    const [areaLimit, setAreaLimit] = useState(100.00);
    const [editedAreaLimit, setEditedAreaLimit] = useState(0.00);

    console.log(filterValue)

    useEffect(() => {
        fetchRequests();
    }, [updates]);

    console.log(requests);

    const fetchRequests = async () => {
        try {
            const packagesRequestData = await API.graphql(graphqlOperation(listPackageRequests));
            //use filter to eliminate requests with null   requestedBy
            const packagesRequests = packagesRequestData.data.listPackageRequests.items.filter((request) => request.requestedBy !== null && request.package !== null);
            setRequests(packagesRequests);
            setLoading(false);
        } catch (error) {
            console.log(error);
        }
    }
    const approveRequest = async (id) => {
        // Ask for confirmation before proceeding
        const confirmUpdate = window.confirm('Are you sure you want to approve the request?');

        if (!confirmUpdate) {
            return; // If the user cancels the confirmation, exit the function
        }

        if( !expirationDate){
            toast.error("Please enter  expiration date of the plan")
            return;
        }
        
        try {
          const planToBeApproved = requests.find((request) => request.id === id);
        
      
          // Check if the package to be approved is 'Premium'
          if (planToBeApproved.package.title === 'AGROGIS Starter' || planToBeApproved.package.title === 'AGROGIS Plus' ) {
            // Update the 'Premium' package without terminating other requests except 'Meteoblue Forecast'
            const userRequests = requests.filter((request) => request.requestedBy.id === planToBeApproved.requestedBy.id);
            
            // Filter out the 'Meteoblue Forecast' package from other requests
            const otherRequests = userRequests.filter((request) =>  request.id !== id);
            const otherRequestsIds = otherRequests.map((request) => request.id);
      
            // Update the 'Premium' package
          await API.graphql(
  graphqlOperation(updatePackageRequest, {
    input: {
      id,
      status: 'approved',
      active:true, 
      expirationDate: expirationDate,
      areaLimit: areaLimit
    }
  })
);

    // Terminate other requests except 'Meteoblue Forecast'
            await Promise.all(
              otherRequestsIds.map(async (id) => {
                await API.graphql(
                  graphqlOperation(updatePackageRequest, { input: { id, status: 'terminated'  , active:false,  expirationDate:'' } })
                );

              })
            );
          } else{
            // Update the package
            await API.graphql(
                graphqlOperation(updatePackageRequest, {
                  input: {
                    id,
                    status: 'approved',
                    active: true,
                    expirationDate: expirationDate,
                    areaLimit: areaLimit
                  }
                })
              );
              
          }
      
          toast.success('Request Approved Successfully');
          setUpdates(!updates);
        } catch (error) {
          console.error(error);
        }
      };
      
      

    const deleteRequest = async (id) => {
        // Ask for confirmation before proceeding
        const confirmDelete = window.confirm('Are you sure you want to delete the request?');

        if (!confirmDelete) {
            return; // If the user cancels the confirmation, exit the function
        }

        try {
            await API.graphql(graphqlOperation(deletePackageRequest, { input: { id } }))
            toast.success('Request Deleted Successfully')
            setUpdates(!updates);
        } catch (error) {
            console.log(error);
        }
    };


    //check requests whose expiration date has been ecxeeded by comparing the expiration date with current day.
    const plansToBeTerminated = requests.filter((request) => request.expirationDate < new Date().toISOString().slice(0, 10) && request.status === 'approved');

    // Terminate plans whose expiration date has been exceeded
    useEffect(() => {
        plansToBeTerminated.forEach(async (plan) => {
            try {
                await API.graphql(graphqlOperation(updatePackageRequest, { input: { id: plan.id, status: 'terminated', active: false } }))
                toast.success('Plan Terminated Successfully')
                setUpdates(!updates);
            } catch (error) {
                console.log(error);
            }
        })
    }, [plansToBeTerminated]);


   
    const terminatePlan = async (id) => {
        // Ask for confirmation before proceeding
        const confirmUpdate = window.confirm('Are you sure you want to terminate the plan?');

        if (!confirmUpdate) {
            return; // If the user cancels the confirmation, exit the function
        }

        try {
             await API.graphql(graphqlOperation(updatePackageRequest, { input: { id, status: 'terminated', active: false , expirationDate:expirationDate, areaLimit:areaLimit} }))
            toast.success('Plan Terminated Successfully')
            setUpdates(!updates);
        } catch (error) {
            console.log(error);
        }
    };


    const handlePackageUpdate = async (id, newAreaLimit) => {
          // Ask for confirmation before proceeding
  const confirmUpdate = window.confirm('Are you sure you want to update the package?');

  if (!confirmUpdate) {
    return; // If the user cancels the confirmation, exit the function
  }

        try {
            await API.graphql(graphqlOperation(updatePackageRequest, { input: { id, expirationDate: expirationDate, areaLimit: newAreaLimit, isAreaRequest: false , areaRequested: 0, status:'approved' , active:true} }))
            toast.success('Plan Updated Successfully')
            setUpdates(!updates);
     
        } catch (error) {
            console.log(error);
        }
    };

    const handleUpdateAreaLimit = async (id) => {
          // Ask for confirmation before proceeding
  const confirmUpdate = window.confirm('Are you sure you want to update the package?');

  if (!confirmUpdate) {
    return; // If the user cancels the confirmation, exit the function
  }

        try {
            await API.graphql(graphqlOperation(updatePackageRequest, { input: { id, expirationDate: expirationDate, areaLimit: areaLimit, } }))
            toast.success('Plan Updated Successfully')
            setUpdates(!updates);
     
        } catch (error) {
            console.log(error);
        }
    };




    const filteredData = requests.filter((request) => request.status === selectedTab);

    const requestsCount = requests.filter((request) => request.status === 'requested').length;
    const areaRequestCount = requests.filter((request) => request.status === 'areaRequest').length;
    const approvedCount = requests.filter((request) => request.status === 'approved').length;
    const terminatedCount = requests.filter((request) => request.status === 'terminated').length;

    return (
        <div>
            <div className='header requests-titles' style={{display:'flex',justifyContent:'space-between'}}>
                <h2 
                style={{textDecoration : selectedTab ==='requested' ? 'underline': ''}}
                onClick={() => setSelectedTab('requested')}
                >Requests ({requestsCount})</h2>
                  <h2 
                style={{textDecoration : selectedTab ==='areaRequest' ? 'underline': ''}}
                onClick={() => setSelectedTab('areaRequest')}
                >Area Requests ({areaRequestCount})</h2>
                <h2
                style={{ textDecoration : selectedTab==='approved' ? 'underline': ''}}
                 onClick={() => setSelectedTab('approved')}>
                    Approved ({approvedCount})</h2>
                    <h2
                style={{ textDecoration : selectedTab==='terminated' ? 'underline': ''}}
                 onClick={() => setSelectedTab('terminated')}>
                    Terminated ({terminatedCount})</h2>
            </div>
            <div className='package-sales-plans'>
                {
                    loading ? <p>Loading...</p> : (
                      filteredData.length === 0 ? <p>No data</p> : (
                            filteredData.filter((request)=> {return request.requestedBy.firstName.toLowerCase().includes(filterValue.toLowerCase()) || request.requestedBy.lastName.toLowerCase().includes(filterValue.toLowerCase())
                            }).map((request, index) => (
                                <div key={index} className='package-card'>
                                    <p>{request.package.title}</p>
                                    <p>{request.requestedBy.firstName}</p>
                                    <p>{request.requestedBy.phone}</p>
                                    <p>{request.status}</p>
                                    {
                                        // input date here for package expiration
                                        request.status === 'requested' ?(
                                            <>
                                            <h4
                                            style={{marginBottom:'10px'}}
                                            >Expiration date</h4>
                                                <input
                                                style={{height:'30px',width:'200px', marginBottom:'10px'}}
                                                 type='date' 
                                                 onChange={(e)=> setExpirationDate(e.target.value)}/>
 
                                            </>
                                        ): request.status === 'approved' || "areaRequest" ? (
                                            <>
                                            <h4
                                            style={{marginBottom:'10px'}}
                                            >Expiration date</h4>
                                               <p> {request.expirationDate}</p>
                                                    <section className='area-limit-section'>
                                                        <div>

                                                            <h4
                                                                style={{ marginBottom: '10px' }}
                                                            >Area Limit</h4>
                                                            <p> {request.areaLimit} ha</p>
                                                        </div>
                                                        {
                                                            request.isAreaRequest ? (
                                                        
                                                        <div>

                                                            <h4
                                                                style={{ marginBottom: '10px' }}
                                                            >Area Requested</h4>
                                                            <p> {request.areaRequested} ha</p>
                                                        </div>
                                                            ) : <></>
}
                                                    </section>
                                            
                                            <Popup
                                            trigger={
                                              request.status === 'approved' ? (
                                                <AiFillEdit  style={{position: 'absolute', right: '10px', top: '10px', cursor: 'pointer'}} />
                                                ) : <></>
                                            
                                            }
                                            modal
                                            nested
                                            zIndex={99999999}
                                            contentStyle={{
                                                
                                                //padding style
                                                padding: "20px",
                                            }}
                                        >
                                            {close => (
                                                <div className='popup-container' style={{position:'relative', padding: "20px"}}>
                                                    <h3>Edit Expiration date</h3>
                                                    <input
                                                    style={{height:'30px',width:'200px', marginBottom:'10px'}}
                                                     type='date' 
                                                    
                                                     onChange={(e)=> setExpirationDate(e.target.value)}/>
                                                    <h3>Edit Area Limit</h3>
                                                    <input 
                                                    style={{height:'30px',width:'200px', marginBottom:'10px'}}
                                                    type='number'
                                                    placeholder='Area Limit'
                                                  
                                                    onChange={(e)=> setAreaLimit(e.target.value)}
                                                    />
                                                    <div>
                                                    <button
                                                    style={{height:'30px',width:'200px', marginBottom:'10px', backgroundColor:'#339475', color:'white'}}
                                                    onClick={() => handleUpdateAreaLimit(request.id)}
                                                    >
                                                        Save
                                                    </button>
                                                    </div>
                                                  <AiOutlineClose onClick={close} style={{position: 'absolute', right: '5px', top: '5px', cursor: 'pointer',}} />
                                                </div>
                                            )}
                                            </Popup>
                                            </>
                                            
                                        ) : <></>

                                        
                                  }
                                    <section className='add-pfarmpopup__buttonsackage'>
                                        {
                                            request.status === 'approved'  ? (
                                                <button
                                                    className='package-button'
                                                    onClick={() => terminatePlan(request.id)}
                                                >
                                                    Terminate plan
                                                </button>
                                            ) : request.status === 'areaRequest'  ? (
                                                <button
                                                className='package-button'
                                                onClick={() => terminatePlan(request.id)}
                                            >
                                                   <Popup
                                            trigger={

                                              
                                                <button className='package-button'   >
                                                    Add area limit
                                                </button>
                                            
                                            }
                                            modal
                                            nested
                                            zIndex={99999999}
                                            contentStyle={{
                                                
                                                //padding style
                                                padding: "20px",
                                            }}
                                        >
                                            {close => (
                                                <div className='popup-container' style={{position:'relative', padding: "20px"}}>
                                                    <h3>Edit Expiration date</h3>
                                                    <input
                                                    style={{height:'30px',width:'200px', marginBottom:'10px'}}
                                                     type='date' 
                                                     value={request.expirationDate}
                                                     onChange={(e)=> setExpirationDate(e.target.value)}/>
                                                    <h3>New Area Limit</h3>
                                                    <input 
                                                    style={{height:'30px',width:'200px', marginBottom:'10px'}}
                                                    type='number'
                                                    placeholder='Area Limit'
                                                   value={request.areaLimit + request.areaRequested}
                                                    onChange={(e)=> setAreaLimit(e.target.value)}
                                                    />
                                                    <div>
                                                    <button
                                                    style={{height:'30px',width:'200px', marginBottom:'10px', backgroundColor:'#339475', color:'white'}}
                                                    onClick={() => handlePackageUpdate(request.id, request.areaLimit + request.areaRequested)}
                                                    >
                                                        Save
                                                    </button>
                                                    </div>
                                                  <AiOutlineClose onClick={close} style={{position: 'absolute', right: '5px', top: '5px', cursor: 'pointer',}} />
                                                </div>
                                            )}
                                            </Popup>
                                            </button>
                                            ): (
                                                <>
                                                <button
                                                className='package-button'
                                                onClick={() => approveRequest(request.id)}
                                            >
                                                Approve 
                                            </button>
                                            <button
                                                className='package-button'
                                                onClick={() => deleteRequest(request.id)}
                                            >
                                                Delete 
                                            </button>
                                                </>
                                            )
                                        }
                                      
                                    </section>
                                </div>
                            ))
                        )
                        
                    )
                }
            </div>
        </div>
    )
}

export default Requests;
