import React, { useEffect } from 'react'
import TopBar from '../../topbar/Topbar'
import { packagePlans } from '../../../../../fARMER/account/subscriptions/Subscriptions'
import {useState} from 'react'
import { API, graphqlOperation } from 'aws-amplify'
import { createPackages, createWeatherStation, deletePackages, deleteWeatherStation, updatePackages, updateWeatherStation } from '../../../../../graphql/mutations'
import { toast } from 'react-toastify'
import { listFarmDetails, listPackages, listWeatherStations } from '../../../../../graphql/queries'
import { AiOutlineDown, AiOutlineUp } from 'react-icons/ai'
import { fetchAllFarmDetails } from '../../../../../fARMER/fetches/FetchFarmDetails'
const WeatherStations = () => {
  const [name, setName] = useState('');
  const [farmDetailsID, setFieldID] = useState('');
  const [api, setApi] = useState('');
  const [features, setFeatures] = useState([]);
  const [newFeature, setNewFeature] = useState('');
  const [update, setUpdate] = useState(false);
  const [packages, setPackages] = useState([]);
  const [farmerID, setFarmerID] = useState('');
  const [image, setImage] = useState('');
  const [showInputs, setShowInputs] = useState(false);

  const handleAddFeature = () => {
    if (newFeature.trim() !== '') {
      setFeatures([...features, newFeature]);
      setNewFeature('');
    }
  };


  const handleRemoveFeature = (index) => {
    const updatedFeatures = [...features];
    updatedFeatures.splice(index, 1);
    setFeatures(updatedFeatures);
  };

  const fetchFarmerID = async () => {
    try{
      const packagesData = await API.graphql(graphqlOperation(listFarmDetails,{
        filter: {
            id: {
                eq: farmDetailsID,
            },
        },
      }));
        const farmDetails = packagesData.data.listFarmDetails.items[0];
        setFarmerID(farmDetails.farmerID);
    } catch (error) {
      console.log(error);
    }
  }
  //send data to backend
  const handleSubmit = async (e) => {
    e.preventDefault();
   
      fetchFarmerID();
        
    
    const data = {
      farmDetailsID,
      api,
      farmerID
    //   image: 'https://images.unsplash.com/photo-1618176729090-253077a8f948?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80'
    };

    console.log(data);

    try{
      //send data to backend
      await API.graphql(graphqlOperation(createWeatherStation, {input: data}));
      toast.success('Station added successfully');
      setUpdate(!update);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchPackages();
  }, [update]);


  //fetch data from backend
  const fetchPackages = async () => {
    try{
      const packagesData = await API.graphql(graphqlOperation(listWeatherStations));
      setPackages(packagesData.data.listWeatherStations.items);

      const nullStations = packagesData.data.listWeatherStations.items.filter((item) => item.farmDetails === null);
      nullStations.forEach(async (item) => {
        await API.graphql(graphqlOperation(deleteWeatherStation, {input: {id: item.id}}));
      });
      console.log(packagesData);
    } catch (error) {
      console.log(error);
    }
  }

  //update package
  const updatePackage = async (id) => {
    try{
      await API.graphql(graphqlOperation(updateWeatherStation, {input: {id}}));
      toast.success('Package updated successfully');
      setUpdate(!update);
    } catch (error) {
      console.log(error);
    }
  }
  //delete package
  const deletePackage = async (id) => {
    try{
      await API.graphql(graphqlOperation(deleteWeatherStation, {input: {id}}));
      toast.success('Package deleted successfully');
      setUpdate(!update);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div className='home-container'>
      <TopBar />
      <div className='packages-container'>
       
        <div className='package-sales-wrapper'>
          <section className='add-package'>
            <h2 onClick={() => setShowInputs(!showInputs)}>Add Weather Station { showInputs ? <AiOutlineUp  /> : <AiOutlineDown onClick={() => setShowInputs(!showInputs)} />}</h2> 
            {
              showInputs && 
              <div className='add-package-wrapper'>
              
                <div className='add-package-item'>
                  <h3>Station API Key</h3>
                  <input type='text' placeholder='Station API Key' value={api} onChange={(e) => setApi(e.target.value)} />
                </div>
                <div className='add-package-item'>
                  <h3>Field ID</h3>
                  <input type='text' placeholder='Field ID' value={farmDetailsID} onChange={(e) => setFieldID(e.target.value)} required />
                </div>
               
              

                <div className='add-package-item'>
                  <button onClick={handleSubmit}>Add Station</button>
                </div>
              </div>
            }


          </section>

          <section className=''>
            <h2>Weather Station</h2>
            <div className='package-sales-plans'>
              {
                packages.length === 0 && (
                  <h3>No stations available</h3>
                )

              }
              {packages && packages.map((plan, index) => (
                <div key={index} className='package-card'>
               
                  {/* <h2 className="package-title">{plan.title}</h2> */}
                  {/* <p className="package-description">{plan.description}</p> */}
                  <h3 className='package-price'>Field ID :{plan.farmDetailsID}</h3>
                    <h3 className='package-price'>Field Name :{plan.farmDetails.name}</h3>
                    {/* <h3 className='package-price'>Farmer Name :{plan.farmer.firstName}</h3> */}
                  <div className='package-bottom'>
                   
                    <button className='package-button'>Edit</button>
                    <button className='package-button' onClick={()=> deletePackage(plan.id)}>Delete</button>
                  </div>
                </div>
              ))}
            </div>

          </section>
        </div>
        </div>
    </div>
  )
}

export default WeatherStations