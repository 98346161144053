// cropData.js
export const cropOptions = [
    {
      name: 'Abyssin cabbage',
      cropUses: ['Biomass (whole plant)', 'Fiber', 'Human consumption (grain)', 'Oil', 'Seeds (grain)'],
    },
    {
      name: 'Actinidia',
      cropUses: ['Fruit (fresh)', 'Fruit (processing)', 'Plant nursery', 'Seeds'],
    },
    {
      name: 'Agretti',
      cropUses: ['Plant (fresh consumption)', 'Plant (processing)', 'Seeds'],
    },
    {
      name: 'Alfalfa',
      cropUses: ['Fodder (fresh or silage)', 'Fodder (hay)', 'Green manure', 'Seeds'],
    },
    {
      name: 'Almond',
      cropUses: ['Nuts', 'Oil', 'Plant nursery'],
    },
    {
      name: 'Amaranth',
      cropUses: ['Human consumption (grain)', 'Seeds'],
    },
    {
      name: 'Apple',
      cropUses: ['Fruit (fresh)', 'Fruit (processing)', 'Plant nursery', 'Seeds'],
    },
    {
      name: 'Apricot',
      cropUses: ['Fruit (fresh)', 'Fruit (processing)', 'Plant nursery', 'Seeds'],
    },
    {
      name: 'Artemisia',
      cropUses: ['Plant (fresh consumption)', 'Plant (processing)', 'Seeds'],
    },
    {
      name: 'Artichoke',
      cropUses: ['Fruit (fresh)', 'Fruit (processing)', 'Seeds'],
    },
    {
      name: 'Asparangus',
      cropUses: ['Fruit (fresh)', 'Fruit (processing)', 'Seeds'],
    },
    {
      name: 'Avocado',
      cropUses: ['Fruit (fresh)', 'Fruit (processing)', 'Plant nursery', 'Seeds'],
    },
    {
      name: 'Bamboo',
      cropUses: ['Fiber', 'Plant nursery'],
    },
    {
      name: 'Barley',
      cropUses: ['Beer', 'Fodder (fresh or silage)', 'Fodder (grain)', 'Human consumption', 'Seeds (grain)'],
    },
    {
      name: 'Basil',
      cropUses: ['Plant (fresh consumption)', 'Plant (processing)', 'Seeds'],
    },
    {
      name: 'Bean',
      cropUses: ['Dry consumption', 'Fruit (fresh)', 'Fruit (processing)', 'Green manure', 'Seeds'],
    },
    {
      name: 'Bergamot',
      cropUses: ['Fruit (fresh)', 'Fruit (processing)', 'Plant nursery', 'Seeds'],
    },
    {
      name: 'Black-eyed peas',
      cropUses: ['Dry consumption', 'Fruit (fresh)', 'Fruit (processing)', 'Seeds'],
    },
    {
      name: 'Blackberries',
      cropUses: ['Fruit (fresh)', 'Fruit (processing)', 'Plant nursery', 'Seeds'],
    },
    {
      name: 'Blackcurrant',
      cropUses: ['Fruit (fresh)', 'Fruit (processing)', 'Plant nursery', 'Seeds'],
    },
    {
      name: 'Blueberry',
      cropUses: ['Fruit (fresh)', 'Fruit (processing)', 'Plant nursery', 'Seeds'],
    },
    {
      name: 'Buckwheat',
      cropUses: ['Biomass (whole plant)', 'Fodder (grain)', 'Human consumption (grain)', 'Seeds (grain)'],
    },
    {
      name: 'Cabbages',
      cropUses: ['Plant (fresh consumption)', 'Plant (processing)', 'Seeds'],
    },
    {
      name: 'Melon',
      cropUses: ['Fruit (fresh)', 'Fruit (processing)', 'Seeds'],
    },
    {
      name: 'Carob',
      cropUses: ['Nuts', 'Plant nursery'],
    },
    {
      name: 'Carrot',
      cropUses: ['Plant (fresh consumption)', 'Plant (processing)', 'Seeds'],
    },
    {
      name: 'Castor oil plant',
      cropUses: ['Biomass (whole plant)', 'Fiber', 'Human consumption (grain)', 'Oil', 'Seeds (grain)'],
    },
    {
      name: 'Celery',
      cropUses: ['Plant (fresh consumption)', 'Plant (processing)', 'Seeds'],
    },
    {
      name: 'Chard',
      cropUses: ['Plant (fresh consumption)', 'Plant (processing)', 'Seeds'],
    },
    {
      name: 'Chestnut',
      cropUses: ['Nuts', 'Oil', 'Plant nursery'],
    },
    {
      name: 'Chick peas',
      cropUses: ['Green manure', 'Human consumption (grain)', 'Seeds'],
    },
    {
      name: 'Chickling vetch',
      cropUses: ['Green manure', 'Human consumption (grain)', 'Seeds'],
    },
    {
      name: 'Chicory',
      cropUses: ['Plant (fresh consumption)', 'Plant (processing)', 'Seeds'],
    },
    {
      name: 'Chives',
      cropUses: ['Plant (fresh consumption)', 'Plant (processing)', 'Seeds'],
    },
    {
      name: 'Citron',
      cropUses: ['Fruit (fresh)', 'Fruit (processing)', 'Plant nursery', 'Seeds'],
    },
    {
      name: 'Clementine',
      cropUses: ['Fruit (fresh)', 'Fruit (processing)', 'Plant nursery', 'Seeds'],
    },
    {
      name: 'Clover',
      cropUses: ['Biomass (whole plant)', 'Fodder (fresh or silage)', 'Fodder (hay)', 'Green manure', 'Seeds'],
    },
    {
      name: 'Common wheat',
      cropUses: ['Biomass (whole plant)', 'Fodder (fresh or silage)', 'Fodder (grain)', 'Human consumption (grain)', 'Seeds (grain)'],
    },
    {
      name: 'Cotton',
      cropUses: ['Fiber', 'Oil', 'Seeds'],
    },
    {
      name: 'Cowpea',
      cropUses: ['Dry consumption', 'Green manure', 'Seeds'],
    },
    {
      name: 'Cumin',
      cropUses: ['Oil', 'Seeds'],
    },
    {
      name: 'Currants',
      cropUses: ['Fruit (fresh)', 'Fruit (processing)', 'Plant nursery', 'Seeds'],
    },
    {
      name: 'Date',
      cropUses: ['Fruit (fresh)', 'Fruit (processing)', 'Plant nursery', 'Seeds'],
    },
    {
      name: 'Dill',
      cropUses: ['Plant (fresh consumption)', 'Plant (processing)', 'Seeds'],
    },
    {
      name: 'Eggplant',
      cropUses: ['Plant (fresh consumption)', 'Plant (processing)', 'Seeds'],
    },
    {
      name: 'Elderberry',
      cropUses: ['Fruit (fresh)', 'Fruit (processing)', 'Plant nursery', 'Seeds'],
    },
    {
      name: 'Fenugreek',
      cropUses: ['Fiber', 'Human consumption (grain)', 'Oil', 'Seeds (grain)'],
    },
    {
      name: 'Fennel',
      cropUses: ['Plant (fresh consumption)', 'Plant (processing)', 'Seeds'],
    },
    {
      name: 'Figs',
      cropUses: ['Fruit (fresh)', 'Fruit (processing)', 'Plant nursery', 'Seeds'],
    },
    {
      name: 'Flax',
      cropUses: ['Fiber', 'Human consumption (grain)', 'Oil', 'Seeds (grain)'],
    },
    {
      name: 'Garlic',
      cropUses: ['Plant (fresh consumption)', 'Plant (processing)', 'Seeds'],
    },
    {
      name: 'Gooseberry',
      cropUses: ['Fruit (fresh)', 'Fruit (processing)', 'Plant nursery', 'Seeds'],
    },
    {
      name: 'Grape',
      cropUses: ['Fruit (fresh)', 'Fruit (processing)', 'Plant nursery', 'Seeds'],
    },
    {
      name: 'Hemp',
      cropUses: ['Fiber', 'Oil', 'Seeds'],
    },
    {
      name: 'Kale',
      cropUses: ['Plant (fresh consumption)', 'Plant (processing)', 'Seeds'],
    },
    {
      name: 'Kiwi',
      cropUses: ['Fruit (fresh)', 'Fruit (processing)', 'Plant nursery', 'Seeds'],
    },
    {
        name: 'Lavender',
        cropUses: ['Plant (fresh consumption)', 'Plant (processing)', 'Seeds'],
      },
      {
        name: 'Leek',
        cropUses: ['Plant (fresh consumption)', 'Plant (processing)', 'Seeds'],
      },
      {
        name: 'Lemon',
        cropUses: ['Fruit (fresh)', 'Fruit (processing)', 'Plant nursery', 'Seeds'],
      },
      {
        name: 'Lentil',
        cropUses: ['Dry consumption', 'Fruit (fresh)', 'Fruit (processing)', 'Green manure', 'Seeds'],
      },
      {
        name: 'Lettuce',
        cropUses: ['Plant (fresh consumption)', 'Plant (processing)', 'Seeds'],
      },
      {
        name: 'Lime',
        cropUses: ['Fruit (fresh)', 'Fruit (processing)', 'Plant nursery', 'Seeds'],
      },
      {
        name: 'Linseed',
        cropUses: ['Biomass (whole plant)', 'Fiber', 'Fodder (grain)', 'Human consumption (grain)', 'Oil', 'Seeds (grain)'],
      },
      {
        name: 'Mango',
        cropUses: ['Fruit (fresh)', 'Fruit (processing)', 'Plant nursery', 'Seeds'],
      },
      {
        name: 'Millet',
        cropUses: ['Biomass (whole plant)', 'Fodder (grain)', 'Human consumption (grain)', 'Seeds (grain)'],
      },
      {
        name: 'Mint',
        cropUses: ['Plant (fresh consumption)', 'Plant (processing)', 'Seeds'],
      },
      {
        name: 'Mung bean',
        cropUses: ['Dry consumption', 'Fruit (fresh)', 'Fruit (processing)', 'Green manure', 'Seeds'],
      },
      {
        name: 'Mushroom',
        cropUses: ['Food', 'Medicinal'],
      },
      {
        name: 'Mustard',
        cropUses: ['Biomass (whole plant)', 'Fodder (grain)', 'Human consumption (grain)', 'Oil', 'Seeds (grain)'],
      },
      {
        name: 'Nectarine',
        cropUses: ['Fruit (fresh)', 'Fruit (processing)', 'Plant nursery', 'Seeds'],
      },
      {
        name: 'Okra',
        cropUses: ['Fruit (fresh)', 'Fruit (processing)', 'Seeds'],
      },
      {
        name: 'Onion',
        cropUses: ['Plant (fresh consumption)', 'Plant (processing)', 'Seeds'],
      },
      {
        name: 'Oregano',
        cropUses: ['Plant (fresh consumption)', 'Plant (processing)', 'Seeds'],
      },
      {
        name: 'Parsley',
        cropUses: ['Plant (fresh consumption)', 'Plant (processing)', 'Seeds'],
      },
      {
        name: 'Parsnip',
        cropUses: ['Plant (fresh consumption)', 'Plant (processing)', 'Seeds'],
      },
      {
        name: 'Passion fruit',
        cropUses: ['Fruit (fresh)', 'Fruit (processing)', 'Plant nursery', 'Seeds'],
      },
      {
        name: 'Peach',
        cropUses: ['Fruit (fresh)', 'Fruit (processing)', 'Plant nursery', 'Seeds'],
      },
      {
        name: 'Pear',
        cropUses: ['Fruit (fresh)', 'Fruit (processing)', 'Plant nursery', 'Seeds'],
      },
      {
        name: 'Peas',
        cropUses: ['Fruit (fresh)', 'Fruit (processing)', 'Green manure', 'Seeds'],
      },
      {
        name: 'Pecan',
        cropUses: ['Nuts', 'Oil', 'Plant nursery'],
      },
      {
        name: 'Pepper',
        cropUses: ['Fruit (fresh)', 'Fruit (processing)', 'Seeds'],
      },
      {
        name: 'Plum',
        cropUses: ['Fruit (fresh)', 'Fruit (processing)', 'Plant nursery', 'Seeds'],
      },
      {
        name: 'Pomegranate',
        cropUses: ['Fruit (fresh)', 'Fruit (processing)', 'Plant nursery', 'Seeds'],
      },
      {
        name: 'Potato',
        cropUses: ['Plant (fresh consumption)', 'Plant (processing)', 'Seeds'],
      },
      {
        name: 'Pumpkin',
        cropUses: ['Fruit (fresh)', 'Fruit (processing)', 'Seeds'],
      },
      {
        name: 'Quince',
        cropUses: ['Fruit (fresh)', 'Fruit (processing)', 'Plant nursery', 'Seeds'],
      },
      {
        name: 'Radish',
        cropUses: ['Plant (fresh consumption)', 'Plant (processing)', 'Seeds'],
      },
      {
        name: 'Raspberry',
        cropUses: ['Fruit (fresh)', 'Fruit (processing)', 'Plant nursery', 'Seeds'],
      },
      {
        name: 'Red beet',
        cropUses: ['Plant (fresh consumption)', 'Plant (processing)', 'Seeds'],
      },
      {
        name: 'Red currant',
        cropUses: ['Fruit (fresh)', 'Fruit (processing)', 'Plant nursery', 'Seeds'],
      },
      {
        name: 'Rhubarb',
        cropUses: ['Plant (fresh consumption)', 'Plant (processing)', 'Seeds'],
      },
      {
        name: 'Rosemary',
        cropUses: ['Plant (fresh consumption)', 'Plant (processing)', 'Seeds'],
      },
      {
        name: 'Rye',
        cropUses: ['Biomass (whole plant)', 'Fodder (fresh or silage)', 'Fodder (grain)', 'Human consumption (grain)', 'Seeds'],
      },
      {
        name: 'Saffron',
        cropUses: ['Plant (fresh consumption)', 'Plant (processing)', 'Seeds'],
      },
      {
        name: 'Sage',
        cropUses: ['Plant (fresh consumption)', 'Plant (processing)', 'Seeds'],
      },
      {
        name: 'Savoy cabbage',
        cropUses: ['Plant (fresh consumption)', 'Plant (processing)', 'Seeds'],
      },
      {
        name: 'Shallot',
        cropUses: ['Plant (fresh consumption)', 'Plant (processing)', 'Seeds'],
      },
      {
        name: 'Sorghum',
        cropUses: ['Biomass (whole plant)', 'Fodder (grain)', 'Human consumption (grain)', 'Seeds (grain)'],
      },
      {
        name: 'Soybean',
        cropUses: ['Biomass (whole plant)', 'Fodder (grain)', 'Human consumption (grain)', 'Seeds (grain)'],
      },
      {
        name: 'Spinach',
        cropUses: ['Plant (fresh consumption)', 'Plant (processing)', 'Seeds'],
      },
      {
        name: 'Strawberry',
        cropUses: ['Fruit (fresh)', 'Fruit (processing)', 'Plant nursery', 'Seeds'],
      },
      {
        name: 'Sugar beet',
        cropUses: ['Plant (fresh consumption)', 'Plant (processing)', 'Seeds'],
      },
      {
        name: 'Sunflower',
        cropUses: ['Biomass (whole plant)', 'Fodder (grain)', 'Human consumption (grain)', 'Oil', 'Seeds (grain)'],
      },

  {
    name: 'Sweet cherry',
    cropUses: ['Fruit (fresh)', 'Fruit (processing)', 'Plant nursery', 'Seeds'],
  },
  {
    name: 'Sweet corn',
    cropUses: ['Biomass (whole plant)', 'Fodder (fresh or silage)', 'Fodder (grain)', 'Human consumption (grain)', 'Seeds (grain)'],
  },
  {
    name: 'Sweet potato',
    cropUses: ['Plant (fresh consumption)', 'Plant (processing)', 'Seeds'],
  },
  {
    name: 'Sweetgum',
    cropUses: ['Wood production'],
  },
  {
    name: 'Tangerine',
    cropUses: ['Fruit (fresh)', 'Fruit (processing)', 'Plant nursery', 'Seeds'],
  },
  {
    name: 'Thyme',
    cropUses: ['Plant (fresh consumption)', 'Plant (processing)', 'Seeds'],
  },
  {
    name: 'Tomato',
    cropUses: ['Fruit (fresh)', 'Fruit (processing)', 'Seeds'],
  },
  {
    name: 'Turmeric',
    cropUses: ['Human consumption', 'Medicinal', 'Spice'],
  },
  {
    name: 'Walnut',
    cropUses: ['Nuts', 'Oil', 'Plant nursery'],
  },
  {
    name: 'Watermelon',
    cropUses: ['Fruit (fresh)', 'Fruit (processing)', 'Seeds'],
  },
  {
    name: 'Wheat',
    cropUses: ['Biomass (whole plant)', 'Fodder (fresh or silage)', 'Fodder (grain)', 'Human consumption (grain)', 'Seeds'],
  },
  {
    name: 'White cabbage',
    cropUses: ['Plant (fresh consumption)', 'Plant (processing)', 'Seeds'],
  },
  {
    name: 'White currant',
    cropUses: ['Fruit (fresh)', 'Fruit (processing)', 'Plant nursery', 'Seeds'],
  },
  {
    name: 'White mustard',
    cropUses: ['Biomass (whole plant)', 'Fodder (grain)', 'Human consumption (grain)', 'Oil', 'Seeds (grain)'],
  },
  {
    name: 'White wine grape',
    cropUses: ['Fruit (fresh)', 'Fruit (processing)', 'Plant nursery', 'Seeds'],
  },
  {
    name: 'Zucchini',
    cropUses: ['Fruit (processing)', 'Fruit (fresh)','Plant nursery', 'Seeds'],
  },

  {
    name: 'Gooseberry',
    cropUses: ['Fruit (processing)', 'Fruit (fresh)', 'Plant nursery', 'Seeds'],
  },
  {
    name: 'Gourd',
    cropUses: ['Fruit (processing)', 'Seeds'],
  },
  {
    name: 'Jerusalem artichoke',
    cropUses: ['Fruit (processing)', 'Seeds'],
  },
  {
    name: 'Jujube',
    cropUses: ['Fruit (processing)', 'Seeds'],
  },
  {
    name: 'Kohlrabi',
    cropUses: ['Fruit (processing)', 'Seeds'],
  },
  {
    name: 'Mulberry',
    cropUses: ['Biomass (whole plant)', 'Fodder (fresh or silage)', 'Fodder (grain)', 'Human consumption (grain)', 'Seeds'],
  },
  {
    name: 'Oat',
    cropUses: ['Biomass (whole plant)', 'Fodder (fresh or silage)', 'Fodder (grain)', 'Human consumption (grain)', 'Seeds'],
  },
  {
    name: 'Oil palm',
    cropUses: ['Fruit (processing)', 'Seeds'],
  },
  {
    name: 'Papaya',
    cropUses: ['Nuts', 'Oil', 'Plant nursery'],
  },
  {
    name: 'Pistachio',
    cropUses: ['Nuts', 'Oil', 'Plant nursery'],
  },
  {
    name: 'Radicchio',
    cropUses: ['Fruit (processing)', 'Seeds'],
  },
  {
    name: 'Sesame',
    cropUses: ['Biomass (whole plant)', 'Seeds'],
  },
  {
    name: 'Sorrel',
    cropUses: ['Fruit (processing)', 'Biomass (whole plant)', 'Fodder (grain)'],
  },
  {
    name: 'Turnip',
    cropUses: ['Plant (fresh consumption)', 'Plant (processing)', 'Seeds'],
  },
  {
    name: 'Watercress',
    cropUses: ['Fruit (processing)', 'Seeds'],
  },
  {
    name: 'Kiwifruit',
    cropUses: ['Fruit (processing)', 'Seeds'],
  },
  {
    name: 'Mandarin',
    cropUses: ['Fruit (processing)', 'Seeds'],
  },
  {
    name: 'Orange',
    cropUses: ['Fruit (processing)', 'Seeds'],
  },
  ];
  
  export default cropOptions;
  