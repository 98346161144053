import React from 'react';

const CropAreaGraph = ({ farmDetailsData }) => {
  const totalArea = farmDetailsData.reduce((sum, item) => sum + item.area, 0);

  const cropPercentages = farmDetailsData.map(item => ({
    name: item.name,
    area: item.area,
    percentage: (item.area / totalArea) * 100
  }));

  const colors = ['#ff0000', '#00ff00', '#0000ff', '#ffff00', '#00ffff', '#ff00ff', '#ff8000', '#8000ff', '#0080ff', '#ff0080', '#80ff00', '#00ff80', '#800000', '#008000', '#000080', '#808000', '#800080', '#008080', '#808080', '#ff8080', '#80ff80', '#8080ff', '#ffff80', '#80ffff', '#ff80ff', '#ff0000', '#00ff00', '#0000ff', '#ffff00', '#00ffff', '#ff00ff', '#ff8000', '#8000ff', '#0080ff', '#ff0080', '#80ff00', '#00ff80', '#800000', '#008000', '#000080', '#808000', '#800080', '#008080', '#808080', '#ff8080', '#80ff80', '#8080ff', '#ffff80', '#80ffff', '#ff80ff'];

  const radius = 80;
  const circumference = 2 * Math.PI * radius;

  let startAngle = 0;

  return (
    <div style={{  alignItems: 'center',    width:'100%'   , display: 'flex', justifyContent: 'center', flexDirection: 'column'
  }}>
      <div style={{ position: 'relative', width: '160px', height: '160px' }}>
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 200 200"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="100" cy="100" r={radius} fill="transparent" stroke="lightgray" strokeWidth="10" />
          {cropPercentages.map((cropPercentage, index) => {
            const sliceAngle = (cropPercentage.percentage / 100) * 360;
            const color = colors[index % colors.length];

            const endAngle = startAngle + sliceAngle;

            const startAngleRadians = (startAngle * Math.PI) / 180;
            const endAngleRadians = (endAngle * Math.PI) / 180;

            const startX = 100 + radius * Math.cos(startAngleRadians);
            const startY = 100 + radius * Math.sin(startAngleRadians);
            const endX = 100 + radius * Math.cos(endAngleRadians);
            const endY = 100 + radius * Math.sin(endAngleRadians);

            const largeArcFlag = sliceAngle <= 180 ? 0 : 1;

            const pathData = `M 100 100 L ${startX} ${startY} A ${radius} ${radius} 0 ${largeArcFlag} 1 ${endX} ${endY} Z`;

            startAngle += sliceAngle;

            return <path key={cropPercentage.name} d={pathData} fill={color} />;
          })}
        </svg>
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: '70%',
            height: '70%',
            borderRadius: '50%',
            backgroundColor: 'white',
            fontSize: '14px',
            fontWeight: 'bold',
            color: 'black',
            textAlign: 'center'
          }}
        >
          {totalArea.toFixed(2)} hectares
        </div>
      </div>
      <div style={{ marginLeft: '20px', width:'60%' }}>
      
         
            <div  style={{ display: 'flex', alignItems: 'center', marginBottom: '5px', width:'100%' }}>
              {/* <div style={{ width: '20px', height: '20px', backgroundColor: color, marginRight: '10px' }}></div> */}
              <div style={{width:"100%", display: 'flex', alignItems: 'center', flexDirection:'column'}}>
              
              <h3 style={{margin:"3px", fontSize:'13px'}}> 
              Total farms mapped today
              </h3> 
              <p>
                8
              </p>
              <div style={{width:"100%", display: 'flex', alignItems: 'center', justifyContent:'space-between'}}>
                <div>
              <h3 style={{margin:"3px", fontSize:'13px'}}>
              Target
              </h3>
              <p>
                10
              </p>
              </div>
              <div>
              <h3 style={{margin:"3px", fontSize:'13px'}}>
              Last Week
              </h3>
              <p>
               14
              </p>
              </div>
              <div>
              <h3 style={{margin:"3px", fontSize:'13px'}}>
              Last Month
              </h3>
              <p>
                30
              </p>
              </div>
              </div>
              </div>
            </div>
       
       
      </div>
    </div>
  );
};

export default CropAreaGraph;
