import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import FarmerTopBar from "../../components/topbar/Topbar";
import "./farm.css";
import { Helmet } from "react-helmet";
import FarmList from "./FarmRegistration";
import { AiFillCaretLeft, AiFillCaretRight } from "react-icons/ai";
import FarmRegistration from "./FarmRegistration";
import DrawingMap from "./drawer/drawing";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../../../lib/init-firebase";
import { API, graphqlOperation } from "aws-amplify";
import { createFarmDetails } from "../../../graphql/mutations";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { FaDrawPolygon } from "react-icons/fa";
import { fetchFarmDetails } from "../../fetches/FetchFarmDetails";
import { farmerID } from "../../../userid";
import { listPackageRequests } from "../../../graphql/queries";
import { useTranslation } from "react-i18next";

const DrawingMapMemoized = React.memo(DrawingMap);

const Maping = () => {
  const navigate = useNavigate();
  const [farm, setfarms] = useState([]);
  const [totalArea, setTotalArea] = useState(0);
  const [farmArea, setFarmArea] = useState(0);
  const [plan, setPlan] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [formLoading, setFormLoading] = useState(false);
  const [defPackage, setPackageName] = useState(
    localStorage.getItem("packageName")
  );
  const [areaLimit, setAreaLimit] = useState(10);
  const { t } = useTranslation();

  //fetch farmer id from local storage and use json parse to convert it to an object
  //fetch package name from local storage

  useEffect(() => {
    async function fetchData() {
      const farmDetailsData = await fetchFarmDetails();
      setfarms(farmDetailsData);
      setTotalArea(farmDetailsData.reduce((acc, curr) => acc + curr.area, 0));
      // console.log(farmDetailsData);
    }

    fetchData();
  }, []);

  // console.log(farm);

  useEffect(() => {
    fetchPlan();
  }, []);
  const fetchPlan = async () => {
    try {
      const packagesRequestData = await API.graphql(
        graphqlOperation(listPackageRequests, {
          filter: {
            requesterID: {
              eq: localStorage.getItem("farmerID"),
            },
            active: {
              eq: true,
            },
          },
        })
      );
      setPlan(packagesRequestData.data.listPackageRequests.items);
      if (packagesRequestData.data.listPackageRequests.items.length > 0) {
        const firstPackageTitle =
          packagesRequestData.data.listPackageRequests.items[0].package.title;

        if (
          firstPackageTitle === "Meteoblue Forecast" &&
          packagesRequestData.data.listPackageRequests.items.length > 1
        ) {
          setSelectedPlan(
            packagesRequestData.data.listPackageRequests.items[1]
          );
          setAreaLimit(
            packagesRequestData.data.listPackageRequests.items[1].areaLimit
          );

          //set the package name to local storage
          localStorage.setItem(
            "packageName",
            packagesRequestData.data.listPackageRequests.items[1].package.title
          );
        } else {
          setSelectedPlan(
            packagesRequestData.data.listPackageRequests.items[0]
          );
          setAreaLimit(
            packagesRequestData.data.listPackageRequests.items[0].areaLimit
          );
          //set the package name to local storage
          localStorage.setItem("packageName", firstPackageTitle);
        }
      } else {
        // Handle the case when there are no items in the list
        setSelectedPlan("AGROGIS Freemium"); // Set a default plan or handle as needed
      }

      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  console.log(selectedPlan);
  let packageName = localStorage.getItem("packageName");

  const [leftWidth, setLeftWidth] = useState(50);
  const [rightWidth, setRightWidth] = useState(50);
  const [formData, setFormData] = useState({
    name: "",
    crop: "",
    cropUse: "",
    Earlyness: "",
    Protection: "",
    TotalYield: "",
    YieldPerha: "",
    sowingDate: "",
    harvestDate: "",
    soilTexture: "",
    drainage: "",
    Management: "",
    slope: "",
    revenue: "",
    notes: "",
    startDate: "",
    ownership: "",
    cadastralMunicipality: "",
    cadastralSheet: null,
    cadastralParticle: null,
    // spatialReference: {},
    // rings: [],
  });

  const handleRightArrowClick = () => {
    setLeftWidth(leftWidth + 10);
    setRightWidth(rightWidth - 10);
  };

  const handleLeftArrowClick = () => {
    setLeftWidth(leftWidth - 10);
    setRightWidth(rightWidth + 10);
  };

  const handleSend = async () => {
    setFormLoading(true);
    if (!formData.name) {
      toast.error(t("toastMessages4.EnterFName"));
      setFormLoading(false);
      return;
    }
    //check if total area exceeds 100ha
    if (selectedPlan) {
      let limit = 0;

      if (defPackage === "AGROGIS Freemium") {
        limit = 100;
      } else if (selectedPlan.package.title === "AGROGIS Starter") {
        limit = selectedPlan.areaLimit;
      } else if (selectedPlan.package.title === "AGROGIS Plus") {
        limit = selectedPlan.areaLimit;
      } else if (selectedPlan.package.title === "AGROGIS Eye") {
        limit = selectedPlan.areaLimit;
      } else if (selectedPlan.package.title === "AGROGIS Eye Plus") {
        limit = selectedPlan.areaLimit;
      }
      if (packageName === null) {
        toast.error(t("tostsMessages4.selectPackage"));
        setFormLoading(false);
        return;
      }

      if (totalArea + formData.area > areaLimit) {
        toast.error(t("toastMessages4.totalAreaExceeds"));
        setFormLoading(false);
        return;
      }
    }

    //check if rings is empty
    if (!formData.rings) {
      toast.info(
        () => (
          <div className="custom-toast">
            <p style={{ color: "black", marginBottom: "10px" }}>
              {t("please draw farm by clicking this icon")}
            </p>
            <FaDrawPolygon style={{ transform: "rotate(-90deg)" }} />
          </div>
        ),
        {
          toastClassName: "custom-toast-container",
        }
      );
      setFormLoading(false);
      return;
    }
    try {
      // const docRef = await addDoc(collection(db, 'polygons'), formData)
      // console.log('Document written with ID: ', docRef.id);
      const farmDetails = {
        ...formData,
        farmerID: localStorage.getItem("farmerID"),
        gmail: localStorage.getItem("farmerEmail"),
        spatialReference: JSON.stringify(formData.spatialReference),
        rings: JSON.stringify(formData.rings),
        area: formData.area,
        active: true,
        byoc: "empty",
        //cadastralSheet is interger
      };

      if (farmDetails.farmerID === null) {
        toast.error(t("toastMessages5.errorAddingFarm"));
        setFormLoading(false);
        return;
      }

      await API.graphql(
        graphqlOperation(createFarmDetails, { input: farmDetails })
      );

      console.log(farmDetails);
      console.log(formData.rings);

      // console.log(farmDetails)
      toast.success(t("toastMessages5.farmAddedSucess"));
      //add it to existing farmDetails in local storage data
      const existingFarmDetails = JSON.parse(
        sessionStorage.getItem("farmDetails")
      );
      const updatedFarmDetails = [...existingFarmDetails, farmDetails];
      sessionStorage.setItem("farmDetails", JSON.stringify(updatedFarmDetails));
      //navigate to farms page
      navigate("/myfarm");
    } catch (error) {
      console.error("Error adding document: ", error);
      toast.error(t("toastMessages5.errorAddingFarm"));
    }

    // console.log(formData)
  };

  const handleFarmRegistration = (name, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleGeometry = (polygonData) => {
    if (polygonData) {
      const flattenedRings = polygonData.rings[0].map((point) => ({
        latitude: point[0],
        longitude: point[1],
      }));

      // const modifiedPolygonData = {
      //   spatialReference: polygonData.spatialReference,
      //   rings: flattenedRings
      // };

      setFormData((prevFormData) => ({
        ...prevFormData,
        spatialReference: polygonData.spatialReference,
        rings: flattenedRings,
        area: polygonData.area,
      }));
    }
  };

  return (
    <div className="home-container">
      <FarmerTopBar />
      <Helmet>
        <link
          rel="stylesheet"
          href="https://js.arcgis.com/4.26/esri/themes/light/main.css"
        />
      </Helmet>
      {/* <h1 style={{ margin: 0 }}>My farm</h1> */}
      <section className="mymap-wrapper">
        <div className="left-myMap" style={{ position: "relative" }}>
          <div className="center-buttons">
            <button onClick={handleLeftArrowClick}>
              <AiFillCaretLeft />
            </button>
            <button onClick={handleRightArrowClick}>
              <AiFillCaretRight />
            </button>
          </div>
          <FarmRegistration
            handleInputChange={handleFarmRegistration}
            handleSend={handleSend}
            formData={formData}
            formLoading={formLoading}
          />
        </div>

        <div className="right-myMap farm-registration">
          <DrawingMapMemoized
            handleGeometry={handleGeometry}
            farm={farm}
            totalArea={totalArea}
            packageName={packageName}
            selectedPlan={selectedPlan}
            areaLimit={areaLimit}
          />
        </div>
      </section>
    </div>
  );
};

export default Maping;
