import React from 'react';
import { useNavigate } from 'react-router-dom';

const SingleAccount = ({ farmer, area, requestsCount, showPackages }) => {
  const navigate = useNavigate();
  console.log(showPackages);
  // Calculate the age of the account
  const calculateAccountAge = () => {
    // Assuming the "customID" is the account creation date
    const accountCreationDate = new Date(farmer.customID);
    const currentDate = new Date();
    const ageInMilliseconds = currentDate - accountCreationDate;
    const ageInDays = Math.floor(ageInMilliseconds / (1000 * 60 * 60 * 24));
    return ageInDays;
  };

  return (
    <div className="single-account-container">
      <h2 className="account-heading">{`${farmer.firstName} ${farmer.lastName}`}</h2>
      <p className="account-email">Email: {farmer.email}</p>
      <p className="account-farms">Farms: {area}</p>
      <p className="account-drone-requests">Drone Requests: {requestsCount}</p>
      <p className="account-age">Member Since: {new Date(farmer.createdAt).toLocaleString()}</p>

      <h4>Active Packages</h4>
      {
        showPackages.length === 0 && (
          <p className="account-no-packages">No packages active</p>
        )
      }
      {
        showPackages && (
          showPackages.map((item) => (
            <div key={item.id} className="account-package">
              <p className="account-package-title">{item.title}</p>
            </div>
          ))
        )
       
      }
      <footer>
        <button className="buy-package">Farms</button>
        <button onClick={() => navigate('/upload')} className="buy-package">Requests</button>
      </footer>
    </div>
  );
};

export default SingleAccount;
