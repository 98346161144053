import React from 'react'
import { FiLoader } from 'react-icons/fi'
import { BiError } from 'react-icons/bi'
import './NoDataAnimation.css'
import { useTranslation } from 'react-i18next'

const NoDataComponent = () => {
  const { t } = useTranslation()
  return (
    <div className="container">
      <FiLoader className="rotate" />
      <BiError className="bounce" />
      <h1 style={{ fontSize: '16px' }}>{t('no Data Available')}</h1>
    </div>
  )
}

export default NoDataComponent
