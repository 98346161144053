import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { landSat } from './fieldsList';
import FarmerTopBar from '../../components/topbar/Topbar';
import MyFarms from '../farms/myFields/MyFarms';
import { useTranslation } from 'react-i18next'
const Nvdi = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const singleLandst = landSat.find((item) => item.id === parseInt(id));

    const [leftWidth, setLeftWidth] = useState(50);
    const [rightWidth, setRightWidth] = useState(50);
    const { t } = useTranslation()
  
    const handleRightArrowClick = () => {
      setLeftWidth(leftWidth +10);
      setRightWidth(rightWidth - 10);
    };
  
    const handleLeftArrowClick = () => {
      setLeftWidth(leftWidth - 10);
      setRightWidth(rightWidth + 10);
      
    };
  
  return (
    <div className='home-container'>
    <FarmerTopBar />
    <Helmet>
      <link
        rel="stylesheet"
        href="https://js.arcgis.com/4.26/esri/themes/light/main.css"
      />
    </Helmet>
    
    <section className='mymap-wrapper'>
      

      <div style={{ width: `${leftWidth}%` , position:'relative' , paddingRight:'20px' }}>
      {/* <div className='center-buttons'>
        <button onClick={handleLeftArrowClick}><AiFillCaretLeft /></button>
        <button onClick={handleRightArrowClick}><AiFillCaretRight /></button>
      </div> */}
       <div className="weather-card">
            <div className="weather-card__info">
              <div className="weather-card__image">
                <img src={singleLandst.image} alt={singleLandst.crop} />
                <div className='crop-name'>
                  <p>{singleLandst.crop}</p>
              </div>
              </div>
             
              <div className="weather-card__details"> 
                <p className="weather-card__description">{t('area')}: {singleLandst.area} ha</p>
                <p className="weather-card__description">{t('perimeter')}: {singleLandst.perimeter}</p>
               
              </div>
            </div>
            <div className='nvdi-graph'>
              <img src='https://csis-website-prod.s3.amazonaws.com/s3fs-public/211004_Bermudez_Table_2.png?uynAiJpf9jJy7I6_2obtmeF343GvSv7y' alt={singleLandst.crop} />
            </div>
          </div>
       
      </div>

      <div style={{ width: `${rightWidth}%` }}>
        <MyFarms />
      </div>
    </section>
  </div>
  )
}

export default Nvdi