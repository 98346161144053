import React, { useState, useEffect, useMemo } from 'react';

import Slider from 'react-slick';
import { Slide } from 'react-slideshow-image';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { API, graphqlOperation } from 'aws-amplify';
import { listFarmDetails } from '../../../../graphql/queries';
import { WeatherCard } from '../../../account/weather/FinalWeatherList';
import { useNavigate } from 'react-router-dom';
import { GiForest } from 'react-icons/gi';
import { BsCloudLightningRain } from 'react-icons/bs';
import { toast } from 'react-toastify';
import NoNewsComponent from '../../../null/NoNews';
import { FaCloudSun } from 'react-icons/fa';
import { useTranslation } from 'react-i18next'


const WeatherSlider = ({farmDetailsData, shouldDisplayWeather}) => {
  const { t } = useTranslation()
  const [trigger, setTrigger] = useState(false);
  useEffect(() => {
    setTrigger(true);
  }, []);
const navigate = useNavigate();

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const farmDetails = useMemo(() => {
    console.log("Memoizing farm details");
    return farmDetailsData.map(farm => {
      const farmData = JSON.parse(farm.rings)[0];
      return {
        ...farm,
        lat: farmData.latitude,
        lon: farmData.longitude
      };
    });
  }, [farmDetailsData]);



  return (
    <div className='weather-slider' >
      {
        shouldDisplayWeather ? (

      <Slider {...settings}>
      {
        farmDetails.map((farm, index) => (
          <div key={index}>
              {/* <div className='farm-slider-header'>
              <section className='farm-slider-header-left'>
              <img src={'/Logo/PNG/weather_icon_homepage.png'} alt="profile" className="icon" />
              <div className='home-cards'>
                  <h3 style={{display: 'block'}}>{farm.name}</h3>
                  <p>{farm.crop}</p>
                </div>
             
              </section>

              <p onClick={()=>
              navigate(`/weather`)
              } className='farm-slider-btn'>{t('see more')}</p>
              </div> */}
            <WeatherCard polygon={farm} trigger={trigger} shouldDisplayWeather={shouldDisplayWeather}/>
          </div>
        ))
      }
      </Slider>
        ): (
          <NoNewsComponent pTag={t('Subscribe to Virtual Weather Station package to view weeather details of your farms')} icon={<img src={'/Logo/PNG/weather_icon_homepage.png'} alt="profile" className="w-20 h-20 m-auto" />} h2Tag={'Access Denied'}/>

        )
      }   
    </div>
  );
};

export default WeatherSlider;
